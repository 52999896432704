import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { UserQuery } from 'src/app/state/userState/user.query';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { IUserGame } from 'src/app/models/api.model';
import { AvatarService } from 'src/app/services/avatar-customization.service';
import { GameService } from 'src/app/state/gameState/game.service';
import { AudioService } from 'src/app/services/audio.service';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { EmptyScene } from 'src/app/phaser/scenes/empty.scene';
import { ITopic } from 'src/app/models/api.model';

@Component({
  selector: 'app-multiplayer-game-results',
  templateUrl: './multiplayer-game-results.page.html',
  styleUrls: ['./multiplayer-game-results.page.scss'],
})
export class MultiplayerGameResultsPage implements OnInit {
  @ViewChildren('avatar') avatarComponentChildren: QueryList<any>;
  confetti: AnimationOptions = {
    path: '/assets/game_results/confetti_v3.json',
  };
  crown: AnimationOptions = {
    path: '/assets/game_results/BADGE_crown.json',
    loop: false
  };
  face: AnimationOptions = {
    path: '/assets/game_results/BADGE_face.json',
    loop: false
  };
  handshake: AnimationOptions = {
    path: '/assets/game_results/BADGE_handshake.json',
    loop: false
  };
  master: AnimationOptions = {
    path: '/assets/game_results/BADGE_Message_MASTER.json',
    loop: false
  };
  row: AnimationOptions = {
    path: '/assets/game_results/BADGE_row.json',
    loop: false
  };
  shield: AnimationOptions = {
    path: '/assets/game_results/BADGE_Shield.json',
    loop: false
  };
  ai: AnimationItem;
  public achievements;
  usersOfGame: any = [];
  userGames: IUserGame[] = [];
  userId: string;
  topicTitle: string;
  currentTopic: ITopic;
  lang: string;

  constructor(
    private gameQuery: GameQuery,
    private userQuery: UserQuery,
    private router: Router,
    private el: ElementRef,
    private avatarService: AvatarService,
    private gameService: GameService,
    private gameInstanceService: GameInstanceService,
    private audioService: AudioService
  ) { }

  ngOnInit() {
    this.gameQuery.topics$.subscribe(topics => {
      if (topics) {
        this.currentTopic = this.gameQuery.getCurrentTopic();
        this.topicTitle = this.currentTopic?.title;
        this.userId = this.userQuery.getUserId();
      }
    });

    this.gameQuery.userGames$.subscribe(res => {
      this.usersOfGame = res?.filter(u => u?.user?.normalUser === 1);
      this.userGames = res;
      if (!this.achievements?.length) {
        this.initializeValues(this.userId);
      }
    });
    this.lang = this.userQuery.getLanguage();
  }

  ionViewWillEnter() {
    this.avatarComponentChildren.toArray().forEach((avatar, index) => {
      avatar.nativeElement.innerHTML = '';
      avatar.nativeElement.appendChild(
        this.avatarService.customizationAvatar(
          this.usersOfGame[index].user.avatar_Body,
          this.usersOfGame[index].user.avatar_SkinTone,
          this.usersOfGame[index].user.avatar_Head,
          this.usersOfGame[index].user.avatar_HairColor,
          this.usersOfGame[index].user.avatar_Accessory,
          this.usersOfGame[index].user.avatar_Face,
          this.usersOfGame[index].country
        )
      );
    });
  }

  animationCreated(animationItem: AnimationItem): void {
    this.ai = animationItem;
    this.ai.setSpeed(0.2);
  }

  playAgain() {
    this.gameInstanceService.resetGameState();
    this.audioService.play('backgroundSample2');
    this.router.navigate(['/select_mode']);
  }

  changePlayer(ev) {
    const element = this.el.nativeElement.querySelector('.avatar.active');
    const badgeElement = this.el.nativeElement.querySelector('badges.active');
    if (element) {
      element.classList.toggle('active');
    }
    if (badgeElement) {
      badgeElement.classList.toggle('active');
    }
    ev.currentTarget.className += ' active';
    this.initializeValues(ev.currentTarget.id);
  }

  initializeValues(id: string) {
    this.achievements = this.userGames?.find(ug => ug.userId === id);
  }
}
