// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: transparent;
}
ion-content .logo-small {
  position: absolute;
  top: 2.083rem;
  right: 2.25rem;
  height: 4.075rem;
}
ion-content .title {
  margin-bottom: 1rem;
  color: var(--color-primary-blue);
  font-weight: bold;
  font-size: 2rem;
}
ion-content .text {
  margin-bottom: 2rem;
  color: var(--color-primary-blue);
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: 400;
}
ion-content .btn {
  height: 3.333rem;
  min-height: 3.333rem;
  --min-height: 3.333rem;
  font-size: 1.167rem;
  text-transform: uppercase !important;
}
ion-content .start-btn {
  min-width: 13.667rem;
  font-weight: 600;
}
ion-content .stay-btn {
  min-width: 11.667rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/game/download/download.page.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAEE;EACE,mBAAA;EACA,gCAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAGE;EACE,mBAAA;EACA,gCAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAIE;EACE,gBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;EACA,oCAAA;AAFJ;AAKE;EACE,oBAAA;EACA,gBAAA;AAHJ;AAME;EACE,oBAAA;EACA,gBAAA;AAJJ","sourcesContent":["ion-content {\n  --background: transparent;\n\n  .logo-small {\n    position: absolute;\n    top: 2.083rem;\n    right: 2.25rem;\n    height: 4.075rem;\n  }\n\n  .title {\n    margin-bottom: 1rem;\n    color: var(--color-primary-blue);\n    font-weight: bold;\n    font-size: 2rem;\n  }\n\n  .text {\n    margin-bottom: 2rem;\n    color: var(--color-primary-blue);\n    font-size: 1.2rem;\n    line-height: 1.3rem;\n    font-weight: 400;\n  }\n\n  .btn {\n    height: 3.333rem;\n    min-height: 3.333rem;\n    --min-height: 3.333rem;\n    font-size: 1.167rem;\n    text-transform: uppercase !important;\n  }\n\n  .start-btn {\n    min-width: 13.667rem;\n    font-weight: 600;\n  }\n\n  .stay-btn {\n    min-width: 11.667rem;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
