import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { UserQuery } from '../state/userState/user.query';
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private userQuery: UserQuery
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.userQuery.getSessionId();
    let newHeaders = req.headers;
    if (token) {
      newHeaders = newHeaders.append('x-access-token', token);
    }
    const authReq = req.clone({headers: newHeaders});
    return next.handle(authReq);
}
}
