import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserQuery } from 'src/app/state/userState/user.query';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { AvatarService } from 'src/app/services/avatar-customization.service';
import { AudioService } from 'src/app/services/audio.service';
import { GameService } from 'src/app/state/gameState/game.service';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { ITopic } from 'src/app/models/api.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-game-results',
  templateUrl: './game-results.page.html',
  styleUrls: ['./game-results.page.scss'],
})
export class GameResultsPage implements OnInit {
  @ViewChild('svg') svg: ElementRef;

  ai: AnimationItem;
  avatarIndex = [];
  confetti: AnimationOptions = {
    path: '/assets/game_results/confetti_v3.json',
  };
  crown: AnimationOptions = {
    path: '/assets/game_results/BADGE_crown.json',
    loop: false
  };
  face: AnimationOptions = {
    path: '/assets/game_results/BADGE_face.json',
    loop: false
  };
  handshake: AnimationOptions = {
    path: '/assets/game_results/BADGE_handshake.json',
    loop: false
  };
  master: AnimationOptions = {
    path: '/assets/game_results/BADGE_Message_MASTER.json',
    loop: false
  };
  row: AnimationOptions = {
    path: '/assets/game_results/BADGE_row.json',
    loop: false
  };
  shield: AnimationOptions = {
    path: '/assets/game_results/BADGE_Shield.json',
    loop: false
  };
  public achievements: any;
  topicTitle: string;
  currentTopic: ITopic;
  lang: string;

  constructor(
    private router: Router,
    private userQuery: UserQuery,
    private gameQuery: GameQuery,
    private avatarService: AvatarService,
    private gameService: GameService,
    private gameInstanceService: GameInstanceService,
    private audioService: AudioService
  ) { }

  ngOnInit() {
    this.currentTopic = this.gameQuery.getCurrentTopic();
    this.topicTitle = this.currentTopic.title;
    this.gameQuery.game$.pipe(untilDestroyed(this)).subscribe(res => {
      this.achievements = res?.userGames.find(ug => ug.userId === this.userQuery.getUserId());
    });
    this.lang = this.userQuery.getLanguage();
  }

  ionViewWillEnter() {
    this.avatarIndex = this.userQuery.getUserAvatarIndex();
    this.svg.nativeElement.innerHTML = '';
    this.svg.nativeElement.appendChild(
      this.avatarService.customizationAvatar(
        this.avatarIndex[0],
        this.avatarIndex[1],
        this.avatarIndex[2],
        this.avatarIndex[3],
        this.avatarIndex[4],
        this.avatarIndex[5],
        this.gameQuery.getValue().gameOptions?.country
      )
    );
  }

  animationCreated(animationItem: AnimationItem): void {
    this.ai = animationItem;
    this.ai.setSpeed(0.2);
  }

  onLoopComplete() {
  }

  playAgain() {
    this.gameInstanceService.resetGameState();
    this.audioService.play('backgroundSample2');
    this.router.navigate(['/select_mode']);
  }
}
