import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, interval, map, Observable, Subscription } from 'rxjs';
import { GameService } from '../state/gameState/game.service';
import { GameQuery } from '../state/gameState/game.query';
import { AudioService } from './audio.service';
import moment from 'moment';
import { ErrorMessages, GameStatus } from '../models/game.model';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { checkUrlInGame } from '../shared/game.utils';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TimerService {
  timerSubscription: Subscription;

  remainToBreak: number;
  breakTime: number;
  totalTime: number;
  negotiationRoundTime: number;
  timeToBreakMsg: string;
  breakStartsMsg: string;
  ticker: Subscription;
  gameStatus: number;
  isInformational = false
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  timeLeft: BehaviorSubject<number>;

  timerActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private gameService: GameService,
    private gameQuery: GameQuery,
    private audioService: AudioService,
    private router: Router
  ) {
    this.timerSubscription = combineLatest([
      this.gameQuery.isInformational$,
      interval(1000)
    ]).pipe(
      untilDestroyed(this),
      filter(([informational, _]) => !informational),
      map(([_, timer]) => timer)
    ).subscribe(() => {
      if (this.timerActive.getValue()) {
        const timeLeft = this.timeLeft.getValue();
        if (timeLeft > 0) {
          this.timeLeft.next(timeLeft - 1);
          this.timerHandler(timeLeft - 1);
        } else if (timeLeft < 0) {
          this.timeLeft.next(0);
          this.timerHandler(0);
        }
      }
    });

    this.gameQuery.breakRemainTime$.pipe(untilDestroyed(this)).subscribe(time => {
      this.breakTime = time;
    });
    this.gameQuery.negotiationTotalTime$.pipe(untilDestroyed(this)).subscribe(time => {
      this.totalTime = time;
    });
    this.gameQuery.negotiationRoundTime$.pipe(untilDestroyed(this)).subscribe(time => {
      this.negotiationRoundTime = time;
    });

    this.gameQuery.isInformational$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isInformational = value;
    });

    this.timeLeft = new BehaviorSubject<number>(this.totalTime);

    combineLatest([
      this.gameQuery.isInformational$,
      this.gameQuery.status$
    ]).pipe(
      untilDestroyed(this),
      filter(([informational, _]) => !informational),
      map(([_, status]) => status)
    ).subscribe(res => {
      if (res === GameStatus.break && this.gameStatus !== GameStatus.finalVote) {
        this.gameService.setNegotiationRemainTime(this.remainToBreak);
        this.loading.next(false);
        this.startTimer();
        this.gameService.setTypeOfPhase('break');
        if (this.gameQuery.hasOpenBilateral()) {
          this.gameService.updateErrorMessage(
            ErrorMessages.bilateralBreakStarts
          );
        } else {
          this.router.navigate(['/coffee-break']);
          this.gameService.updateErrorMessage(ErrorMessages.timeToBreak);
        }
        this.changeTimer(this.breakTime);
        this.intervalAfterNegotiationStarted(this.breakTime, 'break');
      }
      if ((res === GameStatus.started && this.gameQuery.getNegotiationRemainTime()) && this.gameStatus !== GameStatus.finalVote) {
        this.loading.next(false);
        this.changeTimer(this.gameQuery.getNegotiationRemainTime());
        this.intervalAfterNegotiationStarted(this.gameQuery.getNegotiationRemainTime(), 'negotiation');
        this.startTimer();
        this.gameService.setTypeOfPhase('negotiation');
        if (this.gameQuery.hasOpenBilateral()) {
          this.gameService.updateErrorMessage(
            ErrorMessages.bilateralNegotiationStarts
          );
        } else {
          this.router.navigate(['/negotiation_phase']);
          this.gameService.updateErrorMessage(ErrorMessages.timeToNegotiation);
        }
      }
      if ((res === GameStatus.started || res === GameStatus.break) && this.gameStatus === GameStatus.finalVote) {
        this.onPhases();
      }
      this.gameStatus = res;
    });

    combineLatest([
      this.gameQuery.isInformational$,
      this.gameQuery.negotiationStarted$
    ]).pipe(
      untilDestroyed(this),
      filter(([informational, _]) => !informational),
      map(([_, negotiationStarted]) => negotiationStarted)
    ).subscribe(res => {
      const date = new Date(res);
      const currDate = new Date().toISOString();
      if (res && date.getTime() > 0 && checkUrlInGame()) {
        this.startTimer();
        this.changeTimer(this.gameQuery.getNegotiationTotalTime());
        const diffStartedFromServer = moment(currDate).diff(
          moment(res)
        );
        this.gameService.setDiffFromServerTime(Math.abs(diffStartedFromServer));
        this.intervalAfterNegotiationStarted(this.gameQuery.getNegotiationTotalTime(), 'negotiation');
      }
    });
  }

  timerHandler(time: number) {
    if (this.gameQuery.getTypeOfPhase() === 'negotiation') {
      if (this.audioService.activeMusicPlaying !== 'exploreThePossibilities'
        && this.gameQuery.getBreakRound() !== 0) {
        this.audioService.play('exploreThePossibilities');
      }
      if (this.gameQuery.getNegotiationRemainTime()) {
        this.remainToBreak =
          this.gameQuery.getNegotiationRemainTime() - this.negotiationRoundTime;
      } else {
        this.remainToBreak = this.totalTime - this.negotiationRoundTime;
      }
      if (
        this.remainToBreak >= time &&
        this.gameQuery.getTypeOfPhase() === 'negotiation'
      ) {
        if (time <= 0) {
          this.pauseTimer();
          this.audioService.play('time-is-up');
          if (this.gameQuery.isHost()) {
            this.gameService.statusBreak();
          }
        } else {
          this.pauseTimer();
          this.timeLeft.next(this.remainToBreak);
          this.loading.next(true);
          if (this.gameQuery.isHost()) {
            this.gameService.statusBreak()
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                this.loading.next(false);
              });
          }
        }
      }
    } else if (this.gameQuery.getTypeOfPhase() === 'break') {
      if (this.audioService.activeMusicPlaying !== 'infiniteTraffic'
      ) {
        this.audioService.play('infiniteTraffic');
      }
      if (0 >= time && this.gameQuery.getTypeOfPhase() === 'break') {
        this.pauseTimer();
        this.loading.next(true);
        this.audioService.play('time-is-up');
        if (this.gameQuery.isHost()) {
          this.gameService.statusContinue()
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading.next(false);
            });
        }
      }
    }
  }

  startTimer() {
    console.log('Starting the timer');
    if (!this.timerActive.getValue()) {
      this.timerActive.next(true);
    } else {
      console.log('Timer already active');
    }
  }

  pauseTimer() {
    if (!this.isInformational) {
      console.log('Stopping the timer');
      this.timerActive.next(false);
      this.ticker?.unsubscribe();
    }
  }

  resetTimer() {
    console.log('Resetting the timer');
    this.timeLeft.next(this.totalTime);
  }

  getTimeLeft(): Observable<number> {
    return this.timeLeft;
  }

  changeTimer(time: number) {
    this.timeLeft.next(time);
  }

  intervalAfterNegotiationStarted(remainNegotiationTimer: number, typeOfPhase: string) {
    this.ticker?.unsubscribe();
    const date = this.gameQuery.getUpdateDate();

    this.ticker = combineLatest([
      this.gameQuery.isInformational$,
      interval(5000)
    ]).pipe(
      untilDestroyed(this),
      filter(([informational, _]) => !informational),
      map(([_, timer]) => timer)
    ).subscribe(() => {
      if (this.gameQuery.getTypeOfPhase() === typeOfPhase) {
        this.updateValueOfTimer(date, remainNegotiationTimer);
      }
    });
  }

  updateValueOfTimer(updateDate: string, remainTimeOfClock: number) {
    if (this.gameQuery.getUpdateDate()) {
      const currentDate = new Date();
      currentDate.setMilliseconds(currentDate.getMilliseconds() - this.gameQuery.getDiffFromServerTime());
      const currentUtcDate = currentDate.toISOString();
      const diffStartedFromServer = moment(currentUtcDate).diff(
        moment(updateDate), 'seconds'
      );
      if (diffStartedFromServer) {
        const remainTime = remainTimeOfClock - diffStartedFromServer;
        this.changeTimer(remainTime);
      }
    }
  }

  onPhases() {
    const phase = this.gameQuery.getTypeOfPhase();
    let timeVal: number;
    if (phase === 'negotiation') {
      timeVal = this.gameQuery.getNegotiationRemainTime()
        ? this.gameQuery.getNegotiationRemainTime() : this.totalTime;
    } else {
      timeVal = this.breakTime;
    }
    this.startTimer();
    this.intervalAfterNegotiationStarted(timeVal, phase);
    this.updateValueOfTimer(this.gameQuery.getUpdateDate(), timeVal);
  }
}
