import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsoleToggleService {

  constructor() {
  }

  disableConsoleInProduction(): void {
    console.log = () => { };
    console.warn = () => { };
  }
}
