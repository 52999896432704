import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmptyScene, makeEmptyScene } from 'src/app/phaser/scenes/empty.scene';
import { makeTestScene, TestScene } from 'src/app/phaser/scenes/test.scene';
import { GameInstanceService } from 'src/app/services/game-instance.service';
@Component({
  selector: 'app-test',
  templateUrl: './test.page.html',
  styleUrls: ['./test.page.scss'],
})
export class TestPage {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;
  constructor(private gameInstanceService: GameInstanceService, private router: Router) {}

  ionViewWillEnter() {
    this.gameInstanceService.switchScene(TestScene.key, makeTestScene);
  }

  ionViewDidEnter() {
    this.gameInstanceService.gameInstance.scale.refresh();
    this.gameInstanceService.gameInstance.scale.on('resize', this.resize, this);
  }

  ionViewWillLeave() {
    this.gameInstanceService.switchScene(EmptyScene.key, makeEmptyScene);
  }

  resize() {
    if (this.phaserWrapper) {
      this.phaserWrapper.nativeElement.style.width =
        this.gameInstanceService.gameInstance.canvas.style.width;
      this.phaserWrapper.nativeElement.style.height =
        this.gameInstanceService.gameInstance.canvas.style.height;
      this.phaserWrapper.nativeElement.style.marginLeft =
        this.gameInstanceService.gameInstance.canvas.style.marginLeft;
      this.phaserWrapper.nativeElement.style.marginTop =
        this.gameInstanceService.gameInstance.canvas.style.marginTop;
    }
  }
}
