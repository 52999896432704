import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserQuery } from '../../state/userState/user.query';

@Component({
  selector: 'app-game-starting',
  templateUrl: './game-starting.page.html',
  styleUrls: ['./game-starting.page.scss'],
})
export class GameStartingPage {
  ceuMono: string;

  constructor(
    private router: Router,
    private userQuery: UserQuery,
  ) {
    this.ceuMono = this.userQuery.getLanguage();
  }

  startGame() {
    this.router.navigate(['/select_mode']);
  }

}
