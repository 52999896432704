import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'src/environments/environment';
import { AvatarService } from './services/avatar-customization.service';
import { HttpService } from './services/data-storage.service';
import { GameInstanceService } from './services/game-instance.service';
// Game Pages
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { NegotiationPhasePage } from './game/negotiation_phase/negotiation-phase.page';
import { TestPage } from './game/test/test.page';
import { CustomizeAvatarPage } from './game/customize-avatar/customize-avatar.page';
import { SelectModePage } from './game/select-mode/select-mode.page';
import { SinglePlayerPage } from './game/single-player-menu/single-player-menu.page';
import { LaunchScreenPage } from './game/launch-screen/launch-screen.page';
import { PresentationProposalPage } from './game/presentation-proposal/presentation-proposal.page';
import { PresentationOfMissionPage } from './game/presentation-of-mission/presentation-of-mission.page';
import { GameBarComponent } from './game/game-bar/game-bar.component';
import { ProposalPage } from './game/presentation-proposal/proposal/proposal.page';
import { AchievementsPage } from './game/achievements/achievements.page';
import { GameSettingsPage } from './game/game-settings/game-settings.page';
import { MultiplayerGameResultsPage } from './game/multiplayer-game-results/multiplayer-game-results.page';
import { GameResultsPage } from './game/game-results/game-results.page';
import { GameStartingPage } from './game/game-starting/game-starting.page';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BookPageComponent } from './game/book-page/book-page.component';
import { BookButtonComponent } from './components/book-button/book-button.component';
import { QuizGamePage } from './game/quiz-game/quiz-game.page';
import { OverviewPage } from './game/overview/overview.page';
import { DimensionBoxComponent } from './game/overview/dimension-box/dimension-box.component';
import { BilateralDiscussionPage } from './game/bilateral-discussion/bilateral-discussion.page';
import { SvgComponent } from './components/svg/svg.component';
import { VotingModalComponent } from './game/voting/voting-modal/voting-modal.component';
import { VotingSidebarComponent } from './game/voting/voting-sidebar/voting-sidebar.component';
import { CoffeeBreakPage } from './game/coffee-break/coffee-break.page';
import { MultiplayerRoomPage } from './game/multiplayer-room/multiplayer-room.page';
import { MultiplayerJoinPage } from './game/multiplayer-join/multiplayer-join.page';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AssignCountriesPage } from './game/assign-countries/assign-countries.page';
import { EnvService } from './services/env.service';
import { TimerComponent } from './components/timer/timer.component';
import { SettingHelpComponent } from './components/setting-help/setting-help.component';
import { BottomSettingsButtonsComponent } from './components/bottom-settings-buttons/bottom-settings-buttons.component';
import { CountdownModule } from 'ngx-countdown';
import { TimerService } from './services/timer.service';
import { GameBarService } from './services/game-bar.service';
import { NegotiationModalComponent } from './components/modals/negotiation-modal/negotiation-modal.component';
import { WebSocketService } from './services/websocket.service';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { CoffeeBreakBarComponent } from './components/coffee-break-bar/coffee-break-bar.component';
import { PhaserOverlayComponent } from './components/phaser-overlay/phaser-overlay.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorCatchingInterceptor } from './services/error-catching-interceptor.service';
import { WordifyPipe } from './shared/wordify.pipe';
import { NgIdleModule } from '@ng-idle/core';
import { NegotiationTitleComponent } from './components/negotiation-title/negotiation-title.component';
import { AudioService } from './services/audio.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { DirectAccessGuard } from './services/direct-access-guard.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { SplashScreenPage } from './splash-screen/splash-screen.page';
import { VotingFinishModalComponent } from './game/voting/voting-finish-modal/voting-finish-modal.component';
import { provideTippyConfig, TippyDirective } from '@ngneat/helipopper';
import { tooltipVariations } from './models/game.model';
import { TrackingService } from './services/tracking.service';
import { ConsoleToggleService } from './services/consoletoggleservice.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { DownloadPage } from './game/download/download.page';
import { CsrfTokenInterceptorService } from './services/Csrf-token-interceptor.service';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    AvatarComponent,
    AssignCountriesPage,
    SvgComponent,
    CustomizeAvatarPage,
    SelectModePage,
    SinglePlayerPage,
    LaunchScreenPage,
    PresentationProposalPage,
    NegotiationPhasePage,
    NegotiationModalComponent,
    TestPage,
    PresentationOfMissionPage,
    SplashScreenPage,
    BilateralDiscussionPage,
    GameResultsPage,
    MultiplayerGameResultsPage,
    GameBarComponent,
    TimerComponent,
    SettingHelpComponent,
    BottomSettingsButtonsComponent,
    ProposalPage,
    AchievementsPage,
    GameSettingsPage,
    GameStartingPage,
    BackButtonComponent,
    BookPageComponent,
    BookButtonComponent,
    MultiplayerRoomPage,
    MultiplayerJoinPage,
    QuizGamePage,
    OverviewPage,
    DimensionBoxComponent,
    VotingModalComponent,
    VotingSidebarComponent,
    ErrorModalComponent,
    CoffeeBreakPage,
    CoffeeBreakBarComponent,
    PhaserOverlayComponent,
    SpinnerComponent,
    WordifyPipe,
    VotingFinishModalComponent,
    NegotiationTitleComponent,
    DownloadPage
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({}),
    CountdownModule,
    NgIdleModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    BackButtonDisableModule.forRoot({
      preserveScroll: true
    }),
    TippyDirective
  ],
  providers: [
    AvatarService,
    HttpService,
    WebSocketService,
    GameInstanceService,
    AudioService,
    ScreenOrientation,
    Media,
    TimerService,
    TrackingService,
    SocialSharing,
    GameBarService,
    DirectAccessGuard,
    StatusBar,
    ConsoleToggleService,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariations,
      }
    }),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfTokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
