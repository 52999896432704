import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  makePresentationProposalScene,
  PresentationProposalScene,
} from 'src/app/phaser/scenes/presentation-proposal.scene';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import anime from 'animejs/lib/anime.es';
import { EmptyScene, makeEmptyScene } from 'src/app/phaser/scenes/empty.scene';
import { IFinalVote } from 'src/app/models/api.model';
import { GameStatus } from '../../models/game.model';
import { WebSocketService } from 'src/app/services/websocket.service';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from 'src/app/services/tracking.service';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-presentation-proposal-page',
  templateUrl: './presentation-proposal.page.html',
  styleUrls: ['./presentation-proposal.page.scss'],
})
export class PresentationProposalPage {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;
  topicTitle = '';
  pastFiveSeconds = false;
  votingMode = false;
  isProposalTourEnd: boolean;
  nextVotingPhase = false;
  startVoting = false;
  showSVGResult = false;
  votingTimerRound: number;
  height: string;
  finalVotes: IFinalVote[] = [];
  votingStatusSubscription: Subscription;
  proposalTourSubscription: Subscription;
  votingFinished: boolean;
  showGameResult = false;
  approveModal = false;
  rejectedModal = false;
  rejectedFinalModal = false;
  finalVotingModal = false;
  voted = false;
  votingRound: number;
  gameStatus: number;
  hideBox = false;
  waitingToVote = false;
  userVote = false;
  loading = false;
  loadingSubscription: Subscription;
  isSingleMode: boolean;
  votingEnded = false;
  votingStarted?: string;
  isHost = false;
  ticker: ReturnType<typeof setTimeout>;
  isInformational = false;
  europeanCommissionIntroduction = '';
  public sidebarTrigger = new BehaviorSubject<boolean>(false);

  constructor(
    private gameInstanceService: GameInstanceService,
    private router: Router,
    private gameService: GameService,
    private gameQuery: GameQuery,
    private trackingService: TrackingService,
    private userQuery: UserQuery,
    private webSocketService: WebSocketService,
    private translateService: TranslateService
  ) {
    this.votingTimerRound = this.gameQuery.getVotingTime();
    this.votingStatusSubscription = this.gameQuery
      .select('isVoting')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        console.log('isVoting: ', res);
        this.votingMode = res;
        if (this.votingMode) {
          this.votingFinished = false;
        }
      });
    this.proposalTourSubscription = this.gameQuery
      .select('isProposalTourEnd')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        console.log('Proposal tour: ', res);
        this.isProposalTourEnd = res;
      });

    this.gameQuery
      .select('game')
      .pipe(untilDestroyed(this))
      .subscribe((game) => {
        console.log('Game: ', game);
        if (!game) {
          return;
        }
        this.isSingleMode = Boolean(game?.single);
        this.votingRound = game?.votingRound;

        const user = this.userQuery.getValue().user;
        const currrentUserGame = game.userGames?.find(
          (ug) => ug.userId === user.userId
        );
        this.isHost = currrentUserGame?.userHost === 1;

        if (
          this.votingStarted !== game.votingStarted &&
          game.votingStarted &&
          game.votingStarted !== '0001-01-01T00:00:00Z'
        ) {
          this.waitingToVote = true;
          const votingTimeLeft = moment(new Date()).diff(
            game.votingStarted,
            'seconds'
          );
          if (votingTimeLeft > this.votingTimerRound) {
            this.onVotingEnd();
          }
        }
        this.votingStarted = game?.votingStarted;
        if (
          this.gameStatus !== game?.status &&
          game?.status === GameStatus.finalVote
        ) {
          this.pastFiveSeconds = false;
          this.nextVotingPhase = false;
          this.startVoting = false;
          // this.showSVGResult = false;
          this.finalVotes = [];
          this.showGameResult = false;
          this.approveModal = false;
          this.rejectedModal = false;
          this.rejectedFinalModal = false;
          this.finalVotingModal = false;
          this.voted = false;
          this.hideBox = false;
          this.waitingToVote = false;
          this.userVote = false;
          this.loading = false;
          this.votingEnded = false;
        } else if (
          this.gameStatus !== game?.status &&
          game?.status === GameStatus.approved
        ) {
          if (!this.votingEnded) {
            return;
          }
          this.trackingService.click({
            name: 'VotingEnded',
            type: 'event'
          });
          console.log('GameStatus.approved');
          this.showGameResult = true;
          this.startVoting = false;
          this.rejectedModal = false;
          this.rejectedFinalModal = false;
          this.hideBox = true;
          setTimeout(() => {
            this.approveModal = true;
            setTimeout(() => {
              this.approveModal = false;
            }, 15000);
          }, 5000);
          // this.showSVGResult = false;
        } else if (
          this.gameStatus !== game?.status &&
          game?.status === GameStatus.rejected
        ) {
          if (!this.votingEnded) {
            return;
          }
          this.trackingService.click({
            name: 'VotingEnded',
            type: 'event'
          });
          console.log('GameStatus.rejected');
          if (this.votingRound === 2) {
            console.log('GameStatus.rejected 2');
            setTimeout(() => {
              this.rejectedFinalModal = true;
              setTimeout(() => {
                this.rejectedFinalModal = false;
                this.showGameResult = true;
                // this.showSVGResult = true;
                this.voted = true;
              }, 15000);
            }, 5000);
          }
        } else if (
          this.votingMode &&
          this.gameStatus !== game?.status &&
          (game?.status === GameStatus.started
          || game?.status === GameStatus.break)
        ) {
          console.log('GameStatus.started');
          this.rejectedModal = true;
          this.ticker = setTimeout(() => {
            this.rejectedModal = false;
            this.onPhases();
          }, 15000);
        } else {
          this.showGameResult =
            game?.status === GameStatus.rejected ||
            game?.status === GameStatus.approved;
        }

        this.gameStatus = game?.status;

        if (game?.finalVotes?.length !== this.finalVotes?.length) {
          console.log('Game this.finalVotes: ', this.finalVotes);
          this.finalVotes = game?.finalVotes || [];
          const userId = this.userQuery.getUserId();
          this.voted =
            this.finalVotes.find((vote) => vote.userId === userId)
              ?.finalVote !== undefined;
          // console.log('Voted: ', this.voted);
          console.log('this.gameStatus: ', this.gameStatus);
          console.log('this.votingRound: ', this.votingRound);

          if (this.gameStatus === GameStatus.finalVote) {
            console.log('GameStatus.finalVote');
            if (!this.userVote) {
              if (this.voted) {
                this.startVoting = false; // TODO: should change
                // this.showSVGResult = true;
              } else {
                console.log('Not Voted');
                this.startVoting = true;
                // this.showSVGResult = false;
              }
            }
          } else {
            console.log('GameStatus nothing');
          }
        }
      });

      this.translateService.onLangChange
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            console.log('COULDNT TRANSLATE ', err);
            throw err;
          })
        )
        .subscribe(() => {
          this.sidebarTrigger.next(true);
        });
  }

  get onVotingEndFunc() {
    return this.onVotingEnd.bind(this);
  }

  ionViewWillEnter() {
    this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
    if (this.isInformational) {
      this.europeanCommissionIntroduction = this.gameQuery.getCurrentTopic()?.europeanCommissionIntroduction;
    }
    this.userVote = false;
    this.topicTitle = this.gameQuery.getCurrentTopic()?.title;
    if (this.votingMode) {
      document.getElementById('eu-council-game-container').style.width =
        'calc(100% - 15rem)';
      document.getElementById('eu-council-game-container').style.left = '17rem';
    }
    this.gameInstanceService.switchScene(
      PresentationProposalScene.key,
      makePresentationProposalScene
    );
    this.gameInstanceService.isAnimatedSpeechPlaying$.next(true);
    this.loadingSubscription = this.gameInstanceService.loading
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (!res) {
          setTimeout(() => {
            if (!this.isProposalTourEnd) {
              anime.timeline({ loop: false }).add({
                targets: '.anim-4a .word',
                scale: [4, 1],
                opacity: [0, 1],
                translateZ: 0,
                easing: 'easeOutExpo',
                delay: (el, i) => 120 * i,
                complete: () => {
                  anime.timeline({ loop: false }).add({
                    targets: '.anim-4b .word',
                    scale: [4, 1],
                    opacity: [0, 1],
                    translateZ: 0,
                    easing: 'easeOutExpo',
                    duration: 950,
                    delay: (el, i) => 120 * i,
                  });
                  this.gameInstanceService.isAnimatedSpeechPlaying$.next(false);
                },
              });
            } else {
              anime.timeline({ loop: false }).add({
                targets: '.anim-6 .word',
                scale: [4, 1],
                opacity: [0, 1],
                translateZ: 0,
                easing: 'easeOutExpo',
                delay: (el, i) => 120 * i,
                complete: () => {
                  this.gameInstanceService.isAnimatedSpeechPlaying$.next(false);
                },
              });
            }
          }, 200);
          setTimeout(() => {
            this.pastFiveSeconds = true;
            setTimeout(() => {
              this.gameInstanceService.isAnimatedSpeechPlaying$.next(true);
              anime.timeline({ loop: false }).add({
                targets: '.anim-5a .word',
                scale: [4, 1],
                opacity: [0, 1],
                translateZ: 0,
                easing: 'easeOutExpo',
                delay: (el, i) => 120 * i,
                complete: () => {
                  this.gameInstanceService.isAnimatedSpeechPlaying$.next(false);
                  anime.timeline({ loop: false }).add({
                    targets: '.anim-5b .word',
                    scale: [4, 1],
                    opacity: [0, 1],
                    translateZ: 0,
                    easing: 'easeOutExpo',
                    duration: 950,
                    delay: (el, i) => 120 * i,
                  });
                },
              });
            }, 200);
          }, 5000);
          this.loadingSubscription?.unsubscribe();
        }
      });
  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.nextVotingPhase = true;
    }, 10000);

    if (this.votingMode) {
      this.sidebarTrigger.next(true);
      setTimeout(() => {
        if (
          this.isHost &&
          (!this.votingStarted || this.votingStarted === '0001-01-01T00:00:00Z')
        ) {
          this.gameService
            .startVote()
            .then((res) => {
              this.waitingToVote = true;
            })
            .catch((err) => console.log(err));
        }
      }, 10000);
    }
    this.gameInstanceService.gameInstance.scale.on('resize', this.resize, this);
    this.gameInstanceService.gameInstance.scale.refresh();
  }

  onVotingEnd() {
    console.log('onVotingEnd');
    this.votingEnded = true;
    this.startVoting = false;
    this.webSocketService.triggerGameUpdate();
  }

  ionViewWillLeave() {
    this.sidebarTrigger.next(false);
    document.getElementById('eu-council-game-container').style.width = '100%';
    document.getElementById('eu-council-game-container').style.left = '0';
    clearTimeout(this.ticker);
    this.gameInstanceService.switchScene(EmptyScene.key, makeEmptyScene);
  }

  onProposal() {
    this.router.navigate(['/proposal']);
  }

  onPhases() {
    clearTimeout(this.ticker);
    this.gameService.updateIsVoting(false);
    const phase = this.gameQuery.getTypeOfPhase();
    if (phase === 'negotiation') {
      this.router.navigate(['/negotiation_phase']);
    } else {
      this.router.navigate(['/coffee-break']);
    }
  }

  onNegotiationPhase() {
    this.router.navigate(['/negotiation_phase']);
  }

  resize() {
    if (this.phaserWrapper) {
      this.phaserWrapper.nativeElement.style.width =
        this.gameInstanceService.gameInstance.canvas.style.width;
      this.phaserWrapper.nativeElement.style.height =
        this.gameInstanceService.gameInstance.canvas.style.height;
      this.phaserWrapper.nativeElement.style.marginLeft =
        this.gameInstanceService.gameInstance.canvas.style.marginLeft;
      this.phaserWrapper.nativeElement.style.marginTop =
        this.gameInstanceService.gameInstance.canvas.style.marginTop;
    }
  }

  onUserVote(vote: boolean) {
    this.startVoting = false; // shouldn't be set here...
    this.userVote = true;
    this.gameService.postFinalVote(vote).then((res) => {
      this.voted = true;
    });
  }

  onVotingApprovedModalClick() {
    this.approveModal = false;
  }

  onVotingRejectedFinalModalClick() {
    this.rejectedFinalModal = false;
  }

  onEndGame() {
    console.log('end game');
    this.finalVotingModal = true;
  }

  onGameResult() {
    this.trackingService.click({
      name: 'GameResults',
      type: 'event'
    });
    this.loading = true;
    this.gameService
      .gameData()
      .then(() =>
        this.isSingleMode
          ? this.router.navigate(['/game_results'])
          : this.router.navigate(['/multiplayer_game_results'])
      )
      .catch((err) => console.log(err))
      .finally(() => {
        this.loading = false;
      });
  }

  onNegotiationPhasePage() {
    this.gameService.updateProposalTour(false);
    this.router.navigate(['/negotiation_phase']);
  }
}
