// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  height: 100vh;
  --background: transparent;
}

.multiplayer-title {
  margin: 2.667rem auto -5rem auto;
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-primary-blue);
  line-height: 1.375rem;
  width: 100% !important;
}

.margin-start {
  margin-left: 2rem;
}

.avatar-col {
  display: flex;
  justify-content: flex-end;
}
.avatar-col .avatar-select-mode-icon {
  width: 6.25rem;
  height: 9.833rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.img-select-mode-page {
  width: 11rem;
  cursor: pointer;
}

.logo-small {
  height: 4.075rem;
}

.column-style {
  display: grid;
  color: var(--color-primary-blue);
  font-weight: bold;
}

.logo-col {
  margin-right: 2.25rem;
  margin-top: 2.083rem;
}`, "",{"version":3,"sources":["webpack://./src/app/game/select-mode/select-mode.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,qBAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;AACE;EACE,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,YAAA;EACA,eAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,gCAAA;EACA,iBAAA;AAAF;;AAIA;EACE,qBAAA;EACA,oBAAA;AADF","sourcesContent":["ion-content {\n  height: 100vh;\n  --background: transparent;\n}\n\n.multiplayer-title {\n  margin: 2.667rem auto -5rem auto;\n  font-size: 1rem;\n  font-weight: bold;\n  color: var(--color-primary-blue);\n  line-height: 1.375rem;\n  width: 100%  !important;\n}\n\n.margin-start {\n  margin-left: 2rem;\n}\n\n.avatar-col {\n  display: flex;\n  justify-content: flex-end;\n\n  .avatar-select-mode-icon {\n    width: 6.25rem;\n    height: 9.833rem;\n    margin-bottom: 2rem;\n    margin-right: 1rem;\n  }\n}\n\n.img-select-mode-page {\n  width: 11rem;\n  cursor: pointer;\n}\n\n.logo-small {\n  height: 4.075rem;\n}\n\n.column-style {\n  display: grid;\n  color: var(--color-primary-blue);\n  font-weight: bold;\n}\n\n\n.logo-col {\n  margin-right: 2.25rem;\n  margin-top: 2.083rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
