import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GameQuery } from '../state/gameState/game.query';
import { GameService } from '../state/gameState/game.service';
import { AudioService } from './audio.service';
import { GameInstanceService } from './game-instance.service';
import { TimerService } from './timer.service';
import { Environment, EnvService } from './env.service';

@Injectable()
export class DirectAccessGuard {
  constructor(
    private router: Router,
    private gameService: GameService,
    private timerService: TimerService,
    private audioService: AudioService,
    private gameInstanceService: GameInstanceService,
    private gameQuery: GameQuery,
    private envService: EnvService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.envService.env === Environment.debug) {
      return true;
    }
    if (this.router.url === '/') {
      this.router.navigate(['']);
      this.gameService.updateErrorMessage('');
      this.timerService.pauseTimer();
      this.audioService.play('backgroundSample2');
      this.gameService.setTypeOfPhase(null);
      this.gameInstanceService.resetGameState();
      if (this.gameQuery.getGameId()) {
        this.gameService.gameQuit();
      }
      return false;
    }
    return true;
  }
}
