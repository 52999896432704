// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: transparent;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.375rem;
  color: var(--color-primary-blue);
  margin-top: 2.583rem;
}

.continue-btn {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 8.667rem;
  height: 2rem;
  --min-height: 2rem;
  min-height: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1rem;
  line-height: 1rem;
  color: var(--color-primary-blue);
  font-weight: bold;
  margin-top: 1.25rem;
}

.avatarRow {
  margin-top: 2.917rem;
}
.avatarRow .avatar .name {
  font-size: 1rem;
  line-height: 1rem;
  color: var(--color-primary-blue);
  font-weight: bold;
  margin-bottom: 0.625rem;
  height: 2rem;
}
.avatarRow .avatar .avatar-box {
  margin-left: 1rem;
  position: relative;
}
.avatarRow .avatar .avatar-box img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-58%, 17%);
  z-index: -1;
  width: 6rem;
}
.avatarRow .avatar .country {
  font-size: 1rem;
  line-height: 1.375rem;
  color: black;
  margin-top: 1.625rem;
}`, "",{"version":3,"sources":["webpack://./src/app/game/assign-countries/assign-countries.page.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,gCAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;EACA,6BAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;AACF;AAGI;EACE,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,iBAAA;EACA,uBAAA;EACA,YAAA;AADN;AAII;EACE,iBAAA;EACA,kBAAA;AAFN;AAIM;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,+BAAA;EACA,WAAA;EACA,WAAA;AAFR;AAMI;EACE,eAAA;EACA,qBAAA;EACA,YAAA;EACA,oBAAA;AAJN","sourcesContent":["ion-content {\n  --background: transparent;\n}\n\n.title {\n  font-size: 1rem;\n  font-weight: bold;\n  line-height: 1.375rem;\n  color: var(--color-primary-blue);\n  margin-top: 2.583rem;\n}\n\n.continue-btn {\n  position: absolute;\n  bottom: 1rem;\n  left: 50%;\n  transform: translate(-50%, 0);\n  min-width: 8.667rem;\n  height: 2rem;\n  --min-height: 2rem;\n  min-height: 2rem;\n  font-size: 1rem;\n  font-weight: 600;\n}\n\n.subtitle {\n  font-size: 1rem;\n  line-height: 1rem;\n  color: var(--color-primary-blue);\n  font-weight: bold;\n  margin-top: 1.25rem;\n}\n\n.avatarRow {\n  margin-top: 2.917rem;\n\n  .avatar {\n\n    .name {\n      font-size: 1rem;\n      line-height: 1rem;\n      color: var(--color-primary-blue);\n      font-weight: bold;\n      margin-bottom: 0.625rem;\n      height: 2rem;\n    }\n\n    .avatar-box {\n      margin-left: 1rem;\n      position: relative;\n\n      img {\n        position: absolute;\n        top: 0;\n        left: 50%;\n        transform: translate(-58%, 17%);\n        z-index: -1;\n        width: 6rem;\n      }\n    }\n\n    .country {\n      font-size: 1rem;\n      line-height: 1.375rem;\n      color: black;\n      margin-top: 1.625rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
