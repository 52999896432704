import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-phaser-overlay',
  templateUrl: './phaser-overlay.component.html',
  styleUrls: ['./phaser-overlay.component.scss'],
})
export class PhaserOverlayComponent {
  @Input() index: number;
  @Input() dimensionNum: number;
  @Input() negotiationPhase: boolean;
  @Input() coffeeBreak: boolean;
  @Output() reveal = new EventEmitter();
  @Output() prevent = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() startGame = new EventEmitter();

  constructor() {}

  onReveal() {
    this.reveal.emit();
  }
  onCloseModal() {
    this.closeModal.emit();
  }
  onStart() {
    this.startGame.emit();
  }
  preventClick(e: any) {
    e.stopPropagation();
  }
}
