import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { TranslateConfigService } from '../../translate-config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserQuery } from '../../state/userState/user.query';
import { Platform } from '@ionic/angular';
import { ILanguage } from '../../models/user.model';
import { languages } from '../../shared/settings.utils';
import { SettingsService } from '../../services/settings.service';

@UntilDestroy()
@Component({
  selector: 'app-launch-screen',
  templateUrl: './launch-screen.page.html',
  styleUrls: ['./launch-screen.page.scss'],
})
export class LaunchScreenPage implements OnInit {
  presidentPath: string;
  loading = false;
  showStoreIcons = true;
  ceuMono: string;

  constructor(
    private route: ActivatedRoute,
    private gameQuery: GameQuery,
    private router: Router,
    public translateConfigService: TranslateConfigService,
    private userQuery: UserQuery,
    private platform: Platform,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    const lang = this.route.snapshot.queryParamMap.get('lang');

    if (lang) {
      const paramLang = languages.find((language: ILanguage) => language.code === lang);

      if (paramLang) {
        this.settingsService.switchLang(paramLang);
      }
    }

    const nextPage = (this.platform.is('android') || this.platform.is('ios')) && this.platform.is('mobileweb');

    if (nextPage) {
      this.router.navigate(['/download']);
    }

    this.translateConfigService.languageLoading$.pipe(untilDestroyed(this)).subscribe(res => {
      this.loading = res;
    });

    this.gameQuery.president$.pipe(untilDestroyed(this)).subscribe(path => {
      this.presidentPath = path;
    });

    if (this.platform.is('android') || this.platform.is('ios')) {
      this.showStoreIcons = false;
    }

    this.userQuery.settings$.pipe(untilDestroyed(this)).subscribe(lang => {
      this.ceuMono = lang.language;
    });
  }

  startGame() {
    this.router.navigate(['/game-starting']);
  }
}
