import { Component, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GameBarService } from 'src/app/services/game-bar.service';
import anime from 'animejs/lib/anime.es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GameQuery } from "../../state/gameState/game.query";

@UntilDestroy()
@Component({
  selector: 'app-game-bar-component',
  templateUrl: './game-bar.component.html',
  styleUrls: ['./game-bar.component.scss'],
})
export class GameBarComponent implements OnInit {
  achievement: Subscription;
  actionPoints: number;
  alliancePoint: number;
  councilPoints: number;
  countryPoints: number;
  prevActionPoints: number = null;
  prevAlliancePoints: number = null;
  prevCouncilPoints: number = null;
  prevCountryPoints: number = null;
  maxActionPoints: number = null;
  isInformational = false;

  constructor(
    private elem: ElementRef,
    private gameBarService: GameBarService,
    private gameQuery: GameQuery
  ) {
    this.gameQuery.isInformational$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isInformational = value;
    })

    if (!this.isInformational) {
      this.gameBarService.actionPoints.pipe(untilDestroyed(this)).subscribe(value => {
        if (value.actionPoint !== this.actionPoints && !isNaN(this.actionPoints)) {
          this.prevActionPoints = value.actionPoint - this.actionPoints;
          this.animePoints('.prev-action-points');
        }
        if (value.alliancePoint !== this.alliancePoint && !isNaN(this.alliancePoint)) {
          this.prevAlliancePoints = value.alliancePoint - this.alliancePoint;
          this.animePoints('.prev-alliance-points');
        }
        if (value.councilPoint !== this.councilPoints && !isNaN(this.councilPoints)) {
          this.prevCouncilPoints = value.councilPoint - this.councilPoints;
          this.animePoints('.prev-council-points');
        }
        if (value.countryPoint !== this.countryPoints && !isNaN(this.countryPoints)) {
          this.prevCountryPoints = value.countryPoint - this.countryPoints;
          this.animePoints('.prev-country-points');
        }
        this.actionPoints = value.actionPoint;
        this.alliancePoint = value.alliancePoint;
        this.councilPoints = value.councilPoint;
        this.countryPoints = value.countryPoint;
        this.maxActionPoints = value.maxActionPoints;
      });
    }

  }

  ngOnInit() { }

  animePoints(target: string) {
    const actionPointEl = this.elem.nativeElement.querySelector(target);
    anime({
      update: () => {
        setTimeout(() => {
          actionPointEl.style.display = 'block';
        }, 200);
      },
      targets: target,
      keyframes: [
        {translateY: 0, opacity: 0},
        {translateY: -30, opacity: 1, delay: 50, duration: 1000},
        {translateY: -30, opacity: 0, delay: 2000, duration: 500},
        {translateY: 0, opacity: 0}
      ],
      easing: 'linear',
      complete: () => {
        actionPointEl.style.display = 'none';
      }
    });
  }
}
