import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { interval, ReplaySubject } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { take } from 'rxjs/operators';
import { AvatarComponent } from 'src/app/components/avatar/avatar.component';
import { IUserGame } from 'src/app/models/api.model';
import { GameBarService } from 'src/app/services/game-bar.service';
import { CountriesQuery } from 'src/app/state/countriesState/countries.query';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-assign-countries',
  templateUrl: './assign-countries.page.html',
  styleUrls: ['./assign-countries.page.scss'],
})
export class AssignCountriesPage implements AfterViewInit {
  @ViewChildren('avatar') avatarComponentChildren: QueryList<AvatarComponent>;
  code: string;
  users: IUserGame[] = [];
  userCountries: string[] = Array(5).fill('...');
  countries: any[] = [];
  startAnimation = new ReplaySubject();
  showContinueButton = false;
  buttonTimeout: any;

  constructor(
    private router: Router,
    private userQuery: UserQuery,
    private gameQuery: GameQuery,
    private countriesQuery: CountriesQuery,
    private gameService: GameService,
  ) {
    this.gameQuery
      .select((state) => state.game?.code)
      .pipe(untilDestroyed(this))
      .subscribe((code) => {
        this.code = code;
      });

    combineLatest([
      this.userQuery.user$,
      this.gameQuery.select(state => state.game?.userGames),
      this.countriesQuery.countries$
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([user, userGames, countries]) => {
      if (user?.userId && userGames && countries) {
        const currrentUserGame = userGames.find(ug => ug.userId === user.userId);
        const usersArray = [
          currrentUserGame,
          ...userGames.filter(ug => ug.user.normalUser && ug.userId !== user.userId),
        ].slice(0, 5);
        if (!_.isEqual(
            this.users.map(ug => ({
              userId: ug.user.userId,
              name: ug.user.name,
              avatarBody: ug.user.avatar_Body,
              avatarSkinTone: ug.user.avatar_SkinTone,
              avatarHead: ug.user.avatar_Head,
              avatarHairColor: ug.user.avatar_HairColor,
              avatarAccessory: ug.user.avatar_Accessory,
              avatarFace: ug.user.avatar_Face,
              country: ug.country,
            })),
            usersArray.map(ug => ({
              userId: ug.user.userId,
              name: ug.user.name,
              avatarBody: ug.user.avatar_Body,
              avatarSkinTone: ug.user.avatar_SkinTone,
              avatarHead: ug.user.avatar_Head,
              avatarHairColor: ug.user.avatar_HairColor,
              avatarAccessory: ug.user.avatar_Accessory,
              avatarFace: ug.user.avatar_Face,
              country: ug.country,
            }))
          )) {
          this.users = usersArray;
          this.userCountries = Array(5).fill('...');
          this.countries = countries;
          this.startAnimation.next(true);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.startAnimation.pipe(untilDestroyed(this)).subscribe({
      next: (value) => {
        if (value) {
          this.avatarComponentChildren.toArray().forEach((avatar, index) => {
            avatar.setAvatar(
              this.users[index].user.avatar_Body,
              this.users[index].user.avatar_SkinTone,
              this.users[index].user.avatar_Head,
              this.users[index].user.avatar_HairColor,
              this.users[index].user.avatar_Accessory,
              this.users[index].user.avatar_Face,
            );
          });
        }
      }
    });
  }

  ionViewWillEnter() {
    this.gameService.setNegotiationRemainTime(null);
    this.gameService.setTypeOfPhase(null);
    this.showContinueButton = false;

    interval(500).pipe(untilDestroyed(this), take(this.users.length + 1)).subscribe((index) => {
      if (index === this.users.length) {
        this.showContinueButton = true;
        this.buttonTimeout = setTimeout(() => {
          this.router.navigate(['/mission']);
          return;
        }, 10000);
      }
      this.avatarComponentChildren.toArray()[index]?.setAvatar(
        this.users[index].user.avatar_Body,
        this.users[index].user.avatar_SkinTone,
        this.users[index].user.avatar_Head,
        this.users[index].user.avatar_HairColor,
        this.users[index].user.avatar_Accessory,
        this.users[index].user.avatar_Face,
        this.users[index].country,
      );
      this.userCountries[index] = this.countries.find(c => c.countryCode === this.users[index]?.country)?.name;
    });
  }

  ionViewWillLeave() {
    clearInterval(this.buttonTimeout);
  }

  continue() {
    clearInterval(this.buttonTimeout);
    this.router.navigate(['/mission']);
  }
}
