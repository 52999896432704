import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import {
  BilateralAnimationStatus,
  BilateralDiscussionMessageType,
  BilateralStatus,
  ErrorMessages,
  USER_KICKED_MSG,
} from '../models/game.model';
import { interval } from 'rxjs';
import { GameQuery } from '../state/gameState/game.query';
import { GameStore } from '../state/gameState/game.store';
import { UserQuery } from '../state/userState/user.query';
import { EnvService } from './env.service';
import _ from 'lodash';
import { GameService } from '../state/gameState/game.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  public bilateralDiscussion = new EventEmitter<{ type: string; data: any }>();
  public coffeeBreakDataChange = new EventEmitter<{ data: any }>();
  public kickedEvent = new EventEmitter<boolean>();
  public connection: signalR.HubConnection;
  private gameId;
  private pingInterval;

  constructor(
    private envService: EnvService,
    private userQuery: UserQuery,
    private gameQuery: GameQuery,
    private gameStore: GameStore,
    private gameService: GameService,
    private router: Router
  ) {
    this.gameQuery.gameId$.pipe(untilDestroyed(this)).subscribe((id) => {
      if (id) {
        this.joinGame();
      } else {
        if (this.connection?.state === HubConnectionState.Connected && this.gameId) {
          this.gameId = undefined;
          // this.connection.stop().catch(err => console.log(err));
        }
      }
    });
  }

  public initPing() {
    this.pingInterval?.unsubscribe();
    this.pingInterval = interval(20000).pipe(untilDestroyed(this)).subscribe(() => {
      if (this.connection.state === HubConnectionState.Connected) {
        this.connection.invoke('SocketPing');
      }
    });
  }

  public clearSocketError() {
    const msg = this.gameQuery.getValue().errorMessage;
    if (msg === ErrorMessages.errorSocket) {
      this.gameService.updateErrorMessage('');
    }
  }

  public onClose = () => {
    if (this.gameId) {
      this.gameService.updateErrorMessage(ErrorMessages.errorSocket);
    }
    setTimeout(() => {
      this.connection
        .start()
        .then(() => {
          this.clearSocketError();
          this.joinGame();
        })
        .catch((error: any) => {
          console.error(error);
          this.onClose();
        });
    }, 1000);
  };

  public connect() {
    if (!this.connection) {
      this.connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.Information)
        .withUrl(this.envService.baseUrl + '/notify', {
          withCredentials: false,
          timeout: 600000,
        })
        .withAutomaticReconnect()
        .build();

      this.connection.onclose(() => {
        this.onClose();
      });

      this.connection.onreconnected(() => {
        this.joinGame();
        this.initPing();
        this.clearSocketError();
      });

      this.connection.on('GroupMessage', (message) => {
        console.log('SignalR GroupMessage', JSON.parse(message));
        try {
          const messageObj = JSON.parse(message);
          if (messageObj.data === USER_KICKED_MSG) {
            this.kickedEvent.emit(true);
            return;
          } else if (messageObj.data === 'Players decided to continue break' &&
            this.gameQuery.getEndBreakVote()) {
            this.gameService.updateErrorMessage(ErrorMessages.playersDecidedToContinueBreak);
            return;
          }
          const data = JSON.parse(messageObj.data);
          if (data && data.type) {
            if (data.type === 'coffeeBreak') {
              this.coffeeBreakDataChange.emit(data.data);

              this.gameStore.update((state) => {
                const newState = _.cloneDeep(state.coffeeBreak);
                Object.keys(data.data).forEach((key) => {
                  newState[key] = data.data[key];
                });
                return {
                  coffeeBreak: newState,
                };
              });
            } else if (data.type === 'endBreak') {
              if (messageObj.userId !== this.userQuery.getUserId() &&
                this.router.url !== '/negotiation_phase' &&
                this.gameQuery.getTypeOfPhase() === 'break') {
                  const parseMessage = JSON.parse(messageObj.data);
                  const countryCode = parseMessage.data?.countryCode;
                  const endBreakMsg = this.gameQuery.hasOpenBilateral()
                    ? ErrorMessages.endBreakMessageMultiVoteBilateral : ErrorMessages.endBreakMessageMultiVote;
                  this.gameService.updateErrorMessage(endBreakMsg);
                  if (countryCode) {
                    this.gameService.updateCountryCodeOfErrorMessage(countryCode);
                  }
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      });

      this.connection.on('PersonalMessage', (message) => {
        console.log('SignalR PersonalMessage', JSON.parse(message));
        try {
          const messageObj = JSON.parse(message);
          if (messageObj.data === USER_KICKED_MSG) {
            this.kickedEvent.emit(true);
            return;
          }
          const data = JSON.parse(messageObj.data);
          if (data && data.type) {
            if (data.type === BilateralDiscussionMessageType.startNegotiation) {
              console.log('SOCKET DATA TYPE ', data.type, data);
              if (this.gameQuery.getBreakGamePlaying()) {
                this.gameService
                  .negotiationMessage(data.senderId, BilateralDiscussionMessageType.declineNegotiationPlayingGame)
                  .catch((err) => console.error(err));
              } else {
                this.gameService.updateBilateralAnimationsStatus(
                  BilateralAnimationStatus.pendingTrue
                );

                this.gameStore.update((state) => ({
                  bilateralDiscussion: {
                    ...state.bilateralDiscussion,
                    userId: data.senderId,
                    incoming: true,
                    status: BilateralStatus.pending,
                    currentState: [],
                    pending: undefined,
                  },
                }));
              }
            } else if (
              data.type === BilateralDiscussionMessageType.acceptNegotiation
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);

              this.gameService.updateBilateralAnimationsStatus(
                BilateralAnimationStatus.accepted
              );

              this.gameStore.update((state) => ({
                bilateralDiscussion: {
                  ...state.bilateralDiscussion,
                  status: BilateralStatus.empty,
                },
              }));
              this.router.navigate(['/bilateral-discussion']);
            } else if (
              data.type === BilateralDiscussionMessageType.declineNegotiation
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);

              this.gameService.updateBilateralAnimationsStatus(
                BilateralAnimationStatus.declined
              );
              this.gameStore.update((state) => ({
                bilateralDiscussion: {
                  ...state.bilateralDiscussion,
                  status: BilateralStatus.cancelled,
                },
              }));
            }
            else if (
              data.type === BilateralDiscussionMessageType.declineNegotiationPlayingGame
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);
              this.gameStore.update((state) => ({
                bilateralDiscussion: {
                  ...state.bilateralDiscussion,
                  status: BilateralStatus.cancelledPlayingGame,
                },
              }));
            } else if (
              data.type === BilateralDiscussionMessageType.proposeAlliance
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);

              this.gameService.updateBilateralAnimationsStatus(
                BilateralAnimationStatus.pendingTrue
              );
              this.bilateralDiscussion.emit({
                type: data.type,
                data: data.data,
              });
            } else if (
              data.type === BilateralDiscussionMessageType.acceptAlliance
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);

              if (this.gameQuery.getValue().bilateralDiscussion.incoming) {
                this.gameService.updateBilateralAnimationsStatus(
                  BilateralAnimationStatus.pendingFalse
                );
                this.gameService.updateBilateralAnimationsStatus(
                  BilateralAnimationStatus.acceptAlliance
                );
              }
              this.bilateralDiscussion.emit({
                type: data.type,
                data: data.data,
              });
            } else if (
              data.type === BilateralDiscussionMessageType.refuseAlliance
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);

              if (this.gameQuery.getValue().bilateralDiscussion.incoming) {
                this.gameService.updateBilateralAnimationsStatus(
                  BilateralAnimationStatus.pendingFalse
                );
                this.gameService.updateBilateralAnimationsStatus(
                  BilateralAnimationStatus.refuseAlliance
                );
              }
              this.bilateralDiscussion.emit({
                type: data.type,
                data: data.data,
              });
            } else if (
              data.type === BilateralDiscussionMessageType.proposeTradeOff
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);
              this.gameService.updateBilateralAnimationsStatus(
                BilateralAnimationStatus.exchange
              );
              this.gameService.updateBilateralAnimationsStatus(
                BilateralAnimationStatus.pendingTrue
              );

              this.bilateralDiscussion.emit({
                type: data.type,
                data: data.data,
              });
            } else if (
              data.type === BilateralDiscussionMessageType.acceptTradeOff
            ) {
              console.log('SOCKET DATA TYPE ', data.type, data);
              this.gameService.updateBilateralAnimationsStatus(
                BilateralAnimationStatus.tradeoffAccepted
              );

              this.bilateralDiscussion.emit({
                type: data.type,
                data: data.data,
              });
            }
            else {
              console.log('SOCKET DATA TYPE ', data.type, data);

              // this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingTrue);
              this.bilateralDiscussion.emit({
                type: data.type,
                data: data.data,
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
      this.connection.on('GameUpdated', (message) => {
        console.log('SignalR message received', JSON.parse(message));
        try {
          const game = JSON.parse(message);
          if (
            game?.data.gameId &&
            game.data.gameId === this.gameStore.getValue().game?.gameId
          ) {
            this.gameStore.update((state) => ({
              game: {
                ...state.game,
                ...game.data,
              },
            }));
          }
        } catch (error) {
          console.log(error);
        }
      });

      this.connection
        .start()
        .then(() => {
          console.log('SignalR Connected!');
          this.joinGame();
          this.initPing();
          this.clearSocketError();
        })
        .catch((err) => console.error(err));
    }
  }

  public joinGame() {
    if (this.connection?.state === HubConnectionState.Connected) {
      if (this.gameQuery.getGameId()) {
        console.log('SignalR Join Game', this.gameQuery.getGameId());
        this.connection.invoke(
          'JoinGame',
          this.userQuery.getSessionId(),
          this.gameQuery.getGameId()
        );
        this.gameId = this.gameQuery.getGameId();
        this.triggerGameUpdate();
      }
    }
  }

  public triggerGameUpdate() {
    if (this.connection?.state === HubConnectionState.Connected) {
      this.connection?.invoke('GameUpdated');
    }
  }
}
