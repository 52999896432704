import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';
import { CountriesStore } from './countries.store';
import { EnvService } from 'src/app/services/env.service';
import { CountriesQuery } from './countries.query';

export class CountriesResponse {
  data: any;
  message: string;
  status: string;
}

@Injectable({ providedIn: 'root' })
export class CountriesService {
  constructor(
    private envService: EnvService,
    private countriesStore: CountriesStore,
    private countriesQuery: CountriesQuery,
    private http: HttpClient
  ) {}

  getCountries(language, fallback = false) {
    return this.http
      .get<CountriesResponse>(
        `${this.envService.baseApiUrl}/Country/${language}`,
        {}
      )
      .pipe(take(1))
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            if (fallback || language === 'en') {
              this.countriesStore.update({
                countriesFallback: value.data.items,
              });
            }
            const countries = fallback
              ? this.countriesQuery.getValue().countries
              : value.data.items;
            const invalid = countries.some((country) => !country.name);
            if (invalid) {
              console.log('invalid');
              if (this.countriesQuery.getValue().countriesFallback?.length) {
                const countriesFallback =
                  this.countriesQuery.getValue().countriesFallback;
                console.log('fallback', countriesFallback);
                console.log('lalala', countries);
                const newCountries = countries.map((country) => {
                  if (country.name) {
                    return country;
                  } else {
                    return countriesFallback.find(
                      (c) => c.countryCode === country.countryCode
                    );
                  }
                });
                this.countriesStore.update({
                  countries: newCountries,
                });
              } else if (!fallback) {
                this.countriesStore.update({ countries: value.data.items });
                this.getCountries('en', true);
              }
            } else if (!fallback) {
              this.countriesStore.update({ countries: value.data.items });
            }
          }
        })
      )
      .subscribe();
  }
}
