import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameMode } from 'src/app/shared/game.utils';
import { UserService } from 'src/app/state/userState/user.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { AudioService } from 'src/app/services/audio.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'app-select-mode-page',
  templateUrl: './select-mode.page.html',
  styleUrls: ['./select-mode.page.scss'],
})
export class SelectModePage implements OnInit {
  gameMode = GameMode;
  mode: GameMode;
  presidentPath: string;
  mute: boolean;
  ceuMono: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private userQuery: UserQuery,
    private gameQuery: GameQuery,
    private gameService: GameService,
    private audioService: AudioService,
    private translate: TranslateService,
  ) {
    this.ceuMono = this.userQuery.getLanguage();
  }

  ngOnInit() {
    this.gameQuery.president$.pipe(untilDestroyed(this)).subscribe(path => {
      this.presidentPath = path;
    });
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.mode = data.mode as GameMode;
    });
    this.userQuery.settings$.pipe(untilDestroyed(this)).subscribe(settings => {
      this.mute = settings.mute;
    });
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe((event) => {
      this.ceuMono = event.lang;
    });
    if (this.audioService.activeMusicPlaying !== 'backgroundSample2' && this.mode !== GameMode.multiPlayer) {
      this.audioService.play('backgroundSample2');
    }
  }

  ionViewWillEnter() {
    this.gameService.updateSelectedTopic('');
    this.gameService.updateSelectedDifficultyLevel('');
    this.gameService.updateSelectedCountry('');
  }

  switchMute() {
    this.userService.switchMute();
  }

  onPlayerMode(mode) {
    if (mode === GameMode.singlePlayer) {
      this.router.navigate(['/single_player']);
    } else {
      this.router.navigate(['/multiplayer_mode']);
    }
  }

  onCreateMultiplayerGame() {
    this.router.navigate(['/multi_player']);
  }

  onJoinMultiplayerGame() {
    this.router.navigate(['/multiplayer-join']);
  }

  onSettings() {
    this.userService.updateRouterUrl(this.router.url);
    this.router.navigate(['/game_settings']);
  }

  onAchievements() {
    this.router.navigate(['/achievements']);
  }
}
