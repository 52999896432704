import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit {
  @Input() public src = '';

    constructor(
        private el: ElementRef,
        private http: HttpClient,
    ) {}

    ngOnInit(): void {
        this.http.get(this.src, {responseType: 'text'}).pipe(take(1)).subscribe(svg => {
            this.el.nativeElement.innerHTML = svg;
        });
    }

}
