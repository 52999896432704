import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { IAchievement } from 'src/app/models/api.model';
import { GameService } from 'src/app/state/gameState/game.service';
import _ from 'lodash';
import { take } from 'rxjs/operators';
import { CountriesQuery } from 'src/app/state/countriesState/countries.query';

@Component({
  selector: 'app-achievements-page',
  templateUrl: './achievements.page.html',
  styleUrls: ['./achievements.page.scss'],
})
export class AchievementsPage implements OnInit {
  public currentTab = 0;
  public achievements: IAchievement[] = [];
  public badges = initialBadges;

  constructor(private gameService: GameService, private countryQuery: CountriesQuery) {}

  ngOnInit() { }

  switchTab(tab: number) {
    this.currentTab = tab;
  }

  ionViewWillEnter() {
    this.gameService.getAchievements().pipe(take(1)).subscribe((res) => {
      if (res && res.status === 'Success') {
        this.achievements = _.orderBy(res.data.items, (d: IAchievement) =>
                                d.actionPoints + d.councilPoints + d.countryPoints + d.alliancePoints, ['desc']).slice(0, 5);
        this.badges = {...initialBadges};

        res.data.items.forEach(achievement => {
          this.badges.actionPoints += achievement.actionPoints;
          this.badges.councilPoints += achievement.councilPoints;
          this.badges.countryPoints += achievement.countryPoints;
          this.badges.alliancePoints += achievement.alliancePoints;
          this.badges.badgeDiplomat += this.calcBadge(achievement.badgeDiplomat);
          this.badges.badgeSocialite += this.calcBadge(achievement.badgeSocialite);
          this.badges.badgeTactician += this.calcBadge(achievement.badgeTactician);
          this.badges.badgeDefender += this.calcBadge(achievement.badgeDefender);
          this.badges.badgeMediator += this.calcBadge(achievement.badgeMediator);
          this.badges.badgeNegotiator += this.calcBadge(achievement.badgeNegotiator);
          this.badges.totalNoEmojiTasks += achievement.totalNoEmojiTasks;
          this.badges.totalNoExpertQuizTasks += achievement.totalNoExpertQuizTasks;
          this.badges.totalNoSpotTheCountryTasks += achievement.totalNoSpotTheCountryTasks;
        });
      }
    });
  }

  calcBadge(score: number) {
    return score ? 1 : 0;
  }

  parseDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  parseCountryCode(countryCode: string) {
    return this.countryQuery.selectCountryByCountryCode(countryCode);
  }
}

const initialBadges = {
  actionPoints: 0,
  councilPoints: 0,
  countryPoints: 0,
  alliancePoints: 0,
  badgeDiplomat: 0,
  badgeSocialite: 0,
  badgeTactician: 0,
  badgeDefender: 0,
  badgeMediator: 0,
  badgeNegotiator: 0,
  totalNoEmojiTasks: 0,
  totalNoExpertQuizTasks: 0,
  totalNoSpotTheCountryTasks: 0,
};
