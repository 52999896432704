import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CountriesQuery } from 'src/app/state/countriesState/countries.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UserService } from 'src/app/state/userState/user.service';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { GameQuery } from '../../state/gameState/game.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-book-button-component',
  templateUrl: './book-button.component.html',
  styleUrls: ['./book-button.component.scss'],
})
export class BookButtonComponent implements OnInit {
  @Input() url: string;
  @Input() open = false;
  @Input() showBookIcon = true;

  public isProposal: boolean;
  public isToggleChecked = false;
  public countryName: string;
  public flag: string;
  public src: string;
  public isInformational = false;

  constructor(
    private countriesQuery: CountriesQuery,
    private router: Router,
    private location: Location,
    private gameService: GameService,
    private gameQuery: GameQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private gameInstanceService: GameInstanceService
    ) { }

  ngOnInit() {
    const userId = this.userQuery.getUserId();

    combineLatest([
      this.gameQuery.userGames$,
      this.countriesQuery.countries$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userGames, countries]) => {
        if (!userGames || !countries?.length ) {
          return;
        }

        if (userGames) {
          const userGame = userGames?.find((ug) => ug.userId === userId);
          if (userGame) {
            const userCountry = countries?.find((country) => country.countryCode === userGame.country);
            this.flag = userCountry?.countryCode;
            this.countryName = userCountry?.name;
          }
        }
      });

    this.isInformational = this.gameQuery.getCurrentTopicIsInformational();

    this.gameInstanceService.toggleChecked$.asObservable().pipe(untilDestroyed(this)).subscribe(res => {
      this.isToggleChecked = res;
    });
    this.isProposal = this.router.url === '/proposal';
  }

  buttonAction() {
    if (!this.open) {
      this.userService.updateRouterUrl(this.router.url);
      this.router.navigate(['/book']);
    } else if (this.url) {
      this.router.navigate([this.url]);
    } else {
      this.router.navigate([this.userQuery.getRouterUrl()]);
    }
  }

  preventClick(e: any) {
    e.stopPropagation();
  }
}
