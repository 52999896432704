import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, retry, take, delay } from 'rxjs/operators';
import { GameStore } from './game.store';
import { GameQuery } from './game.query';
import { UserQuery } from '../userState/user.query';
import { CountriesQuery } from '../countriesState/countries.query';
import {
  IAchievementResponse,
  ITimeResponse,
  ITimerConfigurationResponse,
  IGame,
  IGameResponse,
  IGameStart,
  ITopicResponse,
  ITopicsResponse,
  IHelpTopicResponse,
  IUserGameNegotiation,
  IUserGameNegotiationBilateral,
  ITalkToAnExpertResponse,
  IUserAvatar,
  IGameCreate,
  IGameUpdate,
  IBilateralHistoryResponse,
} from 'src/app/models/api.model';
import { GameMode } from 'src/app/shared/game.utils';
import _ from 'lodash';
import {
  BilateralDiscussionMessageType,
  BilateralAnimationStatus,
  BilateralStatus,
  INegotiation,
} from 'src/app/models/game.model';
import { UserService } from '../userState/user.service';
import {
  PRESIDENT,
  REPRESENTATIVE,
  EXPERTS_MALE,
  EXPERTS_FEMALE,
} from '../../shared/avatar.utils';
import { getRandomInt } from '../../shared/math.utils';
import { EnvService } from 'src/app/services/env.service';

@Injectable({ providedIn: 'root' })
export class GameService {
  constructor(
    private envService: EnvService,
    private gameStore: GameStore,
    private gameQuery: GameQuery,
    private userQuery: UserQuery,
    private countriesQuery: CountriesQuery,
    private http: HttpClient,
    private userService: UserService
  ) { }

  setNegotiationRemainTime(num: number) {
    this.gameStore.update({ negotiationRemainTime: num });
  }

  setDiffFromServerTime(num: number) {
    this.gameStore.update({ diffFromServerTime: num });
  }

  setNegotiationTotalTime(num: number) {
    this.gameStore.update({ negotiationTotalTime: num });
  }

  setNegotiationRoundTime(num: number) {
    this.gameStore.update({ negotiationRoundTime: num });
  }

  setBreakRemainTime(num: number) {
    this.gameStore.update({ breakRemainTime: num });
  }

  setVotingTime(num: number) {
    this.gameStore.update({ votingTime: num });
  }

  setEndBreakVote(vote: boolean) {
    this.gameStore.update({ endBreakVote: vote });
  }

  setTypeOfPhase(phase: string) {
    this.gameStore.update({ typeOfPhase: phase });
  }

  setRandomPresident() {
    const randomPresident = PRESIDENT[getRandomInt(PRESIDENT.length - 1)];
    this.gameStore.update({ president: randomPresident });
  }

  setRandomRepresentative() {
    const setRandomRepresentative =
      REPRESENTATIVE[getRandomInt(REPRESENTATIVE.length - 1)];
    this.gameStore.update({ representative: setRandomRepresentative });
  }

  setRandomMaleExpert() {
    const shuffleExperts = _.shuffle(EXPERTS_MALE);
    this.gameStore.update({ maleExperts: shuffleExperts });
  }

  setRandomFemaleExpert() {
    const shuffleExperts = _.shuffle(EXPERTS_FEMALE);
    this.gameStore.update({ femaleExperts: shuffleExperts });
  }

  gameData() {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .get<IGameResponse>(`${this.envService.baseApiUrl}/Game/${gameId}`)
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update((state) => ({
              game: {
                ...state.game,
                ...value.data.items[0],
              },
            }));
          }
        })
      )
      .toPromise();
  }

  createGame(mode: string, body: IGameCreate) {
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/create?singlePlayer=${mode === GameMode.singlePlayer
        }`,
        body
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update({ game: value.data.items[0] });
          }
        })
      )
      .toPromise();
  }

  getTopics(language: string) {
    return this.http
      .get<ITopicResponse>(
        `${this.envService.baseApiUrl}/Topics/${language}`,
        {}
      )
      .pipe(take(1))
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            const invalid = value.data.items.some(
              (topic) =>
                !topic.title &&
                !topic.description &&
                !topic.dimensionsIntroduction
            );
            if (invalid) {
              if (this.gameQuery.getValue()?.topicsFallback?.length) {
                this.gameStore.update({
                  topics: this.gameQuery.getValue().topicsFallback,
                });
              } else {
                this.getTopics('en');
              }
            } else {
              this.gameStore.update({ topics: value.data.items });
              if (language === 'en') {
                this.gameStore.update({ topicsFallback: value.data.items });
              }
            }
          }
        })
      )
      .subscribe();
  }

  getStaticTopics(language: string) {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .get<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/static/${gameId}`,
        {}
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            if (value.data.items[0]?.topicsSettings) {
              try {
                const topicsSettings = JSON.parse(
                  value.data.items[0].topicsSettings
                );
                let selectedLangSettings = topicsSettings.find(
                  (setting) => setting.language === language
                ).data;
                const invalid =
                  !selectedLangSettings ||
                  !selectedLangSettings?.title ||
                  !selectedLangSettings?.description ||
                  !selectedLangSettings?.dimensionsIntroduction ||
                  !selectedLangSettings?.quote ||
                  !selectedLangSettings?.topicDisclaimer;
                if (invalid) {
                  selectedLangSettings = topicsSettings.find(
                    (setting) => setting.language === 'en'
                  ).data;
                }
                if (selectedLangSettings) {
                  const topicId = this.gameQuery.getValue().game?.topic;
                  const topics = _.cloneDeep(this.gameQuery.getValue().topics);
                  const topicIndex = topics.findIndex((t) => t.id === topicId);
                  if (topicIndex > -1) {
                    topics[topicIndex] = {
                      ...topics[topicIndex],
                      ...selectedLangSettings,
                    };
                    this.gameStore.update({ topics });
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
        })
      )
      .toPromise();
  }

  getTopic(topicId) {
    return this.http
      .get<ITopicsResponse>(
        `${this.envService.baseApiUrl}/Topics?id=${topicId}`
      )
      .pipe(retry(1));
  }

  getHelpTopics(language: string) {
    return this.http
      .get<IHelpTopicResponse>(
        `${this.envService.baseApiUrl}/HelpTopics/${language}`,
        {}
      )
      .pipe(take(1))
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            const invalid = value.data.items.some((topic) => !topic.text);
            if (invalid) {
              if (this.gameQuery.getValue().helpTopicsFallback.length) {
                this.gameStore.update({
                  helpTopics: this.gameQuery.getValue().helpTopicsFallback,
                });
              } else {
                this.getHelpTopics('en');
              }
            } else {
              this.gameStore.update({ helpTopics: value.data.items });
              if (language === 'en') {
                this.gameStore.update({ helpTopicsFallback: value.data.items });
              }
            }
          }
        })
      )
      .subscribe();
  }

  gameStart(body: IGameStart) {
    const gameId = this.gameQuery.getGameId();
    const lang = this.userQuery.getLanguage();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/start/${gameId}?language=${lang}`,
        body
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update({ game: value.data.items[0] });
            this.userService.resetTheQuizAnswerdQuestions();
          }
        })
      )
      .toPromise();
  }

  statusContinue() {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/${gameId}/continue`,
        null
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update({ game: value.data.items[0] });
          }
        }),
      )
      .toPromise();
  }

  statusBreak() {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/${gameId}/break`,
        null
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update({ game: value.data.items[0] });
          }
        }),
      )
      .toPromise();
  }

  endBreakVote(val: boolean) {
    const gameId = this.gameQuery.getGameId();
    this.setEndBreakVote(val);
    return this.http.post<IGameResponse>(
      `${this.envService.baseApiUrl}/Game/${gameId}/endBreakVote?vote=${val}`,
      null
    )
    .subscribe();
  }

  gameQuit() {
    const gameId = this.gameQuery.getGameId();
    if (gameId) {
      return this.http
        .post<IGameResponse>(
          `${this.envService.baseApiUrl}/Game/${gameId}/quit`,
          null
        )
        .pipe(
          tap((value) => {
            if (value && value.status === 'Success') {
              console.log('game quit success');
            }
          })
        )
        .toPromise();
    } else {
      return Promise.resolve();
    }
  }

  negotiationMessage(
    negotiatorUserId: string,
    type: BilateralDiscussionMessageType,
    data?: number
  ) {
    const message = {
      type,
      senderId: this.userQuery.getUserId(),
      data,
    };
    return this.sendMessageToUser(negotiatorUserId, JSON.stringify(message));
  }

  coffeeBreakMessage(data: any) {
    const message = {
      type: 'coffeeBreak',
      data,
    };
    return this.sendGroupMessage(JSON.stringify(message));
  }

  endBreakMessage(data: any) {
    const message = {
      type: 'endBreak',
      data,
    };
    return this.sendGroupMessage(JSON.stringify(message));
  }

  addCoffeeBreakState(data: any) {
    this.gameStore.update((state) => {
      const newState = _.cloneDeep(state.coffeeBreak);
      Object.keys(data).forEach((key) => {
        newState[key] = data[key];
      });
      return {
        coffeeBreak: newState,
      };
    });
  }

  updateUserAvatar(body: IUserAvatar) {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/${gameId}/user`,
        body
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update({ game: value.data.items[0] });
          }
        })
      )
      .toPromise();
  }

  gameLeave(code: string) {
    return this.http
      .delete<IGameResponse>(`${this.envService.baseApiUrl}/Game/join/${code}`)
      .toPromise();
  }

  gameJoin(code: string) {
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/join/${code}`,
        null
      )
      .toPromise();
  }

  gameUpdate(body: IGameUpdate) {
    return this.http
      .patch<IGameResponse>(`${this.envService.baseApiUrl}/Game`, body)
      .toPromise();
  }

  sendMessageToUser(userId: string, message: string) {
    return this.http
      .post<any>(`${this.envService.baseApiUrl}/Game/sendMessage/${userId}`, {
        message,
      })
      .toPromise();
  }

  sendGroupMessage(message: string) {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<any>(
        `${this.envService.baseApiUrl}/Game/sendGroupMessage/${gameId}`,
        { message }
      )
      .toPromise();
  }

  getAchievements() {
    return this.http.get<IAchievementResponse>(
      `${this.envService.baseApiUrl}/User/achievement`
    );
  }

  getTime() {
    return this.http
      .get<ITimeResponse>(`${this.envService.baseApiUrl}/Game/time`)
      .pipe(retry(1));
  }

  getTimerConfiguration() {
    return this.http
      .get<ITimerConfigurationResponse>(
        `${this.envService.baseApiUrl}/Game/timersconfiguration`
      )
      .pipe(retry(1));
  }

  addTopic(topicState) {
    this.gameStore.update({ topic: topicState });
  }

  getCurrentUserCountry() {
    const userId = this.userQuery.getUserId();
    const userGame = this.gameStore
      .getValue()
      .game?.userGames.find((ug) => ug.userId === userId);
    if (userGame) {
      return this.countriesQuery
        .getCountries()
        .find((country) => country.countryCode === userGame.country);
    }
    return undefined;
  }

  revealPositions(value) {
    this.gameStore.update({ revealPositions: value });
  }
  updateSelectedDimension(value) {
    this.gameStore.update({ selectedDimension: value });
  }

  updateDimensionsCalled(value) {
    switch (value) {
      case 1:
        this.gameStore.update({
          d1Called: this.gameQuery.getNumOfDimensionsCalled().d1 + 1,
        });
        break;
      case 2:
        this.gameStore.update({
          d2Called: this.gameQuery.getNumOfDimensionsCalled().d2 + 1,
        });
        break;
      case 3:
        this.gameStore.update({
          d3Called: this.gameQuery.getNumOfDimensionsCalled().d3 + 1,
        });
        break;
    }
  }

  startBilateral(receivingUserId: string) {
    const sendingUserId = this.userQuery.getUserId();
    const gameId = this.gameQuery.getGameId();
    const body = {
      sendingUserId,
      receivingUserId,
      gameId,
    };
    return this.http
      .post<IGameResponse>(`${this.envService.baseApiUrl}/Game/bilateral`, body)
      .toPromise();
  }

  postBilataralStatus(negotiations: INegotiation[], status: string) {
    return this.http
      .post<IGame>(
        `${this.envService.baseApiUrl}/Game/bilateral/${status}`,
        negotiations
      )
      .toPromise();
  }

  getBilateralDiscussion(currentUserId: string, negotiatorUserId: string) {
    const userGames = this.gameStore.getValue().game?.userGames;
    const topicId = this.gameStore.getValue().game?.topic;
    const topic = this.gameStore
      .getValue()
      .topics.find((t) => t.id === topicId);
    const topicDimensions = topic.dimension;
    const topicDimensionsIds = topicDimensions.map((d) => d.id);
    const currentUserBilateral1 = userGames
      ?.find((ug) => ug.userId === currentUserId)
      ?.user.bilateral?.filter((b) => b.bilateralStatus === 'Success');
    let userGameNegotiations1 = userGames?.find(
      (ug) => ug.userId === currentUserId
    )?.user.userGameNegotiation;
    let userGameNegotiations2 = userGames?.find(
      (ug) => ug.userId === negotiatorUserId
    )?.user.userGameNegotiation;

    userGameNegotiations1 = _.sortBy(userGameNegotiations1, (item) =>
      topicDimensionsIds.indexOf(item.dimension)
    ).map((ugn: IUserGameNegotiation, index: number) => ({
      ...ugn,
      initialPosition: topicDimensions[index].positionContent.find((pc) =>
        pc.currentCountryPosition.find(
          (ccp) =>
            ccp.countryCode ===
            this.getCountryByUserId(currentUserId).countryCode
        )
      ),
      positionComment: topicDimensions[index].positionContent
        .find((pc) =>
          pc.currentCountryPosition.find(
            (ccp) =>
              ccp.countryCode ===
              this.getCountryByUserId(currentUserId).countryCode
          )
        )
        ?.currentCountryPosition.find(
          (ccp) =>
            ccp.countryCode ===
            this.getCountryByUserId(currentUserId).countryCode
        )?.positionComment,
      positionContent: topicDimensions[index].positionContent.find(
        (pc) => pc.id === ugn.position
      ),
      dimensionContent: _.omit(topicDimensions[index], ['positionContent']),
      bilateralStatus: BilateralStatus.empty,
      bilateralInfo: false,
    }));
    userGameNegotiations2 = _.sortBy(userGameNegotiations2, (item) =>
      topicDimensionsIds.indexOf(item.dimension)
    ).map((ugn: IUserGameNegotiation, index: number) => {
      let bilateralStatus = BilateralStatus.empty;
      if (
        currentUserBilateral1?.find(
          (b) =>
            (b.receivingUserGameNegotiationId === ugn.userGameNegotiationId &&
              b.sendingUserGameNegotiationId ===
              userGameNegotiations1[index].userGameNegotiationId) ||
            (b.sendingUserGameNegotiationId === ugn.userGameNegotiationId &&
              b.receivingUserGameNegotiationId ===
              userGameNegotiations1[index].userGameNegotiationId)
        )
      ) {
        bilateralStatus = BilateralStatus.allied;
        userGameNegotiations1[index].bilateralStatus = BilateralStatus.allied;
      }
      return {
        ...ugn,
        initialPosition: topicDimensions[index].positionContent.find((pc) =>
          pc.currentCountryPosition.find(
            (ccp) =>
              ccp.countryCode ===
              this.getCountryByUserId(negotiatorUserId).countryCode
          )
        ),
        positionComment: topicDimensions[index].positionContent
          .find((pc) =>
            pc.currentCountryPosition.find(
              (ccp) =>
                ccp.countryCode ===
                this.getCountryByUserId(negotiatorUserId).countryCode
            )
          )
          ?.currentCountryPosition.find(
            (ccp) =>
              ccp.countryCode ===
              this.getCountryByUserId(negotiatorUserId).countryCode
          )?.positionComment,
        positionContent: topicDimensions[index].positionContent.find(
          (pc) => pc.id === ugn.position
        ),
        dimensionContent: _.omit(topicDimensions[index], ['positionContent']),
        bilateralStatus,
        bilateralInfo: false,
      };
    });
    return [
      userGameNegotiations1,
      userGameNegotiations2,
    ] as IUserGameNegotiationBilateral[][];
  }

  getBilateralHistory() {
    const gameId = this.gameQuery.getGameId();
    if (!gameId) {
      return;
    }
    return this.http
      .get<IBilateralHistoryResponse>(
        `${this.envService.baseApiUrl}/Game/bilateral/userid?gameid=${gameId}`
      )
      .subscribe((value) => {
        if (value && value.status === 'Success') {
          this.gameStore.update({ bilateralHistory: value.data.items });
        }
      });
  }

  getCountryByUserId(userId: string) {
    const userCountry = this.gameStore
      .getValue()
      .game?.userGames.find((ug) => ug.userId === userId)?.country;
    if (userCountry) {
      return this.countriesQuery
        .getCountries()
        .find((country) => country.countryCode === userCountry);
    } else {
      return undefined;
    }
  }

  removePlayer(gameCode: string, userId: string) {
    return this.http
      .delete<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/delete/${gameCode}/${userId}`
      )
      .toPromise();
  }

  updatePlayerPosition(body) {
    let apiPath = !this.gameQuery.getCurrentTopicIsInformational() ? '/Game/position' : '/Game/informationPosition';
    return this.http.post<IGameResponse>(
      `${this.envService.baseApiUrl}${apiPath}`,
      body
    );
  }

  getQuizAnswers(body) {
    return this.http
      .post<ITalkToAnExpertResponse>(
        `${this.envService.baseApiUrl}/Game/talktoanexpert`,
        body
      )
      .pipe(retry(1));
  }

  updateIsVoting(bool) {
    this.gameStore.update({ isVoting: bool });
  }

  updateIsVotingModalOpen(bool) {
    this.gameStore.update({ isVotingModalOpen: bool });
  }

  updateModalOpen(bool) {
    this.gameStore.update({ isModalOpen: bool });
  }

  updateSelectedTopic(value) {
    this.gameStore.update((state) => ({
      gameOptions: {
        ...state.gameOptions,
        topic: value,
      },
    }));
  }

  updateSelectedDifficultyLevel(value) {
    this.gameStore.update((state) => ({
      gameOptions: {
        ...state.gameOptions,
        difficultyLevel: value,
      },
    }));
  }

  updateSelectedCountry(value) {
    this.gameStore.update((state) => ({
      gameOptions: {
        ...state.gameOptions,
        country: value,
      },
    }));
  }

  updateSelectedIsInformational(value) {
    this.gameStore.update((state) => ({
      gameOptions: {
        ...state.gameOptions,
        isInformational: value,
      },
    }));
  }
  updateProposalTour(bool) {
    this.gameStore.update({ isProposalTourEnd: bool });
  }

  updateErrorMessage(msg) {
    setTimeout(() => this.gameStore.update({ errorMessage: msg }), 100);
  }

  updateCountryCodeOfErrorMessage(country: string) {
    this.gameStore.update({ countryCodeOfErrorMessage: country });
  }

  startNPCMovements() {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/startnpcmovements/${gameId}`,
        null
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update({ game: value.data.items[0] });
          }
        })
      )
      .toPromise();
  }

  startVote() {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/${gameId}/startVote`,
        null
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update((state) => ({
              game: { ...state.game, ...value.data.items[0] },
            }));
          }
        })
      )
      .toPromise();
  }

  postFinalVote(vote: boolean) {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/${gameId}/user/vote/${vote}`,
        null
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            this.gameStore.update((state) => ({
              game: { ...state.game, ...value.data.items[0] },
            }));
          }
        })
      )
      .toPromise();
  }

  breakGame(gameName) {
    const gameId = this.gameQuery.getGameId();
    return this.http.post<IGameResponse>(
      `${this.envService.baseApiUrl}/Game/playgame/${gameId}?gameName=${gameName}&status=success`,
      null
    );
  }

  updateBreakGamePlaying(gameName) {
    this.gameStore.update({ breakGamePlaying: gameName });
  }

  updateBreakError(error) {
    this.gameStore.update({ breakError: error });
  }

  updatePositionDescription(message) {
    this.gameStore.update({ positionDescription: message });
  }

  updateSpotTheCountryOverlay(num: number) {
    this.gameStore.update({ spotTheCountryOverlay: num });
  }

  updateSpotTheCountryGames(values) {
    this.gameStore.update({
      spotTheCountryGames: values,
    });
  }

  updateSpotTheCountriesFound(values) {
    this.gameStore.update({
      spotTheCountriesFound: values,
    });
  }

  updateSpotCountryCountdown(bool) {
    this.gameStore.update({
      spotCountryCountdown: bool,
    });
  }

  updateSpotTheCountrySelectedGame(val) {
    this.gameStore.update({
      spotTheCountrySelectedGame: val,
    });
  }

  updateSpotTheCountryGameResult(val: string) {
    this.gameStore.update({
      spotTheCountryGameResult: val,
    });
  }

  updateIsFirstTimeInDimension(dimension) {
    switch (dimension) {
      case 1:
        this.gameStore.update({
          isFirstTimeDimension1: false,
        });
        break;
      case 2:
        this.gameStore.update({
          isFirstTimeDimension2: false,
        });
        break;
      case 3:
        this.gameStore.update({
          isFirstTimeDimension3: false,
        });
        break;
    }
  }

  resetGameState() {
    this.gameStore.update({
      game: undefined,
      topic: null,
      revealPositions: false,
      selectedDimension: { name: 'd1', id: null, value: 1 },
      d1Called: 0,
      d2Called: 0,
      d3Called: 0,
      isVoting: false,
      isVotingModalOpen: false,
      bilateralDiscussion: {
        userId: '',
        incoming: false,
        status: BilateralStatus.empty,
        started: false,
        currentState: [],
        pending: undefined,
      },
      bilateralHistory: [],
      isModalOpen: false,
      isProposalTourEnd: false,
      errorMessage: '',
      countryCodeOfErrorMessage: '',
      breakGamePlaying: null,
      breakError: '',
      positionDescription: '',
      spotTheCountryOverlay: 0,
      coffeeBreak: {},
      spotTheCountryGames: [
        { name: 'white', value: false },
        { name: 'yellow', value: false },
        { name: 'green', value: false },
        { name: 'blue', value: false },
        { name: 'red', value: false },
        { name: 'black', value: false },
      ],
      spotTheCountriesFound: {},
      spotCountryCountdown: false,
      spotTheCountrySelectedGame: null,
      spotTheCountryGameResult: null,
      isFirstTimeDimension1: true,
      isFirstTimeDimension2: true,
      isFirstTimeDimension3: true,
      bilateralStatusAnimations: {
        accepted: false,
        acceptTradeOff: false,
        declined: false,
        pending: false,
        exchange: false,
        acceptAlliance: false,
        refuseAlliance: false,
        proposeTradeOff: false,
        refuseTradeOff: false,
      },
      bilateralNavigation: false,
      negotiationRemainTime: null,
      endBreakVote: null
    });
    const lang = this.userQuery.getLanguage();
    this.getTopics(lang);
  }

  updateBilateralNavigation(bool: boolean) {
    this.gameStore.update({
      bilateralNavigation: bool,
    });
  }
  updateBilateralAnimationsStatus(status: BilateralAnimationStatus) {
    switch (status) {
      case BilateralAnimationStatus.accepted:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            accepted: true,
          },
        }));
        break;
      case BilateralAnimationStatus.tradeoffAccepted:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            acceptTradeOff: true,
          },
        }));
        break;
      case BilateralAnimationStatus.declined:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            declined: true,
          },
        }));
        break;
      case BilateralAnimationStatus.pendingTrue:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            pending: true,
          },
        }));
        break;
      case BilateralAnimationStatus.pendingFalse:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            pending: false,
          },
        }));
        break;
      case BilateralAnimationStatus.exchange:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            exchange: true,
          },
        }));
        break;
      case BilateralAnimationStatus.acceptAlliance:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            acceptAlliance: true,
          },
        }));
        break;
      case BilateralAnimationStatus.refuseAlliance:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            refuseAlliance: true,
          },
        }));
        break;
      case BilateralAnimationStatus.refuseTradeOff:
        this.gameStore.update((state) => ({
          bilateralStatusAnimations: {
            ...state.bilateralStatusAnimations,
            refuseTradeOff: true,
          },
        }));
        break;
      default:
        break;
    }
  }
  resetBilateralAnimations() {
    this.gameStore.update({
      bilateralStatusAnimations: {
        accepted: false,
        acceptTradeOff: false,
        declined: false,
        pending: false,
        exchange: false,
        acceptAlliance: false,
        refuseAlliance: false,
        proposeTradeOff: false,
        refuseTradeOff: false,
      },
    });
  }
  sendRevealPositions(dimension: number) {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<IGameResponse>(
        `${this.envService.baseApiUrl}/Game/${gameId}/revealposition/${dimension}`,
        null
      )
      .pipe(take(1))
      .subscribe();
  }
}
