import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { IGameStart, ITopic, IUserGame } from 'src/app/models/api.model';
import { ErrorMessages, GameStatus } from 'src/app/models/game.model';
import { AvatarService } from 'src/app/services/avatar-customization.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { DifficultyLevel, getDifficultyLevel, getDifficultyLevelById } from 'src/app/shared/game.utils';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { GameStore } from 'src/app/state/gameState/game.store';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UserService } from 'src/app/state/userState/user.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Platform } from '@ionic/angular';
import { EnvService } from 'src/app/services/env.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from 'src/app/services/tracking.service';
import { GameInstanceService } from '../../services/game-instance.service';

@UntilDestroy()
@Component({
  selector: 'app-multiplayer-room',
  templateUrl: './multiplayer-room.page.html',
  styleUrls: ['./multiplayer-room.page.scss'],
})
export class MultiplayerRoomPage implements OnInit {
  @ViewChild('svg') svg: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isHost = false;
  userId = '';
  gameCode = '';
  topics$: Observable<ITopic[]>;
  gameOptionsForm: FormGroup;
  difficultyLevel = DifficultyLevel;
  avatarIndex: number[] = [];
  realUsers: IUserGame[] = [];
  changeName = false;
  newName = '';
  newNamePending = false;
  gameStatusSubscription: Subscription;
  removeUserId = '';
  kicked = false;
  isInformational = false;
  toast;

  constructor(
    private avatarService: AvatarService,
    private envService: EnvService,
    private platform: Platform,
    private router: Router,
    private socialSharing: SocialSharing,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private userQuery: UserQuery,
    private userService: UserService,
    private gameQuery: GameQuery,
    private gameStore: GameStore,
    private gameService: GameService,
    private webSocketService: WebSocketService,
    private gameInstanceService: GameInstanceService,
  ) { }

  ngOnInit() {
    this.topics$ = this.gameQuery.topics$;
    this.gameOptionsForm = new FormGroup({
      topic: new FormControl(''),
      difficultyLevel: new FormControl(null),
    });
    this.webSocketService.kickedEvent.pipe(untilDestroyed(this)).subscribe((res) => {
      this.kicked = res;
    });
    this.gameQuery.game$.pipe(untilDestroyed(this)).subscribe((game) => {
      if (game && game.single === 0) {
        const user = this.userQuery.getValue().user;
        const currrentUserGame = game.userGames?.find(ug => ug.userId === user.userId);
        this.isHost = currrentUserGame?.userHost === 1;
        if (!this.isHost) {
          this.gameOptionsForm.get('topic').disable();
          this.gameOptionsForm.get('difficultyLevel').disable();
        }
        this.gameCode = game.code;
        if (!this.isHost || !this.gameOptionsForm.get('topic').value) {
          this.gameOptionsForm.patchValue({
            topic: game.topic,
            difficultyLevel: !this.gameQuery.getCurrentTopicIsInformational() ? getDifficultyLevel(game.difficulty) : null
          });
        }
        if (!this.changeName) {
          this.newName = currrentUserGame.user.name;
        }
        this.userId = user.userId;

        this.realUsers = [currrentUserGame, ...game.userGames.filter(ug => ug.user.normalUser && ug.userId !== user.userId)];

        this.avatarIndex = [
          user.avatar_Body,
          user.avatar_SkinTone,
          user.avatar_Head,
          user.avatar_HairColor,
          user.avatar_Accessory,
          user.avatar_Face
        ];
        this.renderAvatar();
      }
    });
  }

  async onChangeName() {
    if (this.changeName) {
      if (this.newNamePending) {
        return;
      }
      this.newNamePending = true;
      await this.userService.changePlayerName(this.newName)
        .then(res => {
          this.gameStore.update(state => ({
            game: {
              ...state.game,
              userGames: state.game.userGames.map(ug => {
                if (ug.userId === this.userId) {
                  return {
                    ...ug,
                    user: {
                      ...ug.user,
                      name: this.newName
                    }
                  };
                }
                return ug;
              })
            }
          }));
        }).catch(err => console.log(err))
        .finally(() => {
          this.newNamePending = false;
        });
    }
    this.changeName = !this.changeName;
  }

  ionViewWillEnter() {
    this.gameStatusSubscription = this.gameQuery.select(state => state.game?.status).pipe(
      takeUntil(this.destroy$)
    )
      .pipe(untilDestroyed(this))
      .subscribe(status => {
        if (status === GameStatus.started && this.gameQuery.getValue().game.single === 0) {
          this.destroy$.next(true);
          this.gameInstanceService.loading.next(true);
          const lang = this.userQuery.getLanguage();
          this.gameService.getStaticTopics(lang)
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.gameInstanceService.loading.next(false);
              this.router.navigate(['/assign-countries']);
              this.gameStatusSubscription.unsubscribe();
            });
        }
      });
    if (this.gameStore.getValue().game?.status === GameStatus.started) {
      this.destroy$.next(true);
      this.router.navigate(['/assign-countries']);
      return;
    }
    this.renderAvatar();
    this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
  }

  renderAvatar() {
    if (this.svg?.nativeElement && this.avatarIndex.length) {
      this.svg.nativeElement.innerHTML = '';
      this.svg.nativeElement.appendChild(
        this.avatarService.customizationAvatar(
          this.avatarIndex[0],
          this.avatarIndex[1],
          this.avatarIndex[2],
          this.avatarIndex[3],
          this.avatarIndex[4],
          this.avatarIndex[5]
        )
      );
    }
  }

  onCustomizeAvatar() {
    this.userService.updateRouterUrl(this.router.url);
    this.router.navigate(['/customize_avatar']);
  }

  onLeaveGame() {
    this.destroy$.next(true);
    if (this.isHost) {
      this.gameService.gameQuit().then((res) => {
        this.gameStore.update(state => ({
          game: null,
          errorMessage: ''
        }));
      }).catch(err => console.log(err))
        .finally(() => this.router.navigate(['/multiplayer_mode']));
    } else if (this.gameCode) {
      this.gameService.gameLeave(this.gameCode).then((res) => {
        this.gameStore.update(state => ({
          game: null
        }));
      }).catch(err => console.log(err))
        .finally(() => this.router.navigate(['/multiplayer_mode']));
    }
  }

  closeModal() {
    if (this.removeUserId) {
      this.removeUserId = '';
    }
  }

  handleTopic(ev) {
    if (!this.isHost) {
      return;
    }
    this.gameService.gameUpdate({ gameId: this.gameQuery.getValue().game.gameId, topic: ev.target.value })
      .then(res => {
        this.gameStore.update(state => ({
          game: {
            ...state.game,
            topic: ev.target.value
          }
        }));
      }).finally(
        () => {
          this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
          this.isInformational ? this.gameOptionsForm.get('difficultyLevel').setValue(null) : '';
        }
    );
  }

  handleDifficultylevel(ev) {
    if (!this.isHost) {
      return;
    }
    this.gameService.gameUpdate({ gameId: this.gameQuery.getValue().game.gameId, difficulty: ev.target.value })
      .then(res => {
        this.gameStore.update(state => ({
          game: {
            ...state.game,
            difficulty: getDifficultyLevelById(ev.target.value)
          }
        }));
      });
  }

  shareGameCode() {
    if (this.platform.is('cordova')) {
      this.socialSharing.shareWithOptions({
        subject: this.translateService.instant('multi_player_room_share_code_subject'),
        message: this.translateService.instant('multi_player_room_share_code_msg', { value: this.gameCode }),
        url: this.envService.gameUrl
      }).then(() => {
        console.log('Successful share');
      }).catch((error) => {
        console.log('Error sharing:', error);
      });
    } else {
      if (!navigator.clipboard) {
        navigator.clipboard.writeText(this.gameCode).then(() => {
          this.gameService.updateErrorMessage(
            ErrorMessages.gameCodeCopied
          );
        }, (error) => {
          console.log(error);
        });
      } else {
        const createCopy = (e: ClipboardEvent) => {
          e.clipboardData.setData('text/plain', (this.gameCode));
          e.preventDefault();
          this.gameService.updateErrorMessage(
            ErrorMessages.gameCodeCopied
          );
        };
        document.addEventListener('copy', createCopy);
        document.execCommand('copy');
        document.removeEventListener('copy', createCopy);
      }
    }
  }

  onGameStart() {
    const body: IGameStart = {
      topic: this.gameOptionsForm.get('topic').value,
      difficulty: this.gameOptionsForm.get('difficultyLevel').value
    };
    this.trackingService.click({
      name: 'GameStart',
      type: 'event'
    });
    this.gameInstanceService.loading.next(true);
    this.gameService.gameStart(body).then((res) => {
      this.destroy$.next(true);
      this.router.navigate(['/assign-countries']);
    })
      .catch(err => console.log(err))
      .finally(() => this.gameInstanceService.loading.next(false));
  }

  selectPlayer(index: number) {
    if (this.realUsers[0]?.userHost && index > 0) {
      this.removeUserId = this.realUsers[index].userId;
    }
  }

  removePlayer() {
    if (this.kicked) {
      this.router.navigate(['/select_mode']);
      this.kicked = false;
      this.webSocketService.kickedEvent.emit(false);
    } else if (this.removeUserId) {
      this.gameService.removePlayer(this.gameCode, this.removeUserId)
        .then(() => {
          this.removeUserId = '';
        });
    }
  }
}
