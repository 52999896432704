import { Injectable } from '@angular/core';
import { UserQuery } from 'src/app/state/userState/user.query';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateResponse } from './models/api.model';
import { finalize, take, tap } from 'rxjs/operators';
import { EnvService } from './services/env.service';
import { UserService } from './state/userState/user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  languageLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private envService: EnvService,
    private translate: TranslateService,
    private http: HttpClient,
    private userQuery: UserQuery,
    private userService: UserService,
  ) { }

  getDefaultLanguage() {
    let currentLang = this.userQuery.getLanguage();
    if (!currentLang) {
      currentLang = this.translate.getBrowserLang() || 'en';
      this.userService.switchLanguage(currentLang);
    }
    this.translate.use(currentLang);
    this.getTranslate(currentLang).pipe(take(1)).subscribe();
    return currentLang;
  }

  getTranslate(language?: string) {
    this.languageLoading$.next(true);
    const lang = language || this.userQuery.getLanguage();
    return this.http
      .get<TranslateResponse>(
        `${this.envService.baseApiUrl}/Translations/dictionary/${lang}`,
        {}
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            const translations = value.data.items.filter(item => item.value).map(item => ({
              [item.name]: item.value
            }));
            this.translate.setTranslation(lang,
              Object.assign({}, ...translations)
            );
          }
        })
      ).pipe(
        finalize(() => {
          this.languageLoading$.next(false);
        })
      );
  }

  setTranslation(lang, translations) {
    this.translate.setTranslation(lang, translations);
  }

  changeLanguage(lang) {
    if (!this.translate.getLangs().includes(lang)) {
      this.getTranslate(lang).pipe(take(1)).subscribe(() => {
      this.translate.use(lang);
      });
    } else {
      this.translate.use(lang);
    }
  }

}
