/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ConsoleToggleService } from './consoletoggleservice.service';
import packageJson from '../../../package.json';

export enum Environment {
  debug = 'debug',
  test = 'test',
  dev = 'dev',
  local = 'local',
  stage = 'stage',
  production = 'production'
}

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  private _env: Environment;
  private _baseUrl: string;
  private _baseApiUrl: string;
  private _gameUrl: string;
  private _smartTagJs: string;
  private version: string = packageJson.version;

  constructor(
    private platform: Platform,
    private consoleToggleService: ConsoleToggleService
  ) {
  }

  get env(): Environment {
    return this._env;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  get baseApiUrl(): string {
    return this._baseApiUrl;
  }

  get gameUrl(): string {
    return this._gameUrl;
  }

  get smartTagJs(): string {
    return this._smartTagJs;
  }

  get appVersion(): string {
    return this.version;
  }

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;

    if (/^eucraft.stg.consilium.europa.eu/.test(hostname)) {
      this._env = Environment.stage;
      this._baseUrl = 'https://api-eucraft.stg.consilium.europa.eu';
      this._gameUrl = 'https://eucraft.stg.consilium.europa.eu';
      this._smartTagJs = 'https://tag.aticdn.net/630727/smarttag.js';
      this.consoleToggleService.disableConsoleInProduction();
    } else if (/^eucraft.dev.consilium.europa.eu/.test(hostname)) {
      this._env = Environment.dev;
      this._baseUrl = 'https://api-eucraft.dev.consilium.europa.eu';
      this._gameUrl = 'https://eucraft.dev.consilium.europa.eu';
      this._smartTagJs = 'https://tag.aticdn.net/630727/smarttag.js';
    } else if (/^eucraft.tst.consilium.europa.eu/.test(hostname)) {
      this._env = Environment.test;
      this._baseUrl = 'https://api-eucraft.tst.consilium.europa.eu';
      this._gameUrl = 'https://eucraft.tst.consilium.europa.eu';
      this._smartTagJs = 'https://tag.aticdn.net/630727/smarttag.js';
    } else if (/^eucraft.consilium.europa.eu/.test(hostname) || this.platform.is('capacitor')) {
      this._env = Environment.production;
      this._baseUrl = 'https://api-eucraft.consilium.europa.eu';
      this._gameUrl = 'https://eucraft.consilium.europa.eu';
      this._smartTagJs = 'https://tag.aticdn.net/632633/smarttag.js';
      this.consoleToggleService.disableConsoleInProduction();
    } else if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.dev;
      this._baseUrl = 'https://api-eucraft.dev.consilium.europa.eu';
      this._gameUrl = 'https://eucraft.dev.consilium.europa.eu';
      this._smartTagJs = 'https://tag.aticdn.net/630727/smarttag.js';
    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }

    this._baseApiUrl = this._baseUrl + '/api';
  }
}
