/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  BODY,
  ACCESSORIES,
  FACE,
  SKINTONE,
  HEADSTROKE,
  HAIR,
  HAIRBASE,
  FACEBASE,
  HAIRCOLOR,
  SKINCOLOR,
  HAIRSTROKECOLOR,
  // SLEEVES,
  HANDS,
  FEETS,
  BODYFLAG,
} from '../shared/avatar.utils';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { PhaserScenes } from '../models/game.model';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  constructor(private platform: Platform) {}

  customizationAvatar(
    bodyIndex,
    skinIndex,
    hairIndex,
    hairColorIndex,
    accessoriesIndex,
    faceIndex?,
    countryCode?,
    phaserSVG = null
  ) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.innerHTML = '';
    if (phaserSVG) {
      if (!this.platform.is('capacitor')) {
        svg.setAttribute('width', '120');
        svg.setAttribute('height', '196');
      } else if (this.platform.is('ios')) {
        svg.setAttribute('width', '7.5rem');
        svg.setAttribute('height', '100%');
      } else {
        svg.setAttribute('width', '120');
        svg.setAttribute('height', '196');
      }
    } else {
      svg.setAttribute('width', '6rem');
      svg.setAttribute('height', '100%');
    }
    svg.setAttribute('viewBox', '0 0 120 196');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    //Standar paths for avatar
    const pathHeadStroke = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathHeadStroke.innerHTML = HEADSTROKE[0];

    const pathBaseFace = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathBaseFace.innerHTML = FACEBASE[0];
    if (
      phaserSVG !== PhaserScenes.negotiationScene &&
      phaserSVG !== PhaserScenes.coffeeBreakScene &&
      phaserSVG !== PhaserScenes.bilateralScene
    ) {
      svg.appendChild(this.customizationHands(bodyIndex, skinIndex));
    }
    svg.appendChild(this.customizationBody(bodyIndex));
    if (phaserSVG !== PhaserScenes.negotiationScene) {
      svg.appendChild(this.customizationFeet(bodyIndex));
    }
    svg.appendChild(pathBaseFace);
    svg.appendChild(this.customizationSkinTone(skinIndex));
    if (
      phaserSVG !== PhaserScenes.negotiationScene &&
      phaserSVG !== PhaserScenes.presentationProposalScene &&
      phaserSVG !== PhaserScenes.coffeeBreakScene
    ) {
      svg.appendChild(this.customizationFace(faceIndex));
    }
    svg.appendChild(pathHeadStroke);
    svg.appendChild(this.customizationHair(hairIndex));
    svg.appendChild(this.customizationHairBase(hairIndex, hairColorIndex));
    if (accessoriesIndex > -1) {
      svg.appendChild(this.customizationAccessories(accessoriesIndex));
    }

    if (countryCode) {
      svg.appendChild(
        this.customizationFlagForBodyType(bodyIndex, countryCode)
      );
    }

    return svg;
  }

  customizationBody(bodyIndex) {
    const pathBody = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathBody.innerHTML = BODY[bodyIndex];

    return pathBody;
  }

  customizationHands(bodyIndex, skinIndex) {
    const pathHands = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathHands.innerHTML = HANDS[bodyIndex].replace(
      /HANDS_COLOR/g,
      SKINCOLOR[skinIndex]
    );

    return pathHands;
  }

  customizationFeet(bodyIndex) {
    const pathFeets = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathFeets.innerHTML = FEETS[bodyIndex];

    return pathFeets;
  }

  customizationAccessories(accessoriesIndex) {
    const pathAccessories = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathAccessories.innerHTML = ACCESSORIES[accessoriesIndex];

    return pathAccessories;
  }

  customizationFace(faceIndex) {
    const pathFace = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathFace.innerHTML = FACE[faceIndex];

    return pathFace;
  }

  customizationSkinTone(skinIndex) {
    const pathSkinTone = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathSkinTone.innerHTML = SKINTONE[skinIndex].replace(/DEF_ID/g, uuidv4());

    return pathSkinTone;
  }

  customizationHair(hairIndex) {
    const pathHair = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathHair.innerHTML = HAIR[hairIndex];

    return pathHair;
  }

  customizationHairBase(hairIndex, hairColorIndex) {
    const pathHairBase = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathHairBase.innerHTML = HAIRBASE[hairIndex].replace(
      /HAIR_COLOR/g,
      HAIRCOLOR[hairColorIndex]
    );

    return pathHairBase;
  }

  customizationFlagForBodyType(bodyIndex, countryCode) {
    //default value if nothing is passed in the function
    const pathFlag = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    pathFlag.innerHTML = BODYFLAG[bodyIndex]
      .find((el) => el.code === countryCode)
      .svg.replace(/<MASK_ID>/g, uuidv4())
      .replace(/<MASK_ID2>/g, uuidv4());
    return pathFlag;
  }
}
