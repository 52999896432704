// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  display: flex;
  flex-direction: column;
  background: var(--color-yellow-bookmark) linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48) 100%);
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.16);
  padding: 1rem;
  border-radius: 1rem;
  z-index: 2;
}
.box .text {
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--color-primary-blue);
  font-weight: bold;
}
.box .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.box .buttons div {
  background: white;
  border-radius: 8px;
  cursor: pointer;
}
.box .buttons .accept {
  --background: var(--color-primary-blue);
}
.box .buttons .decline {
  --border-color: var(--color-primary-blue);
  color: var(--color-primary-blue);
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/negotiation-modal/negotiation-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yHAAA;EACA,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;AACF;AACE;EACE,eAAA;EACA,qBAAA;EACA,gCAAA;EACA,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;AAAJ;AAEI;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;AAAN;AAGI;EACE,uCAAA;AADN;AAII;EACE,yCAAA;EACA,gCAAA;AAFN;;AAOA;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,8BAAA;AAJF;;AAMA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AAHF","sourcesContent":[".box {\n  display: flex;\n  flex-direction: column;\n  background: var(--color-yellow-bookmark) linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48) 100%);\n  box-shadow: 0 4px 0 0 rgb(0 0 0 / 16%);\n  padding: 1rem;\n  border-radius: 1rem;\n  z-index: 2;\n\n  .text {\n    font-size: 1rem;\n    line-height: 1.375rem;\n    color: var(--color-primary-blue);\n    font-weight: bold;\n  }\n\n  .buttons {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n    margin-top: 1rem;\n\n    div {\n      background: white;\n      border-radius: 8px;\n      cursor: pointer;\n    }\n\n    .accept {\n      --background: var(--color-primary-blue);\n    }\n\n    .decline {\n      --border-color: var(--color-primary-blue);\n      color: var(--color-primary-blue);\n    }\n  }\n}\n\n.overlay {\n  width: 100%;\n  height: 100%;\n  z-index: 20;\n  top: 0;\n  left: 0;\n  position: absolute;\n  background: rgba(0, 0, 0, 0.5);\n}\n.center {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
