import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserQuery } from '../userState/user.query';
import { GameStore, GameState } from './game.store';
import { BilateralStatus } from 'src/app/models/game.model';

@Injectable({ providedIn: 'root' })
export class GameQuery extends Query<GameState> {
  game$ = this.select('game');
  gameId$ = this.select((state) => state.game?.gameId);
  negotiationStarted$ = this.select((state) => state.game?.negotiationStarted);
  updateDate$ = this.select((state) => state.game?.updateDate);
  breakRound$ = this.select((state) => state.game?.breakRound);
  status$ = this.select((state) => state.game?.status);
  userGames$ = this.select((state) => state.game?.userGames);
  topics$ = this.select('topics');
  revealPositions$ = this.select('revealPositions');
  selectedDimension$ = this.select('selectedDimension');
  president$ = this.select('president');
  representative$ = this.select('representative');
  negotiationRemainTime$ = this.select('negotiationRemainTime');
  negotiationTotalTime$ = this.select('negotiationTotalTime');
  negotiationRoundTime$ = this.select('negotiationRoundTime');
  breakRemainTime$ = this.select('breakRemainTime');
  typeOfPhase$ = this.select('typeOfPhase');
  errorMessageState$ = this.select('errorMessage');
  coffeesAvailable$ = this.select((state) => state.game?.coffesAvailable);
  serverTime$ = this.select('diffFromServerTime');
  isInformational$ = this.select((state) => state.topics?.find((t) => t.id === state.game?.topic)?.isInformational || false);
  constructor(protected store: GameStore, protected userQuery: UserQuery) {
    super(store);
  }

  getCountryCodeOfErrorMessage(): string {
    return this.getValue().countryCodeOfErrorMessage;
  }

  getTypeOfPhase() {
    return this.getValue().typeOfPhase;
  }

  getNegotiationRemainTime() {
    return this.getValue().negotiationRemainTime;
  }

  getBreakRemainTime() {
    return this.getValue().breakRemainTime;
  }

  getVotingTime() {
    return this.getValue().votingTime;
  }

  getEndBreakVote() {
    return this.getValue().endBreakVote;
  }

  getBreakGamePlaying() {
    return this.getValue().breakGamePlaying;
  }

  getTopicsFromState(): any {
    return this.getValue().topics;
  }

  getGameFromState(): any {
    return this.getValue().game;
  }

  getGameId(): string {
    return this.getValue().game?.gameId;
  }

  getNegotiationStarted() {
    return this.getValue().game?.negotiationStarted;
  }

  getUpdateDate() {
    return this.getValue().game?.updateDate;
  }

  getNegotiationTotalTime() {
    return this.getValue().negotiationTotalTime;
  }

  getDiffFromServerTime() {
    return this.getValue().diffFromServerTime;
  }

  getBreakRound() {
    return this.getValue().game?.breakRound;
  }

  getGameStatus() {
    return this.getValue().game?.status;
  }

  getGameMode() {
    return this.getValue().game?.single;
  }

  getGameDifficultyLevel() {
    return this.getValue().game?.difficulty;
  }

  isHost(): boolean {
    const userId = this.userQuery.getUserId();
    const currentUserGame = this.getValue().game?.userGames?.find(
      (ug) => ug.userId === userId
    );
    return currentUserGame?.userHost === 1;
  }

  getTopicId(): number {
    return this.getValue().game?.topic;
  }

  getCurrentTopic() {
    const topicId = this.getValue().game?.topic;
    return this.getValue().topics.find((t) => t.id === topicId);
  }

  getCurrentTopicIsInformational(){
    return this.getCurrentTopic()?.isInformational;
  }

  getCurrentTopicSpeakerIsSecreteriat() {
    return this.getCurrentTopic()?.speakerIsSecreteriat;
  }

  getNumOfDimensionsCalled() {
    return {
      d1: this.getValue().d1Called,
      d2: this.getValue().d2Called,
      d3: this.getValue().d3Called,
    };
  }

  getBilateralUser() {
    return this.getValue().bilateralDiscussion.userId;
  }

  getBilateralStatus() {
    return this.getValue().bilateralDiscussion.status;
  }

  getUserGames() {
    return this.getValue().game?.userGames;
  }
  getSelectedDimension() {
    return this.getValue().selectedDimension;
  }

  getErrorMessage() {
    return this.getValue().errorMessage;
  }

  getSpotTheCountryGames() {
    return this.getValue().spotTheCountryGames;
  }
  getSpotTheCountryGameResult() {
    return this.getValue().spotTheCountryGameResult;
  }

  getSpotTheCountriesFound() {
    return this.getValue().spotTheCountriesFound;
  }

  getIsFirstTimeInDimension(dimension) {
    switch (dimension) {
      case 1:
        return this.getValue().isFirstTimeDimension1;
      case 2:
        return this.getValue().isFirstTimeDimension2;
      case 3:
        return this.getValue().isFirstTimeDimension3;
    }
  }

  hasOpenBilateral() {
    return this.getBilateralUser()
      && this.getBilateralStatus() !== BilateralStatus.pending
      && this.getBilateralStatus() !== BilateralStatus.cancelled
      && this.getBilateralStatus() !== BilateralStatus.cancelledPlayingGame;
  }
}
