import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IGame } from 'src/app/models/api.model';
import { GameQuery } from 'src/app/state/gameState/game.query';
import anime from 'animejs/lib/anime.es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-voting-sidebar-component',
  templateUrl: './voting-sidebar.component.html',
  styleUrls: ['./voting-sidebar.component.scss'],
})
export class VotingSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;
  @Input() trigger: BehaviorSubject<boolean>;
  public topicTitle = '';
  pastFiveSeconds = false;
  public game?: IGame;
  proposals: { positionName: string; dimensionName: string }[] = [];
  narration: anime.timeline;

  constructor(
    private router: Router,
    private gameQuery: GameQuery,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.gameQuery.game$,
      this.gameQuery.topics$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([game, topics]) => {
        if (!topics.length) {
          return;
        }
        this.game = game;
        const proposalsTmp = topics.find((t) => t.id === game?.topic)?.dimension.map(d => ({
          positionName: d.positionContent.find(pc => pc.id === +game.finalProposals?.find(
            fp => +fp.dimension === d.id
          )?.position)?.name,
          dimensionName: d.name
        })) || [];
        if (!_.isEqual(this.proposals, proposalsTmp)) {
          this.proposals = proposalsTmp;
        }
      });
  }

  ngAfterViewInit() {
    this.trigger.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        if ((document.querySelector('.anim-4 .word') as HTMLSpanElement).style.opacity === '0') {
          this.playNarration();
        } else if ((document.querySelector('.anim-6 .word') as HTMLSpanElement).style.opacity === '0') {
          setTimeout(() => {
            this.dimensionNarration();
          }, 200);
        }
      }
    });
  }

  playNarration() {
    setTimeout(() => {
      if(!this.narration)
      {
        this.narration = anime.timeline({loop: false})
        .add({
          targets: '.anim-4 .word',
          scale: [4, 1],
          opacity: [0, 1],
          translateZ: 0,
          easing: 'easeOutExpo',
          delay: (el, i) => 120 * i,
          complete: () => {
            anime.timeline({loop: false})
              .add({
                targets: '.anim-5 .word',
                scale: [4, 1],
                opacity: [0, 1],
                translateZ: 0,
                easing: 'easeOutExpo',
                delay: (el, i) => 120 * i,
                complete: () => {
                  this.dimensionNarration();
                }
              });
          }
        });
      } else {
        this.narration.restart();
      }
    }, 200);
  }

  dimensionNarration() {
    anime.timeline({loop: false})
      .add({
        targets: '.anim-6 .word',
        scale: [4, 1],
        opacity: [0, 1],
        translateZ: 0,
        easing: 'easeOutExpo',
        delay: (el, i) => 120 * i,
        complete: () => {
          anime.timeline({loop: false})
            .add({
              targets: '.anim-7 .word',
              scale: [4, 1],
              opacity: [0, 1],
              translateZ: 0,
              easing: 'easeOutExpo',
              delay: (el, i) => 120 * i,
              complete: () => {
                if ((document.querySelector('.anim-8 .word') as HTMLSpanElement).style.opacity === '0') {
                  anime.timeline({loop: false})
                    .add({
                      targets: '.anim-8 .word',
                      scale: [4, 1],
                      opacity: [0, 1],
                      translateZ: 0,
                      easing: 'easeOutExpo',
                      delay: (el, i) => 120 * i,
                      complete: () => {
                        anime.timeline({loop: false})
                          .add({
                            targets: '.anim-9 .word',
                            scale: [4, 1],
                            opacity: [0, 1],
                            translateZ: 0,
                            easing: 'easeOutExpo',
                            delay: (el, i) => 120 * i,
                            complete: () => {
                              anime.timeline({loop: false})
                                .add({
                                  targets: '.anim-10 .word',
                                  scale: [4, 1],
                                  opacity: [0, 1],
                                  translateZ: 0,
                                  easing: 'easeOutExpo',
                                  delay: (el, i) => 120 * i,
                                });
                            }
                          });
                      }
                    });
                }
              }
            });
        }
      });
  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.pastFiveSeconds = true;
    }, 5000);
  }

  onProposal() {
    this.router.navigate(['/proposal']);
  }
}
