import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { UserQuery } from '../state/userState/user.query';

@Injectable()
export class CsrfTokenInterceptorService implements HttpInterceptor {
  constructor(
    private userQuery: UserQuery
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.userQuery.getCsrfToken();
    let newHeaders = req.headers;
    if (token) {
      newHeaders = newHeaders.append('X-CSRF-TOKEN', token);
    }
    const authReq = req.clone({headers: newHeaders});
    return next.handle(authReq);
  }
}
