import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { Router } from '@angular/router';
import { GameService } from 'src/app/state/gameState/game.service';
import { EmptyScene, makeEmptyScene } from 'src/app/phaser/scenes/empty.scene';

@Component({
  selector: 'app-voting-modal-component',
  templateUrl: './voting-modal.component.html',
  styleUrls: ['./voting-modal.component.scss'],
})
export class VotingModalComponent {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;
  constructor(
    private gameInstanceService: GameInstanceService,
    private router: Router,
    private gameService: GameService
  ) {}

  onGoForVote() {
    // this.gameInstanceService.bringToTop();
    this.router.navigate(['/presentation_proposal']);
    this.gameService.updateIsVotingModalOpen(false);
  }
}
