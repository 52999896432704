import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { BreakGames, ErrorMessages } from 'src/app/models/game.model';
import { GameService } from 'src/app/state/gameState/game.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-coffee-break-bar',
  templateUrl: './coffee-break-bar.component.html',
  styleUrls: ['./coffee-break-bar.component.scss'],
})
export class CoffeeBreakBarComponent implements OnInit {
  @Input() coffeeBreak = false;
  @Input() countdown: number;
  @Input() spotTheCountryTitle: string;
  @Input() spotTheCountrySubtitle: string;

  goToCoffeeBreakButton$: Observable<boolean>;
  numberOfCoffees$: Observable<number>;

  openedMenu = false;
  breakGames = BreakGames;
  gamePlaying$: Observable<BreakGames>;
  isPlaying = false;
  breakGamePlaying: Subscription;

  countryOnePath = '';
  countryTwoPath = '';
  countryThreePath = '';
  countriesFoundSubscription: Subscription;
  firstCountryCodeFlag: string;
  secondCountryCodeFlag: string;
  thirdCountryCodeFlag: string;
  type = null;

  constructor(
    private router: Router,
    private gameService: GameService,
    private gameQuery: GameQuery,
    public gameInstanceService: GameInstanceService,
  ) {
    this.numberOfCoffees$ = this.gameQuery.coffeesAvailable$;
    this.gameService.updateBreakError('');
    this.gamePlaying$ = this.gameQuery.select('breakGamePlaying').pipe(untilDestroyed(this));
    this.gameQuery.select('spotTheCountryGameResult').pipe(untilDestroyed(this)).subscribe((res) => {
      if (res === 'success') {
        this.type = res;
      } else if (res === 'fail') {
        this.type = res;
      } else {
        this.type = null;
      }
    });

    this.countriesFoundSubscription = this.gameQuery
      .select('spotTheCountriesFound')?.pipe(untilDestroyed(this)).subscribe((res) => {
        console.log({ res });

        this.firstCountryCodeFlag = res[1] === undefined ? '' : res[1];
        this.secondCountryCodeFlag = res[2] === undefined ? '' : res[2];
        this.thirdCountryCodeFlag = res[3] === undefined ? '' : res[3];

        this.countryOnePath = `../../../assets/flags/${this.firstCountryCodeFlag}.svg`;
        this.countryTwoPath = `../../../assets/flags/${this.secondCountryCodeFlag}.svg`;
        this.countryThreePath = `../../../assets/flags/${this.thirdCountryCodeFlag}.svg`;
      });

    this.breakGamePlaying = this.gameQuery.select('breakGamePlaying').pipe(untilDestroyed(this)).subscribe((res) => {
      this.isPlaying = res !== null;
    });
  }

  ngOnInit() {}

  ionViewWillExit() {
    this.gameService.updateBreakGamePlaying(null);
  }

  goToCoffeeBreak($event: any) {
    this.router.navigate(['coffee-break']);
  }

  toggleTasksMenu() {
    if (!this.isPlaying) {
      this.openedMenu = !this.openedMenu;
    } else {
      this.gameService.updateErrorMessage(ErrorMessages.breakTaskPlaying);
    }
  }

  preventClick(e: any) {
    e.stopPropagation();
  }

  goToTask(task: BreakGames) {
    if (!this.isPlaying) {
      this.toggleTasksMenu();
      if (task === BreakGames.spotTheCountry) {
        if (
          this.gameInstanceService.timesPlayedPerBreakSpotTheCountry.getValue() >
          0
        ) {
          return;
        }
        this.gameService.updateSpotTheCountryOverlay(1);
      }
      if (task === BreakGames.drinkACoffee) {
        if (
          this.gameInstanceService.timesPlayedPerBreakDrinkCoffee.getValue() > 0
        ) {
          return;
        }
        this.gameService.updateBreakGamePlaying(task);
      }
      if (task === BreakGames.emoji) {
        if (
          this.gameInstanceService.timesPlayedPerBreakEmoji.getValue() > 0
        ) {
          return;
        }
        this.gameService.updateBreakGamePlaying(task);
      }
      if (task === BreakGames.talkToAnExpert) {
        if (this.gameInstanceService.timesPlayedPerBreakTalkToAnExpert.getValue() > 0) {
          return;
        }
        this.gameService.updateBreakGamePlaying(task);
        this.gameInstanceService.startQuiz();
      }
    } else {
      this.gameService.updateErrorMessage(ErrorMessages.breakTaskPlaying);
    }
  }
}
