import { Component, ElementRef, ViewChild } from '@angular/core';
import { getRandomInt } from 'src/app/shared/math.utils';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UserService } from 'src/app/state/userState/user.service';
import {
  IAnswer,
  IFinalAnwser,
  IGameQuiz,
  IUserGame,
} from '../../models/api.model';
import { Router } from '@angular/router';
import { AvatarService } from '../../services/avatar-customization.service';
import { AudioService } from 'src/app/services/audio.service';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { BreakGames } from 'src/app/models/game.model';
import _ from 'lodash';

interface CheckBox {
  value: string;
  questionId: any;
  isItemChecked: boolean;
  disabled: boolean;
  isCorrect: boolean;
  isWrong: boolean;
}

@Component({
  selector: 'app-quiz-game-page',
  templateUrl: './quiz-game.page.html',
  styleUrls: ['./quiz-game.page.scss'],
})
export class QuizGamePage {
  @ViewChild('currentUserAvatar') currentUserAvatar: ElementRef;
  @ViewChild('expertAvatar') expertAvatar: ElementRef;
  userGames: IUserGame[] = [];
  userId: string;
  onGameStart: boolean;
  gameQuiz: IGameQuiz;
  answers: IAnswer[];
  randomQuestionId: number;
  correctAnswers = false;
  playerPopup = false;
  notCorrectAnswers: boolean;
  isDisabled: boolean;
  enableErrorMessage: boolean;
  finalAnswers: IFinalAnwser[];
  isDisabledAnswer: boolean;
  checkboxes: CheckBox[];
  timesPlayedSameQuestion: number;
  maxTimesPlayed: boolean;
  playerPopupTimeout: any;

  constructor(
    private gameQuery: GameQuery,
    private userService: UserService,
    private userQuery: UserQuery,
    private gameService: GameService,
    private router: Router,
    private avatarService: AvatarService,
    private audioService: AudioService,
    private gameInstanceService: GameInstanceService
  ) {
    this.checkboxes = [];
  }

  ionViewWillEnter() {
    if (this.gameInstanceService.timesPlayedPerBreakTalkToAnExpert.getValue() === 0 &&
      !this.gameInstanceService.firstTimeVisitQuizGame.getValue()) {
      this.enableErrorMessage = false;
      this.maxTimesPlayed = false;
      this.userId = this.userQuery.getValue().user.userId;
      this.userGames = this.gameQuery.getValue().game.userGames;
      const currentUserGame = this.userGames.find(
        (ug) => ug.userId === this.userId
      );
      if (currentUserGame) {
        this.currentUserAvatar.nativeElement.innerHTML = '';
        this.currentUserAvatar.nativeElement.appendChild(
          this.avatarService.customizationAvatar(
            currentUserGame.user.avatar_Body,
            currentUserGame.user.avatar_SkinTone,
            currentUserGame.user.avatar_Head,
            currentUserGame.user.avatar_HairColor,
            currentUserGame.user.avatar_Accessory,
            currentUserGame.user.avatar_Face,
            currentUserGame.country
          )
        );
      }

      this.timesPlayedSameQuestion = 0;
      this.gameInit();
      this.gameService.updateBreakGamePlaying(BreakGames.talkToAnExpert);
    } else {
      return;
    }
  }

  verifyCheckOnClick() {
    let selected = 0;
    this.checkboxes.forEach((item) => {
      if (item.isItemChecked) {
        selected++;
      }
    });

    if (selected >= 0 && selected < 3) {
      this.enableErrorMessage = false;
    }

    if (selected > 0 && selected < 2) {
      this.checkboxes.map((item) => {
        if (!item.isItemChecked) {
          return (item.disabled = false);
        }
      });
      this.isDisabled = false;
    } else if (selected === 0) {
      this.checkboxes.map((item) => {
        if (!item.isItemChecked) {
          return (item.disabled = false);
        }
      });
      this.isDisabled = true;
    } else {
      this.checkboxes.map((item) => {
        if (!item.isItemChecked) {
          return (item.disabled = true);
        }
      });
      this.isDisabled = false;
    }
  }

  onStartGame() {
    this.onGameStart = true;
  }

  gameReset(retry = false) {
    this.onGameStart = false;

    if (retry) {
      this.onGameStart = true;
    }

    this.isDisabledAnswer = false;
    this.isDisabled = true;
    this.enableErrorMessage = false;
    this.finalAnswers = [];
    this.correctAnswers = false;
    this.notCorrectAnswers = false;
    this.answers = [];
    this.playerPopup = false;
    this.checkboxes.map((item) => {
      item.isItemChecked = false;
      item.disabled = false;
      item.isCorrect = false;
      item.isWrong = false;
    });
  }

  gameInit() {
    if (this.gameInstanceService.timesPlayedPerBreakTalkToAnExpert.getValue() === 0 &&
      !this.gameInstanceService.firstTimeVisitQuizGame.getValue()) {
      this.gameInstanceService.firstTimeVisitQuizGame.next(true);
      this.gameReset();
      this.checkboxes = [];
      const notPlayedIds =
        _.shuffle(this.gameQuery
          .getCurrentTopic()
          ?.talkToAnExpert.map((t) => t.id)
          .filter(
            (tid) => !this.userQuery.getQuizAnswerdQuestions().includes(tid)
          ) || []);
      if (!notPlayedIds.length) {
        console.log('you played all the questions');
      } else {
        this.randomQuestionId =
          notPlayedIds[getRandomInt(notPlayedIds.length - 1)];
        this.gameQuiz = this.gameQuery
          .getCurrentTopic()
          .talkToAnExpert.find((quiz) => quiz.id === this.randomQuestionId);
        this.gameQuiz.questionChoice.forEach((question) => {
          this.checkboxes.push({
            value: question.question,
            questionId: question.id,
            isItemChecked: false,
            disabled: false,
            isCorrect: false,
            isWrong: false,
          });
        });
      }
    }
  }

  checkAllTheAnswers() {
    return this.answers.every((element) => element.answer === false);
  }

  onCheckAnswers() {
    if (this.timesPlayedSameQuestion > 1) {
      this.goBack();
      return;
    }

    const checkboxLength = this.checkboxes.filter((item) => item.isItemChecked).length;

    if (checkboxLength < 2) {
      this.enableErrorMessage = true;
      return;
    }

    this.gameInstanceService.timesPlayedPerBreakTalkToAnExpert.next(
      this.gameInstanceService.timesPlayedPerBreakTalkToAnExpert.getValue() + 1
    );
    this.timesPlayedSameQuestion++;

    this.isDisabled = true;
    this.checkboxes.forEach((item) => {
      this.answers.push({
        questionId: item.questionId,
        answer: item.isItemChecked,
      });
    });
    const body = {
      gameId: this.gameQuery.getGameId(),
      talkToAnExpertId: this.randomQuestionId,
      questions: this.answers,
    };
    this.gameInstanceService.loading.next(true);
    this.gameService
      .getQuizAnswers(body)
      .toPromise()
      .then((res) => {
        this.gameInstanceService.loading.next(false);
        if (res && res.status === 'Success') {
          this.finalAnswers = res.data.items;
          this.finalAnswers.forEach((item) => {
            this.checkboxes.find(
              (checkbox) => checkbox.questionId === item.questionId
            ).isCorrect = item.answer && item.correct;
            this.checkboxes.find(
              (checkbox) => checkbox.questionId === item.questionId
            ).isWrong = item.answer && !item.correct;
          });

          if (this.checkboxes.filter((checkbox) => checkbox.isCorrect).length === 2) {
            this.correctAnswers = true;
            this.audioService.play('complete-task');
            this.playerPopupTimeout = setTimeout(() => {
              this.playerPopup = true;
            }, 5000);

            this.notCorrectAnswers = false;
          } else {
            if (this.timesPlayedSameQuestion === 2) {
              this.maxTimesPlayed = true;
              this.notCorrectAnswers = false;
              this.playerPopupTimeout = setTimeout(() => {
                this.playerPopup = true;
              }, 5000);
            } else {
              this.notCorrectAnswers = true;
            }
          }
          this.userService.updateQuizAnswerdQuestions(this.randomQuestionId);
        }
      })
      .finally(() => {
        this.enableErrorMessage = false;
        this.gameInstanceService.loading.next(false);
      });
  }

  goBack() {
    this.gameService.updateBreakGamePlaying(BreakGames.talkToAnExpert);
    this.gameInstanceService.firstTimeVisitQuizGame.next(false);
    this.router.navigate(['/coffee-break']);
  }
}
