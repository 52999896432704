/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessages, HelpRoutes } from 'src/app/models/game.model';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UserService } from '../../state/userState/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { GameService } from 'src/app/state/gameState/game.service';

@UntilDestroy()
@Component({
  selector: 'app-setting-help-component',
  templateUrl: './setting-help.component.html',
  styleUrls: ['./setting-help.component.scss'],
})
export class SettingHelpComponent implements OnInit {
  @Input() showSettings = true;
  @Input() showHelp = true;
  @Input() showToggle = false;
  @Input() checked = false;
  @Input() coffeeBreak = false;
  @Input() single = true;
  @Output() toggleChange = new EventEmitter();

  mute: boolean;
  flag: boolean;
  helpModalOpen = false;
  isToggleChecked = false;
  page = 1;
  totalPages = 1;
  title: string;
  showExitGame = false;
  isInformational = false;
  help = {
    title: [],
    texts: [],
  };
  redOvalOpen = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private userQuery: UserQuery,
    public gameQuery: GameQuery,
    public gameService: GameService,
    public gameInstanceService: GameInstanceService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.userQuery.settings$
      .pipe(untilDestroyed(this))
      .subscribe((settings) => {
        this.mute = settings.mute;
      });
    this.flag =
      this.router.url === '/bilateral-discussion' ||
      this.router.url === '/negotiation_phase';

    this.gameInstanceService.toggleChecked$.asObservable().pipe(untilDestroyed(this)).subscribe(res => {
      this.isToggleChecked = res;
    });

    this.gameQuery.gameId$.pipe(untilDestroyed(this)).subscribe(gameId => {
      this.showExitGame = gameId !== undefined && this.router.url === '/negotiation_phase';
    });

    this.gameQuery.isInformational$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isInformational = value;
    });

  }

  onSettings() {
    this.userService.updateRouterUrl(this.router.url);
    this.router.navigate(['/game_settings']);
  }

  onChange(e) {
    this.toggleChange.emit(e);
    if (this.redOvalOpen) {
      this.redOvalOpen = false;
    }
  }

  onHelp() {
    const helpPage = this.router.url;
    if (
      helpPage === HelpRoutes.singlePlayer ||
      helpPage === HelpRoutes.multiPlayer ||
      helpPage === HelpRoutes.joinGame
    ) {
      this.userService.updateRouterUrl(this.router.url);
      this.router.navigate(['/help']);
      return;
    }
    this.helpModalOpen = !this.helpModalOpen;
    this.page = 1;
    switch (helpPage) {
      case HelpRoutes.negotiationBoard:
        this.help = !this.isInformational ? {
          title: [
          {},
          {
            header: 'help_negotiation_phase_how_to_play_p1_title'
          },
          {
            header: 'help_negotiation_phase_how_to_play_p2_title'
          },
          {
            header: 'help_negotiation_phase_how_to_play_p3_title'
          }
          ],
          texts: [
            {},
            {
              text1: 'help_negotiation_phase_how_to_play_p1_par1',
              text2: 'help_negotiation_phase_how_to_play_p1_par2',
            },
            {
              text1: 'help_negotiation_phase_how_to_play_p2_par1',
              text2: 'help_negotiation_phase_how_to_play_p2_par2',
            },
            {
              text1: 'help_negotiation_phase_how_to_play_p3_par1',
              text2: 'help_negotiation_phase_how_to_play_p3_par2',
            },
          ],
        } :
          {
            title: [
              {},
              {
                header: 'help_negotiation_phase_how_to_play_p1_isinformational_title'
              },
              {
                header: 'help_negotiation_phase_how_to_play_p2_isinformational_title'
              },
              {
                header: 'help_negotiation_phase_how_to_play_p3_isinformational_title'
              }
            ],
            texts: [
              {},
              {
                text1: 'help_negotiation_phase_how_to_play_p1_isinformational_par1',
                text2: 'help_negotiation_phase_how_to_play_p1_isinformational_par2',
              },
              {
                text1: 'help_negotiation_phase_how_to_play_p2_isinformational_par1',
                text2: 'help_negotiation_phase_how_to_play_p2_isinformational_par2',
              },
              {
                text1: 'help_negotiation_phase_how_to_play_p3_isinformational_par1',
                text2: 'help_negotiation_phase_how_to_play_p3_isinformational_par2',
              },
            ],
          };
        break;
      case HelpRoutes.coffeeBreak:
        this.help = {
          title: [
            {},
            {
              header: 'help_breaks_how_to_play_p1_title'
            },
            {
              header: 'help_breaks_how_to_play_p2_title'
            },
            {
              header: 'help_breaks_how_to_play_p3_title'
            }

          ],
          texts: [
            {},
            {
              text1: 'help_breaks_how_to_play_p1_par1',
              text2: 'help_breaks_how_to_play_p1_par2',
            },
            {
              text1: 'help_breaks_how_to_play_p2_par1',
              text2: 'help_breaks_how_to_play_p2_par2',
            },
            {
              text1: 'help_breaks_how_to_play_p3_par1',
              text2: 'help_breaks_how_to_play_p3_par2',
            },
          ],
        };
        break;
      case HelpRoutes.bilateralDiscussion:
        this.help = {
          title: [
            {},
            {
              header: 'help_bilateral_how_to_play_p1_title'
            },
            {
              header: 'help_bilateral_how_to_play_p2_title'
            },
            {
              header: 'help_bilateral_how_to_play_p3_title'
            }
          ],
          texts: [
            {},
            {
              text1: 'help_bilateral_how_to_play_p1_par1',
              text2: 'help_bilateral_how_to_play_p1_par2',
            },
            {
              text1: 'help_bilateral_how_to_play_p2_par1',
              text2: 'help_bilateral_how_to_play_p2_par2',
            },
            {
              text1: 'help_bilateral_how_to_play_p3_par1',
              text2: 'help_bilateral_how_to_play_p3_par2',
            },
          ],
        };
        break;
      default:
        break;
    }
    if (
      (this.translateService.instant(this.help.texts[2].text1) !==
        this.help.texts[2].text1 ||
        this.translateService.instant(this.help.texts[2].text2) !==
        this.help.texts[2].text2) &&
      (this.translateService.instant(this.help.texts[3].text1) !==
        this.help.texts[3].text1 ||
        this.translateService.instant(this.help.texts[3].text2) !==
        this.help.texts[3].text2)
    ) {
      this.totalPages = 3;
    } else if (
      this.translateService.instant(this.help.texts[2].text1) !==
      this.help.texts[2].text1 ||
      this.translateService.instant(this.help.texts[2].text2) !==
      this.help.texts[2].text2
    ) {
      this.totalPages = 2;
    }
  }

  onNext() {
    this.page++;
    if (
      this.translateService.instant(this.help.texts[this.page].text1) ===
      this.help.texts[this.page].text1 &&
      this.translateService.instant(this.help.texts[this.page].text2) ===
      this.help.texts[this.page].text2
    ) {
      this.helpModalOpen = false;
      this.page = 1;
    }
  }

  quitGame() {
    this.gameService.updateErrorMessage(ErrorMessages.quitGame);
  }

  onClose() {
    this.helpModalOpen = false;
  }

  switchMute() {
    this.userService.switchMute();
  }

  preventClick(e: any) {
    e.stopPropagation();
  }

  endBreak() {
    const errMsg = this.single ? ErrorMessages.endBreakMessage : ErrorMessages.endBreakMessageMulti;
    this.gameService.updateErrorMessage(
      errMsg
    );
  }
}
