// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-background {
  --background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-background .logo-small {
  position: absolute;
  top: 2.083rem;
  right: 2.25rem;
  height: 4.075rem;
}
.main-background .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.main-background .container .disclaimer {
  width: 30rem;
  text-align: center;
  margin-top: 4rem;
}
.main-background .container .disclaimer .title {
  margin-bottom: 1rem;
  color: var(--color-primary-blue);
  font-weight: bold;
  font-size: 2rem;
}
.main-background .container .disclaimer .text {
  margin-bottom: 2rem;
  color: var(--color-primary-blue);
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: 400;
}
.main-background .container .disclaimer .start-btn {
  min-width: 10.667rem;
  height: 2.667rem;
  min-height: 2.667rem;
  --min-height: 2.667rem;
  font-size: 1.167rem;
  font-weight: 600;
  text-transform: uppercase !important;
}`, "",{"version":3,"sources":["webpack://./src/app/game/game-starting/game-starting.page.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;AAEI;EACE,YAAA;EACA,kBAAA;EACA,gBAAA;AAAN;AAEM;EACE,mBAAA;EACA,gCAAA;EACA,iBAAA;EACA,eAAA;AAAR;AAGM;EACE,mBAAA;EACA,gCAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AADR;AAIM;EACE,oBAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,oCAAA;AAFR","sourcesContent":[".main-background {\n  --background: transparent;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .logo-small {\n    position: absolute;\n    top: 2.083rem;\n    right: 2.25rem;\n    height: 4.075rem;\n  }\n\n  .container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n\n    .disclaimer {\n      width: 30rem;\n      text-align: center;\n      margin-top: 4rem;\n\n      .title {\n        margin-bottom: 1rem;\n        color: var(--color-primary-blue);\n        font-weight: bold;\n        font-size: 2rem;\n      }\n\n      .text {\n        margin-bottom: 2rem;\n        color: var(--color-primary-blue);\n        font-size: 1.2rem;\n        line-height: 1.3rem;\n        font-weight: 400;\n      }\n\n      .start-btn {\n        min-width: 10.667rem;\n        height: 2.667rem;\n        min-height: 2.667rem;\n        --min-height: 2.667rem;\n        font-size: 1.167rem;\n        font-weight: 600;\n        text-transform: uppercase !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
