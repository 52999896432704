import { Component, Input, OnInit } from '@angular/core';
import { GameQuery } from "../../state/gameState/game.query";

@Component({
  selector: 'app-negotiation-title',
  templateUrl: './negotiation-title.component.html',
  styleUrls: ['./negotiation-title.component.scss'],
})
export class NegotiationTitleComponent implements OnInit {
  @Input() topic;
  @Input() dimension;

  isInformational = false;

  constructor(
    private gameQuery: GameQuery
  ) {}

  ngOnInit() {
    this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
  }
}
