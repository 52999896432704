import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.page.html',
  styleUrls: ['./splash-screen.page.scss'],
})
export class SplashScreenPage implements OnInit {
  public enter = false;
  constructor(
    private router: Router,
    private platform: Platform,
  ) { }

  ionViewDidEnter() {
    const nextPage = (this.platform.is('android') || this.platform.is('ios')) && this.platform.is('mobileweb')
      ? ['/download']
      : ['/launch_screen'];
    setTimeout(() => this.router.navigate(nextPage), 3000);
    this.enter = true;
  }

  ngOnInit() {
  }

}
