import * as Phaser from 'phaser';
import { Subject, Subscription, timer } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { IUserGame } from 'src/app/models/api.model';
import { GameStatus, PhaserScenes } from 'src/app/models/game.model';
import moment from 'moment';

let context: any;

const avatarPositions = [
  { x: 800, y: 225 },   //0
  { x: 1010, y: 225 },  //1
  { x: 1100, y: 235 },  //2
  { x: 1190, y: 245 },  //3
  { x: 1270, y: 275 },  //4
  { x: 1350, y: 310 },  //5
  { x: 1410, y: 365 },  //6
  { x: 1450, y: 440 },  //7
  { x: 1470, y: 530 },  //8
  { x: 1430, y: 610 },  //9
  { x: 1370, y: 660 },  //10
  { x: 1300, y: 700 },  //11
  { x: 1230, y: 730 },  //12
  { x: 1150, y: 750 },  //13
  { x: 1060, y: 770 },  //14
  { x: 870, y: 790 },   //15
  { x: 760, y: 780 },   //16
  { x: 650, y: 750 },   //17
  { x: 550, y: 710 },   //18
  { x: 480, y: 670 },   //19
  { x: 410, y: 615 },   //20
  { x: 350, y: 550 },   //21
  { x: 370, y: 450 },   //22
  { x: 430, y: 360 },   //23
  { x: 500, y: 310 },   //24
  { x: 600, y: 270 },   //25
  { x: 690, y: 240 },   //26
];

const countriesInitialPositions = [
  {
    countryCode: 'AT',
    position: 9,
  },
  {
    countryCode: 'BE',
    position: 25,
  },
  {
    countryCode: 'BG',
    position: 10,
  },
  {
    countryCode: 'HR',
    position: 6,
  },
  {
    countryCode: 'CY',
    position: 21,
  },
  {
    countryCode: 'CZ',
    position: 1,
  },
  {
    countryCode: 'DK',
    position: 22,
  },
  {
    countryCode: 'EE',
    position: 11,
  },
  {
    countryCode: 'FI',
    position: 7,
  },
  {
    countryCode: 'FR',
    position: 2,
  },
  {
    countryCode: 'DE',
    position: 5,
  },
  {
    countryCode: 'GR',
    position: 18,
  },
  {
    countryCode: 'HU',
    position: 24,
  },
  {
    countryCode: 'IE',
    position: 20,
  },
  {
    countryCode: 'IT',
    position: 17,
  },
  {
    countryCode: 'LV',
    position: 16,
  },
  {
    countryCode: 'LT',
    position: 19,
  },
  {
    countryCode: 'LU',
    position: 15,
  },
  {
    countryCode: 'MT',
    position: 12,
  },
  {
    countryCode: 'NL',
    position: 14,
  },
  {
    countryCode: 'PL',
    position: 23,
  },
  {
    countryCode: 'PT',
    position: 4,
  },
  {
    countryCode: 'RO',
    position: 8,
  },
  {
    countryCode: 'SK',
    position: 13,
  },
  {
    countryCode: 'SI',
    position: 3,
  },
  {
    countryCode: 'ES',
    position: 26,
  },
  {
    countryCode: 'SE',
    position: 27,
  },
];

enum BUTTONSTATE {
  approvals = 'approvals',
  rejections = 'rejections',
}

export class PresentationProposalScene extends Phaser.Scene {
  static key = 'presentation-proposal-scene';
  usersDataSubscription: Subscription;
  usersData: IUserGame[];
  flagsAssetsB64: any = {};
  table: Phaser.GameObjects.Image;
  avatars: Phaser.GameObjects.Sprite[];
  grid: Phaser.GameObjects.Image;
  tiles: Phaser.GameObjects.Image;
  representativePath: string;
  presidentPath: string;
  radioButtonState = BUTTONSTATE.rejections;
  userCountry: string;
  finalVotes: any;
  approvalVotesUsIds: string[] = [];
  rejectionVotesUsIds: string[] = [];
  notYetVotedUsIds: string[] = [];
  voting: boolean;
  votingSubscription: Subscription;
  faceListeningAnimations: any[] = [];
  presidentIndex: number;
  presidentAnimation: Phaser.GameObjects.Sprite;
  presidentGender: string;
  presidentColor: string;
  minParamDevice = 0;
  votingFactorApprove: number;
  votingFactorReject: number;
  votingDownApprove: Phaser.GameObjects.Sprite;
  votingUpApprove: Phaser.GameObjects.Sprite;
  votingSquareApprove: Phaser.GameObjects.Sprite;
  votingSquareReject: Phaser.GameObjects.Sprite;
  votingDownReject: Phaser.GameObjects.Sprite;
  votingUpReject: Phaser.GameObjects.Sprite;
  votingGreenArrow: Phaser.GameObjects.Sprite;
  votingWhiteArrow: Phaser.GameObjects.Sprite;
  votingWhiteText: Phaser.GameObjects.Text;
  votingWhiteRejectCountryCountText: Phaser.GameObjects.Text;
  votingWhiteRejectCountrySizeText: Phaser.GameObjects.Text;
  votingGreenText: Phaser.GameObjects.Text;
  votingGreenApprovedCountryCountText: Phaser.GameObjects.Text;
  votingGreenApprovedCountrySizeText: Phaser.GameObjects.Text;
  approvalsText: Phaser.GameObjects.Text;
  rejectionsText: Phaser.GameObjects.Text;

  destroy$: Subject<boolean> = new Subject<boolean>();

  private dynamicSvgScaleX = 0.95;
  private dynamicSvgScaleY = 0.9;
  private animScale = 0.72;
  private animPresidentScale = 0.85;

  constructor() {
    super({
      key: PresentationProposalScene.key,
    });

    context.gameQuery.representative$
      .pipe(takeUntil(this.destroy$))
      .subscribe((path) => {
        this.representativePath = path;
      });
    context.gameQuery.president$
      .pipe(takeUntil(this.destroy$))
      .subscribe((path) => {
        this.presidentPath = path;
        this.presidentIndex = +this.presidentPath.slice(-6, -4);

        this.presidentGender = this.presidentPath.slice(10, 16);
        if (this.presidentGender === 'female') {
          this.presidentGender = '_female';
        } else {
          this.presidentGender = '';
        }
        if (this.presidentGender === '') {
          this.presidentIndex = this.presidentIndex + 3;
        }
        this.presidentColor =
          this.presidentIndex === 1 || this.presidentIndex === 4
            ? 'A'
            : this.presidentIndex === 2 || this.presidentIndex === 5
            ? 'C'
            : this.presidentIndex === 3 || this.presidentIndex === 6
            ? 'D'
            : '';
      });

    let indexSub = 0;
    this.usersDataSubscription = context.gameQuery.game$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (!response) {
          return;
        }

        this.approvalVotesUsIds = [];
        this.rejectionVotesUsIds = [];

        this.finalVotes = response.finalVotes;
        indexSub++;
        let positiveVotes = 0;
        let negativeVotes = 0;
        this.votingSquareApprove?.setDisplaySize(
          this.votingSquareApprove?.displayWidth,
          0
        );
        this.votingSquareReject?.setDisplaySize(
          this.votingSquareReject?.displayWidth,
          0
        );

        if (
          response.status === GameStatus.approved ||
          response.status === GameStatus.rejected
        ) {
          this.finalVotes?.forEach((el, i) => {
            if (el.finalVote) {
              this.votingFactorApprove = 3;
              this.approvalVotesUsIds.push(el.userId);
              if (indexSub > 1) {
                timer(i * 100)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    positiveVotes++;
                    this.votingSquareApprove?.setDisplaySize(
                      this.votingSquareApprove?.displayWidth,
                      this.votingSquareApprove?.displayHeight +
                        this.votingFactorApprove
                    );
                    this.votingGreenText?.setText(`${positiveVotes}`);
                    this.votingGreenApprovedCountryCountText?.setText(
                      `${context.settingsService.formatDecimalNumber(response.approvedCountryCount)}%`
                    );
                    this.votingGreenApprovedCountrySizeText?.setText(
                      `${context.settingsService.formatDecimalNumber(response.approvedCountrySize)}%`
                    );
                  });
              }
            } else {
              this.votingFactorReject = 3;
              this.rejectionVotesUsIds.push(el.userId);
              if (indexSub > 1) {
                timer(i * 100)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                    negativeVotes++;
                    this.votingSquareReject?.setDisplaySize(
                      this.votingSquareReject?.displayWidth,
                      this.votingSquareReject?.displayHeight +
                        this.votingFactorReject
                    );
                    this.votingWhiteText?.setText(`${negativeVotes}`);
                    this.votingWhiteRejectCountryCountText?.setText(
                      `${context.settingsService.formatDecimalNumber(response.rejectCountryCount)}%`
                    );
                    this.votingWhiteRejectCountrySizeText?.setText(
                      `${context.settingsService.formatDecimalNumber(response.rejectCountrySize)}%`
                    );
                  });
              }
            }
          });
        }

        this.usersData = response.userGames;

        this.userCountry = this.usersData.find(
          (el) => el.userId === context.userQuery.getUserId()
        ).country;
      });
    context.isAnimatedSpeechPlaying$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res) {
          this.presidentAnimation?.anims.stop();
        } else {
          this.presidentAnimation?.play(
            `presidentAnimation${this.presidentIndex}`
          );
        }
      });

      context.translateService.onLangChange
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) => {
            console.log('COULDNT TRANSLATE ', err);
            throw err;
          })
        )
        .subscribe(() => {
          this.approvalsText?.setText(context.translateService.instant('voting_screen_radio_button_approvals'));
          this.rejectionsText?.setText(context.translateService.instant('voting_screen_radio_button_rejections'));
        });
  }

  maskAvatars(radioButtonState) {
    this.avatars?.forEach((it) => it.clearTint());

    if (radioButtonState === BUTTONSTATE.rejections) {
      this.avatars?.forEach((el) => {
        if (this.approvalVotesUsIds.includes(el.data.values.userId)) {
          el.setTint(0xff404953);
        } else {
          return;
        }
      });
    } else if (radioButtonState === BUTTONSTATE.approvals) {
      this.avatars?.forEach((el) => {
        if (this.rejectionVotesUsIds.includes(el.data.values.userId)) {
          el.setTint(0xff404953);
        } else {
          return;
        }
      });
    }
  }

  createListeningAnimation() {
    const frameNamesFaceListening1 = this.anims.generateFrameNames(
      'faceListening1',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '01_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening1',
      frames: frameNamesFaceListening1,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceListening2 = this.anims.generateFrameNames(
      'faceListening2',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '02_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening2',
      frames: frameNamesFaceListening2,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceListening3 = this.anims.generateFrameNames(
      'faceListening3',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '03_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening3',
      frames: frameNamesFaceListening3,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceListening4 = this.anims.generateFrameNames(
      'faceListening4',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '04_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening4',
      frames: frameNamesFaceListening4,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceListening5 = this.anims.generateFrameNames(
      'faceListening5',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '05_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening5',
      frames: frameNamesFaceListening5,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceListening6 = this.anims.generateFrameNames(
      'faceListening6',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '06_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening6',
      frames: frameNamesFaceListening6,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceListening7 = this.anims.generateFrameNames(
      'faceListening7',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '07_face_listening_still_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceListening7',
      frames: frameNamesFaceListening7,
      frameRate: 15,
      repeat: -1,
    });

    setTimeout(() => {
      this.avatars.forEach((el, index) => {
        this.faceListeningAnimations[index] = this.add
          .sprite(
            el.x - this.minParamDevice,
            el.y,
            `faceListening${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_listening_still_00000.png`
          )
          .setScale(this.animScale, this.animScale)
          .setDepth(el.depth)
          .setData({
            index: el.data.values.faceIndex,
            countryCode: el.data.values.countryCode,
          });

        timer(index * 200)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.faceListeningAnimations[index].play(
              `faceListening${el.data.values.faceIndex}`
            );
          });
      });
    }, 520);
  }

  createPresidentAnimation() {
    const frameNamesPresidentAnimation1 = this.anims.generateFrameNames(
      'presidentAnimation1',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: 'PRESIDENT_female_stA_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'presidentAnimation1',
      frames: frameNamesPresidentAnimation1,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesPresidentAnimation2 = this.anims.generateFrameNames(
      'presidentAnimation2',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: 'PRESIDENT_female_stC_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'presidentAnimation2',
      frames: frameNamesPresidentAnimation2,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesPresidentAnimation3 = this.anims.generateFrameNames(
      'presidentAnimation3',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: 'PRESIDENT_female_stD_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'presidentAnimation3',
      frames: frameNamesPresidentAnimation3,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesPresidentAnimation4 = this.anims.generateFrameNames(
      'presidentAnimation4',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: 'PRESIDENT_stA_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'presidentAnimation4',
      frames: frameNamesPresidentAnimation4,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesPresidentAnimation5 = this.anims.generateFrameNames(
      'presidentAnimation5',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: 'PRESIDENT_stC 2_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'presidentAnimation5',
      frames: frameNamesPresidentAnimation5,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesPresidentAnimation6 = this.anims.generateFrameNames(
      'presidentAnimation6',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: 'PRESIDENT_stD_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'presidentAnimation6',
      frames: frameNamesPresidentAnimation6,
      frameRate: 15,
      repeat: -1,
    });

    this.presidentAnimation = this.add
      .sprite(
        900,
        205,
        `presidentAnimation${this.presidentIndex}`,
        `PRESIDENT${this.presidentGender}_st${this.presidentColor}_00000`
      )
      .setScale(this.animPresidentScale, this.animPresidentScale)
      .setDepth(this.table.depth - 1)
      .setData({
        index: this.presidentIndex,
      });

    this.presidentAnimation.play(`presidentAnimation${this.presidentIndex}`);
  }

  public update(time: number, delta: number): void {
    this.faceListeningAnimations?.forEach((element) => {
      element.setDepth(
        this.avatars.find(
          (it) => it.data.values.countryCode === element.data.values.countryCode
        ).depth + 1
      );
    });

    this.votingUpApprove
      ?.setPosition(
        this.votingDownApprove.x,
        this.votingSquareApprove.y - this.votingSquareApprove.displayHeight
      )
      ?.setDepth(10000);
    this.votingGreenArrow
      ?.setPosition(
        this.votingDownApprove.x,
        this.votingSquareApprove.y - this.votingSquareApprove.displayHeight - 80
      )
      ?.setDepth(10001);
    this.votingGreenText
      ?.setPosition(
        this.votingDownApprove.x,
        this.votingSquareApprove.y - this.votingSquareApprove.displayHeight - 20
      )
      ?.setDepth(10001);
    this.votingGreenApprovedCountryCountText
      ?.setPosition(this.votingDownApprove.x, this.votingSquareApprove.y + 60)
      ?.setDepth(10002);
    this.votingGreenApprovedCountrySizeText
      ?.setPosition(this.votingDownApprove.x, this.votingSquareApprove.y + 95)
      ?.setDepth(10003);

    this.votingUpReject
      ?.setPosition(
        this.votingDownReject.x,
        this.votingSquareReject.y - this.votingSquareReject.displayHeight
      )
      ?.setDepth(10000);
    this.votingWhiteArrow
      ?.setPosition(
        this.votingDownReject.x,
        this.votingSquareReject.y - this.votingSquareReject.displayHeight - 70
      )
      ?.setDepth(10001);
    this.votingWhiteText
      ?.setPosition(
        this.votingDownReject.x,
        this.votingSquareReject.y - this.votingSquareReject.displayHeight - 20
      )
      ?.setDepth(10001);
    this.votingWhiteRejectCountryCountText
      ?.setPosition(this.votingDownReject.x, this.votingSquareReject.y + 60)
      ?.setDepth(10002);
    this.votingWhiteRejectCountrySizeText
      ?.setPosition(this.votingDownReject.x, this.votingSquareReject.y + 95)
      ?.setDepth(10003);
  }

  public create() {
    this.events.on('destroy', () => {
      this.destroy$.next(true);
      this.destroy$.complete();
    });

    this.votingSubscription = context.gameQuery.game$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        if (
          // (res.status === GameStatus.finalVote ||
          //   res.status === GameStatus.approved) &&
          res.status === GameStatus.approved ||
          (res.status === GameStatus.rejected && res.votingRound === 2)
          // this.approvalVotesUsIds.length + this.rejectionVotesUsIds.length === 27
        ) {
          if (
            !this.votingDownApprove &&
            !this.votingSquareApprove &&
            !this.votingUpApprove &&
            !this.votingGreenArrow &&
            !this.votingGreenText &&
            !this.votingGreenApprovedCountryCountText &&
            !this.votingGreenApprovedCountrySizeText &&
            !this.votingDownReject &&
            !this.votingSquareReject &&
            !this.votingUpReject &&
            !this.votingWhiteArrow &&
            !this.votingWhiteText &&
            !this.votingWhiteRejectCountryCountText &&
            !this.votingWhiteRejectCountrySizeText
          ) {
            this.votingDownApprove = this.add
              .sprite(775, 500, 'votingDown')
              .setDepth(9998);
            this.votingSquareApprove = this.add
              .sprite(775, 500, 'votingSquare')
              .setOrigin(0.5, 1)
              .setDepth(9999);
            this.votingUpApprove = this.add.sprite(775, 500, 'votingUp');
            this.votingGreenArrow = this.add.sprite(
              775,
              500,
              'votingGreenArrow'
            );
            this.votingGreenText = this.add
              .text(775, 500, `0`)
              .setFontSize(50)
              .setFontFamily(context.gameFont)
              .setStyle({
                color: '#005840',
              })
              .setAlign('center')
              .setOrigin(0.5, 0)
              .setWordWrapWidth(200);
            this.votingGreenApprovedCountryCountText = this.add
              .text(775, 500, `0%`)
              .setFontSize(32)
              .setFontFamily(context.gameFont)
              .setStyle({
                color: '#FFFFFF',
                fontStyle: 'bold',
              })
              .setAlign('center')
              .setOrigin(0.5, 0)
              .setWordWrapWidth(200);
            this.votingGreenApprovedCountrySizeText = this.add
              .text(775, 500, `0%`)
              .setFontSize(28)
              .setFontFamily(context.gameFont)
              .setStyle({
                color: '#FFD924',
                fontWeight: '800',
              })
              .setAlign('center')
              .setOrigin(0.5, 0)
              .setWordWrapWidth(200);

            this.votingDownReject = this.add
              .sprite(1025, 500, 'votingWhiteDown')
              .setDepth(9998);
            this.votingSquareReject = this.add
              .sprite(1025, 500, 'votingWhiteSquare')
              .setOrigin(0.5, 1)
              .setDepth(9999);
            this.votingUpReject = this.add.sprite(1025, 500, 'votingWhiteUp');
            this.votingWhiteArrow = this.add.sprite(
              1025,
              500,
              'votingWhiteArrow'
            );
            this.votingWhiteText = this.add
              .text(1025, 450, `0`)
              .setFontSize(50)
              .setFontFamily(context.gameFont)
              .setAlign('center')
              .setOrigin(0.5, 0)
              .setStyle({
                color: '#8A8A8A',
              })
              .setWordWrapWidth(200);
            this.votingWhiteRejectCountryCountText = this.add
              .text(1025, 450, `0%`)
              .setFontSize(32)
              .setFontFamily(context.gameFont)
              .setAlign('center')
              .setOrigin(0.5, 0)
              .setStyle({
                color: '#FFFFFF',
                fontStyle: 'bold',
              })
              .setWordWrapWidth(200);
            this.votingWhiteRejectCountrySizeText = this.add
              .text(1025, 450, `0%`)
              .setFontSize(28)
              .setFontFamily(context.gameFont)
              .setAlign('center')
              .setOrigin(0.5, 0)
              .setStyle({
                color: '#FFD924',
                fontWeight: '800',
              })
              .setWordWrapWidth(200);
          }

          const radioButton = this.add
            .sprite(300, 100, 'radio')
            .setScale(1.35, 1.35)
            .setInteractive({ useHandCursor: true })
            .on('pointerdown', () => {
              if (toggleButton.y === 75) {
                toggleButton.setY(125);
                this.radioButtonState = BUTTONSTATE.rejections;
                this.maskAvatars(this.radioButtonState);
              } else if (toggleButton.y === 125) {
                toggleButton.setY(75);
                this.radioButtonState = BUTTONSTATE.approvals;
                this.maskAvatars(this.radioButtonState);
              }
              console.log(this.radioButtonState);
            });

          const toggleButton = this.add
            .sprite(300, 125, 'radioButton')
            .setScale(1.35, 1.35);

          this.approvalsText = this.add
            .text(425, 75, `${context.translateService.instant('voting_screen_radio_button_approvals')}`)
            .setFontSize(30)
            .setFontFamily(context.gameFont)
            .setOrigin(0.5, 0.5)
            .setStyle({
              color: '#FFFFFF',
            });

          this.rejectionsText = this.add
            .text(430, 125, `${context.translateService.instant('voting_screen_radio_button_rejections')}`)
            .setOrigin(0.5, 0.5)
            .setFontSize(30)
            .setFontFamily(context.gameFont)
            .setStyle({
              color: '#FFFFFF',
            });

          this.maskAvatars(BUTTONSTATE.rejections);
        }
      });

    this.avatars = [];
    setTimeout(() => {
      const addFactor = Math.floor(
        Math.abs(
          moment(new Date(2022, 6, 1)).diff(moment(new Date()), 'months')
        ) / 6
      );
      this.usersData?.forEach((ug, i) => {
        let depth;

        const index =
          countriesInitialPositions.find((av) => av.countryCode === ug.country)
            .position - 1 + addFactor > 26
            ? countriesInitialPositions.find(
                (av) => av.countryCode === ug.country
              ).position - 1 + addFactor - 27
            : countriesInitialPositions.find(
                (av) => av.countryCode === ug.country
              ).position - 1 + addFactor;

        if (index < 8 || index > 20) {
          depth = avatarPositions[index].y - 1000;
        } else {
          depth = avatarPositions[index].y;
        }

        this.avatars.push(
          this.add
            .sprite(
              avatarPositions[index].x,
              avatarPositions[index].y,
              `${ug.country?.toString()}FinalVote`
            )
            .setScale(this.dynamicSvgScaleX, this.dynamicSvgScaleY)
            .setOrigin(0.5, 0.5)
            .setDepth(depth)
        );

        this.avatars[i].setData({
          countryCode: `${this.usersData[i].country}`,
          userId: this.usersData[i].userId,
          normalUser: ug.user.normalUser,
          faceIndex: this.usersData[i].user.avatar_Face + 1,
        });
      });
      context.loading.next(false);
    }, 500);

    this.grid = this.add
      .image(900, 555, 'isoGrid')
      .setOrigin(0.5, 0.5)
      .setScale(1.35, 1.35)
      .setAlpha(0.2)
      .setDepth(-1000);
    this.tiles = this.add
      .image(900, 555, 'tiles')
      .setOrigin(0.5, 0.5)
      .setScale(1.35, 1.35)
      .setDepth(this.grid.depth + 1);

    this.table = this.add
      .image(900, 555, 'table')
      .setOrigin(0.5, 0.5)
      .setScale(1.35, 1.35)
      .setDepth(10);
    this.add
      .image(900, 535, 'props')
      .setOrigin(0.5, 0.5)
      .setScale(1.35, 1.35)
      .setDepth(this.table.depth + 1);
    this.add
      .sprite(970, 780, 'representative')
      .setOrigin(0.5, 0.5)
      .setDepth(1000);

    setTimeout(() => {
      this.add
        .image(
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          )?.x - 7,
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          ).y + 80,
          'stroke'
        )
        .setOrigin(0.5, 0.5)
        .setScale(1.6, 1.6)
        .setDepth(
          this.avatars.find(
            (it) => it.data.values.countryCode === this.userCountry
          ).depth - 1
        );
      this.add
        .sprite(
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          ).x -
            7 -
            this.minParamDevice,
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          ).y - 100,
          'indicator'
        )
        .setOrigin(0.5, 0.5)
        .setScale(1.6, 1.6)
        .setDepth(this.avatars.find(
          (it) => it.data.values.countryCode === this.userCountry
        ).depth - 1);
    }, 510);

    this.createListeningAnimation();
    this.createPresidentAnimation();
  }

  loading() {
    context.loading.next(false);
  }

  public preload() {
    context.loading.next(true);
    this.load.svg('table', '../../../assets/round-table.svg');
    this.load.svg('isoGrid', '../../../assets/small-iso-grid.svg');
    this.load.svg('tiles', '../../../assets/d1/tiles.svg');
    this.load.svg('props', '../../../assets/d1/props.svg');
    this.load.svg(
      'representative',
      `../../../assets/Avatar2/06_EU_comission_representative/${this.representativePath}`
    );
    this.load.svg(
      'president',
      `../../../assets/Avatar2/01 president/${this.presidentPath}`
    );
    this.load.svg('stroke', '../../../assets/Stroke-160.svg');
    this.load.svg('indicator', '../../../assets/arrow-indicator-blue.svg');
    this.load.svg('radio', '../../../assets/radio.svg');
    this.load.svg('radioButton', '../../../assets/radioButton.svg');
    this.load.svg(
      'votingDown',
      '../../../assets/final_vote/votingGreenDown.svg',
      { scale: 1.5 }
    );
    this.load.svg('votingUp', '../../../assets/final_vote/votingGreenUp.svg', {
      scale: 1.5,
    });
    this.load.svg(
      'votingSquare',
      '../../../assets/final_vote/votingSquare.svg',
      { scale: 1.5 }
    );
    this.load.svg(
      'votingGreenArrow',
      '../../../assets/final_vote/votingGreenArrow.svg',
      { scale: 1.5 }
    );
    this.load.svg(
      'votingWhiteArrow',
      '../../../assets/final_vote/votingWhiteArrow.svg',
      { scale: 1.5 }
    );
    this.load.svg(
      'votingWhiteSquare',
      '../../../assets/final_vote/votingWhiteSquare.svg',
      { scale: 1.5 }
    );
    this.load.svg(
      'votingWhiteUp',
      '../../../assets/final_vote/votingWhiteUp.svg',
      { scale: 1.5 }
    );
    this.load.svg(
      'votingWhiteDown',
      '../../../assets/final_vote/votingWhiteDown.svg',
      { scale: 1.5 }
    );
    this.load.atlas({
      key: 'faceListening1',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening1-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening1.json',
    });
    this.load.atlas({
      key: 'faceListening2',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening2-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening2.json',
    });
    this.load.atlas({
      key: 'faceListening3',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening3-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening3.json',
    });
    this.load.atlas({
      key: 'faceListening4',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening4-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening4.json',
    });
    this.load.atlas({
      key: 'faceListening5',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening5-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening5.json',
    });
    this.load.atlas({
      key: 'faceListening6',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening6-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening6.json',
    });
    this.load.atlas({
      key: 'faceListening7',
      textureURL:
        '../../../assets/proposal/proposal-animations/faceListening7-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/faceListening7.json',
    });

    this.load.atlas({
      key: 'presidentAnimation1',
      textureURL:
        '../../../assets/proposal/proposal-animations/presidentFemale1-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/presidentFemale1.json',
    });
    this.load.atlas({
      key: 'presidentAnimation2',
      textureURL:
        '../../../assets/proposal/proposal-animations/presidentFemale2-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/presidentFemale2.json',
    });
    this.load.atlas({
      key: 'presidentAnimation3',
      textureURL:
        '../../../assets/proposal/proposal-animations/presidentFemale3-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/presidentFemale3.json',
    });
    this.load.atlas({
      key: 'presidentAnimation4',
      textureURL:
        '../../../assets/proposal/proposal-animations/presidentMale1-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/presidentMale1.json',
    });
    this.load.atlas({
      key: 'presidentAnimation5',
      textureURL:
        '../../../assets/proposal/proposal-animations/presidentMale2-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/presidentMale2.json',
    });
    this.load.atlas({
      key: 'presidentAnimation6',
      textureURL:
        '../../../assets/proposal/proposal-animations/presidentMale3-0.png',
      atlasURL:
        '../../../assets/proposal/proposal-animations/presidentMale3.json',
    });
    this.usersData?.forEach((c, index) => {
      if (c.country === this.userCountry) {
        const s = new XMLSerializer().serializeToString(
          context.avatarConstruction(
            c.country,
            PhaserScenes.presentationProposalScene
          )
        );

        const b64 = 'data:image/svg+xml;base64,' + window.btoa(s);
        const url = b64;

        const key = `${c.country?.toString()}FinalVote`;
        this.flagsAssetsB64[key] = url;

        if (this.textures.exists(key)) {
          this.textures.get(key).destroy();
        }
        this.textures.addBase64(key, this.flagsAssetsB64[key]?.toString());
      } else {
        const s = new XMLSerializer().serializeToString(
          context.npcAvatarConstruction(
            c.country,
            PhaserScenes.presentationProposalScene
          )
        );

        const b64 = `data:image/svg+xml;base64,${window.btoa(s)}`;
        const url = b64;

        const key = `${c.country?.toString()}FinalVote`;
        this.flagsAssetsB64[key] = url;

        if (this.textures.exists(key)) {
          this.textures.get(key).destroy();
        }
        this.textures.addBase64(key, this.flagsAssetsB64[key]?.toString());
      }
    });
    // this.load.on('complete', this.loading);
  }
}

export const makePresentationProposalScene = (ctx) => {
  context = ctx;
  return PresentationProposalScene;
};
