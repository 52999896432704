import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateConfigService } from '../../translate-config.service';

@UntilDestroy()
@Component({
  selector: 'app-download',
  templateUrl: './download.page.html',
  styleUrls: ['./download.page.scss'],
})
export class DownloadPage implements OnInit {
  ceuMono: string;
  loading = false;
  public isIos = false;
  public isAndroid = false;

  constructor(
    private platform: Platform,
    private router: Router,
    private userQuery: UserQuery,
    public translateConfigService: TranslateConfigService
  ) {}

  ngOnInit() {
    this.translateConfigService.languageLoading$.pipe(untilDestroyed(this)).subscribe(res => {
      this.loading = res;
    });

    this.ceuMono = this.userQuery.getLanguage();
    if (this.platform.is('ios')) {
      this.isIos = true;
    } else {
      this.isAndroid = true;
    }
  }

  toLaunchScreen() {
    this.router.navigate(['/launch_screen']);
  }
}
