import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AvatarService } from 'src/app/services/avatar-customization.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { GameStore } from 'src/app/state/gameState/game.store';
import { UserQuery } from 'src/app/state/userState/user.query';

@Component({
  selector: 'app-multiplayer-join',
  templateUrl: './multiplayer-join.page.html',
  styleUrls: ['./multiplayer-join.page.scss'],
})
export class MultiplayerJoinPage implements OnInit {
  @ViewChild('svg') svg: ElementRef;
  joinGameForm: FormGroup;
  avatarIndex: any;
  constructor(
    private router: Router,
    private gameStore: GameStore,
    private gameService: GameService,
    private userQuery: UserQuery,
    private avatarService: AvatarService,
    private gameQuery: GameQuery,
  ) { }

  ngOnInit() {
    this.joinGameForm = new FormGroup({
      code: new FormControl('', Validators.required)
    });
  }

  ionViewWillEnter(){
    this.avatarIndex = this.userQuery.getUserAvatarIndex();
      this.svg.nativeElement.innerHTML = '';
      this.svg.nativeElement.appendChild(
        this.avatarService.customizationAvatar(
          this.avatarIndex[0],
          this.avatarIndex[1],
          this.avatarIndex[2],
          this.avatarIndex[3],
          this.avatarIndex[4],
          this.avatarIndex[5],
          this.gameQuery.getValue().gameOptions?.country
        )
      );
  }

  onSubmit() {
    this.gameService.gameJoin(this.joinGameForm.get('code').value)
      .then(res => {
        this.gameStore.update({game: res.data.items[0]});
        this.gameService.setRandomMaleExpert();
        this.gameService.setRandomFemaleExpert();
        this.router.navigate(['/multiplayer-room']);
      })
      .catch(err => {
        console.log(err);
      });
  }

}
