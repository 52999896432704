import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from '../services/env.service';
import { UserQuery } from '../state/userState/user.query';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const ATInternet: any;

export interface PageInfo {
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  customObject?: any;
}

export interface ClickInfo {
  elem?: any;
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  type: string;
  event?: any;
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private tag: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private envService: EnvService,
    private router: Router,
    private userQuery: UserQuery
  ) { }

  public loadJsScript(renderer: Renderer2): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.envService.smartTagJs;
    script.onload = () => {
      console.log('at script loaded');
      ATInternet.Utils.consentReceived(false);
      this.tag = new ATInternet.Tracker.Tag({
        secure: true,
        disableCookie: true,
        ignoreEmptyChapterValue: true,
        sendHitWhenOptOut: true
      });
      this.pageSend(this.router.url.replace(/[^\w]/gi, ''));
      this.click({
        name: 'AppLaunch',
        type: 'event'
      });
      this.click({
        name: 'InitialLanguage',
        level2: this.userQuery.getLanguage(),
        type: 'event'
      });
    };
    renderer.appendChild(this.document.body, script);
    return script;
  }

  pageSend(name: string): void {
    this.tag?.page.send({name});
  }

  click(clickInfo: ClickInfo) {
    this.tag?.click.send(clickInfo);
  }

  clickListener(info: ClickInfo): void {
    this.tag?.clickListener.send(info);
  };
}
