import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AudioService } from 'src/app/services/audio.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() url: string;
  @Input() text: string;

  constructor(private router: Router, private location: Location, private audioService: AudioService) { }

  ngOnInit() {}

  onBack() {
    this.audioService.play('button-click');
    if (this.url) {
      this.router.navigate([this.url]);
    } else {
      // this.location.back();
    }
  }

}
