import { ILanguage } from '../models/user.model';
import { UserQuery } from '../state/userState/user.query';
import { UserService } from '../state/userState/user.service';
import { CountriesService } from '../state/countriesState/countries.service';
import { TrackingService } from './tracking.service';
import { TranslateConfigService } from '../translate-config.service';
import { GameService } from '../state/gameState/game.service';
import { GameQuery } from '../state/gameState/game.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private language: string;

  constructor(
    private userQuery: UserQuery,
    private userService: UserService,
    private countriesService: CountriesService,
    private trackingService: TrackingService,
    private translateConfigService: TranslateConfigService,
    private gameService: GameService,
    private gameQuery: GameQuery,
  ) {
    this.userQuery.settings$.pipe(untilDestroyed(this)).subscribe(lang => {
      this.language = lang.language;
    });
  }

  switchLang(lang: ILanguage) {
    this.trackingService.click({
      name: 'NewLanguage',
      level2: lang.code,
      type: 'event'
    });
    this.userService.switchLanguage(lang.code);
    if (this.gameQuery.getValue().game?.gameId) {
      this.gameService.getStaticTopics(lang.code);
    } else {
      this.gameService.getTopics(lang.code);
    }
    this.gameService.getHelpTopics(lang.code);
    this.countriesService.getCountries(lang.code);
    this.translateConfigService.changeLanguage(lang.code);
  }

  formatDecimalNumber(value: any) {
    return Intl.NumberFormat(this.language).format(value);
  }
}
