// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-error {
  display: flex;
  justify-content: center;
  z-index: 20;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 51, 153, 0.91);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.box {
  position: relative;
  display: inline-block;
  max-width: 80%;
  padding: 1.875rem 1.75rem 1.3rem 1.75rem;
  text-align: center;
  color: var(--color-white);
  border-radius: 0.458rem;
  background: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.16) 2px 3px 0 1px, rgb(209, 213, 219) 0 0 0 1px inset;
}
.box .main-title {
  color: var(--color-primary-blue);
  letter-spacing: 0;
  line-height: 1.333rem;
  font-size: 1.167rem;
  font-weight: bold;
  margin-bottom: 1.125rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/error-modal/error-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,kCAAA;EACA,kCAAA;UAAA,0BAAA;AACF;;AAEA;EACI,kBAAA;EACA,qBAAA;EACA,cAAA;EACA,wCAAA;EACA,kBAAA;EACA,yBAAA;EACA,uBAAA;EACA,8BAAA;EACA,iFAAA;AACJ;AAEI;EACE,gCAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;AAAN","sourcesContent":[".modal-error {\n  display: flex;\n  justify-content: center;\n  z-index: 20;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: rgba(0, 51, 153, 0.91);\n  backdrop-filter: blur(3px);\n}\n\n.box {\n    position: relative;\n    display: inline-block;\n    max-width: 80%;\n    padding: 1.875rem 1.75rem 1.3rem 1.75rem;\n    text-align: center;\n    color: var(--color-white);\n    border-radius: 0.458rem;\n    background: var(--color-white);\n    box-shadow: rgba(0, 0, 0, 16%) 2px 3px 0 1px,\n    rgb(209, 213, 219) 0 0 0 1px inset;\n\n    .main-title {\n      color: var(--color-primary-blue);\n      letter-spacing: 0;\n      line-height: 1.333rem;\n      font-size: 1.167rem;\n      font-weight: bold;\n      margin-bottom: 1.125rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
