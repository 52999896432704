import type { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';

type Variation = TippyConfig['variations'][0];

export interface Game {
  game: any;
  usersGame: any;
  topics: any;
}

export enum BilateralStatus {
  allied = 'ALLIED',
  notAllied = 'NOT_ALLIED',
  empty = 'EMPTY',
  pending = 'PENDING',
  action = 'ACTION',
  incomingAction = 'INCOMING_ACTION',
  exchange = 'EXCHANGE',
  tradeoff = 'TRADEOFF',
  cancelled = 'CANCELLED',
  cancelledPlayingGame = 'CANCELLED_PLAYING_GAME',
  notEnoughActionPoints = 'NOT_ENOUGH_ACTION_POINTS'
}

export enum Stance {
  redLine = 'Red Line',
  negotiable = 'Negotiable',
  sensitive = 'Sensitive',
}

export enum StanceTranslation {
  redLine = 'position_stance_red_line',
  negotiable = 'position_stance_negotiable',
  sensitive = 'position_stance_sensitive',
}

export enum ColorTranslation {
  white = 'color_white',
  black = 'color_black',
  red = 'color_red',
  green = 'color_green',
  yellow = 'color_yellow',
  blue = 'color_blue',
}

export interface INegotiation {
  sendingUserGameNegotiationid: string;
  receivingUserGameNegotiationid: string;
  gameId: string;
  tradeoff: boolean;
  allied?: boolean;
}

export enum GameStatus {
  created = 1,
  started = 2,
  finalVote = 3,
  approved = 4,
  rejected = 5,
  break = 6,
  quit = 7,
}

export enum BilateralHistoryStatus {
  success = 'Success',
  failed = 'fail',
  broken = 'broken',
  started = 'Started',
}

export enum ErrorMessages {
  bilateralBreakStarts = 'bilateral_talk_info_player_break_starts',
  bilateralNegotiationStarts = 'negotiation_continue_bilateral',
  errorGeneralIssue = 'error_modal_text_1',
  errorSocket = 'error_modal_text_2',
  errorJoinGame = 'multi_player_join_game_wrong_code',
  errorJoinAlreadyStartedGame = 'multi_player_join_game_already_started',
  errorJoinGameFullSeats = 'multi_player_join_game_full_seats',
  errorUserTimeout = 'user_timeout_warning_message',
  finalVote = 'voting_screen_modal_text',
  gameClosed = 'game_closed_generic_error',
  gameCodeCopied = 'multi_player_room_share_code_copied',
  hostQuit = 'multi_player_room_host_quit',
  negotiationStarted = 'negotiation_phase_started',
  networkOffline = 'no_internet_connection_error',
  notEnoughPlayersQuit = 'quit_game_not_enough_players_text',
  talkToAnExpertAlreadyPlayed = 'talk_to_an_expert_cannot_play_again',
  timeToBreak = 'time_to_break_text',
  breakTaskPlaying = 'break_task_playing_text',
  breakTaskEmojiInstructions = 'break_task_emoji_instructions_text',
  breakTaskEmojiInstructionsMultiplayer = 'break_task_emoji_instructions_multiplayer_text',
  timeToNegotiation = 'time_to_negotiation_text',
  quitGame = 'quit_game_text',
  breakTaskDrinkCoffeeInstructions = 'breaks_drink_coffee_instructions',
  errorPermanentMessage = 'error_handling_permanent_error_message_start_over',
  endBreakMessage = 'break_end_confirmation_message_single_player',
  endBreakMessageMulti = 'break_end_confirmation_message_multi_player',
  endBreakMessageMultiVote = 'break_end_confirmation_message_multi_player_vote',
  endBreakMessageMultiVoteBilateral = 'break_end_confirmation_message_multi_player_vote_bilateral',
  playersDecidedToContinueBreak = 'coffee_break_players_decided_to_continue_break'
}

export enum BilateralDiscussionMessageType {
  startNegotiation = 'START_NEGOTIATION',
  acceptNegotiation = 'ACCEPT_NEGOTIATION',
  declineNegotiation = 'DECLINE_NEGOTIATION',
  proposeAlliance = 'PROPOSE_ALLIANCE',
  acceptAlliance = 'ACCEPT_ALLIANCE',
  refuseAlliance = 'REFUSE_ALLIANCE',
  proposeTradeOff = 'PROPOSE_TRADEOFF',
  acceptTradeOff = 'ACCEPT_TRADEOFF',
  refuseTradeOff = 'REFUSE_TRADEOFF',
  closeTalks = 'CLOSE_TALKS',
  declineNegotiationPlayingGame = 'DECLINE_NEGOTIATION_PLAYING_GAME',
}

export enum BilateralAnimationStatus {
  accepted = 'ACCEPTED',
  acceptAlliance = 'ACCEPT_ALLIANCE',
  refuseAlliance = 'REFUSE_ALLIANCE',
  tradeoffAccepted = 'TRADEOFF_ACCEPTED',
  refuseTradeOff = 'REFUSE_TRADEOFF',
  declined = 'DECLINED',
  reset = 'RESET',
  proposeTradeOff = 'PROPOSE_TRADEOFF',
  left = 'LEFT',
  redLineAccepted = 'RED_LINE_ACCEPTED',
  redLineDeviate = 'RED_LINE_DEVIATE',
  redLineUncovered = 'RED_LINE_UNCOVERED',
  pendingTrue = 'PENDING',
  pendingFalse = '!PENDING',
  exchange = 'EXCHANGE',
}

export const USER_KICKED_MSG = 'You have been removed from the Game!!';

export enum TradeOffStatus {
  inactive = 0,
  active = 1,
  selected = 2,
  proposed = 3,
}

export interface GameOptions {
  topic: number;
  difficultyLevel: number;
  country: number;
  isInformational: boolean;
}

export enum BreakGames {
  drinkACoffee = 'drinkACoffee',
  spotTheCountry = 'spotTheCountry',
  emoji = 'emoji',
  talkToAnExpert = 'talkToAnExpert',
}

export enum GameName {
  drinkACoffee = 1,
  spotTheCountry = 2,
  emoji = 3,
}

export enum PhaserScenes {
  negotiationScene = 'negotiation-d1-scene',
  presentationProposalScene = 'presentation-proposal-scene',
  coffeeBreakScene = 'coffee-break-scene',
  bilateralScene = 'bilateral-discussion-scene',
}

export enum HelpRoutes {
  singlePlayer = '/single_player',
  multiPlayer = '/multi_player',
  negotiationBoard = '/negotiation_phase',
  coffeeBreak = '/coffee-break',
  bilateralDiscussion = '/bilateral-discussion',
  joinGame = '/multiplayer-join'
}

export interface DimensionPosition {
  countriesSum: number;
  flag: string;
  id: number;
  name: string;
  noposition?: boolean;
  percentages: number;
  sumOfCountryPopulation: number;
  userDimensionBox: boolean;
  widthValue: string;
  highlightPosition: boolean;
}

export const tooltipVariations: Variation = {
  theme: null,
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 5],
  interactive: true
};
