import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IUser } from 'src/app/models/api.model';
import { UserSettings } from 'src/app/models/user.model';
import { v4 as uuidv4 } from 'uuid';
import {
  BODY,
  ACCESSORIES,
  FACE,
  SKINTONE,
  HAIR,
  HAIRCOLOR
} from '../../shared/avatar.utils';
import { getRandomInt } from '../../shared/math.utils';

export interface UserState {
  user: IUser;
  settings: UserSettings;
  quizAnswerdQuestions: number[];
  routerUrl: string;
}

export const createInitialState = () => ({
    user: {
      /* eslint-disable @typescript-eslint/naming-convention */
      avatar_Accessory: getRandomInt(ACCESSORIES.length - 1),
      avatar_Body: getRandomInt(BODY.length - 1),
      avatar_Face: getRandomInt(FACE.length - 1),
      avatar_HairColor: getRandomInt(HAIRCOLOR.length - 1),
      avatar_Head: getRandomInt(HAIR.length - 1),
      avatar_SkinTone: getRandomInt(SKINTONE.length - 1),
      /* eslint-enable @typescript-eslint/naming-convention */
      createDate: '',
      isDeleted: false,
      name: '',
      normalUser: 1,
      sessionid: uuidv4(),
      // only for development
      // sessionid: 'af9ddebc-aab3-4e12-97cf-3ebb9286d6a4',
      userId: '',
      token: '',
    },
    settings: {
      backgroundMusic: 1,
      soundEffects: 1,
      mute: false,
      language: window.navigator.language?.split('-')[0] || ''
    },
    quizAnswerdQuestions: [],
    routerUrl: '',
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
