import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import { UserQuery } from '../state/userState/user.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare let cordova: any;

interface Sound {
  key: string;
  asset: string;
  music: boolean;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AudioService {
  public musicPlaying = false;
  public activeMusicPlaying: string;
  private music: HTMLAudioElement = new Audio();
  private nativeMusic: MediaObject;
  private nativeSound: MediaObject;

  private sounds: Sound[] = [];
  private audioType = 'html5';
  private backgroundVolume = 1.0;
  private soundVolume = 1.0;
  private mute = false;

  constructor(
    private platform: Platform,
    private media: Media,
    private userQuery: UserQuery,
  ) {
    if (this.platform.is('cordova')) {
      this.audioType = 'native';
    }
    this.userQuery.select('settings').pipe(untilDestroyed(this)).subscribe(settings => {
      if (this.mute !== settings.mute && settings.mute) {
        if (this.audioType === 'native') {
          this.nativeMusic?.setVolume(0);
        } else {
          this.music.volume = 0;
        }
      } else if (!settings.mute && (this.mute !== settings.mute ||
                  this.backgroundVolume !== settings.backgroundMusic ||
                  this.soundVolume !== settings.soundEffects)) {
        this.backgroundVolume = settings.backgroundMusic;
        this.soundVolume = settings.soundEffects;
        if (this.audioType === 'native') {
          this.nativeMusic?.setVolume(this.backgroundVolume);
        } else {
          this.music.volume = this.backgroundVolume;
        }
      }
      this.mute = settings.mute;
    });
  }

  preload(key: string, asset: string, music: boolean) {
    this.sounds.push({
      key,
      asset,
      music
    });
  }

  play(key: string) {
    const audio = this.sounds.find((sound) => sound.key === key);
    if (!audio) {
      return;
    }
    if (this.audioType === 'native') {
      if (audio.music) {
        this.stop();
        this.nativeMusic?.release();
        this.nativeMusic = this.media.create(this.platform.is('android') ?
                                              `${cordova.file.applicationDirectory}public${audio.asset}`
                                              : audio.asset);
        this.nativeMusic.onStatusUpdate.pipe(untilDestroyed(this)).subscribe(status => {
          console.log('onStatusUpdate', status, audio.asset);
          if (status === 1) {
            this.nativeMusic.setVolume(this.mute ? 0 : this.backgroundVolume);
          } else if (status === 4 && this.activeMusicPlaying) {
            this.nativeMusic?.seekTo(0);
            this.nativeMusic?.play();
          }
        });
        this.nativeMusic?.play();
        this.nativeMusic?.setVolume(this.mute ? 0 : this.backgroundVolume);
        this.musicPlaying = true;
        this.activeMusicPlaying = key;
      } else {
        this.nativeSound?.release();
        this.nativeSound = this.media.create(this.platform.is('android') ?
                                              `${cordova.file.applicationDirectory}public${audio.asset}`
                                              : audio.asset);
        this.nativeSound?.onStatusUpdate.pipe(untilDestroyed(this)).subscribe(status => {
          if (status === 1) {
            this.nativeSound?.setVolume(this.mute ? 0 : this.soundVolume);
          }
        });
        this.nativeSound?.play();
        this.nativeSound?.setVolume(this.mute ? 0 : this.soundVolume);
      }
    } else {
      if (audio.music) {
        this.music.src = audio.asset;
        this.music.volume = this.mute ? 0 : this.backgroundVolume;
        this.music.loop = true;
        this.music.load();
        this.music.play().then(_ => {
          this.musicPlaying = true;
          this.activeMusicPlaying = key;
        })
        .catch(error => {
          this.musicPlaying = false;
          console.log(error);
        });
      } else {
        const audioAsset = new Audio(audio.asset);
        audioAsset.volume = this.mute ? 0 : this.soundVolume;
        audioAsset.play();
      }
    }
  }

  stop() {
    if (this.activeMusicPlaying) {
      if (this.audioType === 'native') {
        this.nativeMusic?.release();
      } else {
        this.music?.pause();
      }
    }
    this.activeMusicPlaying = undefined;
  }

  pause() {
    if (this.audioType === 'native') {
      this.nativeMusic?.pause();
    }
  }

  resume() {
    if (this.audioType === 'native') {
      this.nativeMusic?.play();
    }
  }
}
