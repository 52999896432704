import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AvatarService } from 'src/app/services/avatar-customization.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @ViewChild('svg') svg: ElementRef;
  constructor(private avatarService: AvatarService) { }

  ngOnInit() {}

  setAvatar(
    bodyIndex: number,
    skinIndex: number,
    hairIndex: number,
    hairColorIndex: number,
    accessoriesIndex: number,
    faceIndex: number,
    countryCode?: string
  ) {
    this.svg.nativeElement.innerHTML = '';
    this.svg.nativeElement.appendChild(
      this.avatarService.customizationAvatar(
        bodyIndex,
        skinIndex,
        hairIndex,
        hairColorIndex,
        accessoriesIndex,
        faceIndex,
        countryCode
      )
    );
  }
}
