import { combineLatest, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { ICurrentCountryPosition, IDimension, IHelpTopic, ITopic } from '../../models/api.model';
import { IUserGame } from 'src/app/models/api.model';
import { GameService } from 'src/app/state/gameState/game.service';
import { AudioService } from 'src/app/services/audio.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import _ from 'lodash';
import { BilateralHistoryStatus } from 'src/app/models/game.model';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CountriesQuery } from 'src/app/state/countriesState/countries.query';
import { getStanceTranslationKey } from 'src/app/shared/game.utils';

@UntilDestroy()
@Component({
  selector: 'app-book-page-component',
  templateUrl: './book-page.component.html',
  styleUrls: ['./book-page.component.scss'],
})
export class BookPageComponent implements OnInit {
  //To do : Create Topic Model
  topic: ITopic;
  dimensionSub: Subscription;
  dimensionIndex: number;
  bookPath: string;
  isHelp = false;
  isInformational = false;
  public helpTopics: IHelpTopic[];
  public currentCountry: any;
  public page: number;
  public dimensions: IDimension;
  public topicCurrentCountryPosition: ICurrentCountryPosition[];
  selectedIndexes = {topic: 0, subTopic: 0};
  public history: any[][] = [[],[],[]];
  public alliances: any[][] = [[],[],[]];
  public positions = ['', '', ''];
  public bilateralHistoryStatus = BilateralHistoryStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gameQuery: GameQuery,
    private gameService: GameService,
    private userQuery: UserQuery,
    private audioSerive: AudioService,
    private countryQuery: CountriesQuery
  ) {
    this.dimensionSub = this.gameQuery.selectedDimension$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.dimensionIndex = res.value - 1;
      this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
      if (this.isInformational) {
        this.page = 3;
      } else {
        this.page = this.dimensionIndex;
      }
    });
  }

  ngOnInit() {
    // this.page = this.dimensionIndex;
    this.currentCountry = this.gameService.getCurrentUserCountry()?.countryCode;
    combineLatest([
      this.gameQuery.game$,
      this.gameQuery.topics$
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([game, topics]) => {
      if (!game || !topics?.length) {
        return;
      }
      this.topic = topics.find((t) => t.id === game?.topic);
      this.topicCurrentCountryPosition = this.topic?.dimension.map(d =>
        d.positionContent.find(
          pc => pc.currentCountryPosition.find(
            ccp => ccp.countryCode === this.currentCountry
          ))?.currentCountryPosition.find(ccp => ccp.countryCode === this.currentCountry)
      );
    });
    this.gameQuery.select('helpTopics').pipe(untilDestroyed(this)).subscribe(items => {
      this.helpTopics = items;
    });
    this.getBookPath();
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.isHelp = data.help;
      if (this.isHelp) {
        this.page = 3;
      }
    });
  }

  ionViewWillEnter() {
    this.selectedIndexes = {topic: 0, subTopic: 0};
    const userId = this.userQuery.getUserId();
    const game = this.gameQuery.getValue().game;
    const topic = this.gameQuery.getCurrentTopic();
    this.positions = ['','',''];
    const currentUserGame = game?.userGames.find(ug => ug.userId === userId);
    currentUserGame?.user.userGameNegotiation.forEach(ug => {
      topic.dimension.some((dimension, index) => {
        if (dimension.id === ug.dimension) {
          dimension.positionContent.some((position) => {
            if (position.id === ug.position) {
              this.positions[index] = position.name;
              return true;
            }
            return false;
          });
          return true;
        }
        return false;
      });
    });
    this.gameService.getBilateralHistory();
    this.gameQuery.select('bilateralHistory').pipe(untilDestroyed(this)).subscribe((bilateralHistory) => {
      this.history = [[],[],[]];
      this.alliances = [[],[],[]];
      if (bilateralHistory.length) {
        const userGameNegotiations: {[name: string]: IUserGame} = {};
        game.userGames.forEach(ug => {
          ug.user.userGameNegotiation.forEach(ugn => {
            userGameNegotiations[ugn.userGameNegotiationId] = ug;
          });
        });
        const historySorted = _.orderBy(bilateralHistory, [(history) =>
          moment(history.updateDate).format('YYYY-MM-DD HH:mm:ss')
        ], ['desc']);
        historySorted.filter(history => history.status !== BilateralHistoryStatus.failed).forEach(history => {
          topic.dimension.some((dimension, index) => {
            if (dimension.id === +history.dimension) {
              const incoming = (history.userId1 !== userId);
              let positionName = '';
              dimension.positionContent.some((position) => {
                if (position.id === +history.position) {
                  positionName = position.name;
                  return true;
                }
                return false;
              });
              const country = incoming ?
                                userGameNegotiations[history.sendingUserGameNegotiationId].country
                                : userGameNegotiations[history.receivingUserGameNegotiationId].country;
              if (history.status === BilateralHistoryStatus.success) {
                this.alliances[index].push(country);
              }
              if (history.status === BilateralHistoryStatus.success || history.status.toLowerCase() === BilateralHistoryStatus.broken) {
                this.history[index].push({
                  country,
                  tradeoff: history.tradeoff,
                  incoming,
                  status: history.status,
                  position: positionName,
                  brokenBy: history.brokenBy
                });
              }
              this.getBookPath();
              return true;
            }
            return false;
          });
        });
      }
    });
  }

  openBook() {
    this.router.navigate(['/book']);
  }

  activePage(index) {
    this.page = index;
    this.audioSerive.play('change-page');
    this.getBookPath();
  }

  getStanceClass(stance: string) {
    return stance?.toLowerCase().replace(/ /g, '_');
  }

  getStanceTranslation(stance: string) {
    return getStanceTranslationKey(stance);
  }

  onSubTopicClick = (i1: number, i2: number) => {
    this.selectedIndexes.topic = i1;
    this.selectedIndexes.subTopic = i2;
  };

  parseCountryCode(countryCode: string) {
    return this.countryQuery.selectCountryByCountryCode(countryCode);
  }

  getBookPath() {
    if (this.history[this.page]?.length !== 0) {
      this.bookPath = 'blank_book.svg';
    } else {
      this.bookPath = 'book.svg';
    }
  }

  onBack() {
    this.router.navigate([this.userQuery.getRouterUrl() ?? '/select_mode']);
  }
}
