// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-background {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 25rem;
  object-fit: contain;
}

.present {
  width: 5rem;
  opacity: 0;
}
.present.active {
  opacity: 1;
}

.fade-in-image {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/splash-screen/splash-screen.page.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,UAAA;AAAJ;AACI;EAAU,UAAA;AAEd;;AACA;EACI,oBAAA;EACA,4BAAA;EACA,yBAAA;EACA,uBAAA;EACA,wBAAA;AAEJ;;AAAE;EACE;IAAI,UAAA;EAIN;EAHE;IAAM,UAAA;EAMR;AACF","sourcesContent":[".main-background {\n    // background-color: #0B375A;\n    background: transparent;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.logo {\n    width: 25rem;\n    object-fit: contain;\n}\n\n.present {\n    width: 5rem;\n    opacity: 0;\n    &.active {opacity: 1;}\n}\n\n.fade-in-image {\n    animation: fadeIn 2s;\n    -webkit-animation: fadeIn 2s;\n    -moz-animation: fadeIn 2s;\n    -o-animation: fadeIn 2s;\n    -ms-animation: fadeIn 2s;\n  }\n  @keyframes fadeIn {\n    0% {opacity:0;}\n    100% {opacity:1;}\n  }\n  \n  @-moz-keyframes fadeIn {\n    0% {opacity:0;}\n    100% {opacity:1;}\n  }\n  \n  @-webkit-keyframes fadeIn {\n    0% {opacity:0;}\n    100% {opacity:1;}\n  }\n  \n  @-o-keyframes fadeIn {\n    0% {opacity:0;}\n    100% {opacity:1;}\n  }\n  \n  @-ms-keyframes fadeIn {\n    0% {opacity:0;}\n    100% {opacity:1;}\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
