import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { BreakGames } from 'src/app/models/game.model';
import {
  CoffeeBreakScene,
  makeCoffeeBreakScene,
} from 'src/app/phaser/scenes/coffee-break.scene';
import { EmptyScene, makeEmptyScene } from 'src/app/phaser/scenes/empty.scene';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { AudioService } from 'src/app/services/audio.service';
import { Environment, EnvService } from 'src/app/services/env.service';
import { getColorTranslationKey } from 'src/app/shared/game.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-coffee-break',
  templateUrl: './coffee-break.page.html',
  styleUrls: ['./coffee-break.page.scss'],
})
export class CoffeeBreakPage implements OnInit {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;

  index = 0;
  spotTheCountry$: Observable<number>;
  countdDownSubscription: Subscription;
  countdown$: BehaviorSubject<number> = new BehaviorSubject<number>(30);
  timerSubscription: any;
  breakErrorSubscription: Subscription;
  breakError: string;
  spotTheCountryGameResultSubscription: Subscription;
  spotTheCountryTitle: string;
  spotTheCountrySubtitle: string;
  spotTheCountrySelectedGameSub: Subscription;
  spotTheCountrySelectedGame: string;
  showGoToBackButton = false;
  single: boolean;
  usersDataSubscription: Subscription;

  constructor(
    private gameInstanceService: GameInstanceService,
    private gameService: GameService,
    private gameQuery: GameQuery,
    private translateService: TranslateService,
    private audioService: AudioService,
    public envService: EnvService
  ) {
    this.spotTheCountry$ = this.gameQuery.select('spotTheCountryOverlay');
    this.usersDataSubscription = this.gameQuery.game$.pipe(untilDestroyed(this)).subscribe(
      (response) => {
        this.single = response?.single === 1;
      }
    );
  }

  ngOnInit() {
    this.showGoToBackButton = this.envService.env === Environment.debug;
  }

  ionViewWillEnter() {
    if (!this.gameQuery.getValue().game) {
      return;
    }
    this.gameInstanceService.switchScene(
      CoffeeBreakScene.key,
      makeCoffeeBreakScene
    );
  }

  ionViewDidEnter() {
    this.countdown$.next(30);
    this.gameInstanceService.gameInstance.scale.on('resize', this.resize, this);
    this.gameInstanceService.gameInstance.scale.refresh();
    this.gameInstanceService.coffeeBreakPage.next(true);

    this.gameService.updateSpotTheCountryOverlay(0);
    this.gameService.updateBreakGamePlaying(null);
    this.gameService.updateSpotCountryCountdown(false);
  }

  ionViewWillLeave() {
    this.gameService.updateBreakGamePlaying(null);
    this.gameService.updateSpotTheCountriesFound({});
    this.countdDownSubscription?.unsubscribe();
    this.gameInstanceService.coffeeBreakPage.next(false);
    this.gameInstanceService.switchScene(EmptyScene.key, makeEmptyScene);
  }
  startGame() {
    this.index = 0;
    this.gameService.updateSpotTheCountryOverlay(0);
    this.gameService.updateSpotCountryCountdown(true);
    this.gameService.updateBreakGamePlaying(BreakGames.spotTheCountry);
    this.gameService.updateSpotTheCountryGameResult(null);
    this.countdown$.next(30);
    this.spotTheCountrySelectedGameSub = this.gameQuery
      .select('spotTheCountrySelectedGame')
      .subscribe((res) => {
        this.spotTheCountrySelectedGame = res;
      });

    this.spotTheCountryGameResultSubscription = this.gameQuery
      .select('spotTheCountryGameResult')
      .subscribe((res) => {
        if (res === 'success') {
          this.audioService.play('complete-task');
          this.spotTheCountryTitle = this.translateService.instant(
            'spot_the_country_challenge_complete'
          );
          this.spotTheCountrySubtitle = this.translateService.instant(
            'spot_the_country_action_points'
          );
        } else if (res === 'fail') {
          this.spotTheCountryTitle = this.translateService.instant(
            'spot_the_country_task_failed'
          );
          this.spotTheCountrySubtitle = this.translateService.instant(
            'spot_the_country_no_points'
          );
        } else {
          this.spotTheCountryTitle = this.translateService.instant(
            'break_tasks_list_item_spot_the_country'
          );
          const colorTranslation = this.translateService.instant(
            getColorTranslationKey(this.spotTheCountrySelectedGame)
          );
          this.spotTheCountrySubtitle = this.translateService.instant(
            'spot_the_country_task_details',
            {
              color: colorTranslation,
            }
          );
        }
      });

    this.countdDownSubscription = this.gameQuery
      .select('spotCountryCountdown')
      .subscribe((res) => {
        if (res) {
          this.timerSubscription?.unsubscribe();
          this.timerSubscription = interval(1000).subscribe(() => {
            if (this.countdown$.getValue() === 0) {
              this.timerSubscription.unsubscribe();
              this.gameService.updateSpotCountryCountdown(false);
              this.countdDownSubscription.unsubscribe();
              return;
            }
            this.countdown$.next(this.countdown$.getValue() - 1);
          });
        } else {
          this.gameService.updateSpotCountryCountdown(false);
          this.timerSubscription?.unsubscribe();
          this.countdDownSubscription?.unsubscribe();
        }
      });
  }
  closeModal() {
    this.index = 0;
    this.gameService.updateSpotTheCountryOverlay(0);
  }

  resize() {
    if (this.phaserWrapper) {
      this.phaserWrapper.nativeElement.style.width =
        this.gameInstanceService.gameInstance.canvas.style.width;
      this.phaserWrapper.nativeElement.style.height =
        this.gameInstanceService.gameInstance.canvas.style.height;
      this.phaserWrapper.nativeElement.style.marginLeft =
        this.gameInstanceService.gameInstance.canvas.style.marginLeft;
      this.phaserWrapper.nativeElement.style.marginTop =
        this.gameInstanceService.gameInstance.canvas.style.marginTop;
    }
  }
}
