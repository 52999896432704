import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import {
  makeNegotiationScene,
  NegotiationScene,
} from 'src/app/phaser/scenes/negotiation.scene';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { AudioService } from 'src/app/services/audio.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { ITopic, IUser, IUserGame } from 'src/app/models/api.model';
import { UserQuery } from 'src/app/state/userState/user.query';
import { EmptyScene, makeEmptyScene } from 'src/app/phaser/scenes/empty.scene';
import { GameStore } from 'src/app/state/gameState/game.store';
import { Environment, EnvService } from '../../services/env.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

let timeout;

@UntilDestroy()
@Component({
  selector: 'app-negotiation-phase',
  templateUrl: './negotiation-phase.page.html',
  styleUrls: ['./negotiation-phase.page.scss'],
})
export class NegotiationPhasePage implements OnInit {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;

  index = 0;
  btnIndex = 0;
  dimensionNum: number;
  dimensionId: number;
  topic: ITopic;
  user: IUser;
  userHostId: string;
  hasActionPoints: boolean;
  showGoToBreakButton = false;
  isToggleChecked = false;
  toggleSubscription: Observable<boolean>;
  isInformational = false;
  showPositionDescriptionModal = false;
  positionDescriptionText = '';

  constructor(
    public gameInstanceService: GameInstanceService,
    private router: Router,
    private gameService: GameService,
    public gameQuery: GameQuery,
    public gameStore: GameStore,
    public userQuery: UserQuery,
    public envService: EnvService,
    private audioService: AudioService
  ) {
    this.userQuery.user$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.user = res;
    });

    this.gameQuery
      .select((state) => state.game?.userGames)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.hasActionPoints =
          response?.find((el) => el.userId === userQuery.getUserId())
            ?.actionPoint >= 2;
      });

    this.gameQuery
      .select('positionDescription')
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response) {
          this.showPositionDescriptionModal = true;
          this.positionDescriptionText = response;
        } else {
          this.showPositionDescriptionModal = false;
          this.positionDescriptionText = '';
        }
      });

    this.toggleSubscription = this.gameInstanceService.toggleChecked$
      .asObservable()
      .pipe(untilDestroyed(this));
  }
  ngOnInit() {
    this.audioService.play('exploreThePossibilities');
    this.gameService.setTypeOfPhase('negotiation');
    this.showGoToBreakButton = this.envService.env === Environment.debug;

    this.userHostId = this.gameQuery.getGameFromState()?.userGames[0].userId;
    this.gameService.startNPCMovements().catch((err) => console.log(err));
    this.gameService.revealPositions(false);
    timer(10000)
      .pipe(untilDestroyed(this))
      .subscribe((time) => (this.btnIndex = 1));
    this.gameQuery.selectedDimension$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.dimensionNum = res.value;
        this.dimensionId = res.id;
        this.btnIndex = 1;
      });

    this.gameQuery.topics$.pipe(untilDestroyed(this)).subscribe(() => {
      this.topic = this.gameQuery.getCurrentTopic();
      this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
    });

    this.gameInstanceService.toggleChecked$
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.isToggleChecked = res;
      });
  }

  testLog(e) {
    console.log(
      'Click position =>',
      this.gameInstanceService.gameInstance.input.mousePointer.x,
      this.gameInstanceService.gameInstance.input.mousePointer.y,
      { e }
    );
  }

  ionViewWillEnter() {
    this.gameInstanceService.negotiationPage.next(true);
    this.gameInstanceService.switchScene(
      NegotiationScene.key,
      makeNegotiationScene
    );
    if (this.gameInstanceService.isZoomBoardOpen) {
      this.gameService.updateModalOpen(true);
    }
    this.gameInstanceService.toggleChecked$.next(false);
  }

  ionViewDidEnter() {
    this.gameInstanceService.gameInstance.scale.on('resize', this.resize, this);
    this.gameInstanceService.gameInstance.scale.refresh();
    this.btnIndex = 1;
  }

  ionViewWillLeave() {
    this.gameInstanceService.negotiationPage.next(false);
    this.gameInstanceService.switchScene(EmptyScene.key, makeEmptyScene);
    this.gameService.updateModalOpen(false);
    this.gameService.revealPositions(false);
    this.gameInstanceService.toggleChecked$.next(false);
    clearTimeout(timeout);
    this.btnIndex = 0;
    this.gameInstanceService.guideDisabled$.next(false);
  }

  openModal() {
    this.index = 1;
    this.btnIndex = 0;
  }

  openOverview() {
    this.router.navigate(['/overview']);
  }

  reveal() {
    this.index = 0;
    setTimeout(() => {
      this.btnIndex = 2;
    }, 3000);

    this.gameService.updateDimensionsCalled(this.dimensionNum);

    if (
      (this.dimensionNum === 1 &&
        this.gameQuery.getNumOfDimensionsCalled().d1 === 1) ||
      (this.dimensionNum === 2 &&
        this.gameQuery.getNumOfDimensionsCalled().d2 === 1) ||
      (this.dimensionNum === 3 &&
        this.gameQuery.getNumOfDimensionsCalled().d3 === 1)
    ) {
      this.gameService.revealPositions(true);

      if (this.hasActionPoints) {
        this.gameService.sendRevealPositions(this.dimensionId);
      }
    }

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      this.gameService.revealPositions(false);
      this.btnIndex = 0;
    }, 20000);
  }
  closeModal() {
    this.index = 0;
    this.btnIndex = 1;
  }

  closePositionModal() {
    this.showPositionDescriptionModal = false;
    this.positionDescriptionText = '';
  }
  closeInfo() {
    this.btnIndex = 0;
    this.gameService.revealPositions(false);
    clearTimeout(timeout);
  }

  toggleChange(event) {
    this.gameInstanceService.toggleChecked$.next(event.target.checked);
  }

  preventClick(e: any) {
    e.stopPropagation();
  }

  resize() {
    if (this.phaserWrapper) {
      this.phaserWrapper.nativeElement.style.width =
        this.gameInstanceService.gameInstance.canvas.style.width;
      this.phaserWrapper.nativeElement.style.height =
        this.gameInstanceService.gameInstance.canvas.style.height;
      this.phaserWrapper.nativeElement.style.marginLeft =
        this.gameInstanceService.gameInstance.canvas.style.marginLeft;
      this.phaserWrapper.nativeElement.style.marginTop =
        this.gameInstanceService.gameInstance.canvas.style.marginTop;
    }
  }
}
