import { ILanguage } from "../models/user.model";

export const languages: ILanguage[] = [
  {
    title: 'български',
    code: 'bg'
  },
  {
    title: 'Español',
    code: 'es'
  },
  {
    title: 'čeština',
    code: 'cs'
  },
  {
    title: 'dansk',
    code: 'da'
  },
  {
    title: 'Deutsch',
    code: 'de'
  },
  {
    title: 'eesti',
    code: 'et'
  },
  {
    title: 'ελληνικά',
    code: 'el'
  },
  {
    title: 'English',
    code: 'en'
  },
  {
    title: 'français',
    code: 'fr'
  },
  {
    title: 'Gaeilge',
    code: 'ga'
  },
  {
    title: 'hrvatski',
    code: 'hr'
  },
  {
    title: 'italiano',
    code: 'it'
  },
  {
    title: 'lietuvių',
    code: 'lt'
  },
  {
    title: 'latviešu',
    code: 'lv'
  },
  {
    title: 'magyar',
    code: 'hu'
  },
  {
    title: 'Malti',
    code: 'mt'
  },
  {
    title: 'Nederlands',
    code: 'nl'
  },
  {
    title: 'polski',
    code: 'pl'
  },
  {
    title: 'português',
    code: 'pt'
  },
  {
    title: 'română',
    code: 'ro'
  },
  {
    title: 'slovenčina',
    code: 'sk'
  },
  {
    title: 'slovenščina',
    code: 'sl'
  },
  {
    title: 'suomi',
    code: 'fi'
  },
  {
    title: 'svenska',
    code: 'sv'
  }
];
