// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  height: 100vh;
  --background: transparent;
}

.w-h-100 {
  width: 100%;
  height: 100vh;
}

.eu-council-game-container {
  position: absolute;
  top: 0;
  z-index: 1;
}

::ng-deep .back-btn .back {
  bottom: 3.917rem !important;
  left: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/game/coffee-break/coffee-break.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;AACF;;AACA;EACE,kBAAA;EACA,MAAA;EAOA,UAAA;AAJF;;AAWA;EACE,2BAAA;EACA,qBAAA;AARF","sourcesContent":["ion-content {\n  height: 100vh;\n  --background: transparent;\n}\n\n.w-h-100 {\n  width: 100%;\n  height: 100vh;\n}\n.eu-council-game-container {\n  position: absolute;\n  top: 0;\n  // width: 100%;\n  // height: 100vh;\n  // top: 10%;\n  // left: 10%;\n  // height: 600px;\n  // width: 800px;\n  z-index: 1;\n  // max-width: 1600px;\n  // max-height: 900px;\n  // display: flex;\n  // flex-direction: column;\n}\n\n::ng-deep .back-btn .back {\n  bottom: 3.917rem !important;\n  left: 1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
