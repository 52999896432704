import { Injectable } from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import { GameQuery } from '../state/gameState/game.query';
import { UserQuery } from '../state/userState/user.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GameBarService {
  achievements: any;
  actionPoints: BehaviorSubject<any> = new BehaviorSubject<any>(10);

  constructor(private gameQuery: GameQuery, private userQuery: UserQuery) {
    this.gameQuery.select((state) => state.game?.userGames)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.achievements = res.find(ug => ug.userId === this.userQuery.getUserId());
          this.actionPoints.next(this.achievements);
        }
      });
  }
}
