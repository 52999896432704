import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {GameQuery} from 'src/app/state/gameState/game.query';
import {GameService} from 'src/app/state/gameState/game.service';
import { UserService } from 'src/app/state/userState/user.service';
import {ITopic} from '../../../models/api.model';
import anime from 'animejs/lib/anime.es';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-proposal-page',
  templateUrl: './proposal.page.html',
  styleUrls: ['./proposal.page.scss'],
})
export class ProposalPage implements OnInit {
  options: AnimationOptions;
  public topic: ITopic;
  public page = 0;
  public allPages: number;
  public title: string;
  public country: string;
  isInformational = false;
  isSecreteriat = false;
  representativePath: string;
  flag: boolean;
  animations: any[] = [];
  timerId: any;
  private ai: AnimationItem;
  constructor(
    private gameService: GameService,
    private gameQuery: GameQuery,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.translateService.onLangChange
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            console.log('COULDNT TRANSLATE ', err);
            throw err;
          })
        )
        .subscribe(() => {
          this.narration();
        });
  }

  ngOnInit() {
    this.gameQuery.topics$.pipe(untilDestroyed(this)).subscribe(item => {
      if (!item.length) {
        return;
      }
      this.topic = item.find(topic => topic.id === this.gameQuery.getTopicId());
      this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
      this.isSecreteriat = this.gameQuery.getCurrentTopicSpeakerIsSecreteriat();
      this.allPages = this.topic.dimension.length + 1;
      this.gameService.addTopic(this.topic);
      this.gameQuery.representative$.pipe(untilDestroyed(this), take(1)).subscribe(path => {
        this.representativePath = path;
        const animeRepresentativePath = path.slice(0, path.indexOf('.'));
        const jsonTxt = '.json';
        this.options = {
          path: '/assets/testAnimations/representatives/' + animeRepresentativePath + jsonTxt
        };
      });
    });
  }

  ionViewDidEnter() {
    this.narration();
  }

  nextPage() {
    if (this.page === this.allPages) {
      this.gameService.updateProposalTour(true);
      this.router.navigate(['/presentation_proposal']);
    } else {
      this.page++;
      this.narration();
    }
  }

  previousPage() {
    this.page--;
    this.narration();
  }

  narration() {
    if (this.page !== 0) {
      this.flag = false;
    }
    const startAnimation = (page: number) => {
      const activeInstances = anime.running;
      this.animations.forEach(anim => {
        const index = activeInstances.indexOf(anim);
        activeInstances.splice(index, 1);
      });
      this.ai?.play();
      this.animations.push(anime.timeline({loop: false})
      .add({
        targets: `.anim-a .word`,
        scale: [4,1],
        opacity: [0,1],
        translateZ: 0,
        easing: 'easeOutExpo',
        delay: (el, i) => {
          this.setSpeed((120 * i) / 1000);
          return 120 * i;
        },
        complete: () => {
          anime.timeline({loop: false})
          .add({
            targets: `.anim-b .word`,
            scale: [4,1],
            opacity: [0,1],
            translateZ: 0,
            easing: 'easeOutExpo',
            delay: (el, i) => {
              this.setSpeed((120 * i) / 1000);
              return 120 * i;
            },
            complete: () => {
              if (page === 0) {
                this.ai.stop();
                return;
              }
              anime.timeline({loop: false})
              .add({
                targets: `.anim-c .word`,
                scale: [4,1],
                opacity: [0,1],
                translateZ: 0,
                easing: 'easeOutExpo',
                delay: (el, i) => {
                  this.setSpeed((120 * i) / 1000);
                  return 120 * i;
                },
                complete: () => {
                  this.flag = true;
                  anime.timeline({loop: false})
                  .add({
                    targets: `.anim-d .word`,
                    scale: [4,1],
                    opacity: [0,1],
                    translateZ: 0,
                    easing: 'easeOutExpo',
                    delay: (el, i) => {
                      this.setSpeed((120*i) / 1000);
                      return 120*i;
                    },
                    complete: () => {
                      this.ai.stop();
                    }
                  });
                }
              });
            }
          });
        }
      }));
    };
    setTimeout(() => startAnimation(this.page), 200);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.ai = animationItem;
  }

  setSpeed(num: number) {
    if (num > 0.8) {
      num = 0.8;
    }
    this.ai.setSpeed(num);
  }
}
