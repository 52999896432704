// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: rgba(0, 51, 153, 0.91);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.spinner-overlay img {
  width: 5.781rem;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,kCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kCAAA;UAAA,0BAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;AACJ","sourcesContent":[".spinner-overlay {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 999;\n  top: 0;\n  left: 0;\n  background: rgba(0, 51, 153, 0.91);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  backdrop-filter: blur(3px);\n\n  img {\n    width: 5.781rem;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
