// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  flex-direction: row;
  margin-left: 1rem;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.container .dimension {
  background: var(--color-yellow-bookmark) linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48) 100%);
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.375rem;
  font-weight: bold;
  color: var(--color-primary-blue);
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  height: auto;
}
.container .title {
  font-size: 1rem;
  color: #ffffff;
  margin-right: 0.5rem;
  letter-spacing: 0;
  line-height: 1.375rem;
  font-weight: bold;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.24);
}
.container .row {
  display: flex;
  align-items: center;
  margin-top: 1.3rem;
  flex-direction: column;
}
.container .subtitle {
  font-size: 0.8rem;
  color: #ffffff;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.24);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.333rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/negotiation-title/negotiation-title.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;EACA,6BAAA;AACF;AACE;EACE,yHAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,gCAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,oBAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,wCAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AADJ;AAIE;EACE,iBAAA;EACA,cAAA;EACA,wCAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;AAFJ","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  align-self: flex-start;\n  justify-content: center;\n  flex-direction: row;\n  margin-left: 1rem;\n  z-index: 1;\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, 0);\n\n  .dimension {\n    background: var(--color-yellow-bookmark) linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48) 100%);\n    font-size: 1rem;\n    letter-spacing: 0;\n    line-height: 1.375rem;\n    font-weight: bold;\n    color: var(--color-primary-blue);\n    padding: 0.2rem 0.4rem;\n    border-radius: 50%;\n    height: auto;\n  }\n\n  .title {\n    font-size: 1rem;\n    color: #ffffff;\n    margin-right: 0.5rem;\n    letter-spacing: 0;\n    line-height: 1.375rem;\n    font-weight: bold;\n    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.24);\n  }\n\n  .row {\n    display: flex;\n    align-items: center;\n    margin-top: 1.3rem;\n    flex-direction: column;\n  }\n\n  .subtitle {\n    font-size: 0.8rem;\n    color: #ffffff;\n    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.24);\n    font-weight: bold;\n    letter-spacing: 0;\n    line-height: 1.333rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
