import { GameInstanceService } from 'src/app/services/game-instance.service';
import * as Phaser from 'phaser';
let context: any;

export class TestScene extends Phaser.Scene {
  static key = 'test-scene';
  testSprite;
  testSprite2;
  flag;
  avatarGroup;

  constructor(private gameInstanceService: GameInstanceService) {
    super({
      key: TestScene.key,
    });
  }

  public preload() {
    this.load.svg('avatar', '../../../assets/d1/avatar10.svg');
    this.load.atlas({
      key: 'walkAvatar',
      textureURL: '../../../assets/testAnimations/walkAvatar-0.png',
      atlasURL: '../../../assets/testAnimations/walkAvatar.json',
    });
    this.load.svg('flag', '../../../assets/d1/flag-denmark.svg');
  }

  public create() {
    this.testSprite = this.add.sprite(
      1550,
      300,
      'walkAvatar',
      'WALK_FRONT_flag_00000.png'
    );
    this.testSprite2 = this.add.sprite(
      250,
      300,
      'walkAvatar',
      'WALK_FRONT_flag_00000.png'
    );
    this.flag = this.add
      .sprite(this.testSprite.x, this.testSprite.y, 'flag')
      .setScale(0.3, 0.3)
      .setOrigin(0.7, 1);
    this.avatarGroup = this.add.group();

    this.avatarGroup.addMultiple([this.flag, this.testSprite]);

    const frameNames = this.anims.generateFrameNames('walkAvatar', {
      start: 1,
      end: 21,
      zeroPad: 5,
      prefix: 'WALK_FRONT_flag_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'walk',
      frames: frameNames,
      frameRate: 24,
      repeat: -1,
    });
    this.testSprite.play('walk');
    this.testSprite2.play('walk');

    this.tweens.add({
      targets: this.avatarGroup.getChildren(),
      ease: 'Linear',
      x: 200,
      y: 800,
      duration: 6500, // (+`${distance}` / 100),
      delay: 500,
      onComplete: () => {
        this.testSprite.anims.stop();
      },
    });
  }
  public update(time: number, delta: number): void {}
}

export const makeTestScene = (ctx) => {
  context = ctx;
  // console.log({ context });
  return TestScene;
};
