import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserStore } from '../../state/userState/user.store';
import { AvatarService } from 'src/app/services/avatar-customization.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import {
  BODY,
  ACCESSORIES,
  FACE,
  SKINTONE,
  HAIR,
  HAIRCOLOR
} from '../../shared/avatar.utils';
import { getRandomInt } from '../../shared/math.utils';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { IUserAvatar } from 'src/app/models/api.model';

enum Direction {
  FORWARD = 'forward',
  BACKWARD = 'backward'
}

@Component({
  selector: 'app-customize-avatar-page',
  templateUrl: './customize-avatar.page.html',
  styleUrls: ['./customize-avatar.page.scss'],
})
export class CustomizeAvatarPage implements OnInit {
  direction = Direction;
  @ViewChild('svg') svg: ElementRef;
  public bodyIndex = 0;
  public accessoriesIndex = -1;
  public faceIndex = 0;
  public skinIndex = 0;
  public hairIndex = 0;
  public hairColorIndex = 0;
  public testText = '';

  constructor(
    private router: Router,
    private avatarService: AvatarService,
    private gameQuery: GameQuery,
    private gameService: GameService,
    private userStore: UserStore,
    private userQuery: UserQuery
  ) {}

  ngOnInit() {
    this.testText = '1';
  }

  ionViewWillEnter() {
    this.bodyIndex = this.userQuery.getValue().user.avatar_Body;
    this.accessoriesIndex =  this.userQuery.getValue().user.avatar_Accessory;
    this.faceIndex = this.userQuery.getValue().user.avatar_Face;
    this.skinIndex = this.userQuery.getValue().user.avatar_SkinTone;
    this.hairIndex =  this.userQuery.getValue().user.avatar_Head;
    this.hairColorIndex = this.userQuery.getValue().user.avatar_HairColor;
    this.renderAvatar();
  }

  renderAvatar() {
    this.svg.nativeElement.innerHTML = '';
    this.svg.nativeElement.appendChild(
      this.avatarService.customizationAvatar(
        this.bodyIndex,
        this.skinIndex,
        this.hairIndex,
        this.hairColorIndex,
        this.accessoriesIndex,
        this.faceIndex
      )
    );
  }

  changeBody(side: Direction) {
    if (side === Direction.BACKWARD && this.bodyIndex) {
      this.bodyIndex -= 1;
    } else if (side === Direction.FORWARD && this.bodyIndex < BODY.length - 1) {
      this.bodyIndex += 1;
    }
    this.renderAvatar();
  }

  changeAccessories(side: Direction) {
    if (side === Direction.BACKWARD && this.accessoriesIndex > -1) {
      this.accessoriesIndex -= 1;
    } else if (side === Direction.FORWARD && this.accessoriesIndex < ACCESSORIES.length - 1) {
      this.accessoriesIndex += 1;
    }
    this.renderAvatar();
  }

  changeFace(side: Direction) {
    if (side === Direction.BACKWARD && this.faceIndex) {
      this.faceIndex -= 1;
    } else if (side === Direction.FORWARD && this.faceIndex < FACE.length - 1) {
      this.faceIndex += 1;
    }
    this.renderAvatar();
  }

  changeSkinTone(side: Direction) {
    if (side === Direction.BACKWARD && this.skinIndex) {
      this.skinIndex -= 1;
    } else if (side === Direction.FORWARD && this.skinIndex < SKINTONE.length - 1) {
      this.skinIndex += 1;
    }
    this.renderAvatar();
  }

  changeHair(side: Direction) {
    if (side === Direction.BACKWARD && this.hairIndex) {
      this.hairIndex -= 1;
    } else if (side === Direction.FORWARD && this.hairIndex < HAIR.length - 1) {
      this.hairIndex += 1;
    }
    this.renderAvatar();
  }

  changeHairColor(side: Direction) {
    if (side === Direction.BACKWARD && this.hairColorIndex) {
      this.hairColorIndex -= 1;
    } else if (side === Direction.FORWARD && this.hairColorIndex < HAIRCOLOR.length - 1) {
      this.hairColorIndex += 1;
    }
    this.renderAvatar();
  }

  randomAvatarChoice() {
    this.bodyIndex = getRandomInt(BODY.length - 1);
    this.accessoriesIndex = getRandomInt(ACCESSORIES.length) - 1;
    this.faceIndex = getRandomInt(FACE.length - 1);
    this.skinIndex = getRandomInt(SKINTONE.length - 1);
    this.hairIndex = getRandomInt(HAIR.length - 1);
    this.hairColorIndex = getRandomInt(HAIRCOLOR.length - 1);
    this.renderAvatar();
  }

  confirmAvatar() {
    const avatarBody: IUserAvatar = {
      /* eslint-disable @typescript-eslint/naming-convention */
      avatar_Accessory: this.accessoriesIndex,
      avatar_Body: this.bodyIndex,
      avatar_Face: this.faceIndex,
      avatar_HairColor: this.hairColorIndex,
      avatar_Head: this.hairIndex,
      avatar_SkinTone: this.skinIndex
      /* eslint-disable @typescript-eslint/naming-convention */
    };
    this.userStore.update(state => ({
      user: {
        ...state.user,
        ...avatarBody
      }
    }));
    if (this.gameQuery.getValue().game?.gameId && this.gameQuery.getValue().game?.single === 0) {
      this.gameService.updateUserAvatar(avatarBody);
    }
    this.onBack();
  }

  onBack() {
    this.router.navigate([this.userQuery.getRouterUrl()]);
  }
}
