import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/state/userState/user.service';
import { GameQuery } from 'src/app/state/gameState/game.query';
import anime from 'animejs/lib/anime.es';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-presentation-of-mission-page',
  templateUrl: './presentation-of-mission.page.html',
  styleUrls: ['./presentation-of-mission.page.scss'],
})
export class PresentationOfMissionPage implements OnInit {
  // @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;
  options: AnimationOptions;

  index = 0;
  presidentPath: string;
  flag: boolean;
  animations: any[] = [];
  timeoutId: any;
  isInformational = false;
  topicTitle = '';
  private ai: AnimationItem;
  constructor(
    private router: Router,
    private userService: UserService,
    private gameQuery: GameQuery
  ) {}

  ngOnInit() {
    this.gameQuery.president$.pipe(untilDestroyed(this)).subscribe((path) => {
      this.presidentPath = path;
      const animePresidentPath = path.slice(0, path.indexOf('.'));
      const jsonTxt = '.json';
      this.options = {
        path:
          '/assets/testAnimations/presidents/' + animePresidentPath + jsonTxt,
      };
    });
    this.isInformational = this.gameQuery.getCurrentTopicIsInformational();
    if (this.isInformational) {
      this.topicTitle = this.gameQuery.getCurrentTopic().title;
    }
  }

  ionViewWillEnter() {}
  ionViewWillLeave() {}

  ionViewDidEnter() {
    this.animationTexts();
  }

  nextPopUp() {
    this.index++;
    this.animationTexts();
  }

  animationTexts() {
    this.flag = false;
    const startAnimation = (page: number) => {
      const activeInstances = anime.running;
      this.animations.forEach((anim) => {
        const index = activeInstances.indexOf(anim);
        activeInstances.splice(index, 1);
      });
      this.ai.play();
      this.animations.push(
        anime.timeline({ loop: false }).add({
          targets: `.anim-a .word`,
          scale: [4, 1],
          opacity: [0, 1],
          translateZ: 0,
          easing: 'easeOutExpo',
          delay: (el, i) => {
            this.setSpeed((120 * i) / 1000);
            return 120 * i;
          },
          complete: () => {
            anime.timeline({ loop: false }).add({
              targets: `.anim-b .word`,
              scale: [4, 1],
              opacity: [0, 1],
              translateZ: 0,
              easing: 'easeOutExpo',
              delay: (el, i) => {
                this.setSpeed((120 * i) / 1000);
                return 120 * i;
              },
              complete: () => {
                anime.timeline({ loop: false }).add({
                  targets: `.anim-c .word`,
                  scale: [4, 1],
                  opacity: [0, 1],
                  translateZ: 0,
                  easing: 'easeOutExpo',
                  delay: (el, i) => {
                    this.setSpeed((120 * i) / 1000);
                    return 120 * i;
                  },
                  complete: () => {
                    if (page !== 2) {
                      this.ai.stop();
                      return;
                    }
                    this.flag = true;
                    anime.timeline({ loop: false }).add({
                      targets: `.anim-d .word`,
                      scale: [4, 1],
                      opacity: [0, 1],
                      translateZ: 0,
                      easing: 'easeOutExpo',
                      delay: (el, i) => {
                        this.setSpeed((120 * i) / 1000);
                        return 120 * i;
                      },
                      complete: () => {
                        anime.timeline({ loop: false }).add({
                          targets: `.anim-e .word`,
                          scale: [4, 1],
                          opacity: [0, 1],
                          translateZ: 0,
                          easing: 'easeOutExpo',
                          delay: (el, i) => {
                            this.setSpeed((120 * i) / 1000);
                            return 120 * i;
                          },
                          complete: () => {
                            this.ai.stop();
                          },
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        })
      );
    };
    setTimeout(() => startAnimation(this.index), 200);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.ai = animationItem;
  }

  setSpeed(num: number) {
    if (num > 0.8) {
      num = 0.8;
    }
    this.ai.setSpeed(num);
  }

  goToProposal() {
    this.router.navigate(['/presentation_proposal']);
  }
}
