import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserStore, UserState } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  user$ = this.select('user');
  settings$ = this.select('settings');

  constructor(protected store: UserStore) {
    super(store);
  }

  getLanguage() {
    return this.getValue().settings.language;
  }

  getUserAvatarIndex(): number[] {
    return [
      this.getValue().user.avatar_Body,
      this.getValue().user.avatar_SkinTone,
      this.getValue().user.avatar_Head,
      this.getValue().user.avatar_HairColor,
      this.getValue().user.avatar_Accessory,
      this.getValue().user.avatar_Face,
    ];
  }

  getSessionId() {
    return this.getValue().user.sessionid;
  }

  getUserId() {
    return this.getValue().user.userId;
  }

  getQuizAnswerdQuestions() {
    return this.getValue().quizAnswerdQuestions;
  }

  getRouterUrl() {
    return this.getValue().routerUrl;
  }

  getCsrfToken() {
    return this.getValue().user.token;
  }
}
