import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { CountriesQuery } from 'src/app/state/countriesState/countries.query';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { AvatarService } from 'src/app/services/avatar-customization.service';
import { GameBarService } from 'src/app/services/game-bar.service';
import { UserQuery } from 'src/app/state//userState/user.query';
import { IGameStart, ITopic, IUserAvatar } from 'src/app/models/api.model';
import { DifficultyLevel, GameMode } from 'src/app/shared/game.utils';
import { UserService } from 'src/app/state/userState/user.service';
import { Country } from 'src/app/models/country.model';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from 'src/app/services/tracking.service';
import _, { sortBy } from "lodash";

@UntilDestroy()
@Component({
  selector: 'app-single-page',
  templateUrl: './single-player-menu.page.html',
  styleUrls: ['./single-player-menu.page.scss'],
})
export class SinglePlayerPage implements OnInit {
  @ViewChild('svg') svg: ElementRef;
  topics: ITopic[];
  countries: Country[];
  gameMode = GameMode;
  mode: GameMode;
  newGameForm: FormGroup;
  isStartGameDisabled = true;
  topicDescription = '';
  topicDisclaimer = '';
  enableTopicDescription = false;
  enableDifficultyDescription = false;
  avatarIndex = [];
  difficultyLevel = DifficultyLevel;
  loading = false;
  isCountrySelected = false;
  ai: AnimationItem;
  confetti: AnimationOptions = {
    path: '/assets/game_results/confetti_v3.json',
    loop: false,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gameService: GameService,
    private gameQuery: GameQuery,
    private userQuery: UserQuery,
    private countriesQuery: CountriesQuery,
    public formBuilder: FormBuilder,
    private avatarService: AvatarService,
    private userService: UserService,
    private gameBarService: GameBarService,
    private gameInstanceService: GameInstanceService,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.gameQuery.select('topics').pipe(untilDestroyed(this)).subscribe((res) => {
      this.topics = res;
    });
    this.countriesQuery.select('countries').pipe(untilDestroyed(this)).subscribe((res) => {
      this.countries = _.sortBy(res, ['name']);
    });
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe((data) => {
      const options = this.gameQuery.getValue().gameOptions;
      this.mode = data.mode as GameMode;
      this.avatarIndex = this.userQuery.getUserAvatarIndex();
      if (this.mode === GameMode.singlePlayer) {
        this.newGameForm = new FormGroup({
          topic: new FormControl(options.topic || '', Validators.required),
          difficultyLevel: new FormControl(
            {value: options.difficultyLevel || null, disabled: !!options.isInformational},
            Validators.required
          ),
          country: new FormControl(
            {value: options.country || '', disabled: !options.isInformational},
            Validators.required
          ),
        });
        setTimeout(() => {
          this.isStartGameDisabled =
            !options.topic ||
            (!options.difficultyLevel && !this.newGameForm.get('difficultyLevel').disabled) ||
            !options.country;
        }, 50);

      } else {
        this.newGameForm = new FormGroup({
          topic: new FormControl('', Validators.required),
          difficultyLevel: new FormControl({value: null, disabled: false}, Validators.required),
        });
      }
      this.newGameForm.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
        setTimeout(() => {
          this.isStartGameDisabled =
            !val.topic ||
            (!val.difficultyLevel && !this.newGameForm.get('difficultyLevel').disabled) ||
            !(this.mode !== GameMode.singlePlayer || val.country);
        }, 50);
      });
    });
  }

  ionViewWillEnter() {
    this.isStartGameDisabled =
      !this.newGameForm.get('topic').value ||
      !this.newGameForm.get('difficultyLevel').value ||
      !(
        this.mode !== GameMode.singlePlayer ||
        this.newGameForm.get('country').value
      );
    if (
      this.newGameForm.get('difficultyLevel').value !== '' &&
      this.mode === GameMode.singlePlayer
    ) {
      this.newGameForm.get('country').enable();
    }
    this.avatarIndex = this.userQuery.getUserAvatarIndex();
    this.svg.nativeElement.innerHTML = '';
    this.svg.nativeElement.appendChild(
      this.avatarService.customizationAvatar(
        this.avatarIndex[0],
        this.avatarIndex[1],
        this.avatarIndex[2],
        this.avatarIndex[3],
        this.avatarIndex[4],
        this.avatarIndex[5],
        this.gameQuery.getValue().gameOptions?.country
      )
    );
    this.isCountrySelected = false;
    if (document.querySelector('ion-select.select-country')?.shadowRoot?.querySelector('.select-text.select-placeholder+.select-text')) {
      const shadowRoot = document.querySelector('ion-select.select-country')?.shadowRoot;
      shadowRoot.querySelectorAll('.select-text')[0].setAttribute('style', '');
      shadowRoot.querySelectorAll('.select-text')[1].setAttribute('style', 'display: none');
    } else {
      const shadowRoot = document.querySelector('ion-select.select-country')?.shadowRoot;
      if (shadowRoot?.querySelector('.select-text:not(.select-placeholder)')) {
        const countryCode = this.gameQuery.getValue().gameOptions?.country || '';
        const countryName = this.countries.find(country => country.countryCode === countryCode)?.name;
        let label: string = '';
        if (countryName) {
          label = `
          <img class="select-country-icon" style="width: 1.25rem;height: 1.25rem;box-shadow: 0 2px 0 0 rgb(0 0 0 / 16%);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 50%;" src="/assets/flags/${countryCode}.svg"  />
          <div style="margin-left: 2rem">
            ${countryName}
          </div>`;
        }
        if (shadowRoot.querySelectorAll('.select-text').length < 2) {
          const div = document.createElement('div');
          div.className = 'select-text';
          div.innerHTML = label;
          shadowRoot.querySelectorAll('.select-text')[0].setAttribute('style', 'display: none');
          shadowRoot.querySelectorAll('.select-text')[0].insertAdjacentElement('afterend', div);
        } else {
          shadowRoot.querySelectorAll('.select-text').forEach(el => {
            el.innerHTML = label;
          });
        }
      } else {
        shadowRoot?.querySelectorAll('.select-text')[0].setAttribute('style', '');
      }
    }
  }

  handleDifficultylevel(ev) {
    this.enableTopicDescription = false;
    this.enableDifficultyDescription = false;
    if (
      this.newGameForm.get('topic').value !== '' &&
      this.mode === GameMode.singlePlayer
    ) {
      this.newGameForm.get('country').enable();
    }
    this.gameService.updateSelectedDifficultyLevel(ev.target.value);
  }

  onDifficultyClick() {
    this.enableTopicDescription = false;
    // this.enableDifficultyDescription = true;
  }

  handleTopic(ev) {
    if (
      this.newGameForm.get('difficultyLevel').value !== '' &&
      this.mode === GameMode.singlePlayer
    ) {
      this.newGameForm.get('country').enable();
    }
    this.topics.some((topic: ITopic) => {
      if (topic.id === ev.target.value) {
        this.topicDescription = topic.description;
        this.topicDisclaimer = topic.topicDisclaimer;
        this.enableTopicDescription = true;
        if (topic.isInformational) {
          this.newGameForm.get('difficultyLevel').disable();
          this.mode === GameMode.singlePlayer && this.newGameForm.get('country').enable()
          this.newGameForm.get('difficultyLevel').setValue(null);
          this.gameService.updateSelectedDifficultyLevel(null);
        } else {
          this.newGameForm.get('difficultyLevel').enable();
          if (this.newGameForm?.get('country')?.disabled) {
            this.newGameForm?.get('country')?.setValue(null);
            this.gameService?.updateSelectedCountry(null);
          }
        }
        this.gameService.updateSelectedIsInformational(!!topic.isInformational);
        return true;
      }
      return false;
    });
    this.gameService.updateSelectedTopic(ev.target.value);
  }

  onCustomizeAvatar() {
    this.userService.updateRouterUrl(this.router.url);
    this.router.navigate(['/customize_avatar']);
  }

  loadCountryFlags() {
    setTimeout(() => {
      const radios = document.querySelectorAll('ion-popover.countries ion-radio');
      for (let index = 0; index < radios.length; index++) {
        let element = radios[index];
        element.innerHTML = (`
          <div class="countryName">${this.countries[index].name}</div>
          <img class="select-country-icon" style="width: 1.25rem;height: 1.25rem;box-shadow: 0 2px 0 0 rgb(0 0 0 / 16%);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 50%;" src="/assets/flags/${this.countries[index].countryCode}.svg"  />`);
      }
    }, 100);
  }

  loadFlags(ev) {
    this.gameService.updateSelectedCountry(ev.target.value);
    if (ev.target.value) {
      const code = ev.target.value;
      const shadowRoot = document.querySelector('ion-select.select-country')?.shadowRoot;
      if (shadowRoot) {
        const label = `
          <img class="select-country-icon" style="width: 1.25rem;height: 1.25rem;box-shadow: 0 2px 0 0 rgb(0 0 0 / 16%);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 50%;" src="/assets/flags/${code}.svg"  />
          <div style="margin-left: 2rem">${this.countries.find(country => country.countryCode === code).name}</div>`;
        if (shadowRoot.querySelectorAll('.select-text').length < 2) {
          const div = document.createElement('div');
          div.className = 'select-text';
          div.innerHTML = label;
          shadowRoot.querySelectorAll('.select-text')[0].setAttribute('style', 'display: none');
          shadowRoot.querySelectorAll('.select-text')[0].insertAdjacentElement('afterend', div);
        } else {
          shadowRoot.querySelectorAll('.select-text')[1].innerHTML = label;
          shadowRoot.querySelectorAll('.select-text')[0].setAttribute('style', 'display: none');
          shadowRoot.querySelectorAll('.select-text')[1].setAttribute('style', '');
        }
      }

      this.avatarIndex = this.userQuery.getUserAvatarIndex();
      this.svg.nativeElement.innerHTML = '';
      this.svg.nativeElement.appendChild(
        this.avatarService.customizationAvatar(
          this.avatarIndex[0],
          this.avatarIndex[1],
          this.avatarIndex[2],
          this.avatarIndex[3],
          this.avatarIndex[4],
          this.avatarIndex[5],
          code
        )
      );
    }
    this.isCountrySelected = true;
  }

  animationCreated(animationItem: AnimationItem): void {
    this.ai = animationItem;
    this.ai.setSpeed(0.2);
  }

  randomizeExperts() {
    this.gameService.setRandomMaleExpert();
    this.gameService.setRandomFemaleExpert();
  }

  onStartGame() {
    const body: IGameStart = {
      topic: this.newGameForm.get('topic').value,
      difficulty: this.newGameForm.get('difficultyLevel').value,
    };
    const userAvatar = this.userQuery.getUserAvatarIndex();
    const avatarBody: IUserAvatar = {
      /* eslint-disable @typescript-eslint/naming-convention */
      avatar_Body: userAvatar[0],
      avatar_SkinTone: userAvatar[1],
      avatar_Head: userAvatar[2],
      avatar_HairColor: userAvatar[3],
      avatar_Accessory: userAvatar[4],
      avatar_Face: userAvatar[5],
      /* eslint-disable @typescript-eslint/naming-convention */
    };
    this.loading = true;
    this.gameInstanceService.resetGameState();
    if (this.mode === GameMode.singlePlayer) {
      body.country = this.newGameForm.get('country').value;
      this.gameService
        .createGame(this.mode, body)
        .then(() => this.gameService.updateUserAvatar(avatarBody))
        .then(() => this.gameService.gameStart(body))
        .then(() => {
          const lang = this.userQuery.getLanguage();
          return this.gameService.getStaticTopics(lang);
        })
        .then(() => {
          this.randomizeExperts();
          this.trackingService.click({
            name: 'GameStart',
            type: 'event'
          });
          this.router.navigate(['/mission']);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.gameService
        .createGame(this.mode, body)
        .then((res) => {
          this.randomizeExperts();
          this.router.navigate(['/multiplayer-room']);
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.gameService.setNegotiationRemainTime(null);
    this.gameService.setTypeOfPhase(null);
  }

  onBack() {
    this.router.navigate([
      this.mode === GameMode.singlePlayer
        ? '/select_mode'
        : '/multiplayer_mode',
    ]);
  }
}
