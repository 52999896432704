import {Component, Input, OnInit} from '@angular/core';
import {DimensionPosition} from '../../../models/game.model';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-dimension-box-component',
  templateUrl: './dimension-box.component.html',
  styleUrls: ['./dimension-box.component.scss'],
})
export class DimensionBoxComponent implements OnInit {
  @Input() position: DimensionPosition;

  constructor(
    public settingsService: SettingsService,
  ) {
  }

  ngOnInit() {
  }
}
