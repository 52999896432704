import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { GameOptions, INegotiation } from 'src/app/models/game.model';
import {
  IGame,
  ITopic,
  IHelpTopic,
  IBilateralHistory,
} from 'src/app/models/api.model';
import { BilateralStatus, BreakGames } from 'src/app/models/game.model';
import {
  PRESIDENT,
  REPRESENTATIVE,
  EXPERTS_MALE,
  EXPERTS_FEMALE,
} from '../../shared/avatar.utils';
import { getRandomInt } from '../../shared/math.utils';

export interface GameState {
  game: IGame;
  topics: ITopic[];
  topicsFallback: ITopic[];
  helpTopics: IHelpTopic[];
  helpTopicsFallback: IHelpTopic[];
  topic: ITopic;
  revealPositions: boolean;
  selectedDimension: any;
  d1Called: number;
  d2Called: number;
  d3Called: number;
  bilateralDiscussion: {
    userId: string;
    incoming: boolean;
    status: BilateralStatus;
    started: boolean;
    currentState: INegotiation[];
    pending?: {
      dimension: number;
      incoming: boolean;
      exchange?: number;
    };
  };
  bilateralHistory: IBilateralHistory[];
  isVoting: boolean;
  isVotingModalOpen: boolean;
  president: string;
  maleExperts: string[];
  femaleExperts: string[];
  representative: string;
  isModalOpen: boolean;
  gameOptions: GameOptions;
  isProposalTourEnd: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
  countryCodeOfErrorMessage: string;
  breakGamePlaying: BreakGames | null;
  breakError: string;
  positionDescription: string;
  coffeeBreak: any;
  spotTheCountryGames: any[];
  spotTheCountriesFound: any;
  spotTheCountryOverlay: number;
  spotCountryCountdown: boolean;
  spotTheCountrySelectedGame: string;
  spotTheCountryGameResult: string;
  negotiationRemainTime: number;
  negotiationTotalTime: number;
  negotiationRoundTime: number;
  breakRemainTime: number;
  typeOfPhase: string;
  isFirstTimeDimension1: boolean;
  isFirstTimeDimension2: boolean;
  isFirstTimeDimension3: boolean;
  bilateralNavigation: boolean;
  bilateralStatusAnimations: {
    accepted: boolean;
    acceptTradeOff: boolean;
    declined: boolean;
    pending: boolean;
    exchange: boolean;
    acceptAlliance: boolean;
    refuseAlliance: boolean;
    proposeTradeOff: boolean;
    refuseTradeOff: boolean;
    proposeData?: {
      dimension: number;
      incoming: boolean;
      exchange?: number;
    };
  };
  diffFromServerTime: number;
  votingTime: number;
  endBreakVote: boolean;
}

export const createInitialState = () => ({
  game: undefined,
  topics: [],
  topicsFallback: [],
  helpTopics: [],
  helpTopicsFallback: [],
  topic: null,
  revealPositions: false,
  selectedDimension: { name: 'd1', id: null, value: 1 },
  d1Called: 0,
  d2Called: 0,
  d3Called: 0,
  isVoting: false,
  isVotingModalOpen: false,
  president: PRESIDENT[getRandomInt(PRESIDENT.length - 1)],
  maleExperts: [],
  femaleExperts: [],
  representative: REPRESENTATIVE[getRandomInt(REPRESENTATIVE.length - 1)],
  bilateralDiscussion: {
    userId: '',
    incoming: false,
    status: BilateralStatus.empty,
    started: false,
    currentState: [],
    pending: undefined,
  },
  bilateralHistory: [],
  isModalOpen: false,
  gameOptions: {
    topic: null,
    difficultyLevel: null,
    country: null,
    isInformational: false,
  },
  isProposalTourEnd: false,
  errorMessage: '',
  countryCodeOfErrorMessage: '',
  breakGamePlaying: null,
  breakError: null,
  positionDescription: null,
  spotTheCountryOverlay: 0,
  coffeeBreak: {},
  spotTheCountryGames: [
    { name: 'white', value: false },
    { name: 'yellow', value: false },
    { name: 'green', value: false },
    { name: 'blue', value: false },
    { name: 'red', value: false },
    { name: 'black', value: false },
  ],
  spotTheCountriesFound: {},
  spotCountryCountdown: false,
  spotTheCountrySelectedGame: null,
  spotTheCountryGameResult: null,
  negotiationRemainTime: null,
  negotiationTotalTime: null,
  negotiationRoundTime: null,
  breakRemainTime: null,
  typeOfPhase: null,
  isFirstTimeDimension1: true,
  isFirstTimeDimension2: true,
  isFirstTimeDimension3: true,
  bilateralNavigation: false,
  bilateralStatusAnimations: {
    accepted: false,
    acceptTradeOff: false,
    declined: false,
    pending: false,
    exchange: false,
    acceptAlliance: false,
    refuseAlliance: false,
    proposeTradeOff: false,
    proposeData: undefined,
    refuseTradeOff: false,
  },
  diffFromServerTime: null,
  votingTime: null,
  endBreakButton: null
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'game' })
export class GameStore extends Store<GameState> {
  constructor() {
    super(createInitialState());
  }
}
