import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {CountdownComponent} from 'ngx-countdown';
import { GameQuery } from 'src/app/state/gameState/game.query';
import {TimerService} from '../../services/timer.service';
import { AudioService } from 'src/app/services/audio.service';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { filter, map } from "rxjs";
import { combineLatest } from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-timer-component',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
  @Input() isVoting: boolean;
  @Input() onTimeout?: any;
  @Input() votingStarted?: string;
  @ViewChild('cd', {static: false}) private countdown: CountdownComponent;
  @ViewChild('cdVoting', {static: false}) private cdVoting: CountdownComponent;

  timeLeft: number;
  isBreak: boolean;
  isNegotiation: boolean;
  isInformational = false;
  timeLeftToBreak: number;
  votingConfig: any;
  isToggleChecked = false;

  constructor(
    private timerService: TimerService,
    private gameQuery: GameQuery,
    private audioService: AudioService,
    private gameInstanceService: GameInstanceService,
  ) {
    this.timeLeftToBreak = this.gameQuery.getBreakRemainTime();
  }

  ngOnInit() {
    combineLatest([
      this.gameQuery.isInformational$,
      this.timerService.getTimeLeft()
    ]).pipe(
      untilDestroyed(this),
      filter(([informational, _]) => !informational ),
      map(([_, timer]) => timer) // if you only want the value from the first observable
    ).subscribe(time => {
      this.isBreak = this.gameQuery.getTypeOfPhase() === 'break' && window.location.pathname !== '/presentation_proposal';
      if (this.gameQuery.getTypeOfPhase() === 'negotiation') {
        if (this.timerService.timerActive.getValue()) {
          this.isNegotiation = window.location.pathname !== '/presentation_proposal' ? true : false;
          this.timeLeft = time;
        }
      } else if (this.isBreak) {
        this.timeLeftToBreak = time;
        if (this.gameQuery.hasOpenBilateral()) {
          if (this.countdown) {
            this.isNegotiation = true;
            this.timeLeft = this.gameQuery.getNegotiationRemainTime();
            this.countdown.pause();
          }
        } else {
          this.isNegotiation = false;
        }
      }
    });

    this.gameQuery.isInformational$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isInformational = value;
    });

    if (this.isVoting) {
      const votingTime = this.gameQuery.getVotingTime();
      this.isNegotiation = false;
      this.isBreak = false;
      const votingTimeLeft = moment(new Date()).diff(this.votingStarted, 'seconds');
      this.votingConfig = {
        leftTime: (votingTimeLeft < votingTime) ? votingTime - votingTimeLeft : 0,
        demand: false,
        format: 'mm:ss',
        notify: [10]
      };
    }

    this.gameInstanceService.toggleChecked$.asObservable().pipe(untilDestroyed(this)).subscribe(res => {
      this.isToggleChecked = res;
    });
  }

  handleEvent(event) {
    if (event.action === 'done') {
      // check if it is break or negotiation
      if (this.isVoting) {
        this.onTimeout();
      }
    } else if (event.action === 'notify') {
      if (event.left === 10000 && !this.audioService.activeMusicPlaying) {
        this.audioService.play('tenseResult');
      }
    }
  }
}
