import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ILanguage, UserSettings } from 'src/app/models/user.model';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UserService } from 'src/app/state/userState/user.service';
import { TranslateConfigService } from 'src/app/translate-config.service';
import { GameService } from 'src/app/state/gameState/game.service';
import { ErrorMessages } from 'src/app/models/game.model';
import { GameQuery } from '../../state/gameState/game.query';
import { CountriesService } from 'src/app/state/countriesState/countries.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { TrackingService } from 'src/app/services/tracking.service';
import { Platform } from '@ionic/angular';
import Bowser from 'bowser';
import { languages } from '../../shared/settings.utils';
import { SettingsService } from '../../services/settings.service';
import { EnvService } from '../../services/env.service';

@UntilDestroy()
@Component({
  selector: 'app-game-settings',
  templateUrl: './game-settings.page.html',
  styleUrls: ['./game-settings.page.scss'],
})
export class GameSettingsPage implements OnInit {
  [x: string]: any;
  public currentTab = 0;
  public settings$: Observable<UserSettings>;
  public routerUrl: string;
  public mute = false;
  public hasGameId: string;
  public emailHref: string;
  public languages = languages;
  public version: string;

  constructor(
    private userQuery: UserQuery,
    private userService: UserService,
    private countriesService: CountriesService,
    private trackingService: TrackingService,
    private translateConfigService: TranslateConfigService,
    private gameService: GameService,
    private gameQuery: GameQuery,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private platform: Platform,
    private envService: EnvService,
    ) {
    this.version = this.envService.appVersion;
    this.settings$ = this.userQuery.settings$;
    this.settings$.pipe(untilDestroyed(this)).subscribe(settings => {
      this.mute = settings.mute;
    });
    this.gameQuery.gameId$.pipe(untilDestroyed(this)).subscribe(gameId => {
      this.hasGameId = gameId;
    });
    this.logDeviceInfo();
  }

  ngOnInit() {
    this.userQuery.select('routerUrl').pipe(untilDestroyed(this)).subscribe(url => {
      this.routerUrl = url;
    });
  }

  getTitle(tab: number) {
    if (tab === 1) {
      return 'game_settings_language_selection_screen_title';
    } else if (tab === 2) {
      return 'game_settings_audio_setting_screen_title';
    } else {
      return 'game_settings_screen_title';
    }
  }

  switchTab(tab: number) {
    this.currentTab = tab;
  }

  switchLang(lang: ILanguage) {
    this.settingsService.switchLang(lang);
  }

  getLangTitle() {
    const currentLang = this.userQuery.getLanguage();
    return this.languages.find(lang => lang.code === currentLang)?.title || currentLang;
  }

  backgroundMusic(add: number) {
    this.userService.addBackgroundMusic(add);
  }

  soundEffects(add: number) {
    this.userService.addSoundEffects(add);
  }

  switchMute() {
    this.userService.switchMute();
  }

  quitGame() {
    this.gameService.updateErrorMessage(ErrorMessages.quitGame);
  }

  logDeviceInfo = async () => {
    const info = await Device.getInfo();
    const email = 'helpline@consilium.europa.eu';
    const subject = this.translateService.instant('game_settings_screen_email_subject_text');
    const operationSystem = this.translateService.instant('game_settings_screen_operation_system_text');
    const deviceModel = this.translateService.instant('game_settings_screen_device_model_text');
    const version = this.translateService.instant('game_settings_screen_version_text');
    const browser = this.translateService.instant('contact_support_mail_template_browser_info');
    let body= `%0D%0A%0D%0A-------------%0D%0ATechnical info for helpline%0D%0A-------------%0D%0A${deviceModel}: ` +
    info.model + `%0D%0A${operationSystem}: ` + info.operatingSystem + `%0D%0A${version}: ` + info.osVersion;
    if (!this.platform.is('cordova')) {
      const browserInfo = Bowser.getParser(window.navigator.userAgent);
      body += `%0D%0A${browser}: ` + browserInfo.getBrowserName() + ' ' + browserInfo.getBrowserVersion();
    }
    this.emailHref = 'mailto:' + email + '?subject=' + subject
    + '&body=' + body;
  };
}
