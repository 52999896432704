import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay,retryWhen, switchMap } from 'rxjs/operators';
import { ErrorMessages } from '../models/game.model';
import { GameService } from '../state/gameState/game.service';
import { UserService } from '../state/userState/user.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  omitCalls = ['startnpcmovements'];
  flag: boolean;

  constructor(
    private gameService: GameService,
    private userService: UserService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let retries = 0;
    const exceedAttemptLimit = 2;
    this.flag = req.url.includes('/break') || req.url.includes('/continue');
    if (this.flag) {
      return next.handle(req).pipe(
        delay(1000),
        // eslint-disable-next-line arrow-body-style
        retryWhen((error) => {
          return error.pipe(
            concatMap((err) => {
              retries = retries + 1;
              if (retries < exceedAttemptLimit) {
                return of(err);
              } else {
                if (err?.status === 401) {
                  return this.handle401Error(req, next);
                }
                this.displayError(req, err);
                return throwError(err);
              }
            }),
            delay(5000),
          );
        })
      );
    } else {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.status === 401) {
            return this.handle401Error(req, next);
          }
          this.displayError(req, error);
          return throwError(error);
        })
      );
    }
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    const sessionId = uuidv4();

    return this.userService.setSessionUserId(sessionId).pipe(
      switchMap(() => next.handle(this.addTokenHeader(req, sessionId))),
      catchError((error) => {
        this.displayError(req, error);
        return throwError(error);
      })
    );
  }

  private displayError(req: HttpRequest<any>, err: HttpErrorResponse) {
    let skipInterceptor = false;
    if (err?.error?.message === 'User has not enough action points to start bilateral') {
      skipInterceptor = true;
    }
    this.omitCalls.forEach((api) => {
      if (req.url.includes(api)) {
        skipInterceptor = true;
      }
    });
    if (!skipInterceptor) {
      if (req.url.includes('/api/Game/join/')) {
        if (err.error?.message === 'The game code is incorrect') {
          this.gameService.updateErrorMessage(ErrorMessages.errorJoinGame);
        } else if (err.error?.message === 'The Game has already started') {
          this.gameService.updateErrorMessage(ErrorMessages.errorJoinAlreadyStartedGame);
        } else if (err.error?.message === 'The game seats are full') {
          this.gameService.updateErrorMessage(ErrorMessages.errorJoinGameFullSeats);
        } else {
          this.gameService.updateErrorMessage(ErrorMessages.errorGeneralIssue);
        }
      } else if (req.url.includes('/break') || req.url.includes('/continue')) {
        this.gameService.updateErrorMessage(ErrorMessages.errorPermanentMessage);
      } else {
        this.gameService.updateErrorMessage(ErrorMessages.errorGeneralIssue);
      }
    }
  }

  private addTokenHeader(req: HttpRequest<any>, token: string) {
    return req.clone({ headers: req.headers.set('x-access-token', token) });
  }
}
