import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Platform, AlertController, AnimationController, Animation } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { UserService } from './state/userState/user.service';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateConfigService } from './translate-config.service';
import { CountriesService } from './state/countriesState/countries.service';
import { GameService } from './state/gameState/game.service';
import { UserQuery } from './state/userState/user.query';
import { WebSocketService } from './services/websocket.service';
import { GameQuery } from './state/gameState/game.query';
import { ErrorMessages, GameStatus } from 'src/app/models/game.model';
import { TranslateService } from '@ngx-translate/core';
import { GameInstanceService } from './services/game-instance.service';
import { LocationStrategy } from '@angular/common';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { GameStore } from './state/gameState/game.store';
import { AudioService } from './services/audio.service';
import { TimerService } from './services/timer.service';
import { Network } from '@capacitor/network';
import moment from 'moment';
import { Environment, EnvService } from './services/env.service';
import { CountriesStore } from './state/countriesState/countries.store';
import { EmptyScene, makeEmptyScene } from './phaser/scenes/empty.scene';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { TrackingService } from './services/tracking.service';
import { TextZoom, SetOptions } from '@capacitor/text-zoom';
import { checkUrlInGame } from './shared/game.utils';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public sessionId;
  public selectedLanguage: string;
  public gameSubscription: Subscription;
  public votingStatusSubscription: Subscription;
  public errorModalSubscription: Subscription;
  public isModalOpenSubscription: Subscription;
  public isVotingModalOpen: boolean;
  public isModalOpen: boolean;
  public errorMessage: string;
  public isHost: boolean;
  public loading: boolean;
  public isPhaser = false;
  public background: string;
  public backgrounds = [
    'splash-screen',
    'launch-screen',
    'achievements',
    'game-settings',
    'select-mode',
    'game-choose',
    'customize-avatar',
    'presentation-proposal',
    'negotiation-phase',
    'presentation-of-mission',
    'book',
    'quiz-game',
    'overview',
    'bilateral-discussion',
    'game-results',
  ];
  private gameId = '';
  private gameStatus;
  private negotiationStarted;
  private negotiationStartedTimeout;
  private negotiationVisited;
  private tagNames = ['ION-BUTTON', 'BUTTON'];
  private lastBtnEl: any;
  private backButtonSub: Subscription;

  constructor(
    private alertController: AlertController,
    private audioService: AudioService,
    private countriesService: CountriesService,
    private countriesStore: CountriesStore,
    private envService: EnvService,
    private gameInstanceService: GameInstanceService,
    private gameService: GameService,
    private gameStore: GameStore,
    private gameQuery: GameQuery,
    private idle: Idle,
    private location: LocationStrategy,
    private platform: Platform,
    private renderer: Renderer2,
    private router: Router,
    private screenOrientation: ScreenOrientation,
    private timerService: TimerService,
    private trackingService: TrackingService,
    private translateConfigService: TranslateConfigService,
    private translateService: TranslateService,
    private userService: UserService,
    private userQuery: UserQuery,
    private webSocketService: WebSocketService,
    private statusBar: StatusBar
  ) {
    this.gameStore.update({
      errorMessage: '',
      isModalOpen: false,
      isVotingModalOpen: false,
      topicsFallback: [],
      helpTopicsFallback: [],
    });
    this.countriesStore.update({
      countriesFallback: [],
    });

    if (this.envService.env === Environment.debug) {
      this.userService.switchMute(true);
    } else {
      this.gameService.resetGameState();
    }

    this.gameInstanceService.loading.pipe(untilDestroyed(this)).subscribe((res) => {
      this.loading = res;
    });
    this.timerService.loading.pipe(untilDestroyed(this)).subscribe(res => {
      this.loading = res;
    });

    if (this.envService.env === Environment.debug) {
      idle.setIdle(2100);
    } else {
      idle.setIdle(300);
    }

    idle.setTimeout(5);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.selectedLanguage = this.translateConfigService.getDefaultLanguage();
    this.webSocketService.connect();
    this.initializeApp();

    this.gameSubscription = this.gameQuery.select('game').pipe(untilDestroyed(this)).subscribe((res) => {
      this.isHost = this.gameQuery.isHost();
      if (this.gameId !== res?.gameId && res?.gameId) {
        this.idle.watch();
      }
      this.gameId = res?.gameId || '';

      if (this.gameQuery.hasOpenBilateral()) {
        clearTimeout(this.negotiationStartedTimeout);
      }

      if (this.gameStatus !== res?.status) {
        if (res?.status === GameStatus.started) {
          this.negotiationVisited = false;
        } else if (res?.status === GameStatus.finalVote) {
          this.audioService.stop();
          this.gameService.updateIsVoting(true);
          this.timerService.pauseTimer();
          this.gameService.updateErrorMessage(ErrorMessages.finalVote);
        } else if (res?.status === GameStatus.quit) {
          if (window.location.pathname === '/multiplayer-room') {
            if (res.userGames.find(ug => ug.userId === this.userQuery.getValue().user.userId).userHost === 0) {
              this.gameService.updateErrorMessage(ErrorMessages.hostQuit);
            }
          } else if (checkUrlInGame()) {
            if (res.single) {
              this.gameService.updateErrorMessage(ErrorMessages.gameClosed);
            } else {
              this.loading = false;
              this.gameService.updateErrorMessage(ErrorMessages.notEnoughPlayersQuit);
            }
          }
        } else if (res?.status === GameStatus.approved && checkUrlInGame()) {
          this.audioService.play('complete-voting');
        }
        this.gameStatus = res?.status;
      }
      if (this.negotiationStarted !== res?.negotiationStarted) {
        if (res?.negotiationStarted && checkUrlInGame() && !this.negotiationVisited) {
          const timeLeft = moment(`${res.negotiationStarted}`).add(2, 'minutes').diff(new Date(), 'seconds');
          if (timeLeft > 0) {
            this.negotiationStartedTimeout = setTimeout(() => {
              this.gameService.updateErrorMessage(ErrorMessages.negotiationStarted);
            }, timeLeft * 1000);
          }
        }
        this.negotiationStarted = res?.negotiationStarted;
      }
    });

    this.errorModalSubscription = this.gameQuery.select('errorMessage').pipe(untilDestroyed(this)).subscribe(
      (res) => {
        this.errorMessage = res;
      }
    );

    this.isModalOpenSubscription = this.gameQuery
      .select('isModalOpen')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.isModalOpen = res;
      });

    idle.onTimeout.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.gameId) {
        this.idle.watch();
        if (checkUrlInGame()) {
          this.gameService.updateErrorMessage(ErrorMessages.errorUserTimeout);
        }
      }
    });
  }

  @HostListener('window:click', ['$event'])
  onClick(event) {
    if (event && (this.tagNames.includes(event.target.tagName)
      || event.target.classList.value.includes('hover-btns')
      || event.target.classList.value.includes('btn-audio'))) {
      this.audioService.play('button-click');
    }
  }

  @HostListener('window:mouseover', ['$event'])
  onHover(event) {
    if (
      event
      && (this.tagNames.includes(event.target.tagName)
        || event.target.classList.value.includes('hover-btns'))
    ) {
      if (event.target.classList.value.includes('btn-icon') || event.target.classList.value.includes('btn-img')) {
        this.lastBtnEl = event.target.parentNode;
        event.target.parentNode.style.opacity = 0.8;
      } else if (event.target.classList.value.includes('btn-effect')) {
        this.lastBtnEl = event.target.parentNode.parentNode;
        event.target.parentNode.parentNode.style.opacity = 0.8;
      } else {
        this.lastBtnEl = event.target;
        event.target.style.opacity = 0.8;
      }
    } else {
      if (this.lastBtnEl) {
        this.lastBtnEl.style.opacity = 1;
        this.lastBtnEl = null;
      }
    }
  }

  @HostListener('window:beforeunload')
  beforeUnloadHander(): boolean {
    if (this.envService.env !== Environment.debug) {
      return false;
    }
  }

  getBgClass(location: string) {
    let classBg = '';
    switch (location.split('?')[0]) {
      case '/splash_screen':
      case '/coffee-break':
        classBg = 'splash-screen';
        break;
      case '/launch_screen':
      case '/game-starting':
      case '/download':
        classBg = 'launch-screen';
        break;
      case '/achievements':
        classBg = 'achievements';
        break;
      case '/game_settings':
        classBg = 'game-settings';
        break;
      case '/select_mode':
      case '/multiplayer_mode':
      case '/multiplayer-room':
      case '/multiplayer-join':
      case '/assign-countries':
        classBg = 'select-mode';
        break;
      case '/single_player':
      case '/multi_player':
      case '/proposal':
        classBg = 'game-choose';
        break;
      case '/customize_avatar':
        classBg = 'customize-avatar';
        break;
      case '/presentation_proposal':
        classBg = 'presentation-proposal';
        break;
      case '/negotiation_phase':
        classBg = 'negotiation-phase';
        break;
      case '/mission':
        classBg = 'presentation-of-mission';
        break;
      case '/book':
      case '/help':
        classBg = 'book';
        break;
      case '/quiz-game':
        classBg = 'quiz-game';
        break;
      case '/overview':
        classBg = 'overview';
        break;
      case '/bilateral-discussion':
        classBg = 'bilateral-discussion';
        break;
      case '/game_results':
      case '/multiplayer_game_results':
        classBg = 'game-results';
        break;
      default:
        break;
    }
    return classBg;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      Network.addListener('networkStatusChange', status => {
        if (!status.connected) {
          this.gameService.updateErrorMessage(ErrorMessages.networkOffline);
        } else {
          if (this.gameQuery.getErrorMessage() === ErrorMessages.networkOffline) {
            this.gameService.updateErrorMessage('');
          }
        }
      });
      this.platform.pause.pipe(untilDestroyed(this)).subscribe(() => {
        this.audioService.pause();
      });

      this.platform.resume.pipe(untilDestroyed(this)).subscribe(() => {
        this.audioService.resume();
      });
      if (this.platform.is('cordova')) {
        const options: SetOptions = {
          value: 1.0
        };
        TextZoom.set(options);
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY
        );
        this.statusBar.hide();
        if (this.platform.is('ios')) {
          const appElement = document.querySelector('ion-app');
          appElement.classList.add('notch-hide');
        } else {
          this.statusBar.overlaysWebView(true);
        }
      }

      this.background = this.getBgClass(this.location.path());

      if (!this.userQuery.getUserId()) {
        this.userService.setSessionUserId(this.userQuery.getSessionId()).pipe(untilDestroyed(this), take(1)).subscribe();
      } else {
          this.userService.getUser().pipe(untilDestroyed(this), take(1)).subscribe();
      }

      this.gameService.getTopics(this.userQuery.getLanguage());
      this.gameService.getHelpTopics(this.userQuery.getLanguage());
      this.countriesService.getCountries(this.userQuery.getLanguage());
      this.gameService.setRandomPresident();
      this.gameService.setRandomRepresentative();

      this.gameService.getTimerConfiguration().pipe(untilDestroyed(this)).subscribe((res) => {
        this.gameService.setBreakRemainTime(res.data.items[0].breakTime);
        this.gameService.setNegotiationTotalTime(res.data.items[0].gameTime);
        this.gameService.setNegotiationRoundTime(
          res.data.items[0].negotiationRoundTime
        );
        this.gameService.setVotingTime(res.data.items[0].votingTime);
      });

      this.location.onPopState(() => {
        if (this.location.path() === '/negotiation_phase') {
          clearTimeout(this.negotiationStartedTimeout);
          this.negotiationVisited = true;
        }
        this.background = this.getBgClass(this.location.path());
      });

      this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          if ((this.gameInstanceService.gameInstance as Phaser.Game)?.scene.getScenes().length > 1) {
            this.gameInstanceService.switchScene(EmptyScene.key, makeEmptyScene);
          }
          if (event.url === '/negotiation_phase') {
            clearTimeout(this.negotiationStartedTimeout);
            this.negotiationVisited = true;
          }
        } else if (event instanceof NavigationEnd) {
          this.trackingService.pageSend(event.url.replace(/[^\w]/gi, ''));
          this.background = this.getBgClass(event.urlAfterRedirects);
          this.isPhaser = (event.urlAfterRedirects === '/negotiation_phase' || event.urlAfterRedirects === '/coffee-break');
        }
      });

      this.backButtonSub = this.platform.backButton.subscribeWithPriority(10, () => {
        this.backButtonAlert();
      });

      this.userService.setBackgroundMusic(0.4);
      this.userService.setSoundEffects(0.4);

      this.audioService.preload(
        'backgroundSample',
        '/assets/audio/A_Business_That_Inspires.mp3',
        true
      );
      this.audioService.preload(
        'backgroundSample2',
        '/assets/audio/Warm_Inside.mp3',
        true
      );
      this.audioService.preload(
        'exploreThePossibilities',
        '/assets/audio/MA_codemusic_ExploreThePossibilities.mp3',
        true
      );
      this.audioService.preload(
        'infiniteTraffic',
        '/assets/audio/MA_EvgenyMartinov_InfiniteTraffic_Main.mp3',
        true
      );
      this.audioService.preload(
        'tenseResult',
        '/assets/audio/Tense Result Expectation_01 1.mp3',
        true
      );
      this.audioService.preload(
        'button-click',
        '/assets/audio/Double Click 02.mp3',
        false
      );
      this.audioService.preload(
        'modal-open',
        '/assets/audio/Pop Up 2 (0-01).mp3',
        false
      );
      this.audioService.preload(
        'change-page',
        '/assets/audio/Page Turning  (5).mp3',
        false
      );
      this.audioService.preload(
        'time-is-up',
        '/assets/audio/1. Next Level.mp3',
        false
      );
      this.audioService.preload(
        'complete-task',
        '/assets/audio/Slot Machine Mega Win_06.mp3',
        false
      );
      this.audioService.preload(
        'complete-voting',
        '/assets/audio/Tiero - Successful Corporation.mp3',
        true
      );
      const intervalSubscription = interval(1000).pipe(untilDestroyed(this)).subscribe(() => {
        if (!this.audioService.musicPlaying) {
          this.audioService.play('backgroundSample');
        } else {
          intervalSubscription.unsubscribe();
        }
      });
    });
  }

  async backButtonAlert() {
    const quitButtonText = this.translateService.instant('mobile_quit_button');
    const cancelButtonText = this.translateService.instant(
      'mobile_cancel_button'
    );
    let text = this.translateService.instant('mobile_back_button_host_text');
    if (!checkUrlInGame() || !this.isHost) {
      text = this.translateService.instant('mobile_back_button_text');
    }
    const alert = await this.alertController.create({
      message: text,
      cssClass: 'alertControl',
      buttons: [
        {
          text: quitButtonText,
          handler: () => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            navigator['app'].exitApp();
            this.audioService.stop();
          },
        },
        {
          text: cancelButtonText,
          role: 'Cancel',
        },
      ],
    });

    await alert.present();
  }

  ngOnInit() {
    this.trackingService.loadJsScript(this.renderer);
    document.addEventListener('load', () => {
      console.log('LOADED');
    });
    if (document.readyState !== 'loading') {
      this.fontSizeScale();
    } else {
      document.addEventListener('DOMContentLoaded', this.fontSizeScale);
    }
    window.addEventListener('resize', this.fontSizeScale);
    this.gameInstanceService.init('eu-council-game-container');
  }

  ngOnDestroy(): void {
    this.backButtonSub?.unsubscribe();
  }

  ionViewDidEnter() {
    this.gameInstanceService.gameInstance.scale.refresh();
  }

  fontSizeScale() {
    const newWidthRatio = (document.documentElement.clientWidth * 24) / 1334;
    const newHeightRatio = (document.documentElement.clientHeight * 24) / 750;

    // let newRatio = 0;
    // if(this.platform?.is('iphone')){
    //   newRatio = Math.min(newWidthRatio, newHeightRatio) * window.devicePixelRatio;
    // } else {
    //   newRatio = Math.min(newWidthRatio, newHeightRatio) * window.devicePixelRatio;
    // }
    const newRatio = Math.min(newWidthRatio, newHeightRatio);
    document.querySelector('html').style.fontSize = newRatio + 'px';
  }

  enterAnimation(baseEl: HTMLElement, opts?: any): Animation {
    const animationCtrl = new AnimationController();
    if (
      window.location.pathname !== '/negotiation_phase'
      && window.location.pathname !== '/coffee-break'
      && window.location.pathname !== '/bilateral-discussion'
      && window.location.pathname !== '/presentation_proposal'
      && window.location.pathname !== '/mission'
      && window.location.pathname !== '/quiz-game'
    ) {
      const rootAnimation = animationCtrl.create()
        .addElement(opts.enteringEl)
        .duration(400)
        .easing('ease-in')
        .fromTo('opacity', '0', '1');

      const leavingAnimation = animationCtrl.create()
        .addElement(opts.leavingEl)
        .duration(100)
        .easing('ease-out')
        .fromTo('opacity', '1', '0');

      return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
    } else {
      return animationCtrl.create().addAnimation([]);
    }
  };
}
