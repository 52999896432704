import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Country } from '../../models/country.model';

export interface CountriesState {
  countries: Country[];
  countriesFallback: Country[];
}

export const createInitialState = () => ({
  countries: [],
  countriesFallback: [],
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'countries' })
export class CountriesStore extends Store<CountriesState> {
  constructor() {
    super(createInitialState());
  }
  // updateCountryPositionOnDimension(name: string, payload: Position) {
  //   const countryIndex = this.getValue().countries.findIndex(country => country.name === name);
  //   const nextState = produce(this.getValue(), draftState => {
  //     draftState.countries[countryIndex].avatar.scene.dimension1 = { x: payload.x, y: payload.y, end: payload.end, init: payload.init };
  //   });
  //   this.update(nextState);
  // }

  // updateNegotiationsCountries() {
  //  const playerCountry = this.negotiationsQuery.getValue().playerCountry;
  //  const selectedCountry = this.negotiationsQuery.getValue().selectedCountry;
  //  if(playerCountry && selectedCountry) {
  //    this.updateCountryByName(playerCountry?.name, playerCountry);
  //    this.updateCountryByName(selectedCountry.name, selectedCountry)
  //  }
  // }

  // updateCountriesOnNegotiation() {
  //   const playerCountry = this.negotiationsQuery.getValue().playerCountry;
  //   const selectedCountry = this.negotiationsQuery.getValue().selectedCountry;
  //   if(playerCountry && selectedCountry) {
  //     const playerCountryIndex = this.getValue().countries.findIndex(e => e.name === playerCountry.name);
  //     const selectedCountryIndex = this.getValue().countries.findIndex(e => e.name === selectedCountry.name);
  //     const nextState = produce(this.getValue(), draftState => {
  //       draftState.countries[playerCountryIndex].dimensions[0].deals.push({name: selectedCountry.name})
  //       draftState.countries[selectedCountryIndex].dimensions[0].deals.push({name: playerCountry.name})
  //     });
  //     this.update(nextState);
  //   }
  // }

  // updateCountryByName(name: string, payload: Country) {
  //   const countryIndex = this.getValue().countries.findIndex(country => country.name === name);
  //   const nextState = produce(this.getValue(), draftState => {
  //     draftState.countries[countryIndex] = payload;
  //   });
  //   this.update(nextState);
  // }

  // updateCountryPosition(avatar: PlayerAvatar, x: number, y: number) {
  //   const countryIndex = this.getValue().countries.findIndex(e => e.avatar.country === avatar.country);
  //   const nextState = produce(this.getValue(), draftState => {
  //     draftState.countries[countryIndex].avatar.scene.coffeeBreak.x = x;
  //     draftState.countries[countryIndex].avatar.scene.coffeeBreak.y = y;
  //   });
  //   this.update(nextState);
  // }

  // updatePlayerPosition(x: number, y: number) {
  //   const countryIndex = this.getValue().countries.findIndex(country => country.name === 'SK');
  //   const nextState = produce(this.getValue(), draftState => {
  //     draftState.countries[countryIndex].avatar.scene.coffeeBreak.x = x;
  //     draftState.countries[countryIndex].avatar.scene.coffeeBreak.y = y;
  //   });
  //   this.update(nextState);
  // }
}
