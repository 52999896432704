import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) { }

  getCountries() {
    return this.http.get(environment.apiUrl + '/countries/', {
      observe: 'body',
      responseType: 'json'
    });
  }
}
