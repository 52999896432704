import * as Phaser from 'phaser';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUserGame } from 'src/app/models/api.model';
import { GameInstanceService } from 'src/app/services/game-instance.service';
/* eslint-disable max-len */

let context: any;

export class BilateralScene extends Phaser.Scene {
  static key = 'bilateral-discussion-scene';
  usersDataSubscription: Subscription;
  usersData: IUserGame[];
  avatars: Phaser.GameObjects.Sprite[] = [];
  userCountry: string;
  bilateralNavigationSubscription: Subscription;
  faceVictoriousAnimations: Phaser.GameObjects.Sprite[] = [];
  faceRefusingAnimations: Phaser.GameObjects.Sprite[] = [];
  faceDisappointedAnimations: Phaser.GameObjects.Sprite[] = [];
  faceConcentratedAnimations: Phaser.GameObjects.Sprite[] = [];
  faceAngledL0Animations: Phaser.GameObjects.Sprite[] = [];
  faceAngledL30Animations: Phaser.GameObjects.Sprite[] = [];
  faceAngledL60Animations: Phaser.GameObjects.Sprite[] = [];
  faceAngledR0Animations: Phaser.GameObjects.Sprite[] = [];
  faceAngledR30Animations: Phaser.GameObjects.Sprite[] = [];
  faceAngledR60Animations: Phaser.GameObjects.Sprite[] = [];
  armsVictoriousAnimations: Phaser.GameObjects.Sprite[] = [];
  armsThumbsUpAnimations: Phaser.GameObjects.Sprite[] = [];
  armsRefusingAnimations: Phaser.GameObjects.Sprite[] = [];
  armsConcentratedAnimations: Phaser.GameObjects.Sprite[] = [];
  animationsSubscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  private dynamicSvgScale = 1.7;
  private animScale = 1.2;

  constructor() {
    super({
      key: BilateralScene.key,
    });

    this.usersDataSubscription = context.gameQuery.game$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (!response) {
          return;
        }
        this.usersData = response.userGames;

        this.userCountry = this.usersData.find(
          (el) => el.userId === context.userQuery.getUserId()
        ).country;
      });
  }

  public update(time: number, delta: number): void {}

  public create() {
    this.events.on('destroy', () => {
      this.destroy$.next(true);
      this.destroy$.complete();
    });
    this.bilateralNavigationSubscription = context.gameQuery
      .select('bilateralNavigation')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.avatars?.forEach((av) => {
            av.destroy(true);
          });
          this.avatars = [];
          let floorBox: Phaser.GameObjects.Sprite;
          let floorBoxLightBlue: Phaser.GameObjects.Sprite;
          const positionY = this.game.scale.height / 2;
          this.usersData.forEach((ug, i) => {
            if (i > 1) {
              return;
            }
            let positionX = 200;
            if (i === 0) {
              context.createTexturesBilateral(true);
              setTimeout(() => {
                this.avatars.push(
                  this.add
                    .sprite(
                      positionX,
                      positionY,
                      `${context.currentUserGame.country}Bilateral`
                    )
                    .setScale(this.dynamicSvgScale, this.dynamicSvgScale)
                    .setOrigin(0.5, 0.5)
                    .setDepth(30000)
                );
                this.avatars[i].setData({
                  countryCode: `${context.currentUserGame.country}`,
                  userId: context.currentUserGame.userId,
                  normalUser: context.currentUserGame.user.normalUser,
                  faceIndex: context.currentUserGame.user.avatar_Face + 1,
                  skinToneIndex:
                    context.currentUserGame.user.avatar_SkinTone === 0
                      ? 'A'
                      : context.currentUserGame.user.avatar_SkinTone === 1
                      ? 'C'
                      : context.currentUserGame.user.avatar_SkinTone === 2
                      ? 'D'
                      : null,
                  bodyIndex: context.currentUserGame.user.avatar_Body + 1,
                });
              }, 800);
            }
            if (i === 1) {
              positionX = 1650;
              context.createTexturesBilateral(false);
              setTimeout(() => {
                this.avatars.push(
                  this.add
                    .sprite(
                      positionX,
                      positionY,
                      `${context.negotiatorUserGame.country}Bilateral`
                    )
                    .setScale(this.dynamicSvgScale, this.dynamicSvgScale)
                    .setOrigin(0.5, 0.5)
                    .setDepth(30000)
                );
                this.avatars[i].setData({
                  countryCode: `${context.negotiatorUserGame.country}`,
                  userId: context.negotiatorUserGame.userId,
                  normalUser: context.negotiatorUserGame.user.normalUser,
                  faceIndex: context.negotiatorUserGame.user.avatar_Face + 1,
                  skinToneIndex:
                    context.negotiatorUserGame.user.avatar_SkinTone === 0
                      ? 'A'
                      : context.negotiatorUserGame.user.avatar_SkinTone === 1
                      ? 'C'
                      : context.negotiatorUserGame.user.avatar_SkinTone === 2
                      ? 'D'
                      : null,
                  bodyIndex: context.negotiatorUserGame.user.avatar_Body + 1,
                });
                floorBox?.destroy(true);
                floorBoxLightBlue?.destroy(true);

                floorBox = this.add.sprite(
                  this.avatars[0].x - 15,
                  this.avatars[0].y + this.avatars[0].displayHeight / 2,
                  'floorBox'
                );
                floorBoxLightBlue = this.add.sprite(
                  this.avatars[1].x - 15,
                  this.avatars[1].y + this.avatars[1].displayHeight / 2,
                  'floorBoxLightBlue'
                );
                this.createSmileAnimations();
                this.createFaceRefusingAnimations();
                this.createFaceDisappointedAnimations();
                this.createFaceConcentratedAnimations();
                this.createThumbsUpAnimations();
                this.createArmsRefusingAnimations();
                this.createArmsConcentratedAnimations();
                this.createArmsVictoriousAnimations();
                this.createFaceAngledAnimations();
                setTimeout(() => {
                  this.initAllAnimations();
                });
              }, 900);
            }
          });
        } else {
          this.avatars?.forEach((av) => {
            av.destroy(true);
          });
        }
      });

    setTimeout(() => {
      this.avatars
        .find((el) => {
          console.log(el);
          return el.data.values.countryCode === this.userCountry;
        })
        ?.setDepth(1000);
    }, 300);
  }
  createSmileAnimations() {
    const frameNamesFaceVictorious1 = this.anims.generateFrameNames(
      'faceVictorious1',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious1',
      frames: frameNamesFaceVictorious1,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceVictorious2 = this.anims.generateFrameNames(
      'faceVictorious2',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious2',
      frames: frameNamesFaceVictorious2,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceVictorious3 = this.anims.generateFrameNames(
      'faceVictorious3',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious3',
      frames: frameNamesFaceVictorious3,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceVictorious4 = this.anims.generateFrameNames(
      'faceVictorious4',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious4',
      frames: frameNamesFaceVictorious4,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceVictorious5 = this.anims.generateFrameNames(
      'faceVictorious5',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious5',
      frames: frameNamesFaceVictorious5,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceVictorious6 = this.anims.generateFrameNames(
      'faceVictorious6',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious6',
      frames: frameNamesFaceVictorious6,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceVictorious7 = this.anims.generateFrameNames(
      'faceVictorious7',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceVictorious7',
      frames: frameNamesFaceVictorious7,
      frameRate: 15,
      repeat: 0,
    });
  }
  createFaceRefusingAnimations() {
    const frameNamesFaceRefusing1 = this.anims.generateFrameNames(
      'faceRefusing1',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing1',
      frames: frameNamesFaceRefusing1,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceRefusing2 = this.anims.generateFrameNames(
      'faceRefusing2',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing2',
      frames: frameNamesFaceRefusing2,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceRefusing3 = this.anims.generateFrameNames(
      'faceRefusing3',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing3',
      frames: frameNamesFaceRefusing3,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceRefusing4 = this.anims.generateFrameNames(
      'faceRefusing4',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing4',
      frames: frameNamesFaceRefusing4,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceRefusing5 = this.anims.generateFrameNames(
      'faceRefusing5',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing5',
      frames: frameNamesFaceRefusing5,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceRefusing6 = this.anims.generateFrameNames(
      'faceRefusing6',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing6',
      frames: frameNamesFaceRefusing6,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceRefusing7 = this.anims.generateFrameNames(
      'faceRefusing7',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_refusing_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceRefusing7',
      frames: frameNamesFaceRefusing7,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
  }
  createFaceDisappointedAnimations() {
    const frameNamesFaceDisappointed1 = this.anims.generateFrameNames(
      'faceDisappointed1',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed1',
      frames: frameNamesFaceDisappointed1,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceDisappointed2 = this.anims.generateFrameNames(
      'faceDisappointed2',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed2',
      frames: frameNamesFaceDisappointed2,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceDisappointed3 = this.anims.generateFrameNames(
      'faceDisappointed3',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed3',
      frames: frameNamesFaceDisappointed3,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceDisappointed4 = this.anims.generateFrameNames(
      'faceDisappointed4',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed4',
      frames: frameNamesFaceDisappointed4,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceDisappointed5 = this.anims.generateFrameNames(
      'faceDisappointed5',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed5',
      frames: frameNamesFaceDisappointed5,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceDisappointed6 = this.anims.generateFrameNames(
      'faceDisappointed6',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed6',
      frames: frameNamesFaceDisappointed6,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceDisappointed7 = this.anims.generateFrameNames(
      'faceDisappointed7',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_disappointed_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceDisappointed7',
      frames: frameNamesFaceDisappointed7,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
  }

  createFaceConcentratedAnimations() {
    const frameNamesFaceConcentrated1 = this.anims.generateFrameNames(
      'faceConcentrated1',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated1',
      frames: frameNamesFaceConcentrated1,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceConcentrated2 = this.anims.generateFrameNames(
      'faceConcentrated2',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated2',
      frames: frameNamesFaceConcentrated2,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceConcentrated3 = this.anims.generateFrameNames(
      'faceConcentrated3',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated3',
      frames: frameNamesFaceConcentrated3,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceConcentrated4 = this.anims.generateFrameNames(
      'faceConcentrated4',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated4',
      frames: frameNamesFaceConcentrated4,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceConcentrated5 = this.anims.generateFrameNames(
      'faceConcentrated5',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated5',
      frames: frameNamesFaceConcentrated5,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceConcentrated6 = this.anims.generateFrameNames(
      'faceConcentrated6',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated6',
      frames: frameNamesFaceConcentrated6,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceConcentrated7 = this.anims.generateFrameNames(
      'faceConcentrated7',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceConcentrated7',
      frames: frameNamesFaceConcentrated7,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
  }

  createFaceAngledAnimations() {
    const frameNamesFaceAngled1L0 = this.anims.generateFrameNames(
      'faceAngled1L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled1L0',
      frames: frameNamesFaceAngled1L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled1L30 = this.anims.generateFrameNames(
      'faceAngled1L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled1L30',
      frames: frameNamesFaceAngled1L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled1L60 = this.anims.generateFrameNames(
      'faceAngled1L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled1L60',
      frames: frameNamesFaceAngled1L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled2L0 = this.anims.generateFrameNames(
      'faceAngled2L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled2L0',
      frames: frameNamesFaceAngled2L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled2L30 = this.anims.generateFrameNames(
      'faceAngled2L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled2L30',
      frames: frameNamesFaceAngled2L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled2L60 = this.anims.generateFrameNames(
      'faceAngled2L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled2L60',
      frames: frameNamesFaceAngled2L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled3L0 = this.anims.generateFrameNames(
      'faceAngled3L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled3L0',
      frames: frameNamesFaceAngled3L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled3L30 = this.anims.generateFrameNames(
      'faceAngled3L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled3L30',
      frames: frameNamesFaceAngled3L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled3L60 = this.anims.generateFrameNames(
      'faceAngled3L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled3L60',
      frames: frameNamesFaceAngled3L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled4L0 = this.anims.generateFrameNames(
      'faceAngled4L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled4L0',
      frames: frameNamesFaceAngled4L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled4L30 = this.anims.generateFrameNames(
      'faceAngled4L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled4L30',
      frames: frameNamesFaceAngled4L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled4L60 = this.anims.generateFrameNames(
      'faceAngled4L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled4L60',
      frames: frameNamesFaceAngled4L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled5L0 = this.anims.generateFrameNames(
      'faceAngled5L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled5L0',
      frames: frameNamesFaceAngled5L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled5L30 = this.anims.generateFrameNames(
      'faceAngled5L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled5L30',
      frames: frameNamesFaceAngled5L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled5L60 = this.anims.generateFrameNames(
      'faceAngled5L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled5L60',
      frames: frameNamesFaceAngled5L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled6L0 = this.anims.generateFrameNames(
      'faceAngled6L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled6L0',
      frames: frameNamesFaceAngled6L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled6L30 = this.anims.generateFrameNames(
      'faceAngled6L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled6L30',
      frames: frameNamesFaceAngled6L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled6L60 = this.anims.generateFrameNames(
      'faceAngled6L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled6L60',
      frames: frameNamesFaceAngled6L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled7L0 = this.anims.generateFrameNames(
      'faceAngled7L0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_L_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled7L0',
      frames: frameNamesFaceAngled7L0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled7L30 = this.anims.generateFrameNames(
      'faceAngled7L30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_L_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled7L30',
      frames: frameNamesFaceAngled7L30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled7L60 = this.anims.generateFrameNames(
      'faceAngled7L60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_L_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled7L60',
      frames: frameNamesFaceAngled7L60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });

    const frameNamesFaceAngled1R0 = this.anims.generateFrameNames(
      'faceAngled1R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled1R0',
      frames: frameNamesFaceAngled1R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled1R30 = this.anims.generateFrameNames(
      'faceAngled1R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled1R30',
      frames: frameNamesFaceAngled1R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled1R60 = this.anims.generateFrameNames(
      'faceAngled1R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled1R60',
      frames: frameNamesFaceAngled1R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled2R0 = this.anims.generateFrameNames(
      'faceAngled2R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled2R0',
      frames: frameNamesFaceAngled2R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled2R30 = this.anims.generateFrameNames(
      'faceAngled2R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled2R30',
      frames: frameNamesFaceAngled2R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled2R60 = this.anims.generateFrameNames(
      'faceAngled2R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled2R60',
      frames: frameNamesFaceAngled2R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled3R0 = this.anims.generateFrameNames(
      'faceAngled3R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled3R0',
      frames: frameNamesFaceAngled3R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled3R30 = this.anims.generateFrameNames(
      'faceAngled3R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled3R30',
      frames: frameNamesFaceAngled3R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled3R60 = this.anims.generateFrameNames(
      'faceAngled3R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled3R60',
      frames: frameNamesFaceAngled3R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled4R0 = this.anims.generateFrameNames(
      'faceAngled4R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled4R0',
      frames: frameNamesFaceAngled4R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled4R30 = this.anims.generateFrameNames(
      'faceAngled4R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled4R30',
      frames: frameNamesFaceAngled4R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled4R60 = this.anims.generateFrameNames(
      'faceAngled4R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled4R60',
      frames: frameNamesFaceAngled4R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled5R0 = this.anims.generateFrameNames(
      'faceAngled5R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled5R0',
      frames: frameNamesFaceAngled5R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled5R30 = this.anims.generateFrameNames(
      'faceAngled5R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled5R30',
      frames: frameNamesFaceAngled5R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled5R60 = this.anims.generateFrameNames(
      'faceAngled5R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled5R60',
      frames: frameNamesFaceAngled5R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled6R0 = this.anims.generateFrameNames(
      'faceAngled6R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled6R0',
      frames: frameNamesFaceAngled6R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled6R30 = this.anims.generateFrameNames(
      'faceAngled6R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled6R30',
      frames: frameNamesFaceAngled6R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled6R60 = this.anims.generateFrameNames(
      'faceAngled6R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled6R60',
      frames: frameNamesFaceAngled6R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled7R0 = this.anims.generateFrameNames(
      'faceAngled7R0',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_R_0_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled7R0',
      frames: frameNamesFaceAngled7R0,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled7R30 = this.anims.generateFrameNames(
      'faceAngled7R30',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_R_30_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled7R30',
      frames: frameNamesFaceAngled7R30,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesFaceAngled7R60 = this.anims.generateFrameNames(
      'faceAngled7R60',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_face_R_60_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'faceAngled7R60',
      frames: frameNamesFaceAngled7R60,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
  }

  createThumbsUpAnimations() {
    const frameNamesArmsThumbsUp1A = this.anims.generateFrameNames(
      'armsThumbsUp1A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp1A',
      frames: frameNamesArmsThumbsUp1A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp1C = this.anims.generateFrameNames(
      'armsThumbsUp1C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp1C',
      frames: frameNamesArmsThumbsUp1C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp1D = this.anims.generateFrameNames(
      'armsThumbsUp1D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp1D',
      frames: frameNamesArmsThumbsUp1D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp2A = this.anims.generateFrameNames(
      'armsThumbsUp2A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp2A',
      frames: frameNamesArmsThumbsUp2A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp2C = this.anims.generateFrameNames(
      'armsThumbsUp2C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp2C',
      frames: frameNamesArmsThumbsUp2C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp2D = this.anims.generateFrameNames(
      'armsThumbsUp2D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp2D',
      frames: frameNamesArmsThumbsUp2D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp3A = this.anims.generateFrameNames(
      'armsThumbsUp3A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp3A',
      frames: frameNamesArmsThumbsUp3A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp3C = this.anims.generateFrameNames(
      'armsThumbsUp3C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp3C',
      frames: frameNamesArmsThumbsUp3C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp3D = this.anims.generateFrameNames(
      'armsThumbsUp3D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp3D',
      frames: frameNamesArmsThumbsUp3D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp4A = this.anims.generateFrameNames(
      'armsThumbsUp4A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp4A',
      frames: frameNamesArmsThumbsUp4A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp4C = this.anims.generateFrameNames(
      'armsThumbsUp4C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp4C',
      frames: frameNamesArmsThumbsUp4C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp4D = this.anims.generateFrameNames(
      'armsThumbsUp4D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp4D',
      frames: frameNamesArmsThumbsUp4D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp5A = this.anims.generateFrameNames(
      'armsThumbsUp5A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp5A',
      frames: frameNamesArmsThumbsUp5A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp5C = this.anims.generateFrameNames(
      'armsThumbsUp5C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp5C',
      frames: frameNamesArmsThumbsUp5C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp5D = this.anims.generateFrameNames(
      'armsThumbsUp5D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp5D',
      frames: frameNamesArmsThumbsUp5D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp6A = this.anims.generateFrameNames(
      'armsThumbsUp6A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp6A',
      frames: frameNamesArmsThumbsUp6A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp6C = this.anims.generateFrameNames(
      'armsThumbsUp6C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp6C',
      frames: frameNamesArmsThumbsUp6C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp6D = this.anims.generateFrameNames(
      'armsThumbsUp6D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp6D',
      frames: frameNamesArmsThumbsUp6D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp7A = this.anims.generateFrameNames(
      'armsThumbsUp7A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stA_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp7A',
      frames: frameNamesArmsThumbsUp7A,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp7C = this.anims.generateFrameNames(
      'armsThumbsUp7C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stC_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp7C',
      frames: frameNamesArmsThumbsUp7C,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsThumbsUp7D = this.anims.generateFrameNames(
      'armsThumbsUp7D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stD_thumb_up_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsThumbsUp7D',
      frames: frameNamesArmsThumbsUp7D,
      frameRate: 15,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
  }

  createArmsRefusingAnimations() {
    const frameNamesArmsRefusing1A = this.anims.generateFrameNames(
      'armsRefusing1A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing1A',
      frames: frameNamesArmsRefusing1A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing1C = this.anims.generateFrameNames(
      'armsRefusing1C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing1C',
      frames: frameNamesArmsRefusing1C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing1D = this.anims.generateFrameNames(
      'armsRefusing1D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing1D',
      frames: frameNamesArmsRefusing1D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing2A = this.anims.generateFrameNames(
      'armsRefusing2A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing2A',
      frames: frameNamesArmsRefusing2A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing2C = this.anims.generateFrameNames(
      'armsRefusing2C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing2C',
      frames: frameNamesArmsRefusing2C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing2D = this.anims.generateFrameNames(
      'armsRefusing2D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing2D',
      frames: frameNamesArmsRefusing2D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing3A = this.anims.generateFrameNames(
      'armsRefusing3A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing3A',
      frames: frameNamesArmsRefusing3A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing3C = this.anims.generateFrameNames(
      'armsRefusing3C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing3C',
      frames: frameNamesArmsRefusing3C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing3D = this.anims.generateFrameNames(
      'armsRefusing3D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing3D',
      frames: frameNamesArmsRefusing3D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing4A = this.anims.generateFrameNames(
      'armsRefusing4A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing4A',
      frames: frameNamesArmsRefusing4A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing4C = this.anims.generateFrameNames(
      'armsRefusing4C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing4C',
      frames: frameNamesArmsRefusing4C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing4D = this.anims.generateFrameNames(
      'armsRefusing4D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing4D',
      frames: frameNamesArmsRefusing4D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing5A = this.anims.generateFrameNames(
      'armsRefusing5A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing5A',
      frames: frameNamesArmsRefusing5A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing5C = this.anims.generateFrameNames(
      'armsRefusing5C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing5C',
      frames: frameNamesArmsRefusing5C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing5D = this.anims.generateFrameNames(
      'armsRefusing5D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing5D',
      frames: frameNamesArmsRefusing5D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing6A = this.anims.generateFrameNames(
      'armsRefusing6A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing6A',
      frames: frameNamesArmsRefusing6A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing6C = this.anims.generateFrameNames(
      'armsRefusing6C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing6C',
      frames: frameNamesArmsRefusing6C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing6D = this.anims.generateFrameNames(
      'armsRefusing6D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing6D',
      frames: frameNamesArmsRefusing6D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing7A = this.anims.generateFrameNames(
      'armsRefusing7A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stA_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing7A',
      frames: frameNamesArmsRefusing7A,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing7C = this.anims.generateFrameNames(
      'armsRefusing7C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stC_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing7C',
      frames: frameNamesArmsRefusing7C,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsRefusing7D = this.anims.generateFrameNames(
      'armsRefusing7D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stD_cross_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsRefusing7D',
      frames: frameNamesArmsRefusing7D,
      repeat: 0,
      yoyo: true,
      showOnStart: true,
      hideOnComplete: true,
    });
  }

  createArmsConcentratedAnimations() {
    const frameNamesArmsConcentrated1A = this.anims.generateFrameNames(
      'armsConcentrated1A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '01_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated1A',
      frames: frameNamesArmsConcentrated1A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated1C = this.anims.generateFrameNames(
      'armsConcentrated1C',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '01_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated1C',
      frames: frameNamesArmsConcentrated1C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated1D = this.anims.generateFrameNames(
      'armsConcentrated1D',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '01_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated1D',
      frames: frameNamesArmsConcentrated1D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated2A = this.anims.generateFrameNames(
      'armsConcentrated2A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '02_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated2A',
      frames: frameNamesArmsConcentrated2A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated2C = this.anims.generateFrameNames(
      'armsConcentrated2C',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '02_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated2C',
      frames: frameNamesArmsConcentrated2C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated2D = this.anims.generateFrameNames(
      'armsConcentrated2D',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '02_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated2D',
      frames: frameNamesArmsConcentrated2D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated3A = this.anims.generateFrameNames(
      'armsConcentrated3A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '03_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated3A',
      frames: frameNamesArmsConcentrated3A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated3C = this.anims.generateFrameNames(
      'armsConcentrated3C',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '03_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated3C',
      frames: frameNamesArmsConcentrated3C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated3D = this.anims.generateFrameNames(
      'armsConcentrated3D',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '03_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated3D',
      frames: frameNamesArmsConcentrated3D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated4A = this.anims.generateFrameNames(
      'armsConcentrated4A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '04_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated4A',
      frames: frameNamesArmsConcentrated4A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated4C = this.anims.generateFrameNames(
      'armsConcentrated4C',
      {
        start: 1,
        end: 6,
        zeroPad: 5,
        prefix: '04_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated4C',
      frames: frameNamesArmsConcentrated4C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated4D = this.anims.generateFrameNames(
      'armsConcentrated4D',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '04_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated4D',
      frames: frameNamesArmsConcentrated4D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated5A = this.anims.generateFrameNames(
      'armsConcentrated5A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '05_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated5A',
      frames: frameNamesArmsConcentrated5A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated5C = this.anims.generateFrameNames(
      'armsConcentrated5C',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '05_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated5C',
      frames: frameNamesArmsConcentrated5C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated5D = this.anims.generateFrameNames(
      'armsConcentrated5D',
      {
        start: 1,
        end: 6,
        zeroPad: 5,
        prefix: '05_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated5D',
      frames: frameNamesArmsConcentrated5D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated6A = this.anims.generateFrameNames(
      'armsConcentrated6A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '06_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated6A',
      frames: frameNamesArmsConcentrated6A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated6C = this.anims.generateFrameNames(
      'armsConcentrated6C',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '06_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated6C',
      frames: frameNamesArmsConcentrated6C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated6D = this.anims.generateFrameNames(
      'armsConcentrated6D',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '06_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated6D',
      frames: frameNamesArmsConcentrated6D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated7A = this.anims.generateFrameNames(
      'armsConcentrated7A',
      {
        start: 1,
        end: 9,
        zeroPad: 5,
        prefix: '07_arms_stA_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated7A',
      frames: frameNamesArmsConcentrated7A,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated7C = this.anims.generateFrameNames(
      'armsConcentrated7C',
      {
        start: 1,
        end: 6,
        zeroPad: 5,
        prefix: '07_arms_stC_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated7C',
      frames: frameNamesArmsConcentrated7C,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
    const frameNamesArmsConcentrated7D = this.anims.generateFrameNames(
      'armsConcentrated7D',
      {
        start: 1,
        end: 6,
        zeroPad: 5,
        prefix: '07_arms_stD_concentrated_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsConcentrated7D',
      frames: frameNamesArmsConcentrated7D,
      frameRate: 15,
      yoyo: true,
      repeat: 0,
      showOnStart: true,
      hideOnComplete: true,
    });
  }

  createArmsVictoriousAnimations() {
    const frameNamesArmsVictorious1A = this.anims.generateFrameNames(
      'armsVictorious1A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious1A',
      frames: frameNamesArmsVictorious1A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious1C = this.anims.generateFrameNames(
      'armsVictorious1C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious1C',
      frames: frameNamesArmsVictorious1C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious1D = this.anims.generateFrameNames(
      'armsVictorious1D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '01_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious1D',
      frames: frameNamesArmsVictorious1D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious2A = this.anims.generateFrameNames(
      'armsVictorious2A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious2A',
      frames: frameNamesArmsVictorious2A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious2C = this.anims.generateFrameNames(
      'armsVictorious2C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious2C',
      frames: frameNamesArmsVictorious2C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious2D = this.anims.generateFrameNames(
      'armsVictorious2D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '02_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious2D',
      frames: frameNamesArmsVictorious2D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious3A = this.anims.generateFrameNames(
      'armsVictorious3A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious3A',
      frames: frameNamesArmsVictorious3A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious3C = this.anims.generateFrameNames(
      'armsVictorious3C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious3C',
      frames: frameNamesArmsVictorious3C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious3D = this.anims.generateFrameNames(
      'armsVictorious3D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '03_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious3D',
      frames: frameNamesArmsVictorious3D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious4A = this.anims.generateFrameNames(
      'armsVictorious4A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious4A',
      frames: frameNamesArmsVictorious4A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious4C = this.anims.generateFrameNames(
      'armsVictorious4C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious4C',
      frames: frameNamesArmsVictorious4C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious4D = this.anims.generateFrameNames(
      'armsVictorious4D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '04_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious4D',
      frames: frameNamesArmsVictorious4D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious5A = this.anims.generateFrameNames(
      'armsVictorious5A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious5A',
      frames: frameNamesArmsVictorious5A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious5C = this.anims.generateFrameNames(
      'armsVictorious5C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious5C',
      frames: frameNamesArmsVictorious5C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious5D = this.anims.generateFrameNames(
      'armsVictorious5D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '05_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious5D',
      frames: frameNamesArmsVictorious5D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious6A = this.anims.generateFrameNames(
      'armsVictorious6A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious6A',
      frames: frameNamesArmsVictorious6A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious6C = this.anims.generateFrameNames(
      'armsVictorious6C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious6C',
      frames: frameNamesArmsVictorious6C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious6D = this.anims.generateFrameNames(
      'armsVictorious6D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '06_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious6D',
      frames: frameNamesArmsVictorious6D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious7A = this.anims.generateFrameNames(
      'armsVictorious7A',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stA_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious7A',
      frames: frameNamesArmsVictorious7A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious7C = this.anims.generateFrameNames(
      'armsVictorious7C',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stC_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious7C',
      frames: frameNamesArmsVictorious7C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsVictorious7D = this.anims.generateFrameNames(
      'armsVictorious7D',
      {
        start: 1,
        end: 73,
        zeroPad: 5,
        prefix: '07_arms_stD_victorious_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsVictorious7D',
      frames: frameNamesArmsVictorious7D,
      frameRate: 15,
      repeat: 0,
    });
  }

  initAllAnimations() {
    this.faceVictoriousAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.armsVictoriousAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.armsThumbsUpAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.armsRefusingAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.armsConcentratedAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceRefusingAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceDisappointedAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceConcentratedAnimations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceAngledL0Animations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceAngledL30Animations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceAngledL60Animations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceAngledR0Animations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceAngledR30Animations.forEach((fc) => {
      fc.destroy(true);
    });
    this.faceAngledR60Animations.forEach((fc) => {
      fc.destroy(true);
    });

    this.avatars.forEach((el, index) => {
      this.faceVictoriousAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceVictorious${el.data.values.faceIndex}`,
          `0${el.data.values.faceIndex}_face_victorious_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });

      this.faceRefusingAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceRefusing${el.data.values.faceIndex}`,
          `0${el.data.values.faceIndex}_face_refusing_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceDisappointedAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceDisappointed${el.data.values.faceIndex}`,
          `0${el.data.values.faceIndex}_face_disappointed_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceConcentratedAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceConcentrated${el.data.values.faceIndex}`,
          `0${el.data.values.faceIndex}_face_concentrated_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });

      this.faceAngledL0Animations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceAngled${el.data.values.faceIndex}L0`,
          `0${el.data.values.faceIndex}_face_L_0_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceAngledL30Animations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceAngled${el.data.values.faceIndex}L30`,
          `0${el.data.values.faceIndex}_face_L_30_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceAngledL60Animations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceAngled${el.data.values.faceIndex}L60`,
          `0${el.data.values.faceIndex}_face_L_60_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceAngledR0Animations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceAngled${el.data.values.faceIndex}R0`,
          `0${el.data.values.faceIndex}_face_R_0_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceAngledR30Animations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceAngled${el.data.values.faceIndex}R30`,
          `0${el.data.values.faceIndex}_face_R_30_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });
      this.faceAngledR60Animations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `faceAngled${el.data.values.faceIndex}R60`,
          `0${el.data.values.faceIndex}_face_R_60_00000.png`
        )
        .setScale(this.animScale, this.animScale)
        .setDepth(el.depth + 100)
        .setVisible(false)
        .setData({
          faceIndex: el.data.values.faceIndex,
          countryCode: el.data.values.countryCode,
        });

      this.armsVictoriousAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `armsVictorious${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
          `0${el.data.values.bodyIndex}_arms_st${el.data.values.skinToneIndex}_victorious_00000.png`
        )
        .setScale(this.animScale + 0.2, this.animScale + 0.2)
        .setDepth(el.depth + 1)
        .setData({
          index: el.data.values.bodyIndex,
          countryCode: el.data.values.country,
          skinToneIndex: el.data.values.skinToneIndex,
          bodyIndex: el.data.values.bodyIndex,
        });
      this.armsThumbsUpAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `armsThumbsUp${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
          `0${el.data.values.bodyIndex}_arms_st${el.data.values.skinToneIndex}_thumb_up_00000.png`
        )
        .setScale(this.animScale + 0.2, this.animScale + 0.2)
        .setDepth(el.depth + 1)
        .setVisible(false)
        .setData({
          index: el.data.values.bodyIndex,
          countryCode: el.data.values.country,
          skinToneIndex: el.data.values.skinToneIndex,
          bodyIndex: el.data.values.bodyIndex,
        });
      this.armsRefusingAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `armsRefusing${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
          `0${el.data.values.bodyIndex}_arms_st${el.data.values.skinToneIndex}_cross_00000.png`
        )
        .setScale(this.animScale + 0.2, this.animScale + 0.2)
        .setDepth(el.depth + 1)
        .setVisible(false)
        .setData({
          index: el.data.values.bodyIndex,
          countryCode: el.data.values.country,
          skinToneIndex: el.data.values.skinToneIndex,
          bodyIndex: el.data.values.bodyIndex,
        });
      this.armsConcentratedAnimations[index] = this.add
        .sprite(
          el.x,
          el.y,
          `armsConcentrated${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
          `0${el.data.values.bodyIndex}_arms_st${el.data.values.skinToneIndex}_concentrated_00000.png`
        )
        .setScale(this.animScale + 0.2, this.animScale + 0.2)
        .setDepth(el.depth + 1)
        .setVisible(false)
        .setData({
          index: el.data.values.bodyIndex,
          countryCode: el.data.values.country,
          skinToneIndex: el.data.values.skinToneIndex,
          bodyIndex: el.data.values.bodyIndex,
        });
    });

    this.faceVictoriousAnimations.forEach((fa, i) => {
      fa.on('animationcomplete', () => {
        context.gameService.resetBilateralAnimations();
      });
    });
    this.faceRefusingAnimations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
        this.faceAngledL0Animations[i].setAlpha(0);
        this.faceAngledL30Animations[i].setAlpha(0);
        this.faceAngledL60Animations[i].setAlpha(0);
        this.faceAngledR0Animations[i].setAlpha(0);
        this.faceAngledR30Animations[i].setAlpha(0);
        this.faceAngledR60Animations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
        this.faceAngledL0Animations[i].setAlpha(1);
        this.faceAngledL30Animations[i].setAlpha(1);
        this.faceAngledL60Animations[i].setAlpha(1);
        this.faceAngledR0Animations[i].setAlpha(1);
        this.faceAngledR30Animations[i].setAlpha(1);
        this.faceAngledR60Animations[i].setAlpha(1);
      });
    });
    this.faceDisappointedAnimations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
        this.faceAngledL0Animations[i].setAlpha(0);
        this.faceAngledL30Animations[i].setAlpha(0);
        this.faceAngledL60Animations[i].setAlpha(0);
        this.faceAngledR0Animations[i].setAlpha(0);
        this.faceAngledR30Animations[i].setAlpha(0);
        this.faceAngledR60Animations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceAngledL0Animations[i].setAlpha(1);
        this.faceAngledL30Animations[i].setAlpha(1);
        this.faceAngledL60Animations[i].setAlpha(1);
        this.faceAngledR0Animations[i].setAlpha(1);
        this.faceAngledR30Animations[i].setAlpha(1);
        this.faceAngledR60Animations[i].setAlpha(1);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.faceConcentratedAnimations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
      });
    });
    this.faceAngledL0Animations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.faceAngledL30Animations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.faceAngledL60Animations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.faceAngledR0Animations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.faceAngledR30Animations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.faceAngledR60Animations.forEach((fa, i) => {
      fa.on('animationstart', () => {
        this.faceVictoriousAnimations[i].setVisible(false);
        this.faceConcentratedAnimations[i].setAlpha(0);
      });
      fa.on('animationcomplete', () => {
        this.faceVictoriousAnimations[i].setVisible(true);
        this.faceConcentratedAnimations[i].setAlpha(1);
      });
    });
    this.armsThumbsUpAnimations.forEach((art, i) => {
      art.on('animationstart', () => {
        this.armsVictoriousAnimations[i].setVisible(false);
      });
      art.on('animationcomplete', () => {
        this.armsVictoriousAnimations[i].setVisible(true);
      });
    });
    this.armsConcentratedAnimations.forEach((art, i) => {
      art.on('animationstart', () => {
        this.armsVictoriousAnimations[i].setVisible(false);
      });
      art.on('animationcomplete', () => {
        this.armsVictoriousAnimations[i].setVisible(true);
      });
    });
    this.armsRefusingAnimations.forEach((art, i) => {
      art.on('animationstart', () => {
        this.armsVictoriousAnimations[i].setVisible(false);
      });
      art.on('animationcomplete', () => {
        this.armsVictoriousAnimations[i].setVisible(true);
        context.gameService.resetBilateralAnimations();
      });
    });

    this.animationsSubscription = context.gameQuery
      .select('bilateralStatusAnimations')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.accepted) {
          this.faceVictoriousAnimations.forEach((fa, i) => {
            fa.play(
              `faceVictorious${this.faceVictoriousAnimations[i].data.values.faceIndex}`
            );
          });
          this.armsVictoriousAnimations[0].play(
            `armsVictorious${this.armsVictoriousAnimations[0].data.values.bodyIndex}${this.armsVictoriousAnimations[0].data.values.skinToneIndex}`
          );
          this.armsThumbsUpAnimations[1].play(
            `armsThumbsUp${this.armsThumbsUpAnimations[1].data.values.bodyIndex}${this.armsThumbsUpAnimations[1].data.values.skinToneIndex}`
          );
        } else if (res.acceptTradeOff) {
          this.faceConcentratedAnimations[1].anims.complete();
          this.armsConcentratedAnimations[1].anims.complete();
          this.faceVictoriousAnimations.forEach((fa, i) => {
            fa.play(
              `faceVictorious${this.faceVictoriousAnimations[i].data.values.faceIndex}`
            );
          });
          this.armsVictoriousAnimations[1].play(
            `armsVictorious${this.armsVictoriousAnimations[1].data.values.bodyIndex}${this.armsVictoriousAnimations[1].data.values.skinToneIndex}`
          );
          this.armsThumbsUpAnimations[0].play(
            `armsThumbsUp${this.armsThumbsUpAnimations[0].data.values.bodyIndex}${this.armsThumbsUpAnimations[0].data.values.skinToneIndex}`
          );
        } else if (res.refuseTradeOff) {
          this.faceConcentratedAnimations[1].anims.complete();
          this.armsConcentratedAnimations[1].anims.complete();
          this.faceRefusingAnimations[0].play(
            `faceRefusing${this.faceRefusingAnimations[0].data.values.faceIndex}`
          );
          this.faceDisappointedAnimations[1].play(
            `faceDisappointed${this.faceDisappointedAnimations[1].data.values.faceIndex}`
          );
          this.armsRefusingAnimations.forEach((fa, i) => {
            fa.play(
              `armsRefusing${this.armsRefusingAnimations[i].data.values.bodyIndex}${this.armsRefusingAnimations[i].data.values.skinToneIndex}`
            );
          });
        } else if (res.declined) {
          this.faceConcentratedAnimations[1].anims.complete();
          this.armsConcentratedAnimations[1].anims.complete();
          this.faceRefusingAnimations[1].play(
            `faceRefusing${this.faceRefusingAnimations[1].data.values.faceIndex}`
          );
          this.faceDisappointedAnimations[0].play(
            `faceDisappointed${this.faceDisappointedAnimations[0].data.values.faceIndex}`
          );
          this.armsRefusingAnimations.forEach((fa, i) => {
            fa.play(
              `armsRefusing${this.armsRefusingAnimations[i].data.values.bodyIndex}${this.armsRefusingAnimations[i].data.values.skinToneIndex}`
            );
          });
        } else if (res.pending) {
          if (res.proposeData) {
            switch (res.proposeData.dimension) {
              case 0:
                this.faceAngledR60Animations[0].play(
                  `faceAngled${this.faceAngledR60Animations[0].data.values.faceIndex}R60`
                );
                break;
              case 1:
                this.faceAngledR30Animations[0].play(
                  `faceAngled${this.faceAngledR30Animations[0].data.values.faceIndex}R30`
                );
                break;
              case 2:
                this.faceAngledR0Animations[0].play(
                  `faceAngled${this.faceAngledR0Animations[0].data.values.faceIndex}R0`
                );
                break;
            }
          }
          if (context.gameQuery.getValue().bilateralDiscussion.incoming) {
            if (!res.exchange) {
              this.faceConcentratedAnimations[0].play(
                `faceConcentrated${this.faceConcentratedAnimations[0].data.values.faceIndex}`
              );
              this.armsConcentratedAnimations[0].play(
                `armsConcentrated${this.armsConcentratedAnimations[0].data.values.bodyIndex}${this.armsConcentratedAnimations[0].data.values.skinToneIndex}`
              );
              setTimeout(() => {
                switch (
                  (context as GameInstanceService).gameQuery.getValue()
                    .bilateralDiscussion.pending?.dimension
                ) {
                  case 0:
                    this.faceAngledL60Animations[1].play(
                      `faceAngled${this.faceAngledL60Animations[1].data.values.faceIndex}L60`
                    );
                    break;
                  case 1:
                    this.faceAngledL30Animations[1].play(
                      `faceAngled${this.faceAngledL30Animations[1].data.values.faceIndex}L30`
                    );
                    break;
                  case 2:
                    this.faceAngledL0Animations[1].play(
                      `faceAngled${this.faceAngledL0Animations[1].data.values.faceIndex}L0`
                    );
                    break;
                }
              });
            } else {
              this.faceConcentratedAnimations[0].anims.complete();
              this.armsConcentratedAnimations[0].anims.complete();
              this.faceConcentratedAnimations[1].play(
                `faceConcentrated${this.faceConcentratedAnimations[1].data.values.faceIndex}`
              );
              this.armsConcentratedAnimations[1].play(
                `armsConcentrated${this.armsConcentratedAnimations[1].data.values.bodyIndex}${this.armsConcentratedAnimations[1].data.values.skinToneIndex}`
              );
            }
          } else {
            if (!res.exchange) {
              this.faceConcentratedAnimations[1].play(
                `faceConcentrated${this.faceConcentratedAnimations[1].data.values.faceIndex}`
              );
              this.armsConcentratedAnimations[1].play(
                `armsConcentrated${this.armsConcentratedAnimations[1].data.values.bodyIndex}${this.armsConcentratedAnimations[1].data.values.skinToneIndex}`
              );
            } else {
              this.faceConcentratedAnimations[1].anims.complete();
              this.armsConcentratedAnimations[1].anims.complete();
              this.faceConcentratedAnimations[0].play(
                `faceConcentrated${this.faceConcentratedAnimations[0].data.values.faceIndex}`
              );
              this.armsConcentratedAnimations[0].play(
                `armsConcentrated${this.armsConcentratedAnimations[0].data.values.bodyIndex}${this.armsConcentratedAnimations[0].data.values.skinToneIndex}`
              );
              this.faceConcentratedAnimations[1].anims.complete();
              setTimeout(() => {
                switch (
                  (context as GameInstanceService).gameQuery.getValue()
                    .bilateralDiscussion.pending?.exchange
                ) {
                  case 0:
                    this.faceAngledL60Animations[1].play(
                      `faceAngled${this.faceAngledL60Animations[1].data.values.faceIndex}L60`
                    );
                    break;
                  case 1:
                    this.faceAngledL30Animations[1].play(
                      `faceAngled${this.faceAngledL30Animations[1].data.values.faceIndex}L30`
                    );
                    break;
                  case 2:
                    this.faceAngledL0Animations[1].play(
                      `faceAngled${this.faceAngledL0Animations[1].data.values.faceIndex}L0`
                    );
                    break;
                }
              });
            }
          }
        } else if (res.acceptAlliance) {
          this.faceVictoriousAnimations.forEach((fa, i) => {
            fa.play(
              `faceVictorious${this.faceVictoriousAnimations[i].data.values.faceIndex}`
            );
          });
          this.armsVictoriousAnimations[1].play(
            `armsVictorious${this.armsVictoriousAnimations[1].data.values.bodyIndex}${this.armsVictoriousAnimations[1].data.values.skinToneIndex}`
          );
          this.armsThumbsUpAnimations[0].play(
            `armsThumbsUp${this.armsThumbsUpAnimations[0].data.values.bodyIndex}${this.armsThumbsUpAnimations[0].data.values.skinToneIndex}`
          );
        } else if (res.refuseAlliance) {
          this.faceRefusingAnimations[0].play(
            `faceRefusing${this.faceRefusingAnimations[0].data.values.faceIndex}`
          );
          this.faceDisappointedAnimations[1].play(
            `faceDisappointed${this.faceDisappointedAnimations[1].data.values.faceIndex}`
          );
          this.armsRefusingAnimations.forEach((fa, i) => {
            fa.play(
              `armsRefusing${this.armsRefusingAnimations[i].data.values.bodyIndex}${this.armsRefusingAnimations[i].data.values.skinToneIndex}`
            );
          });
        } else if (res.proposeTradeOff) {
          this.faceVictoriousAnimations.forEach((fa, i) => {
            fa.play(
              `faceVictorious${this.faceVictoriousAnimations[i].data.values.faceIndex}`
            );
          });
          this.armsVictoriousAnimations[1].play(
            `armsVictorious${this.armsVictoriousAnimations[1].data.values.bodyIndex}${this.armsVictoriousAnimations[1].data.values.skinToneIndex}`
          );
          this.armsThumbsUpAnimations[0].play(
            `armsThumbsUp${this.armsThumbsUpAnimations[0].data.values.bodyIndex}${this.armsThumbsUpAnimations[0].data.values.skinToneIndex}`
          );
          return;
        }
      });
  }

  loading() {
    context.loading.next(false);
  }

  public preload() {
    context.loading.next(true);
    this.load.on('complete', this.loading);

    this.load.svg(
      'floorBoxLightBlue',
      `../../../assets/negotiation/floor_box_light_blue.svg`
    );
    this.load.svg('floorBox', `../../../assets/negotiation/floor_box.svg`);

    this.load.atlas({
      key: 'faceVictorious1',
      textureURL: '../../../assets/bilateral-animations/faceVictorious1-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious1.json',
    });
    this.load.atlas({
      key: 'faceVictorious2',
      textureURL: '../../../assets/bilateral-animations/faceVictorious2-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious2.json',
    });
    this.load.atlas({
      key: 'faceVictorious3',
      textureURL: '../../../assets/bilateral-animations/faceVictorious3-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious3.json',
    });
    this.load.atlas({
      key: 'faceVictorious4',
      textureURL: '../../../assets/bilateral-animations/faceVictorious4-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious4.json',
    });
    this.load.atlas({
      key: 'faceVictorious5',
      textureURL: '../../../assets/bilateral-animations/faceVictorious5-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious5.json',
    });
    this.load.atlas({
      key: 'faceVictorious6',
      textureURL: '../../../assets/bilateral-animations/faceVictorious6-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious6.json',
    });
    this.load.atlas({
      key: 'faceVictorious7',
      textureURL: '../../../assets/bilateral-animations/faceVictorious7-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceVictorious7.json',
    });

    this.load.atlas({
      key: 'faceDisappointed1',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed1-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed1.json',
    });
    this.load.atlas({
      key: 'faceDisappointed2',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed2-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed2.json',
    });
    this.load.atlas({
      key: 'faceDisappointed3',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed3-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed3.json',
    });
    this.load.atlas({
      key: 'faceDisappointed4',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed4-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed4.json',
    });
    this.load.atlas({
      key: 'faceDisappointed5',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed5-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed5.json',
    });
    this.load.atlas({
      key: 'faceDisappointed6',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed6-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed6.json',
    });
    this.load.atlas({
      key: 'faceDisappointed7',
      textureURL:
        '../../../assets/bilateral-animations/faceDisappointed7-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceDisappointed7.json',
    });

    this.load.atlas({
      key: 'faceRefusing1',
      textureURL: '../../../assets/bilateral-animations/faceRefusing1-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing1.json',
    });
    this.load.atlas({
      key: 'faceRefusing2',
      textureURL: '../../../assets/bilateral-animations/faceRefusing2-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing2.json',
    });
    this.load.atlas({
      key: 'faceRefusing3',
      textureURL: '../../../assets/bilateral-animations/faceRefusing3-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing3.json',
    });
    this.load.atlas({
      key: 'faceRefusing4',
      textureURL: '../../../assets/bilateral-animations/faceRefusing4-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing4.json',
    });
    this.load.atlas({
      key: 'faceRefusing5',
      textureURL: '../../../assets/bilateral-animations/faceRefusing5-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing5.json',
    });
    this.load.atlas({
      key: 'faceRefusing6',
      textureURL: '../../../assets/bilateral-animations/faceRefusing6-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing6.json',
    });
    this.load.atlas({
      key: 'faceRefusing7',
      textureURL: '../../../assets/bilateral-animations/faceRefusing7-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceRefusing7.json',
    });

    this.load.atlas({
      key: 'faceConcentrated1',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated1-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated1.json',
    });
    this.load.atlas({
      key: 'faceConcentrated2',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated2-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated2.json',
    });
    this.load.atlas({
      key: 'faceConcentrated3',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated3-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated3.json',
    });
    this.load.atlas({
      key: 'faceConcentrated4',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated4-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated4.json',
    });
    this.load.atlas({
      key: 'faceConcentrated5',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated5-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated5.json',
    });
    this.load.atlas({
      key: 'faceConcentrated6',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated6-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated6.json',
    });
    this.load.atlas({
      key: 'faceConcentrated7',
      textureURL:
        '../../../assets/bilateral-animations/faceConcentrated7-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceConcentrated7.json',
    });

    this.load.atlas({
      key: 'armsVictorious1A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious1A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious1A.json',
    });
    this.load.atlas({
      key: 'armsVictorious1C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious1C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious1C.json',
    });
    this.load.atlas({
      key: 'armsVictorious1D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious1D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious1D.json',
    });
    this.load.atlas({
      key: 'armsVictorious2A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious2A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious2A.json',
    });
    this.load.atlas({
      key: 'armsVictorious2C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious2C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious2C.json',
    });
    this.load.atlas({
      key: 'armsVictorious2D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious2D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious2D.json',
    });
    this.load.atlas({
      key: 'armsVictorious3A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious3A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious3A.json',
    });
    this.load.atlas({
      key: 'armsVictorious3C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious3C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious3C.json',
    });
    this.load.atlas({
      key: 'armsVictorious3D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious3D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious3D.json',
    });
    this.load.atlas({
      key: 'armsVictorious4A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious4A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious4A.json',
    });
    this.load.atlas({
      key: 'armsVictorious4C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious4C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious4C.json',
    });
    this.load.atlas({
      key: 'armsVictorious4D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious4D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious4D.json',
    });
    this.load.atlas({
      key: 'armsVictorious5A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious5A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious5A.json',
    });
    this.load.atlas({
      key: 'armsVictorious5C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious5C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious5C.json',
    });
    this.load.atlas({
      key: 'armsVictorious5D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious5D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious5D.json',
    });
    this.load.atlas({
      key: 'armsVictorious6A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious6A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious6A.json',
    });
    this.load.atlas({
      key: 'armsVictorious6C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious6C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious6C.json',
    });
    this.load.atlas({
      key: 'armsVictorious6D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious6D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious6D.json',
    });
    this.load.atlas({
      key: 'armsVictorious7A',
      textureURL: '../../../assets/bilateral-animations/armsVictorious7A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious7A.json',
    });
    this.load.atlas({
      key: 'armsVictorious7C',
      textureURL: '../../../assets/bilateral-animations/armsVictorious7C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious7C.json',
    });
    this.load.atlas({
      key: 'armsVictorious7D',
      textureURL: '../../../assets/bilateral-animations/armsVictorious7D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsVictorious7D.json',
    });

    this.load.atlas({
      key: 'armsConcentrated1A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated1A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated1A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated1C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated1C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated1C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated1D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated1D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated1D.json',
    });
    this.load.atlas({
      key: 'armsConcentrated2A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated2A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated2A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated2C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated2C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated2C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated2D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated2D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated2D.json',
    });
    this.load.atlas({
      key: 'armsConcentrated3A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated3A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated3A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated3C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated3C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated3C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated3D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated3D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated3D.json',
    });
    this.load.atlas({
      key: 'armsConcentrated4A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated4A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated4A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated4C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated4C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated4C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated4D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated4D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated4D.json',
    });
    this.load.atlas({
      key: 'armsConcentrated5A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated5A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated5A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated5C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated5C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated5C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated5D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated5D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated5D.json',
    });
    this.load.atlas({
      key: 'armsConcentrated6A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated6A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated6A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated6C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated6C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated6C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated6D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated6D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated6D.json',
    });
    this.load.atlas({
      key: 'armsConcentrated7A',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated7A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated7A.json',
    });
    this.load.atlas({
      key: 'armsConcentrated7C',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated7C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated7C.json',
    });
    this.load.atlas({
      key: 'armsConcentrated7D',
      textureURL:
        '../../../assets/bilateral-animations/armsConcentrated7D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsConcentrated7D.json',
    });

    this.load.atlas({
      key: 'armsThumbsUp1A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp1A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp1A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp1C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp1C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp1C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp1D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp1D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp1D.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp2A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp2A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp2A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp2C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp2C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp2C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp2D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp2D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp2D.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp3A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp3A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp3A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp3C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp3C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp3C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp3D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp3D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp3D.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp4A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp4A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp4A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp4C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp4C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp4C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp4D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp4D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp4D.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp5A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp5A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp5A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp5C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp5C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp5C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp5D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp5D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp5D.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp6A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp6A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp6A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp6C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp6C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp6C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp6D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp6D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp6D.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp7A',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp7A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp7A.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp7C',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp7C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp7C.json',
    });
    this.load.atlas({
      key: 'armsThumbsUp7D',
      textureURL: '../../../assets/bilateral-animations/armsThumbsUp7D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsThumbsUp7D.json',
    });

    this.load.atlas({
      key: 'armsRefusing1A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing1A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing1A.json',
    });
    this.load.atlas({
      key: 'armsRefusing1C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing1C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing1C.json',
    });
    this.load.atlas({
      key: 'armsRefusing1D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing1D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing1D.json',
    });
    this.load.atlas({
      key: 'armsRefusing2A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing2A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing2A.json',
    });
    this.load.atlas({
      key: 'armsRefusing2C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing2C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing2C.json',
    });
    this.load.atlas({
      key: 'armsRefusing2D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing2D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing2D.json',
    });
    this.load.atlas({
      key: 'armsRefusing3A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing3A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing3A.json',
    });
    this.load.atlas({
      key: 'armsRefusing3C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing3C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing3C.json',
    });
    this.load.atlas({
      key: 'armsRefusing3D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing3D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing3D.json',
    });
    this.load.atlas({
      key: 'armsRefusing4A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing4A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing4A.json',
    });
    this.load.atlas({
      key: 'armsRefusing4C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing4C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing4C.json',
    });
    this.load.atlas({
      key: 'armsRefusing4D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing4D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing4D.json',
    });
    this.load.atlas({
      key: 'armsRefusing5A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing5A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing5A.json',
    });
    this.load.atlas({
      key: 'armsRefusing5C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing5C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing5C.json',
    });
    this.load.atlas({
      key: 'armsRefusing5D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing5D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing5D.json',
    });
    this.load.atlas({
      key: 'armsRefusing6A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing6A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing6A.json',
    });
    this.load.atlas({
      key: 'armsRefusing6C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing6C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing6C.json',
    });
    this.load.atlas({
      key: 'armsRefusing6D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing6D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing6D.json',
    });
    this.load.atlas({
      key: 'armsRefusing7A',
      textureURL: '../../../assets/bilateral-animations/armsRefusing7A-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing7A.json',
    });
    this.load.atlas({
      key: 'armsRefusing7C',
      textureURL: '../../../assets/bilateral-animations/armsRefusing7C-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing7C.json',
    });
    this.load.atlas({
      key: 'armsRefusing7D',
      textureURL: '../../../assets/bilateral-animations/armsRefusing7D-0.png',
      atlasURL: '../../../assets/bilateral-animations/armsRefusing7D.json',
    });

    this.load.atlas({
      key: 'faceAngled1L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled1L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled1L0.json',
    });
    this.load.atlas({
      key: 'faceAngled1L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled1L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled1L30.json',
    });
    this.load.atlas({
      key: 'faceAngled1L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled1L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled1L60.json',
    });
    this.load.atlas({
      key: 'faceAngled2L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled2L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled2L0.json',
    });
    this.load.atlas({
      key: 'faceAngled2L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled2L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled2L30.json',
    });
    this.load.atlas({
      key: 'faceAngled2L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled2L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled2L60.json',
    });
    this.load.atlas({
      key: 'faceAngled3L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled3L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled3L0.json',
    });
    this.load.atlas({
      key: 'faceAngled3L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled3L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled3L30.json',
    });
    this.load.atlas({
      key: 'faceAngled3L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled3L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled3L60.json',
    });
    this.load.atlas({
      key: 'faceAngled4L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled4L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled4L0.json',
    });
    this.load.atlas({
      key: 'faceAngled4L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled4L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled4L30.json',
    });
    this.load.atlas({
      key: 'faceAngled4L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled4L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled4L60.json',
    });
    this.load.atlas({
      key: 'faceAngled5L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled5L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled5L0.json',
    });
    this.load.atlas({
      key: 'faceAngled5L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled5L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled5L30.json',
    });
    this.load.atlas({
      key: 'faceAngled5L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled5L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled5L60.json',
    });
    this.load.atlas({
      key: 'faceAngled6L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled6L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled6L0.json',
    });
    this.load.atlas({
      key: 'faceAngled6L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled6L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled6L30.json',
    });
    this.load.atlas({
      key: 'faceAngled6L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled6L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled6L60.json',
    });
    this.load.atlas({
      key: 'faceAngled7L0',
      textureURL: '../../../assets/bilateral-animations/faceAngled7L0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled7L0.json',
    });
    this.load.atlas({
      key: 'faceAngled7L30',
      textureURL: '../../../assets/bilateral-animations/faceAngled7L30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled7L30.json',
    });
    this.load.atlas({
      key: 'faceAngled7L60',
      textureURL: '../../../assets/bilateral-animations/faceAngled7L60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled7L60.json',
    });

    this.load.atlas({
      key: 'faceAngled1R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled1R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled1R0.json',
    });

    this.load.atlas({
      key: 'faceAngled1R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled1R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled1R30.json',
    });
    this.load.atlas({
      key: 'faceAngled1R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled1R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled1R60.json',
    });
    this.load.atlas({
      key: 'faceAngled2R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled2R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled2R0.json',
    });
    this.load.atlas({
      key: 'faceAngled2R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled2R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled2R30.json',
    });
    this.load.atlas({
      key: 'faceAngled2R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled2R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled2R60.json',
    });
    this.load.atlas({
      key: 'faceAngled3R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled3R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled3R0.json',
    });
    this.load.atlas({
      key: 'faceAngled3R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled3R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled3R30.json',
    });
    this.load.atlas({
      key: 'faceAngled3R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled3R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled3R60.json',
    });
    this.load.atlas({
      key: 'faceAngled4R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled4R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled4R0.json',
    });
    this.load.atlas({
      key: 'faceAngled4R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled4R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled4R30.json',
    });
    this.load.atlas({
      key: 'faceAngled4R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled4R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled4R60.json',
    });
    this.load.atlas({
      key: 'faceAngled5R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled5R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled5R0.json',
    });
    this.load.atlas({
      key: 'faceAngled5R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled5R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled5R30.json',
    });
    this.load.atlas({
      key: 'faceAngled5R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled5R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled5R60.json',
    });
    this.load.atlas({
      key: 'faceAngled6R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled6R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled6R0.json',
    });
    this.load.atlas({
      key: 'faceAngled6R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled6R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled6R30.json',
    });
    this.load.atlas({
      key: 'faceAngled6R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled6R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled6R60.json',
    });
    this.load.atlas({
      key: 'faceAngled7R0',
      textureURL: '../../../assets/bilateral-animations/faceAngled7R0-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled7R0.json',
    });
    this.load.atlas({
      key: 'faceAngled7R30',
      textureURL: '../../../assets/bilateral-animations/faceAngled7R30-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled7R30.json',
    });
    this.load.atlas({
      key: 'faceAngled7R60',
      textureURL: '../../../assets/bilateral-animations/faceAngled7R60-0.png',
      atlasURL: '../../../assets/bilateral-animations/faceAngled7R60.json',
    });
  }
}

export const makeBilateralScene = (ctx) => {
  context = ctx;
  return BilateralScene;
};
