import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  BilateralDiscussionMessageType,
  BilateralStatus,
  PhaserScenes,
} from '../models/game.model';
import { CoffeeBreakScene } from '../phaser/scenes/coffee-break.scene';
import { CountriesQuery } from '../state/countriesState/countries.query';
import { CountriesStore } from '../state/countriesState/countries.store';
import { PresentationProposalScene } from '../phaser/scenes/presentation-proposal.scene';
import { GameQuery } from '../state/gameState/game.query';
import { GameService } from '../state/gameState/game.service';
import { GameStore } from '../state/gameState/game.store';
import { UserQuery } from '../state/userState/user.query';
import { AvatarService } from './avatar-customization.service';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketService } from './websocket.service';
import { EmptyScene, makeEmptyScene } from '../phaser/scenes/empty.scene';
import { TranslateConfigService } from '../translate-config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnvService } from './env.service';
import { SettingsService } from "./settings.service";

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GameInstanceService {
  svg: ElementRef;
  avatarIndex = [];
  userGames = [];
  public gameInstance: any;
  npcIndexes: any[];
  userData: Subscription;
  isZoomBoardOpen = false;
  userId: string;
  negotiatorUserId: string;

  key: string;
  flagsAssetsB64: any = {};
  currentUserGame: any;
  negotiatorUserGame: any;
  bilateralNav = false;
  routerEvents: any;
  hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  coffeeBreakPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  negotiationPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  timesPlayedPerBreakTalkToAnExpert: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  firstTimeVisitQuizGame: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  timesPlayedPerBreakSpotTheCountry: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  timesPlayedPerBreakEmoji: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  timesPlayedPerBreakDrinkCoffee: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  breakRoundSubscription: Subscription;
  breakRound = 0;
  gameFont = '"Source Sans 3"';
  isAnimatedSpeechPlaying$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  toggleChecked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  guideDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private countriesStore: CountriesStore,
    private countriesQuery: CountriesQuery,
    public gameQuery: GameQuery,
    private gameStore: GameStore,
    private gameService: GameService,
    private userQuery: UserQuery,
    private avatarConstructionService: AvatarService,
    public router: Router,
    private translateService: TranslateService,
    private translate: TranslateConfigService,
    private webSocketService: WebSocketService,
    private envService: EnvService,
    private settingsService: SettingsService,
  ) {
    this.routerEvents = this.router.events;
    this.breakRound = this.gameQuery.getValue()?.game?.breakRound;
    this.breakRoundSubscription = this.gameQuery.game$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (this.breakRound !== res?.breakRound) {
          this.timesPlayedPerBreakTalkToAnExpert.next(0);
          this.firstTimeVisitQuizGame.next(false);
          this.timesPlayedPerBreakSpotTheCountry.next(0);
          this.timesPlayedPerBreakEmoji.next(0);
          this.timesPlayedPerBreakDrinkCoffee.next(0);
          this.breakRound = res?.breakRound;
        }
      });
    document.addEventListener('visibilitychange', () => {
      this.hidden.next(document.hidden);
    });
  }

  init(parentContainer = 'eu-council-game-container') {
    this.avatarIndex = this.userQuery.getUserAvatarIndex();
    this.userData = this.gameQuery
      .select('game')
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.userGames = response?.userGames;
      });
    this.gameInstance = new Phaser.Game({
      type: Phaser.WEBGL,
      scene: [makeEmptyScene(this)],
      parent: parentContainer,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 1800,
        height: 1012,
      },
      physics: {
        default: 'arcade',
        arcade: {
          // gravity: { y: 300 },
          debug: false,
        },
      },

      transparent: true,
      render: {
        transparent: true,
        antialias: true,

        // premultipliedAlpha: true, //webgl configs
        // preserveDrawingBuffer: false, //webgl configs
        // failIfMajorPerformanceCaveat: true, //webgl configs
        // powerPreference: 'high-performance', //webgl configs
        // antialiasGL: true,
        // mipmapFilter: 'NEAREST_MIPMAP_LINEAR',
      },
      pixelArt: false,
      fps: {
        target: 30,
        min: 10,
        forceSetTimeOut: true,
      },
      dom: {
        createContainer: true,
      },
    });
    this.gameInstance.gameInstanceService = this;
  }

  setScale(key: string) {
    if (key === CoffeeBreakScene.key) {
      (this.gameInstance as Phaser.Game).scale.setGameSize(
        window.innerWidth,
        window.innerHeight
      );
    } else {
      (this.gameInstance as Phaser.Game).scale.setGameSize(1800, 1012);
    }
  }

  switchScene(key: string, scene: any, hidden = false) {
    if (!hidden) {
      (this.gameInstance as Phaser.Game).scene
        .getScenes()
        .forEach((activeScene: any) => {
          // (this.gameInstance as Phaser.Game).scene.stop(activeScene);
          // (this.gameInstance as Phaser.Game).scene.remove(activeScene);
          activeScene.scene.setVisible(activeScene.key === key);
        });
    }
    if (!(this.gameInstance as Phaser.Game).scene.getScene(key)) {
      this.addScene(key, scene, hidden);
    } else {
      if (hidden) {
        (this.gameInstance as Phaser.Game).scene
          .getScene(key)
          .scene.setVisible(false)
          .sendToBack();
      } else {
        (this.gameInstance as Phaser.Game).scene
          .getScene(key)
          .scene.setVisible(true)
          .bringToTop();
        this.setScale(key);
      }
    }
  }

  addScene(key: string, scene: any, hidden = false) {
    (this.gameInstance as Phaser.Game).scene.add(key, scene(this), true, {
      hidden,
    });
    if (!hidden) {
      this.setScale(key);
      (this.gameInstance as Phaser.Game).scale.refresh();
    }
  }

  deleteScene(key: string) {
    (this.gameInstance as Phaser.Game).scene.remove(key);
  }

  bringToTop() {
    const game = this.gameInstance as Phaser.Game;
    game.scene.bringToTop(PresentationProposalScene.key);
  }

  restart(sceneKey) {
    const game = this.gameInstance as Phaser.Game;
    // game.scene.getScene(NegotiationD1Scene.key).scene.restart();
    // game.scene.stop(sceneKey);
    // game.scene.start(sceneKey);
  }

  bilateralAvatarConstruction(scene, current) {
    if (current) {
      return this.avatarConstructionService.customizationAvatar(
        this.currentUserGame.user.avatar_Body,
        this.currentUserGame.user.avatar_SkinTone,
        this.currentUserGame.user.avatar_Head,
        this.currentUserGame.user.avatar_HairColor,
        this.currentUserGame.user.avatar_Accessory,
        this.currentUserGame.user.avatar_Face,
        this.currentUserGame.country,
        this.defineSceneKey(scene)
      );
    } else {
      return this.avatarConstructionService.customizationAvatar(
        this.negotiatorUserGame.user.avatar_Body,
        this.negotiatorUserGame.user.avatar_SkinTone,
        this.negotiatorUserGame.user.avatar_Head,
        this.negotiatorUserGame.user.avatar_HairColor,
        this.negotiatorUserGame.user.avatar_Accessory,
        this.negotiatorUserGame.user.avatar_Face,
        this.negotiatorUserGame.country,
        this.defineSceneKey(scene)
      );
    }
  }

  createTexturesBilateral(current: boolean) {
    this.userId = this.userQuery.getValue().user.userId;
    this.negotiatorUserId =
      this.gameQuery.getValue().bilateralDiscussion.userId;
    this.currentUserGame = this.userGames.find(
      (ug) => ug.userId === this.userId
    );
    this.negotiatorUserGame = this.userGames.find(
      (ug) => ug.userId === this.negotiatorUserId
    );
    const s = new XMLSerializer().serializeToString(
      this.bilateralAvatarConstruction(PhaserScenes.bilateralScene, current)
    );

    const b64 = 'data:image/svg+xml;base64,' + window.btoa(s);
    const url = b64;
    let key = '';
    if (!current) {
      key = `${this.negotiatorUserGame.country}Bilateral`;
    } else {
      key = `${this.currentUserGame.country}Bilateral`;
    }
    this.flagsAssetsB64[key] = url;

    if ((this.gameInstance as Phaser.Game).textures.exists(key)) {
      (this.gameInstance as Phaser.Game).textures.get(key).destroy();
    }
    (this.gameInstance as Phaser.Game).textures.addBase64(
      key,
      this.flagsAssetsB64[key]?.toString()
    );
  }

  npcAvatarConstruction(countryCode, scene) {
    const usersGame = this.userGames.find((el) => el.country === countryCode);
    this.npcIndexes = [
      usersGame?.user.avatar_Body,
      usersGame?.user.avatar_SkinTone,
      usersGame?.user.avatar_Head,
      usersGame?.user.avatar_HairColor,
      usersGame?.user.avatar_Accessory,
      usersGame?.user.avatar_Face,
    ];

    return this.avatarConstructionService.customizationAvatar(
      this.npcIndexes[0],
      this.npcIndexes[1],
      this.npcIndexes[2],
      this.npcIndexes[3],
      this.npcIndexes[4],
      this.npcIndexes[5],
      countryCode,
      this.defineSceneKey(scene)
    );
  }

  avatarConstruction(countryCode, scene) {
    this.avatarIndex = this.userQuery.getUserAvatarIndex();
    return this.avatarConstructionService.customizationAvatar(
      this.avatarIndex[0],
      this.avatarIndex[1],
      this.avatarIndex[2],
      this.avatarIndex[3],
      this.avatarIndex[4],
      this.avatarIndex[5],
      countryCode,
      this.defineSceneKey(scene)
    );
  }

  defineSceneKey(scene: any) {
    let key;
    if (scene === PhaserScenes.negotiationScene) {
      key = PhaserScenes.negotiationScene;
    } else if (scene === PhaserScenes.presentationProposalScene) {
      key = PhaserScenes.presentationProposalScene;
    } else {
      key = PhaserScenes.coffeeBreakScene;
    }
    return key;
  }

  startBilateral(userId: string) {
    const requestedUserGame = this.userGames.find((ug) => ug.userId === userId);
    if (requestedUserGame.user.normalUser) {
      this.gameService
        .negotiationMessage(
          userId,
          BilateralDiscussionMessageType.startNegotiation
        )
        .then(() => {
          this.gameStore.update({
            bilateralDiscussion: {
              userId,
              incoming: false,
              status: BilateralStatus.pending,
              started: false,
              currentState: [],
              pending: undefined,
            },
          });
        }, error => {
          console.log(error);
          this.gameStore.update({
            bilateralDiscussion: {
              userId,
              incoming: false,
              status: BilateralStatus.notEnoughActionPoints,
              started: false,
              currentState: [],
              pending: undefined,
            },
          });
        });
    } else {
      this.gameStore.update({
        bilateralDiscussion: {
          userId,
          incoming: false,
          status: BilateralStatus.empty,
          started: false,
          currentState: [],
          pending: undefined,
        },
      });
      this.router.navigate(['/bilateral-discussion']);
    }
  }

  startQuiz() {
    if (this.timesPlayedPerBreakTalkToAnExpert.getValue() > 0) {
      return;
    }
    this.router.navigate(['/quiz-game']);
  }

  hideZoomBoard() {
    this.isZoomBoardOpen = false;
  }

  showZoomBoard() {
    this.isZoomBoardOpen = true;
  }

  resetGameState() {
    this.gameService.resetGameState();
    this.timesPlayedPerBreakDrinkCoffee.next(0);
    this.timesPlayedPerBreakSpotTheCountry.next(0);
    this.timesPlayedPerBreakEmoji.next(0);
    this.timesPlayedPerBreakTalkToAnExpert.next(0);
    this.firstTimeVisitQuizGame.next(false);
    (this.gameInstance as Phaser.Game).scene
      .getScenes(false)
      .filter((sc) => sc.scene.key !== EmptyScene.key)
      .forEach((sc) => {
        this.deleteScene(sc.scene.key);
      });
  }
}
