import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {GameQuery} from 'src/app/state/gameState/game.query';
import {UserService} from 'src/app/state/userState/user.service';
import {ITopic} from '../../models/api.model';
import {CountriesQuery} from '../../state/countriesState/countries.query';
import {Platform} from '@ionic/angular';
import {DimensionPosition} from '../../models/game.model';
import { GameService } from 'src/app/state/gameState/game.service';

@Component({
  selector: 'app-overview-page',
  templateUrl: './overview.page.html',
  styleUrls: ['./overview.page.scss'],
})
export class OverviewPage implements OnInit, AfterViewInit {
  @ViewChildren('widgetsContent') widgetsContent: QueryList<ElementRef>;
  public topicId: any;
  public topic: ITopic;
  public topics: any;
  public hideScrollLeft = [];
  public hideScrollRight = [];
  public userGames = [];
  public percentages = 0;
  public countries = [];
  public containerWidth = 40;
  public totalSumOfCountriesPopulation = 0;
  public userCountry: string;
  public scrollableDivs: any;
  public multiDimension: DimensionPosition[][] = [[], [], []];
  public dimensionsInfo = {};

  constructor(
    private gameQuery: GameQuery,
    private gameService: GameService,
    private router: Router,
    private userService: UserService,
    private countriesQuery: CountriesQuery,
    private platform: Platform,
  ) {
  }

  ngOnInit() {
    this.topicId = this.gameQuery.getTopicId();
    this.topics = this.gameQuery.getTopicsFromState();

    this.topic = this.topics.find(topic => topic.id === this.topicId);

    this.topic.dimension.forEach((dimension, index) => {
      this.dimensionsInfo[index] = {name: dimension.name, noPositionSum: 0, highlightNoPosition: false};
    });

    this.userCountry = this.gameService.getCurrentUserCountry()?.countryCode;
    this.userGames = this.gameQuery.getUserGames();
    this.countries = this.countriesQuery.getCountries();
    this.countries.forEach((country) => {
      this.totalSumOfCountriesPopulation += parseInt(country.size, 10);
    });

    this.userGames.forEach((userGame) => {
      this.topic.dimension.forEach((dimension, index: number) => {

        userGame.user.userGameNegotiation.some((user) => {

          if (user.dimension === dimension.id) {
            const userPosition = dimension.positionContent.find(position => position.id === user.position);

            if (!this.multiDimension[index][userPosition.id]) {
              this.multiDimension[index][userPosition.id] = {
                name: userPosition.name,
                id: userPosition.id,
                flag: '',
                noposition: userPosition?.noposition ?? false,
                sumOfCountryPopulation: 0,
                countriesSum: 0,
                percentages: 0,
                widthValue: '',
                userDimensionBox: false,
                highlightPosition: false,
              };
            }

            this.countries.some((country) => {
              if (country.countryCode === userGame.country) {
                if (userGame.country === this.userCountry) {
                  this.multiDimension[index][userPosition.id].flag = this.userCountry;
                  this.multiDimension[index][userPosition.id].userDimensionBox = true;
                }
                this.multiDimension[index][userPosition.id].sumOfCountryPopulation += parseInt(country.size, 10);
                this.multiDimension[index][userPosition.id].countriesSum += 1;
                return true;
              }
              return false;
            });

            if (this.multiDimension[index][userPosition.id].countriesSum === 0) {
              this.multiDimension[index][userPosition.id].percentages = ((0 / 27) * 100) + (0 * 100);
              this.multiDimension[index][userPosition.id].widthValue = `8rem`;
            } else {
              this.multiDimension[index][userPosition.id].percentages = (
                ((this.multiDimension[index][userPosition.id].countriesSum / 27) * 100) +
                ((this.multiDimension[index][userPosition.id].sumOfCountryPopulation / this.totalSumOfCountriesPopulation) * 100)
              ) / 2;

              const val = (this.containerWidth) * (parseInt(this.multiDimension[index][userPosition.id].percentages.toFixed(2), 10) / 100);
              this.multiDimension[index][userPosition.id].widthValue = `${val}rem`;
              if (((this.multiDimension[index][userPosition.id].countriesSum / 27) * 100) > 55 &&
                ((this.multiDimension[index][userPosition.id].sumOfCountryPopulation / this.totalSumOfCountriesPopulation) * 100) > 65) {
                this.multiDimension[index][userPosition.id].highlightPosition = true;
              }
            }
            return true;
          }
          return false;
        });
      });
    });

    this.multiDimension.forEach((dimension, index) => {
      this.multiDimension[index] = Object.values(dimension);
      this.multiDimension[index].sort((a, b) => b.percentages - a.percentages);
      this.dimensionsInfo[index].noPositionSum = this.multiDimension[index]?.find(position => position?.noposition)?.countriesSum ?? 0;
      const tempNoPosition = this.multiDimension[index]?.find(position => position?.noposition);
      this.dimensionsInfo[index].highlightNoPosition = (tempNoPosition?.countriesSum / 27) * 100 > 55
        && (tempNoPosition?.sumOfCountryPopulation / 100) > 65;
    });
  }

  ngAfterViewInit() {
    this.scrollableDivs = this.widgetsContent.toArray();
  }

  ionViewWillEnter() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.scrollableDivs.forEach((element: ElementRef, index) => {
          this.hideScrollLeft[index] = true;
          this.hideScrollRight[index] = true;
        });
      } else {
        this.scrollableDivs.forEach((element: ElementRef, index) => {
          this.hideScrollLeft[index] = element.nativeElement.scrollLeft === 0;
          this.hideScrollRight[index] = element.nativeElement.scrollWidth <= element.nativeElement.clientWidth;
        });
      }
    });
  }

  scrollLeft(i) {
    this.scrollableDivs[i].nativeElement.scrollLeft -= 100;
    this.onHideScroll(i);
  }

  scrollRight(i) {
    this.scrollableDivs[i].nativeElement.scrollLeft += 100;
    this.onHideScroll(i);
  }

  onHideScroll(i) {
    this.hideScrollLeft[i] = this.scrollableDivs[i].nativeElement.scrollLeft === 0;
    this.hideScrollRight[i] = this.scrollableDivs[i].nativeElement.scrollWidth -
      (this.scrollableDivs[i].nativeElement.clientWidth + this.scrollableDivs[i].nativeElement.scrollLeft) === 0;
  }

  onBack() {
    this.router.navigate(['/negotiation_phase']);
  }
}
