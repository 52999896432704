import * as EasyStar from 'easystarjs';
import Phaser from 'phaser';
import { IUserGame } from '../../models/api.model';
import { Subject, Subscription, timer } from 'rxjs';
import { Country } from 'src/app/models/country.model';
import {
  BilateralHistoryStatus,
  BreakGames,
  ErrorMessages,
  GameName,
  PhaserScenes,
} from 'src/app/models/game.model';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { getRandomInt } from 'src/app/shared/math.utils';
import _ from 'lodash';
import { Environment } from "../../services/env.service";

let context: any;

enum DrinkCoffeeErrors {
  gamePlayLimitReached = 'Game PLay limit reached!!',
  noMoreCoffees = 'No more coffes available !!',
}

enum PopUpTypeOfUse {
  noPoints = 'noPoints',
  bilateral = 'bilateral',
}

export class CoffeeBreakScene extends Phaser.Scene {
  static key = 'coffee-break-scene';
  static active = true;

  usersDataSubscription: Subscription;
  flagsAssetsB64: string[] = [];

  easystar: any;
  map: Phaser.Tilemaps.Tilemap;
  tileLayer: any;
  userCountry: string;
  triggerTimer: any;
  allAvatars: any = {};
  avatarsData: any;
  playerAvatar: any[] = [];
  isSinglePlayer: boolean;
  isHostOfGame: boolean;

  initialTile = 16;
  gameTileSize = 16;

  actualImageWidth = 1331;
  actualImageHeight = 1501;

  bg: Phaser.GameObjects.Image;
  staticObject: Phaser.GameObjects.Image;
  balcony: Phaser.GameObjects.Image;
  controls: Phaser.Cameras.Controls.SmoothedKeyControl;
  myAvatar: Phaser.Types.Physics.Arcade.ImageWithDynamicBody;
  realPlayers: IUserGame[] = [];
  npcPlayers: IUserGame[] = [];
  npcTexturePlayers: any = [];
  npcMovements: any = [];
  myPlayerMovement: Phaser.Tweens.Timeline;
  npcInternvalMovement: any;
  emojiTimeoutReset: any;
  emojiStartMultiplayer = false;
  resizeWindow = false;

  topLamp: Phaser.GameObjects.Sprite | undefined;
  vase: Phaser.GameObjects.Sprite | undefined;
  chair1: Phaser.GameObjects.Sprite | undefined;
  chair2: Phaser.GameObjects.Sprite | undefined;
  tableSmall: Phaser.GameObjects.Sprite | undefined;
  table1: Phaser.GameObjects.Sprite | undefined;
  phoneBooth: Phaser.GameObjects.Sprite | undefined;
  chair3: Phaser.GameObjects.Sprite | undefined;
  chair4: Phaser.GameObjects.Sprite | undefined;
  table2: Phaser.GameObjects.Sprite | undefined;
  table3: Phaser.GameObjects.Sprite | undefined;
  kiosk: Phaser.GameObjects.Sprite | undefined;
  chair5: Phaser.GameObjects.Sprite | undefined;
  chair6: Phaser.GameObjects.Sprite | undefined;
  chair7: Phaser.GameObjects.Sprite | undefined;
  table4: Phaser.GameObjects.Sprite | undefined;
  chair8: Phaser.GameObjects.Sprite | undefined;
  downLamp: Phaser.GameObjects.Sprite | undefined;
  table5: Phaser.GameObjects.Sprite | undefined;
  chair9: Phaser.GameObjects.Sprite | undefined;
  books: Phaser.GameObjects.Sprite | undefined;
  cursors: any;
  coffeeDrinkLabel: Phaser.GameObjects.Sprite;
  coffeeDrinkLabelText: Phaser.GameObjects.Text;
  emojiGameLabel: Phaser.GameObjects.Sprite;
  emojiLabelSmallDialog: Phaser.GameObjects.Sprite;
  blinkEmoji: Phaser.GameObjects.Sprite;
  loveEmoji: Phaser.GameObjects.Sprite;
  laughEmoji: Phaser.GameObjects.Sprite;
  sweatEmoji: Phaser.GameObjects.Sprite;
  whiteDialogEmoji: Phaser.GameObjects.Sprite;
  emojiCheck: Phaser.GameObjects.Sprite;
  hasClickedEmoji = false;
  emojiActions: any = [];
  emojiActionsMultiplayer: any = [];
  realPlayersEmoji: any = [];
  multiplayerEmojiGame: any = [];
  multiplayerEmojiPlayerPost = false;
  allEmojis: any = [];
  labelGameGroupEmoji: any = [];
  playerEmojiSelected: string;
  coffeeDistance: number;
  distancePopUp: Phaser.GameObjects.Sprite;
  distancePopUpText: Phaser.GameObjects.Text;
  countries: Country[];
  coffee2: Phaser.GameObjects.Sprite;
  coffee1: Phaser.GameObjects.Sprite;
  coffee3: Phaser.GameObjects.Sprite;
  drinkCoffeeTween: Phaser.Tweens.Tween;
  coffees: Phaser.GameObjects.Sprite[] = [];
  coffeesAvailable: number;
  coffeesAvailableSubscription: Subscription;
  breakGamePlayingSubscription: Subscription;
  breakGamePlaying: BreakGames;
  breakErrorSubscription: Subscription;
  breakError: string;
  errorPopUp: Phaser.GameObjects.Sprite;
  errorPopUpText: Phaser.GameObjects.Text;
  spotTheCountryGameResponse: any[];
  spotTheCountrySelectedGame: any;
  spotTheCountryMyAvatarLabel: Phaser.GameObjects.Sprite;
  spotTheCountryMyAvatarLabelText: Phaser.GameObjects.Text;
  countdownSubscription: Subscription;
  countdown: boolean;
  npcSpotTheCountryStatusLabel: any;
  npcSpotTheCountryStatusLabelText: any;
  boltWithoutAnim: Phaser.GameObjects.Sprite;
  boltWithBarAnim: Phaser.GameObjects.Sprite;
  armsAnimationsWave = [];
  faceAnimationsSmile = [];
  boardAnimScale: 0.2;
  armsAnimationsWaveMyAvatar: Phaser.GameObjects.Sprite;
  faceAnimationsSmileMyAvatar: Phaser.GameObjects.Sprite;
  armsCoffeeAnimationMyAvatar: Phaser.GameObjects.Sprite;
  faceCoffeeAnimationMyAvatar: Phaser.GameObjects.Sprite;
  faceAnimationsBlinkMyAvatar: Phaser.GameObjects.Sprite;
  faceAnimationsLaughMyAvatar: Phaser.GameObjects.Sprite;
  faceAnimationsLoveMyAvatar: Phaser.GameObjects.Sprite;
  faceAnimationsSweatMyAvatar: Phaser.GameObjects.Sprite;
  animationsScale: number;
  carpets: Phaser.GameObjects.Sprite[] = [];
  avatarPopUpBreak: Phaser.GameObjects.Sprite;
  negotiateButtonBreak: Phaser.GameObjects.Sprite;
  avatarPopUpGroupBreak: Phaser.GameObjects.Group;
  peopleIconBreak: Phaser.GameObjects.Sprite;
  popUpCountryTextBreak: Phaser.GameObjects.Text;
  popUpPopulationTextBreak: Phaser.GameObjects.Text;
  breakRound: number;
  maleExperts: string[];
  femaleExperts: string[];
  maleExpert: Phaser.GameObjects.Sprite;
  femaleExpert: Phaser.GameObjects.Sprite;
  myPlayerIndicator: Phaser.GameObjects.Sprite;
  moveConfirmationPopUp: Phaser.GameObjects.Sprite;
  moveConfirmationPopUpText: Phaser.GameObjects.Text;
  confirmButton: Phaser.GameObjects.Sprite;
  confirmButtonText: Phaser.GameObjects.Text;
  myBilateral: string;
  myUserBilateral: string;
  hiddenSubscription: Subscription;
  coffeeBreakPageSubscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  breakGamePostActionSubscription: Subscription;
  loaderAnim: Phaser.GameObjects.Sprite;
  click$: Subject<any> = new Subject<any>();
  faceAnimationsBlink: Phaser.GameObjects.Sprite[] = [];
  faceAnimationsLaugh: Phaser.GameObjects.Sprite[] = [];
  faceAnimationsLove: Phaser.GameObjects.Sprite[] = [];
  faceAnimationsSweat: Phaser.GameObjects.Sprite[] = [];
  negotiateButtonTextBreak: Phaser.GameObjects.Text;
  npcFoundData: any[];

  private usersData: IUserGame[];

  private debounceOnResize = _.debounce(() => this.reziseUpdateSceneAssets(), 200, {});

  constructor() {
    super({
      key: CoffeeBreakScene.key,
      active: CoffeeBreakScene.active,
    });
    this.easystar = new EasyStar.js();

    context.gameQuery.select('maleExperts').pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.maleExperts = res;
    });

    context.gameQuery.select('femaleExperts').pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.femaleExperts = res;
    });

    context.gameQuery.select('coffeeBreak').pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.avatarsData = res;
      // this.coffeeBreakDataChange.emit({data: this.avatarsData});
    });

    this.usersDataSubscription = context.gameQuery.game$.pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        if (!response) {
          clearInterval(this.npcInternvalMovement);
          return;
        }
        console.log('response: ', response);
        this.isSinglePlayer = response.single === 1;

        this.realPlayers = [];
        this.npcPlayers = [];

        response?.userGames.forEach((userGame: IUserGame) => {
          if (userGame.user.normalUser === 1) {
            this.realPlayers.push(userGame);
          } else {
            this.npcPlayers.push(userGame);
          }
        });

        this.usersData = response.userGames;

        this.userCountry = this.usersData.find(
          (el) => el.userId === context.userQuery.getUserId()
        )?.country;

        this.isHostOfGame =
          this.usersData.find(
            (el) => el.userId === context.userQuery.getUserId()
          )?.userHost === 1;
      }
    );

    console.log('isHostOfGame: ', this.isHostOfGame);
    this.countries = context.countriesQuery.getCountries();
    context.countriesQuery.countries$
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      this.countries = res;
    });
    this.coffeesAvailableSubscription =
      context.gameQuery.coffeesAvailable$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.coffeesAvailable = res;
        this.checkCoffeesVisibility();
      });

    this.breakGamePlayingSubscription = context.gameQuery
      .select('breakGamePlaying')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.breakGamePlaying = res;
        this.avatarPopUpGroupBreak?.children?.each((ch: Phaser.GameObjects.Sprite) => ch?.setVisible(false));


        if (this.breakGamePlaying) {
          this.femaleExpert?.disableInteractive();
          this.maleExpert?.disableInteractive();
        } else {
          this.femaleExpert?.setInteractive();
          this.maleExpert?.setInteractive();
        }

        if (this.breakGamePlaying === BreakGames.drinkACoffee) {
          context.gameService.updateErrorMessage(
            ErrorMessages.breakTaskDrinkCoffeeInstructions
          );
          this.checkCoffeesVisibility();
          this.coffees.forEach((coffee) => {
            if(coffee.visible){
              this.addInteractive(coffee);
            }
          });
        }

        if (this.breakGamePlaying === BreakGames.spotTheCountry) {
          this.spotTheCountryGameResponse =
            context.gameQuery.getSpotTheCountryGames();

          const tempGame = this.spotTheCountryGameResponse.filter(
            (item) => item.value === false
          );

          if (tempGame.length === 0) {
            context.gameService.updateBreakGamePlaying(null);
            return;
          }

          context.gameService.updateSpotTheCountriesFound({});
          context.gameService.updateSpotTheCountryGameResult(null);

          this.spotTheCountrySelectedGame =
            tempGame[getRandomInt(tempGame.length - 1)];

          context.gameService.updateSpotTheCountrySelectedGame(
            this.spotTheCountrySelectedGame.name
          );

          const spotTheCountryGameUpdated = this.spotTheCountryGameResponse.map(
            (object) => {
              if (object.name === this.spotTheCountrySelectedGame?.name) {
                return { ...object, value: true };
              }
              return object;
            }
          );
          context.gameService.updateSpotTheCountryGames(
            spotTheCountryGameUpdated
          );
          context.gameService.updateSpotCountryCountdown(true);

          setTimeout(() => {
            this.countdownSubscription = context.gameQuery
              .select('spotCountryCountdown')
              .pipe(takeUntil(this.destroy$))
              .subscribe((resp: boolean) => {
                this.countdown = resp;
                if (context.gameQuery.getSpotTheCountryGameResult()) {
                  return;
                }
                if (!this.countdown) {
                  if (Object.keys(context.gameQuery.getSpotTheCountriesFound()).length >= 3) {
                    this.spotTheCountryPostAction();
                  } else {
                    if (Object.keys(context.gameQuery.getSpotTheCountriesFound()).length > 0) {
                      context.timesPlayedPerBreakSpotTheCountry.next(
                        context.timesPlayedPerBreakSpotTheCountry.getValue() + 1
                      );
                    }
                    context.gameService.updateSpotTheCountryGameResult('fail');
                    context.gameService.updateSpotCountryCountdown(false);
                    setTimeout(() => {
                      context.gameService.updateBreakGamePlaying(null);
                    }, 3000);
                    this.countdownSubscription.unsubscribe();
                  }
                }
              });
          });
        }

        if (this.breakGamePlaying === BreakGames.emoji) {
          this.emojiGame();
        }
      });
  }

  // onResize() {
  //   this.resizeWindow = false;
  // }

  resizeEventFunction() {
    // this.resizeWindow = true;
    this.debounceOnResize();
  }

  emojiGame() {
    setTimeout(() => {
      this.myAvatar?.setInteractive({ useHandCursor: true });

      this.npcTexturePlayers.forEach((avatar) => {
        avatar.disableInteractive();
      });

      this.femaleExpert?.disableInteractive();

      this.maleExpert?.disableInteractive();

      context.gameService.updateErrorMessage(
        ErrorMessages.breakTaskEmojiInstructions
      );

      this.myAvatar?.on('pointerdown', () => {

        context.gameService.updateErrorMessage('');

        this.myAvatar?.removeInteractive();

        this.changeOneLabelGameGroupEmojiVisibility(0);

        this.changeAllEmojiVisibility(true);

        this.blinkEmoji?.setInteractive({ useHandCursor: true });
        this.laughEmoji?.setInteractive({ useHandCursor: true });
        this.loveEmoji?.setInteractive({ useHandCursor: true });
        this.sweatEmoji?.setInteractive({ useHandCursor: true });

        this.emojiTimeoutResetGame(20000);
        }
      );
    }, 100);
  }

  multiplayerEmojiResponseGame() {
    setTimeout(() => {
      this.myAvatar?.setInteractive({ useHandCursor: true });

      this.npcTexturePlayers.forEach((avatar) => {
        avatar.disableInteractive();
      });

      this.femaleExpert?.disableInteractive();

      this.maleExpert?.disableInteractive();

      this.myAvatar?.removeInteractive();

      context.gameService.updateErrorMessage(
        ErrorMessages.breakTaskEmojiInstructionsMultiplayer
      );

      this.changeOneLabelGameGroupEmojiVisibility(0);

      this.changeAllEmojiVisibility(true);

      this.blinkEmoji?.setInteractive({ useHandCursor: true });
      this.laughEmoji?.setInteractive({ useHandCursor: true });
      this.loveEmoji?.setInteractive({ useHandCursor: true });
      this.sweatEmoji?.setInteractive({ useHandCursor: true });

      this.emojiTimeoutResetGame(20000);
    }, 100);
  }

  emojiTimeoutResetGame(value: number) {
    this.emojiTimeoutReset = setTimeout(() => {
      this.emojiGameLabel?.setVisible(false);
      this.emojiLabelSmallDialog?.setVisible(false);
      this.changeAllEmojiVisibility(false);
      this.hasClickedEmoji = false;
      this.playerEmojiSelected = '';
      this.npcResetVisibility();
      if (this.multiplayerEmojiGame) {
        this.playerResetVisibility();
      }
      this.npcTexturePlayers.forEach((avatar) => {
        avatar.setInteractive();
      });
      context.gameService.updateBreakGamePlaying(null);
    }, value);
  }

  resetEmojiTexture() {
    this.emojiGameLabel?.setVisible(false);
    this.emojiLabelSmallDialog?.setVisible(false);
    this.changeAllEmojiVisibility(false);
    this.hasClickedEmoji = false;
    this.playerEmojiSelected = '';
    this.npcResetVisibility();
    this.emojiStartMultiplayer = false;
    if (this.multiplayerEmojiGame) {
      this.playerResetVisibility();
    }
    this.npcTexturePlayers.forEach((avatar) => {
      avatar.setInteractive();
    });
    clearTimeout(this.emojiTimeoutReset);
    context.gameService.updateBreakGamePlaying(null);
  }

  emojiGameGroupMessage() {
    const game = {
      emoji: {
        gameType: 'emoji',
        userStartedTheGame: this.myAvatar.data.values.userId,
        userStartedTheGameCountry: this.myAvatar.data.values.country,
        selectedEmoji: this.playerEmojiSelected,
        realPlayerCountryPlaying: this.realPlayersEmoji,
      },
    };
    context.gameService.coffeeBreakMessage(game);
  }

  playerAnswerEmojiGroupMessage() {
    const game = {
      emoji: {
        gameType: 'emoji',
        userStartedTheGame: this.multiplayerEmojiGame.userStartedTheGame,
        userStartedTheGameCountry: this.multiplayerEmojiGame.userStartedTheGameCountry,
        selectedEmoji: this.multiplayerEmojiGame.selectedEmoji,
        playerAnswerEmoji: this.playerEmojiSelected,
        playerAnswerUsedId: this.myAvatar.data.values.userId,
      },
    };
    context.gameService.coffeeBreakMessage(game);
  }

  changeOneEmojiVisibility(index: number) {
    this.allEmojis.forEach((emoji, i: number) => emoji.setVisible(i === index));
  }

  changeAllEmojiVisibility(value: boolean) {
    this.allEmojis.forEach((emoji) => emoji.setVisible(value));
  }

  changeOneLabelGameGroupEmojiVisibility(index: number) {
    this.labelGameGroupEmoji.forEach((emoji, i: number) => emoji.setVisible(i === index));
  }

  npcResetVisibility() {
    this.emojiActions?.forEach((emoji) => {
      emoji.emojiAction.setVisible(false);
      emoji.emojiDialog.setVisible(false);
      emoji.emojiDialogCheck.setVisible(false);
    });
  }

  playerResetVisibility() {
    this.emojiActionsMultiplayer?.forEach((emoji) => {
      emoji.emojiAction.setVisible(false);
      emoji.emojiDialog.setVisible(false);
      emoji.emojiDialogCheck.setVisible(false);
    });
    this.multiplayerEmojiPlayerPost = false;
  }

  setAssetScale() {
    return this.game.scale.width <= this.actualImageWidth
      ? (this.game.scale.width / this.actualImageWidth) * 0.76
      : (this.game.scale.width / this.actualImageWidth) * 0.64;
  }

  npcRandomEmojiFunction() {
    if (this.isSinglePlayer) {
      setTimeout(() => {
        this.npcRandomEmoji();
      }, 5000);
    } else {
      this.realPlayersEmoji = [];

      this.npcTexturePlayers.forEach((npc) => {
        const diffFromX = Math.abs(this.myAvatar.data.values.fromX - npc.data.values.fromX);
        const diffFromY = Math.abs(this.myAvatar.data.values.fromY - npc.data.values.fromY);

        if (diffFromX < 10  && diffFromY < 10) {
          if (!this.isSinglePlayer && npc.data.values.normalUser === 1) {
            this.realPlayersEmoji.push(npc.data.values);
          }
        }
      });

      if (this.realPlayersEmoji.length > 0) {
        this.emojiGameGroupMessage();
      }

      setTimeout(() => {
        this.npcRandomEmoji();
      }, 10000);
    }
  }

  multiplayerShowEmoji(init = true) {
    if (init) {
      this.emojiActionsMultiplayer?.forEach((emoji) => {
        emoji.emojiAction.destroy(true);
        emoji.emojiDialog.destroy(true);
        emoji.emojiDialogCheck.destroy(true);
      });

      this.emojiActionsMultiplayer = [];
    }

    const player = this.npcTexturePlayers.find((npc) => npc.data.values.userId ===
      (this.multiplayerEmojiGame.playerAnswerUsedId ?
        this.multiplayerEmojiGame.playerAnswerUsedId :
        this.multiplayerEmojiGame.userStartedTheGame));

      const emojiDialog = this.add.sprite(
      player.x,
      player.y - player.displayHeight / 1.5,
      'emoji-white-dialog'
    )
      .setDepth(player.depth + 1)
      .setScale(this.setAssetScale())
      .setData('countryEmoji', player.data.values.country);

    const emojiDialogCheck = this.add.sprite(
      emojiDialog.x - emojiDialog.displayWidth / 2,
      emojiDialog.y - emojiDialog.displayHeight / 2,
      'emojiCheck'
    )
      .setDepth(emojiDialog.depth + 2)
      .setScale(this.setAssetScale())
      .setData('countryEmoji', player.data.values.country)
      .setVisible(this.multiplayerEmojiGame.selectedEmoji === this.multiplayerEmojiGame.playerAnswerEmoji);

    const emojiAction = this.add.sprite(
      player.x,
      player.y - player.displayHeight / 1.5,
      this.multiplayerEmojiGame.playerAnswerUsedId ? this.multiplayerEmojiGame.playerAnswerEmoji : this.multiplayerEmojiGame.selectedEmoji
    )
      .setDepth(player.depth + 2)
      .setScale(this.setAssetScale())
      .setData('countryEmoji', player.data.values.country);

    this.emojiActionsMultiplayer.push({emojiAction, emojiDialog, emojiDialogCheck});

    if (this.multiplayerEmojiGame.selectedEmoji === this.multiplayerEmojiGame.playerAnswerEmoji) {
      this.playEmojiAnims();
      this.emojiPostAction();
    }
  }

  pointerEVentDowmMulti() {
    if (this.breakGamePlaying === BreakGames.emoji) {
      this.npcRandomEmojiFunction();
    } else {
      if (this.multiplayerEmojiGame.selectedEmoji === this.playerEmojiSelected) {
        this.emojiActionsMultiplayer?.forEach((emoji) => {
          emoji?.emojiDialogCheck?.setVisible(true);
        });
        console.log('correct player');
        this.playEmojiAnims();
        if (!this.multiplayerEmojiPlayerPost) {
          this.emojiPostAction();
        }
      }
      this.playerAnswerEmojiGroupMessage();
    }
  }

  npcRandomEmoji() {
    this.emojiActions?.forEach((emoji) => {
      emoji.emojiAction.destroy(true);
      emoji.emojiDialog.destroy(true);
      emoji.emojiDialogCheck.destroy(true);
    });

    this.emojiActions = [];

    let npcFound = false;
    this.npcFoundData = [];

    this.npcTexturePlayers.forEach((npc) => {

      const diffFromX = Math.abs(this.myAvatar.data.values.fromX - npc.data.values.fromX);
      const diffFromY = Math.abs(this.myAvatar.data.values.fromY - npc.data.values.fromY);

      if (diffFromX < 10  && diffFromY < 10) {
        npcFound = true;

        const randomEmoji = Math.random() > 0.5;

        if (randomEmoji && npc.data.values.normalUser !== 1) {
          this.npcFoundData.push(npc.data.values);
          const emojiDialog = this.add.sprite(
            npc.x,
            npc.y - npc.displayHeight / 1.5,
            'emoji-white-dialog'
          )
            .setDepth(npc.depth + 1)
            .setScale(this.setAssetScale())
            .setData('countryEmoji', npc.data.values.country);

          const emojiDialogCheck = this.add.sprite(
            emojiDialog.x - emojiDialog.displayWidth / 2,
            emojiDialog.y - emojiDialog.displayHeight / 2,
            'emojiCheck'
          )
            .setDepth(emojiDialog.depth + 2)
            .setScale(this.setAssetScale())
            .setData('countryEmoji', npc.data.values.country);

          const emojiAction = this.add.sprite(
            npc.x,
            npc.y - npc.displayHeight / 1.5,
            this.playerEmojiSelected
          )
            .setDepth(npc.depth + 2)
            .setScale(this.setAssetScale())
            .setData('countryEmoji', npc.data.values.country);

          this.emojiActions.push({emojiAction, emojiDialog, emojiDialogCheck});
        }
      }
    });

    if(npcFound) {
      if (this.emojiActions.length === 0 && this.realPlayersEmoji.length === 0) {
        console.log('No emoji found');
        this.changeAllEmojiVisibility(false);
        this.emojiLabelSmallDialog?.setVisible(false);
        this.showDistancePopUp(`${context.translateService.instant(
          'break_task_emoji_no_player_response'
        )}`);
        setTimeout(() => {
          this.resetEmojiTexture();
        }, 1500);
      } else if (this.emojiActions.length > 0) {
        console.log('Emoji found');
        this.playEmojiAnims();
        this.emojiPostAction();
      }
    } else {
      console.log('No player found');
      this.changeAllEmojiVisibility(false);
      this.emojiLabelSmallDialog?.setVisible(false);
      this.showDistancePopUp(`${context.translateService.instant(
        'break_task_emoji_not_enough_players_around_you_message'
      )}`);
      setTimeout(() => {
        this.resetEmojiTexture();
      }, 1500);
    }
  }

  playEmojiAnims() {
    switch (this.playerEmojiSelected) {
      case 'blink-emoji':
        this.npcFoundData?.forEach((el) => {
          this.faceAnimationsBlink
            .find((it) => it.data.values.countryCode === el.country)
            .play(`faceBlink${el.faceIndex}`);
          });

        this.faceAnimationsBlinkMyAvatar.play(`faceBlink${this.myAvatar.data.values.faceIndex}`);

        this.realPlayers.forEach((rp)=> {
            this.faceAnimationsBlink
            .find((it) => it.data.values.countryCode === rp?.country)?.play(`faceBlink${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === rp?.country)?.data.values.faceIndex}`);
          });

        this.emojiActionsMultiplayer?.forEach((emoji) => {
          this.faceAnimationsBlink
            .find((it) => it.data.values.countryCode === emoji.emojiAction.data.values.countryEmoji)
            ?.play(`faceBlink${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === emoji.emojiAction.data.values.countryEmoji)?.data.values.faceIndex}`);
        });
        break;
      case 'love-emoji':
        this.npcFoundData?.forEach((el) => {
          this.faceAnimationsLove
            .find((it) => it.data.values.countryCode === el.country)
            .play(`faceLove${el.faceIndex}`);
          });

        this.faceAnimationsLoveMyAvatar.play(`faceLove${this.myAvatar.data.values.faceIndex}`);

        this.realPlayers.forEach((rp)=> {
          this.faceAnimationsLove
          .find((it) => it.data.values.countryCode === rp?.country)?.play(`faceLove${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === rp?.country)?.data.values.faceIndex}`);
        });

        this.emojiActionsMultiplayer?.forEach((emoji) => {
            this.faceAnimationsLove
            .find((it) => it.data.values.countryCode === emoji.emojiAction.data.values.countryEmoji)
            ?.play(`faceLove${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === emoji.emojiAction.data.values.countryEmoji)?.data.values.faceIndex}`);
        });
        break;
      case 'laugh-emoji':
        this.npcFoundData?.forEach((el) => {
          this.faceAnimationsLaugh
            .find((it) => it.data.values.countryCode === el.country)
            .play(`faceLaugh${el.faceIndex}`);
          });

        this.faceAnimationsLaughMyAvatar.play(`faceLaugh${this.myAvatar.data.values.faceIndex}`);

        this.realPlayers.forEach((rp)=> {
          this.faceAnimationsLaugh
          .find((it) => it.data.values.countryCode === rp?.country)?.play(`faceLaugh${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === rp?.country)?.data.values.faceIndex}`);
        });

        this.emojiActionsMultiplayer?.forEach((emoji) => {
            this.faceAnimationsLaugh
            .find((it) => it.data.values.countryCode === emoji.emojiAction.data.values.countryEmoji)
            ?.play(`faceLaugh${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === emoji.emojiAction.data.values.countryEmoji)?.data.values.faceIndex
            }`);
        });
        break;
      case 'sweat-emoji':
        this.npcFoundData?.forEach((el) => {
          this.faceAnimationsSweat
            .find((it) => it.data.values.countryCode === el.country)
            .play(`faceSweat${el.faceIndex}`);
          });

        this.faceAnimationsSweatMyAvatar.play(`faceSweat${this.myAvatar.data.values.faceIndex}`);

        this.realPlayers.forEach((rp)=> {
          this.faceAnimationsSweat
          .find((it) => it.data.values.countryCode === rp?.country)?.play(`faceSweat${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === rp?.country)?.data.values.faceIndex}`);
        });

        this.emojiActionsMultiplayer?.forEach((emoji) => {
            this.faceAnimationsSweat
            .find((it) => it.data.values.countryCode === emoji.emojiAction.data.values.countryEmoji)
            ?.play(`faceSweat${this.npcTexturePlayers
            ?.find((it)=> it.data.values.country === emoji.emojiAction.data.values.countryEmoji)?.data.values.faceIndex}`);
        });
        break;
    }
  }

  emojiPostAction() {
    context.gameService
      .breakGame(GameName.emoji)
      .pipe(
        catchError((err) => {
          context.gameService.updateBreakError(err.error.message);

          // context.timesPlayedPerBreakEmoji.next(0);

          this.multiplayerEmojiPlayerPost = false;

          this.breakErrorSubscription = context.gameQuery
            .select('breakError')
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
              this.errorPopUpDisplay(response);
            });
          setTimeout(() => {
            this.resetEmojiTexture();
          }, 1500);
          throw err;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && value.status === 'Success') {
          context.gameStore.update({ game: value.data.items[0] });

          this.multiplayerEmojiPlayerPost = true;

          context.timesPlayedPerBreakEmoji.next(
            context.timesPlayedPerBreakEmoji.getValue() + 1);

          this.showDistancePopUp(`${context.translateService.instant(
            'break_task_emoji_success_text'
          )}`);

          console.log('EMOJI RECORDED ON SERVER');

          setTimeout(() => {
            this.resetEmojiTexture();
          }, 1500);
        }
      });
  }

  spotTheCountryPostAction() {
    context.gameService
      .breakGame(GameName.spotTheCountry)
      .pipe(
        catchError((err) => {
          context.gameService.updateBreakError(err.error.message);
          context.gameService.updateSpotTheCountryGameResult('fail');
          context.timesPlayedPerBreakSpotTheCountry.next(
            context.timesPlayedPerBreakSpotTheCountry.getValue() + 1
          );

          this.breakErrorSubscription = context.gameQuery
            .select('breakError')
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
              this.errorPopUpDisplay(response);
            });
          setTimeout(() => {
            context.gameService.updateBreakGamePlaying(null);
          }, 3000);
          this.countdownSubscription.unsubscribe();
          throw err;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && value.status === 'Success') {
          context.gameStore.update({ game: value.data.items[0] });
          context.timesPlayedPerBreakSpotTheCountry.next(
            context.timesPlayedPerBreakSpotTheCountry.getValue() + 1
          );
          if (Object.keys(context.gameQuery.getSpotTheCountriesFound()).length >= 3) {
            context.gameService.updateSpotTheCountryGameResult('success');
            this.spotTheCountryMyAvatarLabel.setVisible(false);
            this.spotTheCountryMyAvatarLabelText.setVisible(false);
            this.boltWithoutAnim
              .play('boltWithout')
              .setPosition(this.myAvatar.x, this.myAvatar.y - 80)
              .setScale(0.3)
              .setDepth(this.myAvatar.depth + 100);
          } else {
            context.gameService.updateSpotTheCountryGameResult('fail');
          }
          context.gameService.updateSpotCountryCountdown(false);
        }
        setTimeout(() => {
          context.gameService.updateBreakGamePlaying(null);
        }, 3000);
        this.countdownSubscription.unsubscribe();
      });
  }

  errorPopUpDisplay(response: string) {
    this.errorPopUpText?.destroy(true);
    this.errorPopUp?.destroy(true);
    if (this.breakError) {
      this.errorPopUp = this.add
        .sprite(window.innerWidth / 2, this.myAvatar.y, 'errorPopUp')
        .setScale(
          this.game.scale.width / this.actualImageWidth,
          this.game.scale.width / this.actualImageWidth
        )
        .setDepth(3000)
        .setOrigin(0.5, 0.5);

      this.errorPopUpText = this.add
        .text(this.errorPopUp.x, this.errorPopUp.y, response)
        .setDepth(this.errorPopUp.depth + 1)
        .setOrigin(0.5, 0.5)
        .setAlign('center')
        .setFontSize(20)
        .setFontFamily(context.gameFont)
        .setPadding(20, 60, 20, 60)
        .setWordWrapWidth(400)
        .setStyle({
          color: '#003399',
        });

      this.errorPopUp.setDisplaySize(
        this.errorPopUpText.width,
        this.errorPopUpText.height
      );

      timer(4000).pipe(takeUntil(this.destroy$)).subscribe(
        (time) => (
          this.errorPopUp.destroy(true), this.errorPopUpText.destroy(true)
        )
      );
    }
  }

  setGrid() {
    const height = this.game.scale.width * 1.13;
    const width = this.game.scale.width;

    const grid = [];
    for (let y = 0; y < height; y++) {
      const col = [];
      for (let x = 0; x < width; x++) {
        // In each cell we store the ID of the tile, which corresponds
        // to its index in the tileset of the map ("ID" field in Tiled)
        const tile = this.tileLayer.getTileAt(x, y);
        if (tile && tile.index) {
          col.push(tile.index);
        }
      }
      grid.push(col);
    }
    // Game.finder.setGrid(grid);
    // console.log('grid: ', grid);
    this.easystar.setGrid(grid);
    this.easystar.enableDiagonals();
    this.easystar.setAcceptableTiles([2]);
    this.easystar.enableCornerCutting();
  }

  setTopCollisionTiles(tiles: number[]) {
    const height = this.game.scale.width * 1.13;
    const width = this.game.scale.width;

    const tileSet = new Set(tiles);

    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const tile = this.tileLayer.getTileAt(x, y);

        if (tile && tileSet.has(tile.index)) {
          tile.setCollision(true, true, true, true);
        }
      }
    }
  }

  initColliders() {
    this.setGrid();
    this.map.setCollision([1]);
    this.setTopCollisionTiles([1]);
    // this.setTopCollisionTiles(this.tileLayer.layer.data);

    this.physics.add.collider(this.myAvatar, this.tileLayer);
    // this.physics.add.collider(this.player, this.layer);
    // this.physics.add.collider(this.enemies, this.layer);
    // this.physics.add.overlap(this.player, this.items, this.pickItem, null, this);
    // this.physics.add.overlap(this.player, this.enemies, this.checkAgainstEnemies, null, this);
  }

  findPath(fromX: number, fromY: number, toX: number, toY: number) {
    this.easystar.findPath(fromX, fromY, toX, toY, (path: any) => {
      if (path === null) {
        console.log('------------------------------------------------------------------');
        console.log('myAvatar: ', this.myAvatar.data.values);
        console.log('this.tileLayer.getTileAt(fromX, fromY).index: ', this.tileLayer.getTileAt(fromX, fromY).index);
        console.log('this.tileLayer.getTileAt(fromX + 1, fromY + 1).index: ', this.tileLayer.getTileAt(fromX + 1, fromY + 1).index);
        console.log('this.tileLayer.getTileAt(fromX + 1, fromY + 0).index: ', this.tileLayer.getTileAt(fromX + 1, fromY).index);
        console.log('this.tileLayer.getTileAt(fromX + 0, fromY + 1).index: ', this.tileLayer.getTileAt(fromX, fromY + 1).index);
        console.log('this.tileLayer.getTileAt(fromX, fromY): ', this.tileLayer.getTileAt(fromX, fromY));
        console.log('this.tileLayer.getTileAt(toX, toY).index: ', this.tileLayer.getTileAt(toX, toY).index);
        console.log('this.tileLayer.getTileAt(toX + 1, toY + 1).index: ', this.tileLayer.getTileAt(toX + 1, toY + 1).index);
        console.log('this.tileLayer.getTileAt(toX + 1, toY + 0).index: ', this.tileLayer.getTileAt(toX + 1, toY).index);
        console.log('this.tileLayer.getTileAt(toX + 0, toY + 1).index: ', this.tileLayer.getTileAt(toX, toY + 1).index);
        console.log('this.tileLayer.getTileAt(toX, toY): ', this.tileLayer.getTileAt(toX, toY));
        console.log('fromX', fromX);
        console.log('fromY', fromY);
        console.log('toX', toX);
        console.log('toY', toY);
        console.log('Path was not found.');
      } else {
        // alert('Path was found. The first Point is ' + path[0].x + ' ' + path[0].y);
        this.movePlayerByPath(path);
      }
    });

    this.easystar.calculate();
  }

  drinkCoffee() {
    this.drinkCoffeeTween = this.tweens?.add({
      targets: this.myAvatar,
      duration: 2000,
      ease: 'Linear',
      onComplete: () => {
        context.timesPlayedPerBreakDrinkCoffee.next(
          context.timesPlayedPerBreakDrinkCoffee.getValue() + 1
        );
        context.gameService.updateBreakGamePlaying(null);
        this.armsCoffeeAnimationMyAvatar
          .play(
            `armsCoffee${this.myAvatar.data.values.bodyIndex}${this.myAvatar.data.values.skinToneIndex}`
          )
          .setPosition(this.myAvatar.x, this.myAvatar.y)
          .setDepth(this.myAvatar.depth + 100);
        this.faceCoffeeAnimationMyAvatar
          .play(`faceCoffee${this.myAvatar.data.values.faceIndex}`)
          .setPosition(this.myAvatar.x, this.myAvatar.y)
          .setDepth(this.myAvatar.depth + 100);

        this.boltWithBarAnim
          .play('boltWithBar')
          .setPosition(
            this.myAvatar.x,
            this.myAvatar.y - this.myAvatar.displayHeight
          )
          .setScale(0.3)
          .setDepth(this.myAvatar.depth + 100);
      },
    });
  }

  movePlayerByPath(path: any) {
    if (
      this.myPlayerMovement &&
      this.myPlayerMovement.isPlaying() &&
      this.myPlayerMovement
    ) {
      return;
    }

    const tweens = [];
    for (let i = 2; i < path.length - 1; i++) {
      const ex = path[i + 1].x;
      const ey = path[i + 1].y;

      tweens.push({
        targets: this.myAvatar,
        x: { value: ex * this.gameTileSize, duration: 200 },
        y: { value: ey * this.gameTileSize - 30, duration: 200 },
        duration: i === 1 ? 1000 : 300,
      });
    }
    this.myPlayerMovement = this.tweens.timeline({
      tweens,
      onComplete: () => {
        this.myAvatar.setData({
          fromX: this.myAvatar.data.values.toX,
          fromY: this.myAvatar.data.values.toY,
        });
      },
    });
  }

  checkDistanceFromMyAvatar(
    xTo,
    yTo,
    xFrom = this.myAvatar.x,
    yFrom = this.myAvatar.y
  ) {
    return +Phaser.Math.Distance.Between(xFrom, yFrom, xTo, yTo + 100).toFixed(
      2
    );
  }

  destroy() {
    clearInterval(this.npcInternvalMovement);
    clearInterval(this.triggerTimer);
  }

  randomPosition(user: IUserGame, index: number) {
    this.allAvatars[user.country] = {
      country: user.country,
      userGameNegotiation: this.usersData.find(
        (us) => us.country === user.country
      ).user.userGameNegotiation[0],
      countryName: this.countries.find(
        (cntr) => cntr.countryCode === user.country
      ).name,
      userId: user.userId,
      tile: {
        x: null,
        y: null,
        pixelX: null,
        pixelY: null,
        fromX: null,
        fromY: null,
        toX: null,
        toY: null,
      },
      normalUser: user.user.normalUser,
      skinToneIndex:
        user.user.avatar_SkinTone === 0
          ? 'A'
          : user.user.avatar_SkinTone === 1
          ? 'C'
          : user.user.avatar_SkinTone === 2
          ? 'D'
          : null,
      faceIndex: user.user.avatar_Face + 1,
      bodyIndex: user.user.avatar_Body + 1,
    };
  }

  randomNpcPosition(user: IUserGame, index: number) {
    let tile;

    do {
      const x = Math.floor(
        Phaser.Math.Between(0, this.game.scale.width) / this.gameTileSize
      );
      const y = Math.floor(
        Phaser.Math.Between(0, this.game.scale.width * 1.13) / this.gameTileSize
      );

      tile = this.tileLayer.getTileAt(x, y);
    } while (tile?.index !== 2);

    const fromX = Math.floor(tile.x / this.gameTileSize);
    const fromY = Math.floor(tile.y / this.gameTileSize);

    const myBilateral = user.user.bilateral?.find(
      (b) => b.bilateralStatus === BilateralHistoryStatus.started
    );

    this.allAvatars[user.country] = {
      country: user.country,
      userGameNegotiation: this.usersData.find(
        (us) => us.country === user.country
      ).user.userGameNegotiation[0],
      userId: user.userId,
      countryName: this.countries.find(
        (cntr) => cntr.countryCode === user.country
      ).name,
      tile: { x: tile.x, y: tile.y, fromX, fromY, pixelX: tile.pixelX, pixelY: tile.pixelY },
      normalUser:
        this.realPlayers.find((p) => p.country === user.country)?.user
          .normalUser === 1
          ? 1
          : 0,
      hasBilateralOpen: myBilateral !== undefined,
      skinToneIndex:
        user.user.avatar_SkinTone === 0
          ? 'A'
          : user.user.avatar_SkinTone === 1
          ? 'C'
          : user.user.avatar_SkinTone === 2
          ? 'D'
          : null,
      faceIndex: user.user.avatar_Face + 1,
      bodyIndex: user.user.avatar_Body + 1,
    };
  }

  movePlayer() {
    this.npcTexturePlayers.forEach((avatar: any) => {
      avatar.setDepth(this.positionHeight(avatar.y));
    });
    this.myAvatar.setDepth(this.positionHeight(this.myAvatar.y));
  }

  positionWidth(width: number) {
    const widthPercentage = width / this.actualImageWidth;
    return widthPercentage * this.game.scale.width;
  }

  positionHeight(height: number) {
    const heightPercentage = height / this.actualImageHeight;
    return heightPercentage * this.game.scale.width * 1.13;
  }

  positionHeightDepth(height: number) {
    const scaleRatio = this.game.scale.width / this.actualImageWidth;
    const heightPercentage = height / this.actualImageHeight;
    return heightPercentage * this.game.scale.width * 1.13 * scaleRatio;
  }

  initFurniture() {
    const scaleRatio = this.game.scale.width / this.actualImageWidth;

    this.topLamp = this.add.sprite(
      this.positionWidth(290),
      this.positionHeight(0),
      'furniture',
      'top_lamp'
    );
    this.topLamp.setScale(scaleRatio);
    this.topLamp.setDepth(this.positionHeightDepth(2000));
    this.topLamp.setOrigin(0, 0);

    this.vase = this.add.sprite(
      this.positionWidth(447),
      this.positionHeight(1070),
      'furniture',
      'vase'
    );
    this.vase.setScale(scaleRatio);
    this.vase.setDepth(this.positionHeightDepth(1369));
    this.vase.setOrigin(0, 0);

    this.chair1 = this.add.sprite(
      this.positionWidth(420),
      this.positionHeight(80),
      'furniture',
      'chair-right-yellow'
    );
    this.chair1.setScale(scaleRatio);
    this.chair1.setDepth(this.positionHeightDepth(145));
    this.chair1.setOrigin(0, 0);

    this.chair2 = this.add.sprite(
      this.positionWidth(150),
      this.positionHeight(220),
      'furniture',
      'chair-back-blue'
    );
    this.chair2.setScale(scaleRatio);
    this.chair2.setDepth(this.positionHeightDepth(270));
    this.chair2.setOrigin(0, 0);

    this.tableSmall = this.add.sprite(
      this.positionWidth(190),
      this.positionHeight(344),
      'furniture',
      'small-table'
    );
    this.tableSmall.setScale(scaleRatio);
    this.tableSmall.setDepth(this.positionHeightDepth(400));
    this.tableSmall.setOrigin(0, 0);

    this.table1 = this.add.sprite(
      this.positionWidth(285),
      this.positionHeight(170),
      'furniture',
      'table-big'
    );
    this.table1.setScale(scaleRatio);
    this.table1.setDepth(this.positionHeightDepth(170));
    this.table1.setOrigin(0, 0);

    this.phoneBooth = this.add.sprite(
      this.positionWidth(900),
      this.positionHeight(250),
      'furniture',
      'phone-booth'
    );
    this.phoneBooth.setScale(scaleRatio);
    this.phoneBooth.setDepth(80);
    this.phoneBooth.setOrigin(0, 0);

    this.kiosk = this.add.sprite(
      this.positionWidth(385),
      this.positionHeight(515),
      'furniture',
      'kiosk'
    );
    this.kiosk.setScale(scaleRatio);
    this.kiosk.setDepth(this.positionHeightDepth(830));
    this.kiosk.setOrigin(0, 0);

    this.coffee3 = this.add
      .sprite(
        this.positionWidth(470),
        this.positionHeight(790),
        'furniture',
        'coffee'
      )
      .setScale(scaleRatio)
      .setDepth(this.positionHeightDepth(835))
      .setOrigin(0.5, 0.5).setData({coffee: true});

    this.coffee2 = this.add
      .sprite(
        this.positionWidth(535),
        this.positionHeight(830),
        'furniture',
        'coffee'
      )
      .setScale(scaleRatio)
      .setDepth(this.positionHeightDepth(832))
      .setOrigin(0.5, 0.5).setData({coffee: true});

    this.coffee1 = this.add
      .sprite(
        this.positionWidth(640),
        this.positionHeight(830),
        'furniture',
        'coffee'
      )
      .setScale(scaleRatio)
      .setDepth(this.positionHeightDepth(835))
      .setOrigin(0.5, 0.5).setData({coffee: true});

    this.coffees = [this.coffee1, this.coffee2, this.coffee3];

    this.checkCoffeesVisibility();

    this.chair3 = this.add.sprite(
      this.positionWidth(1080),
      this.positionHeight(470),
      'furniture',
      'chair-right-green'
    );
    this.chair3.setScale(scaleRatio);
    this.chair3.setDepth(80);
    this.chair3.setOrigin(0, 0);

    this.chair4 = this.add.sprite(
      this.positionWidth(880),
      this.positionHeight(490),
      'furniture',
      'chair-top-yellow'
    );
    this.chair4.setScale(scaleRatio);
    this.chair4.setDepth(this.positionHeightDepth(550));
    this.chair4.setOrigin(0, 0);

    this.table2 = this.add.sprite(
      this.positionWidth(1000),
      this.positionHeight(590),
      'furniture',
      'table-yellow-paper'
    );
    this.table2.setScale(scaleRatio);
    this.table2.setDepth(this.positionHeightDepth(615));
    this.table2.setOrigin(0, 0);

    this.table3 = this.add.sprite(
      this.positionWidth(260),
      this.positionHeight(600),
      'furniture',
      'small-table-book'
    );
    this.table3.setScale(scaleRatio);
    this.table3.setDepth(225);
    this.table3.setOrigin(0, 0);

    this.chair5 = this.add.sprite(
      this.positionWidth(1157),
      this.positionHeight(1100),
      'furniture',
      'chair-right-red'
    );
    this.chair5.setScale(scaleRatio);
    this.chair5.setDepth(this.positionHeightDepth(1147));
    this.chair5.setOrigin(0, 0);

    this.chair6 = this.add.sprite(
      this.positionWidth(940),
      this.positionHeight(1100),
      'furniture',
      'chair-left-green'
    );
    this.chair6.setScale(scaleRatio);
    this.chair6.setDepth(this.positionHeightDepth(1147));
    this.chair6.setOrigin(0, 0);

    this.chair7 = this.add.sprite(
      this.positionWidth(910),
      this.positionHeight(1230),
      'furniture',
      'chair-back-blue'
    );
    this.chair7.setScale(scaleRatio);
    this.chair7.setDepth(this.positionHeightDepth(1276));
    this.chair7.setOrigin(0, 0);

    this.table4 = this.add.sprite(
      this.positionWidth(1040),
      this.positionHeight(1185),
      'furniture',
      'table-paper'
    );
    this.table4.setScale(scaleRatio);
    this.table4.setDepth(this.positionHeightDepth(1211));
    this.table4.setOrigin(0, 0);

    this.chair8 = this.add.sprite(
      this.positionWidth(510),
      this.positionHeight(1270),
      'furniture',
      'chair-right-dark'
    );
    this.chair8.setScale(scaleRatio);
    this.chair8.setDepth(80);
    this.chair8.setOrigin(0, 0);

    this.downLamp = this.add.sprite(
      this.positionWidth(285),
      this.positionHeight(1095),
      'furniture',
      'down_lamp'
    );
    this.downLamp.setScale(scaleRatio);
    this.downLamp.setDepth(this.positionHeightDepth(2000));
    this.downLamp.setOrigin(0, 0);

    this.table5 = this.add.sprite(
      this.positionWidth(427),
      this.positionHeight(1370),
      'furniture',
      'table-smartphone'
    );
    this.table5.setScale(scaleRatio);
    this.table5.setDepth(this.positionHeightDepth(1369));
    this.table5.setOrigin(0, 0);

    this.chair9 = this.add.sprite(
      this.positionWidth(240),
      this.positionHeight(1380),
      'furniture',
      'chair-back-grey'
    );
    this.chair9.setScale(scaleRatio);
    this.chair9.setDepth(this.positionHeightDepth(1410));
    this.chair9.setOrigin(0, 0);

    this.books = this.add.sprite(
      this.positionWidth(525),
      this.positionHeight(1420),
      'furniture',
      'books'
    );
    this.books.setScale(scaleRatio);
    this.books.setDepth(this.positionHeightDepth(1410));
    this.books.setOrigin(0, 0);
  }

  updateFurniturePosition() {
    const scaleRatio = this.game.scale.width / this.actualImageWidth;

    this.topLamp.setPosition(
      this.positionWidth(290),
      this.positionHeight(0),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(2000));

    this.vase.setPosition(
      this.positionWidth(447),
      this.positionHeight(1070),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1369));

    this.chair1.setPosition(
      this.positionWidth(420),
      this.positionHeight(80),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(145));

    this.chair2.setPosition(
      this.positionWidth(150),
      this.positionHeight(220),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(270));

    this.tableSmall.setPosition(
      this.positionWidth(190),
      this.positionHeight(344),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(400));

    this.table1.setPosition(
      this.positionWidth(285),
      this.positionHeight(170),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(170));

    this.phoneBooth.setPosition(
      this.positionWidth(900),
      this.positionHeight(250),
    )
    .setScale(scaleRatio)
    .setDepth(80);

    this.kiosk.setPosition(
      this.positionWidth(385),
      this.positionHeight(515),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(830));

    this.coffee3.setPosition(
        this.positionWidth(470),
        this.positionHeight(790),
      )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(835));

    this.coffee2.setPosition(
        this.positionWidth(535),
        this.positionHeight(830),
      )
      .setScale(scaleRatio)
      .setDepth(this.positionHeightDepth(832));

    this.coffee1.setPosition(
        this.positionWidth(640),
        this.positionHeight(830),
      )
      .setScale(scaleRatio)
      .setDepth(this.positionHeightDepth(835));


    this.chair3.setPosition(
      this.positionWidth(1080),
      this.positionHeight(470),
    )
    .setScale(scaleRatio)
    .setDepth(80);

    this.chair4.setPosition(
      this.positionWidth(880),
      this.positionHeight(490),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(550));

    this.table2.setPosition(
      this.positionWidth(1000),
      this.positionHeight(590),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(615));

    this.table3.setPosition(
      this.positionWidth(260),
      this.positionHeight(600),
    )
    .setScale(scaleRatio)
    .setDepth(225);

    this.chair5.setPosition(
      this.positionWidth(1157),
      this.positionHeight(1100),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1147));

    this.chair6.setPosition(
      this.positionWidth(940),
      this.positionHeight(1100),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1147));

    this.chair7.setPosition(
      this.positionWidth(910),
      this.positionHeight(1230),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1276));

    this.table4.setPosition(
      this.positionWidth(1040),
      this.positionHeight(1185),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1211));

    this.chair8.setPosition(
      this.positionWidth(510),
      this.positionHeight(1270),
    )
    .setScale(scaleRatio)
    .setDepth(80);

    this.downLamp.setPosition(
      this.positionWidth(285),
      this.positionHeight(1095),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(2000));

    this.table5.setPosition(
      this.positionWidth(427),
      this.positionHeight(1370),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1369));

    this.chair9.setPosition(
      this.positionWidth(240),
      this.positionHeight(1380),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1410));

    this.books.setPosition(
      this.positionWidth(525),
      this.positionHeight(1420),
    )
    .setScale(scaleRatio)
    .setDepth(this.positionHeightDepth(1410));
  }

  checkCoffeesVisibility() {
    this.coffees.forEach((coffee) => coffee.setVisible(true));

    switch (this.coffeesAvailable) {
      case 0:
        this.coffee1?.setVisible(false);
        this.coffee2?.setVisible(false);
        this.coffee3?.setVisible(false);
        break;
      case 1:
        this.coffee2?.setVisible(false);
        this.coffee3?.setVisible(false);
        break;
      case 2:
        this.coffee1?.setVisible(false);
        break;
      case 3:
        this.coffees.forEach((coffee) => coffee.setVisible(true));
        break;
    }
  }

  addInteractive(gameObject: Phaser.GameObjects.Sprite) {
    gameObject.disableInteractive();
    gameObject.setInteractive({ useHandCursor: true });

    gameObject.on('pointerdown', (e) => {
      if (!this.scene.isVisible()) {
        return;
      }
      this.coffeeDistance = this.checkDistanceFromMyAvatar(
        this.coffee2?.x,
        this.coffee2?.y
      );
      if (this.breakGamePlaying === BreakGames.spotTheCountry) {
        this.playSpotTheCountry(gameObject);
      } else if (this.breakGamePlaying === BreakGames.drinkACoffee) {
        if (gameObject.data.values.coffee) {
          if (this.coffeeDistance > 350) {
              this.showDistancePopUp(`${context.translateService.instant('break_tasks_drink_a_coffee_too_far')}`);
          } else {
            this.click$?.pipe(debounceTime(1000), takeUntil(this.destroy$))
              ?.subscribe((res) => {
                if (res) {
                  this.tryToDrinkCoffee();
                }
            });
            this.click$.next(e);
            this.loaderAnim?.play('loaderAnim');
          }
      }
      } else {
        if (!gameObject.data.values.coffee) {
          this.bilateralPopUp(gameObject);
        }
        return;
      }
    });
  }

  checkIfBilateralPopUpExists() {
    if (
      this.avatarPopUpBreak &&
      this.negotiateButtonBreak &&
      this.peopleIconBreak &&
      this.popUpCountryTextBreak &&
      this.popUpPopulationTextBreak
    ) {
      return true;
    }
  }

  bilateralPopUp(gameObject: any) {
    this.avatarPopUpGroupBreak?.children?.each((ch: Phaser.GameObjects.Sprite) => ch?.destroy(true));

    const requestedUserGame = this.usersData.find(
      (ug) => ug.userId === gameObject.data.values.userId
    );

    const myUserGame = this.usersData.find(
      (ug) => ug.country === this.userCountry
    );

    this.myBilateral = requestedUserGame.user.bilateral?.find(
      (b) => b?.bilateralStatus === BilateralHistoryStatus.started
    )?.bilateralStatus;

    this.myUserBilateral = myUserGame.user.bilateral?.find(
      (b) => b?.bilateralStatus === BilateralHistoryStatus.started
    )?.bilateralStatus;

    if (gameObject.data.values.country !== this.userCountry) {
      if (
        this.myBilateral !== BilateralHistoryStatus.started &&
        this.myUserBilateral !== BilateralHistoryStatus.started
      ) {
        this.avatarPopUpBreak = this.add
          .sprite(
            gameObject.x - 10,
            gameObject.y - gameObject.displayHeight / 1.7,
            'avatarPopupBreak'
          )
          .setDepth(gameObject.depth + 50000)
          .setScale(
            this.game.scale.width <= this.actualImageWidth
              ? (this.game.scale.width / this.actualImageWidth) * 0.76
              : (this.game.scale.width / this.actualImageWidth) * 0.64
          );
        this.negotiateButtonBreak = this.add
          .sprite(
            this.avatarPopUpBreak.x,
            this.avatarPopUpBreak.y + 20,
            'negotiateButtonBreak'
          )
          .setDepth(this.avatarPopUpBreak.depth + 1)
          .setInteractive({ useHandCursor: true })
          .setScale(
            this.game.scale.width <= this.actualImageWidth
              ? (this.game.scale.width / this.actualImageWidth) * 0.76
              : (this.game.scale.width / this.actualImageWidth) * 0.64
          )
          .on('pointerdown', () => {
            if (!this.scene.isVisible()) {
              return;
            }
            if (this.usersData
              .find((el) => el.userId === context.userQuery.getUserId()).actionPoint !== 0){
                context.startBilateral(gameObject.data.values.userId);
                setTimeout(() => {

                  this.avatarPopUpGroupBreak?.children?.each((ch: Phaser.GameObjects.Sprite) => ch?.destroy(true));
                }, 3000);
              } else {
                this.createWarningPopUp(PopUpTypeOfUse.noPoints);
              }

          });
        this.negotiateButtonTextBreak = this.add
          .text(
            this.negotiateButtonBreak.x,
            this.negotiateButtonBreak.y,
            `${context.translateService.instant(
              'plakat_global_negotiate_button'
            )}`
          )
          .setFontSize(16)
          .setScale(
            (this.game.scale.width <= this.actualImageWidth
              ? (this.game.scale.width / this.actualImageWidth) * 0.76
              : (this.game.scale.width / this.actualImageWidth) * 0.64)
          )
          .setOrigin(0, 0)
          .setFontFamily(context.gameFont)
          .setDepth(this.negotiateButtonBreak.depth + 1);

        this.peopleIconBreak = this.add
          .sprite(
            this.avatarPopUpBreak.x - 60,
            this.avatarPopUpBreak.y + 50,
            'peopleIconBreak'
          )
          .setDepth(this.avatarPopUpBreak.depth + 1)
          .setScale(
            this.game.scale.width <= this.actualImageWidth
              ? (this.game.scale.width / this.actualImageWidth) * 0.76
              : (this.game.scale.width / this.actualImageWidth) * 0.64
          );
        this.popUpCountryTextBreak = this.add
          .text(
            this.avatarPopUpBreak.x - 60,
            this.avatarPopUpBreak.y - 100,
            `${
              this.countries.find(
                (item) => item.countryCode === gameObject.data.values.country
              ).name
            }`
          )
          .setFontSize(24)
          .setFontFamily(context.gameFont)
          .setPadding(1, 15)
          .setDepth(this.avatarPopUpBreak.depth + 10)
          .setData({countryCode:  this.countries.find(
            (item) => item.countryCode === gameObject.data.values.country
          ).countryCode})
          .setScale(
            this.game.scale.width <= this.actualImageWidth
              ? (this.game.scale.width / this.actualImageWidth) * 0.76
              : (this.game.scale.width / this.actualImageWidth) * 0.64
          )
          .setStyle({
            color: '#003399',
            // line-height: 24px;
          });
        this.popUpPopulationTextBreak = this.add
          .text(
            this.avatarPopUpBreak.x - 30,
            this.avatarPopUpBreak.y - 45,
            `${context.settingsService.formatDecimalNumber(gameObject.data.values.weight)}% ${context.translateService.instant('dimension_overview_population')}`
          )
          .setFontSize(22)
          .setFontFamily(context.gameFont)
          .setPadding(10, 10)
          .setDepth(this.avatarPopUpBreak.depth + 10)
          .setScale(
            this.game.scale.width <= this.actualImageWidth
              ? (this.game.scale.width / this.actualImageWidth) * 0.76
              : (this.game.scale.width / this.actualImageWidth) * 0.64
          )
          .setStyle({
            color: '#003399',
            // line-height: 24px;
          });

        this.setPositionOnBilateralPopUp(gameObject);

        this.avatarPopUpGroupBreak = this.add.group();
        this.avatarPopUpGroupBreak.addMultiple([
          this.avatarPopUpBreak,
          this.negotiateButtonBreak,
          this.negotiateButtonTextBreak,
          this.peopleIconBreak,
          this.popUpCountryTextBreak,
          this.popUpPopulationTextBreak,
        ]);
        this.avatarPopUpGroupBreak?.children?.each((ch: Phaser.GameObjects.Sprite) => ch?.setVisible(true));

      } else {
        this.createWarningPopUp(PopUpTypeOfUse.bilateral, gameObject);
      }
    }
  }

  setPositionOnBilateralPopUp(gameObject: Phaser.GameObjects.Sprite) {
    this.avatarPopUpBreak?.setPosition(
      gameObject?.x - 10,
      gameObject?.y - gameObject?.displayHeight
    );
    this.negotiateButtonBreak.setPosition(
      this.avatarPopUpBreak.x,
      this.avatarPopUpBreak.y + this.avatarPopUpBreak.displayHeight / 4
    )
    .setDisplaySize((this.negotiateButtonTextBreak?.width) <= 100 ? (this.negotiateButtonTextBreak?.displayWidth * 2.5)
      : this.negotiateButtonTextBreak?.displayWidth * 1.8, this.negotiateButtonBreak.displayHeight);

    this.negotiateButtonTextBreak.setPosition(
      this.negotiateButtonBreak.x - this.negotiateButtonBreak.displayWidth / 2.5,
      this.negotiateButtonBreak.y - this.negotiateButtonBreak.displayHeight / 4,
    );

    this.avatarPopUpBreak?.setDisplaySize(this.negotiateButtonBreak.displayWidth * 1.3, this.avatarPopUpBreak.displayHeight);

    this.peopleIconBreak.setPosition(
      this.avatarPopUpBreak.x - this.avatarPopUpBreak.displayWidth / 2.7,
      this.avatarPopUpBreak.y - this.avatarPopUpBreak.displayHeight / 8.5
    );

    this.popUpCountryTextBreak
      .setPosition(
        this.avatarPopUpBreak.x,
        this.avatarPopUpBreak.y - this.avatarPopUpBreak.displayHeight / 1.8
      )
      .setOrigin(0.5, 0)
      .setText(
        `${
          this.countries.find(
            (item) => item.countryCode === gameObject.data.values.country
          ).name
        }`
      );
    this.popUpPopulationTextBreak
      .setPosition(
        this.peopleIconBreak.x + this.peopleIconBreak.displayWidth / 2,
        this.avatarPopUpBreak.y - this.avatarPopUpBreak.displayHeight / 4
      )
      .setText(`${context.settingsService.formatDecimalNumber(gameObject.data.values.weight)} % ${context.translateService.instant('dimension_overview_population')}`);
  }

  showDistancePopUp(message: string) {
    this.distancePopUp.setVisible(true);
    this.distancePopUp.setY(this.myAvatar.y).setDepth(this.positionHeightDepth(15000));
    this.distancePopUpText.setText(message);
    this.distancePopUp.setDisplaySize(
      this.distancePopUpText.width,
      this.distancePopUpText.height
    );
    this.distancePopUpText.setVisible(true);
    this.distancePopUpText.setY(this.myAvatar.y).setDepth(this.positionHeightDepth(15000));

    setInterval(() => {
      this.distancePopUp.setVisible(false);
      this.distancePopUpText.setVisible(false);
    }, 4500);
  }

  playSpotTheCountry(gameObject) {
    if (gameObject.data.values.coffee) {
      return;
    }
    this.myAvatarSpotTheCountryPopUp(gameObject);
    this.findCountryColor(gameObject);
  }

  findCountryColor(gameObject) {
    if(gameObject.data.values.coffee){
      return;
    }
    if (
      gameObject.data.values.color.includes(
        this.spotTheCountrySelectedGame.name
      )
    ) {
      const countriesFound = {
        ...context.gameQuery.getSpotTheCountriesFound(),
      };
      if (
        Object.values(countriesFound).includes(gameObject.data.values.country)
      ) {
        console.log('ALREADY FOUND THIS COUNTRY');
        return;
      }
      console.log(this.spotTheCountrySelectedGame);

      countriesFound[Object.keys(countriesFound).length + 1] =
        this.countries.find(
          (el) => el.countryCode === gameObject.data.values.country
        ).countryCode;
      console.log({ countriesFound });

      if (Object.keys(countriesFound).length > 3) {
        return;
      }

      context.gameService.updateSpotTheCountriesFound(countriesFound);
      this.npcSpotCountryStatusPopUp(gameObject, 'success');

      const spriteCountry = gameObject.data.values.country;
      const spriteBodyIndex = this.armsAnimationsWave.find(
        (el) => el.data.values.countryCode === spriteCountry
      ).data.values.bodyIndex;
      const spriteSkinTone = this.armsAnimationsWave.find(
        (el) => el.data.values.countryCode === spriteCountry
      ).data.values.skinToneIndex;

      this.armsAnimationsWave
        .find((el) => el.data.values.countryCode === spriteCountry)
        .play(`armsWave${spriteBodyIndex}${spriteSkinTone}`)
        .setPosition(
          this.armsAnimationsWave.find(
            (el) => el.data.values.countryCode === spriteCountry
          ).x,
          this.armsAnimationsWave.find(
            (el) => el.data.values.countryCode === spriteCountry
          ).y - 80
        )
        .setDepth(
          this.armsAnimationsWave.find(
            (el) => el.data.values.countryCode === spriteCountry
          ).depth + 100
        );

      this.faceAnimationsSmile
        .find((el) => el.data.values.countryCode === spriteCountry)
        .play(
          `faceSmile${
            this.faceAnimationsSmile.find(
              (el) => el.data.values.countryCode === spriteCountry
            ).data.values.faceIndex
          }`
        )
        .setPosition(
          this.faceAnimationsSmile.find(
            (el) => el.data.values.countryCode === spriteCountry
          ).x,
          this.faceAnimationsSmile.find(
            (el) => el.data.values.countryCode === spriteCountry
          ).y - 80
        )
        .setDepth(
          this.faceAnimationsSmile.find(
            (el) => el.data.values.countryCode === spriteCountry
          ).depth + 100
        );

      setTimeout(() => {
        this.armsAnimationsWaveMyAvatar
          .play(
            `armsWave${this.myAvatar.data.values.bodyIndex}${this.myAvatar.data.values.skinToneIndex}`
          )
          .setPosition(this.myAvatar.x, this.myAvatar.y - 80)
          .setDepth(this.myAvatar.depth + 100);
        this.faceAnimationsSmileMyAvatar
          .play(`faceSmile${this.myAvatar.data.values.faceIndex}`)
          .setPosition(this.myAvatar.x, this.myAvatar.y - 80)
          .setDepth(this.myAvatar.depth + 100);
      }, 500);
      if (Object.keys(countriesFound).length === 3) {
        this.spotTheCountryPostAction();
      }
    } else {
      this.npcSpotCountryStatusPopUp(gameObject, 'fail');
    }
  }
  npcSpotCountryStatusPopUp(sprite: Phaser.GameObjects.Sprite, status: string) {
    let text = '';

    if (status === 'success') {
      text = context.translateService.instant(
        'spot_the_country_hello');
    } else {
      text = context.translateService.instant(
        'spot_the_country_not_valid_country'
      );
    }

    if (
      !this.npcSpotTheCountryStatusLabel ||
      !this.npcSpotTheCountryStatusLabelText
    ) {
      this.npcSpotTheCountryStatusLabel = this.add
        .sprite(sprite.x, sprite.y - 120, 'talk-balloonSpotTheCountry')
        .setDepth(3000)
        .setOrigin(0.5, 0.5);

      this.npcSpotTheCountryStatusLabelText = this.add
        .text(
          this.npcSpotTheCountryStatusLabel.x,
          this.npcSpotTheCountryStatusLabel.y,
          text
        )
        .setDepth(this.npcSpotTheCountryStatusLabel.depth + 1)
        .setOrigin(0.5, 0.5)
        .setAlign('center')
        .setWordWrapWidth(280)
        .setFontSize(20)
        .setFontFamily(context.gameFont)
        .setPadding(20, 20, 20, 40)
        .setStyle({
          color: '#564547',
        });
    } else {
      this.npcSpotTheCountryStatusLabel.setVisible(true);
      this.npcSpotTheCountryStatusLabelText.setVisible(true);
      this.npcSpotTheCountryStatusLabel.setPosition(sprite.x, sprite.y - 100);
      this.npcSpotTheCountryStatusLabelText.setPosition(
        this.npcSpotTheCountryStatusLabel.x,
        this.npcSpotTheCountryStatusLabel.y
      );
      this.npcSpotTheCountryStatusLabelText.setText(text);
    }

    this.npcSpotTheCountryStatusLabel.setDisplaySize(
      this.npcSpotTheCountryStatusLabelText.width,
      this.npcSpotTheCountryStatusLabelText.height
    );

    setTimeout(() => {
      this.npcSpotTheCountryStatusLabel?.setVisible(false);
      this.npcSpotTheCountryStatusLabelText?.setVisible(false);
    }, 2500);
  }

  myAvatarSpotTheCountryPopUp(sprite) {
    if (
      !this.spotTheCountryMyAvatarLabel ||
      !this.spotTheCountryMyAvatarLabelText
    ) {
      this.spotTheCountryMyAvatarLabel = this.add
        .sprite(this.myAvatar.x, this.myAvatar.y - 120, 'spotTheCountryPopUp')
        .setDepth(3000)
        .setOrigin(0.5, 0.5);

      this.spotTheCountryMyAvatarLabelText = this.add
        .text(
          this.spotTheCountryMyAvatarLabel.x,
          this.spotTheCountryMyAvatarLabel.y,
          `${
            this.countries.find(
              (item) => item.countryCode === sprite.data.values.country
            ).name
          }`
        )
        .setDepth(this.spotTheCountryMyAvatarLabel.depth + 1)
        .setOrigin(0.5, 0.5)
        .setAlign('center')
        .setFontSize(20)
        .setFontFamily(context.gameFont)
        .setPadding(20, 20, 20, 40)
        .setStyle({
          color: '#FFD924',
        });
    } else {
      this.spotTheCountryMyAvatarLabel.setVisible(true);
      this.spotTheCountryMyAvatarLabelText.setVisible(true);
      this.spotTheCountryMyAvatarLabel.setPosition(
        this.myAvatar.x,
        this.myAvatar.y - 100
        );
        this.spotTheCountryMyAvatarLabelText.setPosition(
          this.spotTheCountryMyAvatarLabel.x,
          this.spotTheCountryMyAvatarLabel.y
          );
      this.spotTheCountryMyAvatarLabelText.setText(`${
        this.countries.find(
          (item) => item.countryCode === sprite.data.values.country
        ).name
      }`);
    }

    this.spotTheCountryMyAvatarLabel.setDisplaySize(
      this.spotTheCountryMyAvatarLabelText.width,
      this.spotTheCountryMyAvatarLabelText.height
    );
  }

  tryToDrinkCoffee() {
    this.breakGamePostActionSubscription?.unsubscribe();
    this.breakGamePostActionSubscription = context.gameService
      .breakGame(GameName.drinkACoffee)
      .pipe(
        catchError((err) => {
          context.gameService.updateBreakError(err.error.message);
          context.gameService.updateBreakGamePlaying(null);
          this.loaderAnim?.anims.complete();
          this.breakErrorSubscription = context.gameQuery
            .select('breakError')
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              this.breakError = res;
              if (
                this.isSinglePlayer &&
                this.breakError === DrinkCoffeeErrors.gamePlayLimitReached
              ) {
                this.breakError = context.translateService.instant(
                  'coffee_break_error1'
                );
              } else if (
                this.isSinglePlayer &&
                this.breakError === DrinkCoffeeErrors.noMoreCoffees
              ) {
                this.breakError = DrinkCoffeeErrors.noMoreCoffees;
              } else if (
                !this.isSinglePlayer &&
                this.breakError === DrinkCoffeeErrors.gamePlayLimitReached
              ) {
                this.breakError = context.translateService.instant(
                  'coffee_break_error2'
                );
              }
              this.errorPopUpDisplay(this.breakError);

              context.gameService.updateBreakGamePlaying(null);
            });
          this.checkCoffeesVisibility();
          context.gameService.updateBreakGamePlaying(null);
          throw err;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && value.status === 'Success') {
          this.loaderAnim?.anims.complete();
          context.gameStore.update({ game: value.data.items[0] });
          context.gameService.updateBreakGamePlaying(BreakGames.drinkACoffee);

          this.drinkCoffee();
          this.checkCoffeesVisibility();
        }
      });
  }

  init(data) {
    if (data.hidden) {
      this.scene.setVisible(false).sendToBack();
    }
  }

  loading() {
    context.loading.next(false);
  }

  public preload(): void {
    context.loading.next(true);
    // this.load.on('complete', this.loading);

    // const loadingText = this.add.text(500, 100, 'Loading...').setFontSize(24);

    this.load.image('tiles2', 'assets/coffee-break/tileset-2.png');
    this.load.tilemapTiledJSON('tilemap2', 'assets/coffee-break/mynew.json');
    this.load.svg('static-object', '/assets/coffee-break/statis-object.svg');
    this.load.image('balcony', '/assets/coffee-break/balcony.png');
    this.load.svg('avatarBreak', '../../../assets/d1/avatar10.svg');
    this.load.svg('endBreakButton', '/assets/yellowButton.svg', {scale: 0.7});

    this.load.svg(
      'expert_female_01.svg',
      `/assets/Avatar2/02_experts/expert_female_01.svg`
    );
    this.load.svg(
      'expert_female_02.svg',
      `/assets/Avatar2/02_experts/expert_female_02.svg`
    );
    this.load.svg(
      'expert_female_03.svg',
      `/assets/Avatar2/02_experts/expert_female_03.svg`
    );
    this.load.svg(
      'expert_male_01.svg',
      `/assets/Avatar2/02_experts/expert_male_01.svg`
    );
    this.load.svg(
      'expert_male_02.svg',
      `/assets/Avatar2/02_experts/expert_male_02.svg`
    );
    this.load.svg(
      'expert_male_03.svg',
      `/assets/Avatar2/02_experts/expert_male_03.svg`
    );

    this.load.image(
      'bg',
      '../../../assets/coffee-break/EU-councilGame-breaks-noObj-updated.jpg'
    );
    this.load.atlas(
      'furniture',
      'assets/coffee-break/furniture_v1.png',
      'assets/coffee-break/furniture_v1_atlas.json'
    );
    this.load.svg(
      'coffee',
      'assets/coffee-break/coffee.svg'
    );
    this.load.svg(
      'drinkCoffee',
      '../../../assets/coffee-break/drinkCoffee.svg'
    );
    this.load.svg('lastCoffee', '../../../assets/coffee-break/lastcoffee.svg');
    this.load.svg(
      'errorPopUp',
      '../../../assets/coffee-break/distancePopUp.svg'
    );
    this.load.svg(
      'talk-balloonSpotTheCountry',
      '../../../assets/coffee-break/talk-balloonSpotTheCountry.svg'
    );
    this.load.svg(
      'spotTheCountryPopUp',
      '../../../assets/coffee-break/spotTheCountryPopUp.svg'
    );
    this.load.svg(
      'myPlayerIndicatorBreak',
      '../../../assets/myPlayerIndicator.svg',
      {scale: 2.5}
    );

    this.load.atlas({
      key: 'boltWithout',
      textureURL:
        '../../../assets/coffee-break/animations/boltWithoutBar-0.png',
      atlasURL: '../../../assets/coffee-break/animations/boltWithoutBar.json',
    });
    this.load.atlas({
      key: 'boltWithBar',
      textureURL: '../../../assets/coffee-break/animations/boltWithBar-0.png',
      atlasURL: '../../../assets/coffee-break/animations/boltWithBar.json',
    });

    this.load.atlas({
      key: 'armsWave1A',
      textureURL: '../../../assets/coffee-break/animations/armsWave1A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave1A.json',
    });
    this.load.atlas({
      key: 'armsWave1C',
      textureURL: '../../../assets/coffee-break/animations/armsWave1C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave1C.json',
    });
    this.load.atlas({
      key: 'armsWave1D',
      textureURL: '../../../assets/coffee-break/animations/armsWave1D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave1D.json',
    });
    this.load.atlas({
      key: 'armsWave2A',
      textureURL: '../../../assets/coffee-break/animations/armsWave2A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave2A.json',
    });
    this.load.atlas({
      key: 'armsWave2C',
      textureURL: '../../../assets/coffee-break/animations/armsWave2C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave2C.json',
    });
    this.load.atlas({
      key: 'armsWave2D',
      textureURL: '../../../assets/coffee-break/animations/armsWave2D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave2D.json',
    });
    this.load.atlas({
      key: 'armsWave3A',
      textureURL: '../../../assets/coffee-break/animations/armsWave3A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave3A.json',
    });
    this.load.atlas({
      key: 'armsWave3C',
      textureURL: '../../../assets/coffee-break/animations/armsWave3C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave3C.json',
    });
    this.load.atlas({
      key: 'armsWave3D',
      textureURL: '../../../assets/coffee-break/animations/armsWave3D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave3D.json',
    });
    this.load.atlas({
      key: 'armsWave4A',
      textureURL: '../../../assets/coffee-break/animations/armsWave4A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave4A.json',
    });
    this.load.atlas({
      key: 'armsWave4C',
      textureURL: '../../../assets/coffee-break/animations/armsWave4C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave4C.json',
    });
    this.load.atlas({
      key: 'armsWave4D',
      textureURL: '../../../assets/coffee-break/animations/armsWave4D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave4D.json',
    });
    this.load.atlas({
      key: 'armsWave5A',
      textureURL: '../../../assets/coffee-break/animations/armsWave5A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave5A.json',
    });
    this.load.atlas({
      key: 'armsWave5C',
      textureURL: '../../../assets/coffee-break/animations/armsWave5C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave5C.json',
    });
    this.load.atlas({
      key: 'armsWave5D',
      textureURL: '../../../assets/coffee-break/animations/armsWave5D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave5D.json',
    });
    this.load.atlas({
      key: 'armsWave6A',
      textureURL: '../../../assets/coffee-break/animations/armsWave6A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave6A.json',
    });
    this.load.atlas({
      key: 'armsWave6C',
      textureURL: '../../../assets/coffee-break/animations/armsWave6C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave6C.json',
    });
    this.load.atlas({
      key: 'armsWave6D',
      textureURL: '../../../assets/coffee-break/animations/armsWave6D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave6D.json',
    });
    this.load.atlas({
      key: 'armsWave7A',
      textureURL: '../../../assets/coffee-break/animations/armsWave7A-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave7A.json',
    });
    this.load.atlas({
      key: 'armsWave7C',
      textureURL: '../../../assets/coffee-break/animations/armsWave7C-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave7C.json',
    });
    this.load.atlas({
      key: 'armsWave7D',
      textureURL: '../../../assets/coffee-break/animations/armsWave7D-0.png',
      atlasURL: '../../../assets/coffee-break/animations/armsWave7D.json',
    });
    this.load.atlas({
      key: 'faceSmile1',
      textureURL: '../../../assets/coffee-break/animations/faceSmile1-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile1.json',
    });
    this.load.atlas({
      key: 'faceSmile2',
      textureURL: '../../../assets/coffee-break/animations/faceSmile2-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile2.json',
    });
    this.load.atlas({
      key: 'faceSmile3',
      textureURL: '../../../assets/coffee-break/animations/faceSmile3-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile3.json',
    });
    this.load.atlas({
      key: 'faceSmile4',
      textureURL: '../../../assets/coffee-break/animations/faceSmile4-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile4.json',
    });
    this.load.atlas({
      key: 'faceSmile5',
      textureURL: '../../../assets/coffee-break/animations/faceSmile5-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile5.json',
    });
    this.load.atlas({
      key: 'faceSmile6',
      textureURL: '../../../assets/coffee-break/animations/faceSmile6-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile6.json',
    });
    this.load.atlas({
      key: 'faceSmile7',
      textureURL: '../../../assets/coffee-break/animations/faceSmile7-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSmile7.json',
    });
    this.load.atlas({
      key: 'altWalkingBreak',
      textureURL: '../../../assets/d1/animations-neg-phase/altWalking-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/altWalking.json',
    });

    this.load.svg('avatarPopupBreak', '../../../assets/avatarPopup.svg', {
      // scale: this.boardAnimScale,
    });
    this.load.svg('negotiateButtonBreak', '../../../assets/btnNegotiate.svg', {
      // scale: this.boardAnimScale,
    });
    this.load.svg('peopleIconBreak', '../../../assets/people.svg', {
      // scale: this.boardAnimScale,
    });
    this.load.atlas({
      key: 'armsCoffee1A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee1A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee1A.json',
    });
    this.load.atlas({
      key: 'armsCoffee1C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee1C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee1C.json',
    });
    this.load.atlas({
      key: 'armsCoffee1D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee1D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee1D.json',
    });
    this.load.atlas({
      key: 'armsCoffee2A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee2A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee2A.json',
    });
    this.load.atlas({
      key: 'armsCoffee2C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee2C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee2C.json',
    });
    this.load.atlas({
      key: 'armsCoffee2D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee2D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee2D.json',
    });
    this.load.atlas({
      key: 'armsCoffee3A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee3A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee3A.json',
    });
    this.load.atlas({
      key: 'armsCoffee3C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee3C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee3C.json',
    });
    this.load.atlas({
      key: 'armsCoffee3D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee3D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee3D.json',
    });
    this.load.atlas({
      key: 'armsCoffee4A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee4A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee4A.json',
    });
    this.load.atlas({
      key: 'armsCoffee4C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee4C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee4C.json',
    });
    this.load.atlas({
      key: 'armsCoffee4D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee4D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee4D.json',
    });
    this.load.atlas({
      key: 'armsCoffee5A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee5A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee5A.json',
    });
    this.load.atlas({
      key: 'armsCoffee5C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee5C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee5C.json',
    });
    this.load.atlas({
      key: 'armsCoffee5D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee5D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee5D.json',
    });
    this.load.atlas({
      key: 'armsCoffee6A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee6A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee6A.json',
    });
    this.load.atlas({
      key: 'armsCoffee6C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee6C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee6C.json',
    });
    this.load.atlas({
      key: 'armsCoffee6D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee6D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee6D.json',
    });
    this.load.atlas({
      key: 'armsCoffee7A',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee7A-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee7A.json',
    });
    this.load.atlas({
      key: 'armsCoffee7C',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee7C-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee7C.json',
    });
    this.load.atlas({
      key: 'armsCoffee7D',
      textureURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee7D-0.png',
      atlasURL:
        '../../../assets/coffee-break/animations/armsDrinkCoffee7D.json',
    });
    this.load.atlas({
      key: 'faceCoffee1',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee1-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee1.json',
    });
    this.load.atlas({
      key: 'faceCoffee2',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee2-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee2.json',
    });
    this.load.atlas({
      key: 'faceCoffee3',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee3-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee3.json',
    });
    this.load.atlas({
      key: 'faceCoffee4',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee4-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee4.json',
    });
    this.load.atlas({
      key: 'faceCoffee5',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee5-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee5.json',
    });
    this.load.atlas({
      key: 'faceCoffee6',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee6-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee6.json',
    });
    this.load.atlas({
      key: 'faceCoffee7',
      textureURL: '../../../assets/coffee-break/animations/faceCoffee7-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceCoffee7.json',
    });

    this.load.atlas({
      key: 'faceBlink1',
      textureURL: '../../../assets/coffee-break/animations/faceBlink1-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink1.json',
    });
    this.load.atlas({
      key: 'faceBlink2',
      textureURL: '../../../assets/coffee-break/animations/faceBlink2-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink2.json',
    });
    this.load.atlas({
      key: 'faceBlink3',
      textureURL: '../../../assets/coffee-break/animations/faceBlink3-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink3.json',
    });
    this.load.atlas({
      key: 'faceBlink4',
      textureURL: '../../../assets/coffee-break/animations/faceBlink4-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink4.json',
    });
    this.load.atlas({
      key: 'faceBlink5',
      textureURL: '../../../assets/coffee-break/animations/faceBlink5-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink5.json',
    });
    this.load.atlas({
      key: 'faceBlink6',
      textureURL: '../../../assets/coffee-break/animations/faceBlink6-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink6.json',
    });
    this.load.atlas({
      key: 'faceBlink7',
      textureURL: '../../../assets/coffee-break/animations/faceBlink7-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceBlink7.json',
    });

    this.load.atlas({
      key: 'faceLaugh1',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh1-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh1.json',
    });
    this.load.atlas({
      key: 'faceLaugh2',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh2-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh2.json',
    });
    this.load.atlas({
      key: 'faceLaugh3',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh3-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh3.json',
    });
    this.load.atlas({
      key: 'faceLaugh4',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh4-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh4.json',
    });
    this.load.atlas({
      key: 'faceLaugh5',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh5-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh5.json',
    });
    this.load.atlas({
      key: 'faceLaugh6',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh6-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh6.json',
    });
    this.load.atlas({
      key: 'faceLaugh7',
      textureURL: '../../../assets/coffee-break/animations/faceLaugh7-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLaugh7.json',
    });

    this.load.atlas({
      key: 'faceLove1',
      textureURL: '../../../assets/coffee-break/animations/faceLove1-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove1.json',
    });
    this.load.atlas({
      key: 'faceLove2',
      textureURL: '../../../assets/coffee-break/animations/faceLove2-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove2.json',
    });
    this.load.atlas({
      key: 'faceLove3',
      textureURL: '../../../assets/coffee-break/animations/faceLove3-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove3.json',
    });
    this.load.atlas({
      key: 'faceLove4',
      textureURL: '../../../assets/coffee-break/animations/faceLove4-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove4.json',
    });
    this.load.atlas({
      key: 'faceLove5',
      textureURL: '../../../assets/coffee-break/animations/faceLove5-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove5.json',
    });
    this.load.atlas({
      key: 'faceLove6',
      textureURL: '../../../assets/coffee-break/animations/faceLove6-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove6.json',
    });
    this.load.atlas({
      key: 'faceLove7',
      textureURL: '../../../assets/coffee-break/animations/faceLove7-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceLove7.json',
    });

    this.load.atlas({
      key: 'faceSweat1',
      textureURL: '../../../assets/coffee-break/animations/faceSweat1-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat1.json',
    });
    this.load.atlas({
      key: 'faceSweat2',
      textureURL: '../../../assets/coffee-break/animations/faceSweat2-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat2.json',
    });
    this.load.atlas({
      key: 'faceSweat3',
      textureURL: '../../../assets/coffee-break/animations/faceSweat3-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat3.json',
    });
    this.load.atlas({
      key: 'faceSweat4',
      textureURL: '../../../assets/coffee-break/animations/faceSweat4-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat4.json',
    });
    this.load.atlas({
      key: 'faceSweat5',
      textureURL: '../../../assets/coffee-break/animations/faceSweat5-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat5.json',
    });
    this.load.atlas({
      key: 'faceSweat6',
      textureURL: '../../../assets/coffee-break/animations/faceSweat6-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat6.json',
    });
    this.load.atlas({
      key: 'faceSweat7',
      textureURL: '../../../assets/coffee-break/animations/faceSweat7-0.png',
      atlasURL: '../../../assets/coffee-break/animations/faceSweat7.json',
    });

    this.load.atlas({
      key: 'loaderAnim',
      textureURL: '../../../assets/coffee-break/animations/loaderAnim.png',
      atlasURL: '../../../assets/coffee-break/animations/loaderAnim.json',
    });
    this.load.svg('genericPopUpBreak', '../../../assets/genericPopUp.svg', {
      // scale: this.assetScale,
    });
    this.load.svg('defaultButtonBreak', '../../../assets/Default.svg', {
      // scale: this.assetScale,
    });
    this.load.svg('secondaryButtonBreak', '../../../assets/secondary.svg', {
      // scale: this.assetScale,
    });
    this.load.svg(
      'blink-emoji',
      '../../../assets/coffee-break/emoji/blink_emoji.svg'
    );
    this.load.svg(
      'love-emoji',
      '../../../assets/coffee-break/emoji/love_emoji.svg'
    );
    this.load.svg(
      'laugh-emoji',
      '../../../assets/coffee-break/emoji/laugh_emoji.svg'
    );
    this.load.svg(
      'sweat-emoji',
      '../../../assets/coffee-break/emoji/sweat_emoji.svg'
    );
    this.load.svg(
      'emojiLabel',
      '../../../assets/coffee-break/emoji/emojiLabel.svg'
    );
    this.load.svg(
      'emoji-white-dialog',
      '../../../assets/coffee-break/emoji/white-dialog.svg'
    );
    this.load.svg(
      'emojiLabelSmallDialog',
      '../../../assets/coffee-break/emoji/emojiLabelSmallDialog.svg'
    );
    this.load.svg(
      'emojiCheck',
      '../../../assets/coffee-break/emoji/emoji-check.svg'
    );

    // this.load.on('complete', () => {
    //   loadingText.destroy();
    // });

    if (!this.usersData) {
      this.usersData = context.gameQuery.getValue().game.userGames ?? [];
    }

    this.usersData?.forEach((user: IUserGame, index: number) => {
      if (user.country === this.userCountry) {
        const s = new XMLSerializer().serializeToString(
          context.avatarConstruction(
            user.country,
            PhaserScenes.coffeeBreakScene
          )
        );
        const url = 'data:image/svg+xml;base64,' + window.btoa(s);
        this.flagsAssetsB64.push(url);
        const key = `${user.country?.toString()}Break`;
        if (this.textures.exists(key)) {
          this.textures.get(key).destroy();
        }
        this.textures.addBase64(key, this.flagsAssetsB64[index]?.toString());
        // console.log('TEXTURE ADD HOST');
      } else {
        const s = new XMLSerializer().serializeToString(
          context.npcAvatarConstruction(
            user.country,
            PhaserScenes.coffeeBreakScene
          )
        );
        const url = `data:image/svg+xml;base64,${window.btoa(s)}`;
        this.flagsAssetsB64.push(url);
        const key = `${user.country?.toString()}Break`;
        if (this.textures.exists(key)) {
          this.textures.get(key).destroy();
        }
        this.textures.addBase64(key, this.flagsAssetsB64[index]?.toString());
      }
    });
  }

  public create(): void {
    this.events.on('destroy', () => {
      this.destroy$.next(true);
      this.destroy$.complete();
      removeEventListener('resize', this.resizeEventFunction);
    });

    window.addEventListener('resize', this.resizeEventFunction.bind(this));

    this.animationsScale =
      this.game.scale.width <= this.actualImageWidth
        ? (this.game.scale.width / this.actualImageWidth) * 0.62
        : (this.game.scale.width / this.actualImageWidth) * 0.52;
    const initialTileWidth = this.actualImageWidth / this.initialTile;
    const gameTileWidth = this.game.scale.width / this.initialTile;
    const tileRatio = gameTileWidth / initialTileWidth;
    this.gameTileSize = this.initialTile * tileRatio;

    this.input.setPollAlways();
    this.input.activePointer.updateWorldPoint(this.cameras.main);

    this.bg = this.add
      .image(0, 0, 'bg')
      .setOrigin(0, 0)
      .setDisplaySize(this.game.scale.width, this.game.scale.width * 1.13);
    // .setDisplaySize(1331, 1501);

    this.bg.setInteractive();

    this.bg.on('pointerdown', () => {
      if (
        (this.myPlayerMovement &&
          this.myPlayerMovement.isPlaying() &&
          this.myPlayerMovement) ||
        !this.scene.isVisible()
      ) {
        return;
      }

      if (
        (this.spotTheCountryMyAvatarLabel &&
          this.spotTheCountryMyAvatarLabelText) ||
        (this.npcSpotTheCountryStatusLabel &&
          this.npcSpotTheCountryStatusLabelText) ||
        this.avatarPopUpBreak
      ) {
        this.spotTheCountryMyAvatarLabel?.setVisible(false);
        this.spotTheCountryMyAvatarLabelText?.setVisible(false);
        this.npcSpotTheCountryStatusLabel?.setVisible(false);
        this.npcSpotTheCountryStatusLabelText?.setVisible(false);
        this.avatarPopUpGroupBreak?.children?.each((ch: Phaser.GameObjects.Sprite) => ch?.setVisible(false));
      }

      const x = this.cameras.main.scrollX + this.input.activePointer.x;
      const y = this.cameras.main.scrollY + this.input.activePointer.y;

      const fromX = this.myAvatar.data.values.fromX;
      const fromY = this.myAvatar.data.values.fromY;

      const toX = Math.floor(x / this.gameTileSize);
      const toY = Math.floor(y / this.gameTileSize);

      if (
        !this.isSinglePlayer &&
        this.tileLayer.getTileAt(toX, toY).index === 2
      ) {
        context.gameService.coffeeBreakMessage({
          [this.userCountry]: {
            country: this.userCountry,
            tile: {
              x: this.myAvatar.x,
              y: this.myAvatar.y,
              fromX,
              fromY,
              toX: toX === undefined ? fromX : toX,
              toY: toY === undefined ? fromY : toY,
            },
            normalUser: 1,
          },
        });

        this.myAvatar.setData({
          fromX,
          fromY,
          toX,
          toY,
          tile: {
            x: this.myAvatar.x,
            y: this.myAvatar.y,
            fromX,
            fromY,
            toX,
            toY,
          },
        });

        this.findPath(fromX, fromY, toX, toY);
      } else {
        this.findPath(fromX, fromY, toX, toY);
        this.myAvatar.setData({
            fromX,
            fromY,
            toX,
            toY,
        });
      }
    });

    this.staticObject = this.add
      .image(0, 0, 'static-object')
      .setOrigin(0)
      // .setDisplaySize(1331, 1501)
      .setDisplaySize(this.game.scale.width, this.game.scale.width * 1.13)
      .setDepth(this.positionHeightDepth(200))
      .setAlpha(1);

    this.balcony = this.add
      .image(0, 0, 'balcony')
      .setOrigin(0)
      .setDisplaySize(this.game.scale.width, this.game.scale.width * 1.13)
      .setDepth(this.positionHeightDepth(1400))
      .setAlpha(1);

    const maleExpertPosition = [
      {
        x: 1000,
        y: 400,
        depth: 400,
      },
      {
        x: 250,
        y: 150,
        depth: 150,
      },
      {
        x: 250,
        y: 1250,
        depth: 400,
      },
    ];

    const femaleExpertPosition = [
      {
        x: 260,
        y: 620,
        depth: 650,
      },
      {
        x: 900,
        y: 1050,
        depth: 400,
      },
      {
        x: 1300,
        y: 600,
        depth: 400,
      },
    ];

    context.gameQuery.game$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response && this.breakRound !== response?.breakRound) {
        if (context.envService.env === Environment.debug && response?.breakRound === 0) {
          this.breakRound = 1;
        } else {
          this.breakRound = response.breakRound;
        }

        this.femaleExpert?.destroy(true);
        this.maleExpert?.destroy(true);

        setTimeout(() => {
          this.femaleExpert = this.add
            .sprite(
              this.positionWidth(femaleExpertPosition[this.breakRound - 1]?.x),
              this.positionHeight(femaleExpertPosition[this.breakRound - 1]?.y),
              this.femaleExperts[this.breakRound - 1]
            )
            .setScale(
              this.game.scale.width <= this.actualImageWidth
                ? (this.game.scale.width / this.actualImageWidth) * 0.76
                : (this.game.scale.width / this.actualImageWidth) * 0.64
            )
            .setData(
              {
                pixelX: femaleExpertPosition[this.breakRound - 1]?.x,
                pixelY: femaleExpertPosition[this.breakRound - 1]?.y,
              }
            )
            .setDepth(
              this.positionHeightDepth(
                femaleExpertPosition[this.breakRound - 1]?.depth
              )
            )
            .setOrigin(0.5, 0.5)
            .setInteractive({useHandCursor: true})
            .on('pointerdown', () => {
              if (!this.scene.isVisible()) {
                return;
              }
              this.avatarPopUpGroupBreak?.children?.each((ch: Phaser.GameObjects.Sprite) => ch?.setVisible(false));
              if (context.timesPlayedPerBreakTalkToAnExpert.getValue() > 0) {
                context.gameService.updateErrorMessage(
                  ErrorMessages.talkToAnExpertAlreadyPlayed
                );
                return;
              }
              context.startQuiz();
            });

          this.maleExpert = this.add
            .sprite(
              this.positionWidth(maleExpertPosition[this.breakRound - 1]?.x),
              this.positionHeight(maleExpertPosition[this.breakRound - 1]?.y),
              this.maleExperts[this.breakRound - 1]
            )
            .setScale(
              this.game.scale.width <= this.actualImageWidth
                ? (this.game.scale.width / this.actualImageWidth) * 0.76
                : (this.game.scale.width / this.actualImageWidth) * 0.64
            )
            .setData(
              {
                pixelX: maleExpertPosition[this.breakRound - 1]?.x,
                pixelY: maleExpertPosition[this.breakRound - 1]?.y,
              }
            )
            .setDepth(
              this.positionHeightDepth(maleExpertPosition[this.breakRound - 1]?.depth)
            )
            .setInteractive({useHandCursor: true})
            .on('pointerdown', () => {
              if (!this.scene.isVisible()) {
                return;
              }
              this.avatarPopUpGroupBreak?.setVisible(false);
              if (context.timesPlayedPerBreakTalkToAnExpert.getValue() > 0) {
                context.gameService.updateErrorMessage(
                  ErrorMessages.talkToAnExpertAlreadyPlayed
                );
                return;
              }
              context.startQuiz();
            })
            .setOrigin(0.5, 0.5);
        }, 300);
      }
    });

    this.map = this.make.tilemap({
      key: 'tilemap2',
      tileWidth: this.gameTileSize,
      tileHeight: this.gameTileSize,
    });

    this.map.addTilesetImage('tileset-2', 'tiles2');

    for (let i = 0; i < this.map.layers.length; i++) {
      this.tileLayer = this.map.createLayer(i, 'tileset-2', 0, 0);
      this.tileLayer.setDepth(i);
      this.tileLayer.setDisplaySize(
        this.game.scale.width,
        this.game.scale.width * 1.13
      );
      this.tileLayer.setAlpha(0);
      // this.tileLayer.putTileAt(1, 31,18);
    }

    this.initFurniture();

    this.distancePopUpConstruction();

    this.setGrid();
    this.map.setCollision([1]);
    this.setTopCollisionTiles([1]);

    this.realPlayers.forEach((user: IUserGame, index: number) => {
      this.randomPosition(user, index);
    });

    if (this.isHostOfGame) {
      this.npcPlayers.forEach((user: IUserGame, index: number) => {
        this.randomNpcPosition(user, index);
      });
      context.gameService.addCoffeeBreakState(this.allAvatars);
    } else {
      this.npcPlayers.forEach((user: IUserGame, index: number) => {
        this.randomNpcPosition(user, index);
      });
      context.gameService.addCoffeeBreakState(this.allAvatars);
    }

    setTimeout(() => {
      this.triggerTimer = this.time.addEvent({
        callback: this.updateAvatarsDepth,
        callbackScope: this,
        delay: 120, // 1000 = 1 second
        loop: true,
      });
    }, 550);

    const playerPosition = [
      {
        x: 880,
        y: 300,
      },
      {
        x: 840,
        y: 320,
      },
      {
        x: 800,
        y: 340,
      },
      {
        x: 760,
        y: 360,
      },
      {
        x: 720,
        y: 380,
      },
    ];

    this.carpets = [];
    setTimeout(() => {
      this.usersData.forEach((u, i) => {
        const avatarObjAll = this.allAvatars;
        const avatarObjKeys = Object.keys(this.allAvatars);
        const avatarObj = avatarObjAll[avatarObjKeys[i]];
        if (avatarObj.normalUser === 1) {
          if (avatarObj.country === this.userCountry) {
            this.myAvatar = this.physics.add.sprite(
              this.positionWidth(playerPosition[i].x),
              this.positionHeight(playerPosition[i].y),
              `${avatarObj.country}Break`
            );
            this.myAvatar.setScale(
              this.game.scale.width <= this.actualImageWidth
                ? (this.game.scale.width / this.actualImageWidth) * 0.76
                : (this.game.scale.width / this.actualImageWidth) * 0.64
            );
            this.myAvatar.setData({
              normalUser: avatarObj.normalUser,
              fromX: Math.floor(
                this.positionWidth(playerPosition[i].x / this.gameTileSize)
              ),
              fromY: Math.floor(
                this.positionHeight(playerPosition[i].y / this.gameTileSize)
              ),
              pixelX: playerPosition[i].x,
              pixelY: playerPosition[i].y,
              country: avatarObj.country,
              userId: avatarObj.userId,
              skinToneIndex: avatarObj.skinToneIndex,
              bodyIndex: avatarObj.bodyIndex,
              faceIndex: avatarObj.faceIndex,
            });
            this.myAvatar.setOrigin(0.5, 0.5);
            this.physics.add.collider(this.myAvatar, this.tileLayer);

            this.myPlayerIndicator = this.add
              .sprite(
                this.myAvatar.x,
                this.myAvatar.y,
                'myPlayerIndicatorBreak'
              )
              .setScale(this.myAvatar.scale);
          } else {
            if (avatarObj.country !== this.userCountry) {
              const tempNpcTextSprite = this.physics.add
                .sprite(
                  this.positionWidth(playerPosition[i].x),
                  this.positionHeight(playerPosition[i].y),
                  `${avatarObj.country}Break`
                )
                .setScale(
                  this.game.scale.width <= this.actualImageWidth
                    ? (this.game.scale.width / this.actualImageWidth) * 0.76
                    : (this.game.scale.width / this.actualImageWidth) * 0.64
                )
                .setOrigin(0.5, 0.5)
                .setDepth(this.positionHeightDepth(playerPosition[1].y))
                .setData({
                  fromX: Math.floor(
                    this.positionWidth(playerPosition[i].x / this.gameTileSize)
                  ),
                  fromY: Math.floor(
                    this.positionHeight(playerPosition[i].y / this.gameTileSize)
                  ),
                  normalUser: avatarObj.normalUser,
                  country: avatarObj.country,
                  countryName: avatarObj.countryName,
                  userId: avatarObj.userId,
                  skinToneIndex: avatarObj.skinToneIndex,
                  bodyIndex: avatarObj.bodyIndex,
                  faceIndex: avatarObj.faceIndex,
                  weight: avatarObj.userGameNegotiation.weight,
                });
              this.npcTexturePlayers.push(tempNpcTextSprite);
              this.carpets.push(
                this.add
                  .sprite(
                    avatarObj.x - 5,
                    avatarObj.y + avatarObj.displayHeight / 2,
                    'altWalkingBreak',
                    '02b_character_selection_Blue_00001'
                  )
                  .setData({ countryCode: `${avatarObj.country}` })
                  .setScale(this.animationsScale * 2, this.animationsScale * 2)
                  .setAlpha(0)
              );
              this.addInteractive(tempNpcTextSprite);
            }
          }
        } else {
          const npcTextureAvatar = this.physics.add
            .sprite(
              this.positionWidth(avatarObj.tile.pixelX),
              this.positionHeight(avatarObj.tile.pixelY),
              `${avatarObj.country}Break`
            )
            .setScale(
              this.game.scale.width <= this.actualImageWidth
                ? (this.game.scale.width / this.actualImageWidth) * 0.76
                : (this.game.scale.width / this.actualImageWidth) * 0.64
            )
            .setOrigin(0.5, 0.5)
            .setDepth(this.positionHeightDepth(avatarObj.tile.pixelY))
            .setData({
              pixelX: avatarObj.tile.pixelX,
              pixelY: avatarObj.tile.pixelY,
              fromX: avatarObj.tile.x,
              fromY: avatarObj.tile.y,
              normalUser: avatarObj.normalUser,
              country: avatarObj.country,
              userId: avatarObj.userId,
              hasBilateralOpen: avatarObj.hasBilateralOpen,
              color: this.countries.find(
                (cntr) => cntr.countryCode === avatarObj.country
              ).spotCountryColor,
              countryName: avatarObj.countryName,
              skinToneIndex: avatarObj.skinToneIndex,
              bodyIndex: avatarObj.bodyIndex,
              faceIndex: avatarObj.faceIndex,
              weight: avatarObj.userGameNegotiation.weight,
            });
          this.npcTexturePlayers.push(npcTextureAvatar);
          this.addInteractive(npcTextureAvatar);
          this.carpets.push(
            this.add
              .sprite(
                avatarObj.x - 5,
                avatarObj.y + avatarObj.displayHeight / 2,
                'altWalkingBreak',
                '02b_character_selection_Blue_00001'
              )
              .setData({ countryCode: `${avatarObj.country}` })
              .setScale(this.animationsScale * 2, this.animationsScale * 2)
              .setAlpha(0)
          );
        }
      });

    this.emojiGameLabel = this.add
      .sprite(
        this.myAvatar.x,
        this.myAvatar.y,
        'emojiLabel'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.myAvatar.depth + 1)
      .setOrigin(0.5, 0.5)
      .setVisible(false);

    this.emojiLabelSmallDialog = this.add
      .sprite(
        this.myAvatar.x,
        this.myAvatar.y,
        'emojiLabelSmallDialog'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.myAvatar.depth + 1)
      .setOrigin(0.5, 0.5)
      .setVisible(false);

    this.whiteDialogEmoji = this.add
      .sprite(
        this.myAvatar.x,
        this.myAvatar.y,
        'emoji-white-dialog'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.myAvatar.depth + 1)
      .setOrigin(0.5, 0.5)
      .setVisible(false);

    this.labelGameGroupEmoji = [this.emojiGameLabel, this.emojiLabelSmallDialog];

    this.blinkEmoji = this.add
      .sprite(
        this.emojiGameLabel.x,
        this.emojiGameLabel.y,
        'blink-emoji'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.emojiGameLabel.depth + 1)
      .setOrigin(0.5, 0.5)
      .setInteractive({ useHandCursor: true })
      .setVisible(false);

    this.loveEmoji = this.add
      .sprite(
        this.emojiGameLabel.x,
        this.emojiGameLabel.y,
        'love-emoji'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.emojiGameLabel.depth + 1)
      .setOrigin(0.5, 0.5)
      .setInteractive({ useHandCursor: true })
      .setVisible(false);

    this.laughEmoji = this.add
      .sprite(
        this.emojiGameLabel.x,
        this.emojiGameLabel.y,
        'laugh-emoji'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.emojiGameLabel.depth + 1)
      .setOrigin(0.5, 0.5)
      .setInteractive({ useHandCursor: true })
      .setVisible(false);

    this.sweatEmoji = this.add
      .sprite(
        this.emojiGameLabel.x,
        this.emojiGameLabel.y,
        'sweat-emoji'
      )
      .setScale(this.setAssetScale())
      .setDepth(this.emojiGameLabel.depth + 1)
      .setOrigin(0.5, 0.5)
      .setInteractive({ useHandCursor: true })
      .setVisible(false);

    this.blinkEmoji?.on('pointerdown', (e) => {
      console.log('blinkEmoji clicked');
      this.hasClickedEmoji = true;
      this.playerEmojiSelected = this.blinkEmoji.texture.key;
      this.blinkEmoji?.removeInteractive();
      this.changeOneLabelGameGroupEmojiVisibility(1);
      this.changeOneEmojiVisibility(0);
      this.pointerEVentDowmMulti();
    });

    this.laughEmoji?.on('pointerdown', () => {
      console.log('laughEmoji clicked');
      this.hasClickedEmoji = true;
      this.playerEmojiSelected = this.laughEmoji.texture.key;
      this.laughEmoji?.removeInteractive();
      this.changeOneLabelGameGroupEmojiVisibility(1);
      this.changeOneEmojiVisibility(1);
      this.pointerEVentDowmMulti();
    });

    this.loveEmoji?.on('pointerdown', () => {
      console.log('loveEmoji clicked');
      this.hasClickedEmoji = true;
      this.playerEmojiSelected = this.loveEmoji.texture.key;
      this.loveEmoji?.removeInteractive();
      this.changeOneLabelGameGroupEmojiVisibility(1);
      this.changeOneEmojiVisibility(2);
      this.pointerEVentDowmMulti();
    });

    this.sweatEmoji?.on('pointerdown', () => {
      console.log('sweatEmoji clicked');
      this.hasClickedEmoji = true;
      this.playerEmojiSelected = this.sweatEmoji.texture.key;
      this.sweatEmoji?.removeInteractive();
      this.changeOneLabelGameGroupEmojiVisibility(1);
      this.changeOneEmojiVisibility(3);
      this.pointerEVentDowmMulti();
    });

    this.allEmojis = [this.blinkEmoji, this.laughEmoji, this.loveEmoji, this.sweatEmoji];

    this.coffeeBreakPageSubscription = context.coffeeBreakPage.pipe(takeUntil(this.destroy$)).subscribe(
      (value) => {
        if (!value && this.npcInternvalMovement) {
          clearInterval(this.npcInternvalMovement);
        } else {
          if (this.isSinglePlayer) {
            this.singlePlayerNpcMovement();
          } else {
            if (this.isHostOfGame) {
              this.multiplayerNpcMovements();
            }
          }
        }
      }
    );

      this.hiddenSubscription = context.hidden.pipe(takeUntil(this.destroy$)).subscribe((hidden) => {
        if (
          !hidden &&
          (context.gameInstance as Phaser.Game).scene.isVisible(this.scene.key)
        ) {
          if (this.isSinglePlayer) {
            this.singlePlayerNpcMovement();
          } else {
            if (this.isHostOfGame) {
              this.multiplayerNpcMovements();
            }
          }
        } else {
          clearInterval(this.npcInternvalMovement);
        }
      });

      if (!this.isSinglePlayer) {
        context.webSocketService.coffeeBreakDataChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
          const allData = res;

          Object.keys(allData).forEach((key, index) => {
            if (allData[key].gameType === 'emoji' && this.breakGamePlaying === null) {
              this.multiplayerEmojiGame = allData[key];

              const playerId = allData[key]?.realPlayerCountryPlaying?.find((player) => player.userId === this.myAvatar.data.values.userId);

              if (playerId) {
                this.emojiStartMultiplayer = false;
              }

              if (allData[key].userStartedTheGame !== this.myAvatar.data.values.userId &&
                playerId && !this.emojiStartMultiplayer) {
                this.emojiStartMultiplayer = true;
                this.multiplayerShowEmoji();
                this.multiplayerEmojiResponseGame();
              }
            } else if (allData[key].gameType === 'emoji' && allData[key].userStartedTheGame === this.myAvatar.data.values.userId &&
              allData[key].playerAnswerUsedId) {
              this.multiplayerEmojiGame = allData[key];

              if (this.breakGamePlaying === BreakGames.emoji) {
                this.multiplayerShowEmoji(false);
              }
            }

            if (allData[key].country !== this.userCountry) {
              const avatarTexture = this.npcTexturePlayers.find(
                (avatar: any) => {
                  if (avatar.data.values.country === allData[key].country) {
                    return avatar;
                  }
                }
              );

              if (avatarTexture) {
                avatarTexture.setData({
                  fromX:
                    avatarTexture.data.values.fromX !== undefined
                      ? avatarTexture.data.values.fromX
                      : allData[key].tile.fromX,
                  fromY:
                    avatarTexture.data.values.fromY !== undefined
                      ? avatarTexture.data.values.fromY
                      : allData[key].tile.fromY,
                  toX: allData[key].tile.toX,
                  toY: allData[key].tile.toY,
                  normalUser: allData[key].normalUser,
                });

                this.findPathNPC(
                  avatarTexture.data.values.fromX,
                  avatarTexture.data.values.fromY,
                  avatarTexture.data.values.toX,
                  avatarTexture.data.values.toY,
                  avatarTexture
                );

                avatarTexture.setData({
                  fromX: avatarTexture?.data?.values?.toX,
                  fromY: avatarTexture?.data?.values?.toY,
                });
              }
            }
          });
        });
      }

      this.createBoltWithoutBarAnimation();
      this.createBoltWithBarAnimation();
      this.createSpotCountrySmileAnimation();
      this.createAltWalkingAnimation();
      this.createDrinkCoffeeAnimation();
      this.createLoaderAnimation();
      this.createEmojiAnimations();
    }, 500);

    this.cursors = this.input.keyboard.createCursorKeys();

    //  Set the camera bounds to be the size of the image
    // image actual size is 1331 x 1501
    // this.cameras.main.setBounds(0, 0, 1331, 1501);
    // this.physics.world.setBounds(0, 0, 1331, 1501);

    // Responsive width and height
    this.cameras.main.setBounds(
      0,
      0,
      this.game.scale.width,
      this.game.scale.width * 1.13
    );
    this.physics.world.setBounds(
      0,
      0,
      this.game.scale.width,
      this.game.scale.width * 1.13
    );
  }

  singlePlayerNpcMovement() {
    if (this.npcInternvalMovement) {
      clearInterval(this.npcInternvalMovement);
    }
    this.npcInternvalMovement = setInterval(() => {
      const randomNpcTexturePlayer = getRandomInt(
        this.npcTexturePlayers.length - 1
      );
      const avatar = this.npcTexturePlayers[randomNpcTexturePlayer];
      this.avatarMovement(avatar);
      this.findPathNPC(
        avatar.data.values.fromX,
        avatar.data.values.fromY,
        avatar.data.values.toX,
        avatar.data.values.toY,
        avatar
      );
      avatar.setData({
        fromX: avatar.data.values.toX,
        fromY: avatar.data.values.toY,
      });
    }, 10000);
  }

  multiplayerNpcMovements() {
    if (this.npcInternvalMovement) {
      clearInterval(this.npcInternvalMovement);
    }
    this.npcInternvalMovement = setInterval(() => {
      const newAllAvatars = {};

      const randomNpcTexturePlayer = getRandomInt(
        this.npcTexturePlayers.length - 1
      );
      const avatar = this.npcTexturePlayers[randomNpcTexturePlayer];
      if (
        avatar?.data?.values?.normalUser !== 1 &&
        !avatar?.data?.values?.hasBilateralOpen
      ) {
        this.avatarMovement(avatar);

        this.findPathNPC(
          avatar.data.values.fromX,
          avatar.data.values.fromY,
          avatar.data.values.toX,
          avatar.data.values.toY,
          avatar
        );

        newAllAvatars[avatar.data.values.country] = {
          country: avatar.data.values.country,
          tile: {
            x: avatar.x,
            y: avatar.y,
            pixelX: avatar.data.values.pixelX,
            pixelY: avatar.data.values.pixelY,
            fromX: avatar.data.values.fromX,
            fromY: avatar.data.values.fromY,
            toX: avatar.data.values.toX,
            toY: avatar.data.values.toY,
          },
          normalUser: avatar.data.values.normalUser,
          hasBilateralOpen: avatar.data.values.hasBilateralOpen,
        };

        avatar.setData({
          fromX: avatar.data.values.toX,
          fromY: avatar.data.values.toY,
        });
      }

      context.gameService.coffeeBreakMessage(newAllAvatars);
    }, 10000);
  }

  reziseUpdateSceneAssets() {
    if (this.scene.isVisible()) {
      this.game.scale.resize(window.innerWidth, window.innerHeight);
      this.game.scale.refresh();
      this.game.scale.setGameSize(window.innerWidth, window.innerHeight);

      this.cameras.main.setBounds(
        0,
        0,
        this.game.scale.width,
        this.game.scale.width * 1.13
      );
      this.physics.world.setBounds(
        0,
        0,
        this.game.scale.width,
        this.game.scale.width * 1.13
      );

      this.bg.setDisplaySize(window.innerWidth, window.innerWidth * 1.13);
      this.staticObject.setDisplaySize(window.innerWidth, window.innerWidth * 1.13);
      this.balcony.setDisplaySize(window.innerWidth, window.innerWidth * 1.13);

      this.updateFurniturePosition();

      const initialTileWidth = this.actualImageWidth / this.initialTile;
      const gameTileWidth = this.game.scale.width / this.initialTile;
      const tileRatio = gameTileWidth / initialTileWidth;
      this.gameTileSize = this.initialTile * tileRatio;

      this.map.setBaseTileSize(this.gameTileSize, this.gameTileSize);

      this.animationsScale =
        this.game.scale.width <= this.actualImageWidth
          ? (this.game.scale.width / this.actualImageWidth) * 0.62
          : (this.game.scale.width / this.actualImageWidth) * 0.52;

      this.armsCoffeeAnimationMyAvatar
        ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
        .setDepth(this.myAvatar?.depth + 0.00001);
      this.faceCoffeeAnimationMyAvatar
        ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
        .setDepth(this.myAvatar?.depth + 0.00001);

      this.armsAnimationsWave?.forEach((element) => {
        element?.setScale(this.animationsScale);
      });
      this.faceAnimationsSmile?.forEach((element) => {
        element?.setScale(this.animationsScale);
      });
      this.carpets?.forEach((element) => {
        element?.setScale(this.animationsScale);
      });

      this.maleExpert?.setScale(this.setAssetScale())
        .setPosition(this.positionWidth(this.maleExpert?.data?.values?.pixelX), this.positionHeight(this.maleExpert?.data?.values?.pixelY));
      this.femaleExpert?.setScale(this.setAssetScale())
        .setPosition(this.positionWidth(this.femaleExpert?.data?.values?.pixelX), this.positionHeight(this.femaleExpert?.data?.values?.pixelY));

      this.myAvatar?.setScale(this.setAssetScale());

      this.myAvatar?.setPosition(this.myAvatar?.data?.values?.fromX * this.gameTileSize, this.myAvatar?.data?.values?.fromY * this.gameTileSize);

      this.armsAnimationsWaveMyAvatar
        ?.setScale(this.animationsScale);

      this.faceAnimationsSmileMyAvatar
        ?.setScale(this.animationsScale);

      this.faceAnimationsBlinkMyAvatar
        ?.setScale(this.animationsScale);

      this.faceAnimationsSweatMyAvatar
        ?.setScale(this.animationsScale);

      this.faceAnimationsLoveMyAvatar
        ?.setScale(this.animationsScale);

      this.faceAnimationsLaughMyAvatar
        ?.setScale(this.animationsScale);

      this.faceAnimationsBlink?.forEach(element => {
        element?.setScale(this.animationsScale);
      });
      this.faceAnimationsLaugh?.forEach(element => {
        element?.setScale(this.animationsScale);
      });
      this.faceAnimationsLove?.forEach(element => {
        element?.setScale(this.animationsScale);
      });
      this.faceAnimationsSweat?.forEach(element => {
        element?.setScale(this.animationsScale);
      });

      this.cameras.main.startFollow(this.myAvatar);

      this.npcTexturePlayers?.forEach((player) => {
        player?.setScale(this.setAssetScale());

        player?.setPosition(this.positionWidth(player?.data?.values?.pixelX), this.positionHeight(player?.data?.values?.pixelY));
      });
    }
  }

  createAltWalkingAnimation() {
    const frameNamesAltWalking = this.anims.generateFrameNames(
      'altWalkingBreak',
      {
        start: 1,
        end: 29,
        zeroPad: 5,
        prefix: '02b_character_selection_Blue_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'altWalkingBreak',
      frames: frameNamesAltWalking,
      frameRate: 15,
      repeat: -1,
    });
  }

  createSpotCountrySmileAnimation() {
    const frameNamesFaceSmile1 = this.anims.generateFrameNames('faceSmile1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile1',
      frames: frameNamesFaceSmile1,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceSmile2 = this.anims.generateFrameNames('faceSmile2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile2',
      frames: frameNamesFaceSmile2,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceSmile3 = this.anims.generateFrameNames('faceSmile3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile3',
      frames: frameNamesFaceSmile3,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceSmile4 = this.anims.generateFrameNames('faceSmile4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile4',
      frames: frameNamesFaceSmile4,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceSmile5 = this.anims.generateFrameNames('faceSmile5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile5',
      frames: frameNamesFaceSmile5,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceSmile6 = this.anims.generateFrameNames('faceSmile6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile6',
      frames: frameNamesFaceSmile6,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceSmile7 = this.anims.generateFrameNames('faceSmile7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_smile_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSmile7',
      frames: frameNamesFaceSmile7,
      frameRate: 15,
      repeat: 0,
    });

    const frameNamesArmsWave1A = this.anims.generateFrameNames('armsWave1A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave1A',
      frames: frameNamesArmsWave1A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave1C = this.anims.generateFrameNames('armsWave1C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave1C',
      frames: frameNamesArmsWave1C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave1D = this.anims.generateFrameNames('armsWave1D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave1D',
      frames: frameNamesArmsWave1D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave2A = this.anims.generateFrameNames('armsWave2A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave2A',
      frames: frameNamesArmsWave2A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave2C = this.anims.generateFrameNames('armsWave2C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave2C',
      frames: frameNamesArmsWave2C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave2D = this.anims.generateFrameNames('armsWave2D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave2D',
      frames: frameNamesArmsWave2D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave3A = this.anims.generateFrameNames('armsWave3A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave3A',
      frames: frameNamesArmsWave3A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave3C = this.anims.generateFrameNames('armsWave3C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave3C',
      frames: frameNamesArmsWave3C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave3D = this.anims.generateFrameNames('armsWave3D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave3D',
      frames: frameNamesArmsWave3D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave4A = this.anims.generateFrameNames('armsWave4A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave4A',
      frames: frameNamesArmsWave4A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave4C = this.anims.generateFrameNames('armsWave4C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave4C',
      frames: frameNamesArmsWave4C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave4D = this.anims.generateFrameNames('armsWave4D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave4D',
      frames: frameNamesArmsWave4D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave5A = this.anims.generateFrameNames('armsWave5A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave5A',
      frames: frameNamesArmsWave5A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave5C = this.anims.generateFrameNames('armsWave5C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave5C',
      frames: frameNamesArmsWave5C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave5D = this.anims.generateFrameNames('armsWave5D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave5D',
      frames: frameNamesArmsWave5D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave6A = this.anims.generateFrameNames('armsWave6A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave6A',
      frames: frameNamesArmsWave6A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave6C = this.anims.generateFrameNames('armsWave6C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave6C',
      frames: frameNamesArmsWave6C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave6D = this.anims.generateFrameNames('armsWave6D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave6D',
      frames: frameNamesArmsWave6D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave7A = this.anims.generateFrameNames('armsWave7A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_arms_stA_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave7A',
      frames: frameNamesArmsWave7A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave7C = this.anims.generateFrameNames('armsWave7C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_arms_stC_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave7C',
      frames: frameNamesArmsWave7C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsWave7D = this.anims.generateFrameNames('armsWave7D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_arms_stD_wave_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsWave7D',
      frames: frameNamesArmsWave7D,
      frameRate: 15,
      repeat: 0,
    });

    setTimeout(() => {
      this.npcTexturePlayers.forEach((el, index) => {
        this.armsAnimationsWave[index] = this.add
          .sprite(
            el.x,
            el.y,
            `armsWave${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
            `0${el.data.values.bodyIndex}_arms_st${el.data.values.skinToneIndex}_wave_00000.png`
          )
          .setScale(this.animationsScale, this.animationsScale)
          .setDepth(el.depth)
          .setVisible(true)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.country,
            skinToneIndex: el.data.values.skinToneIndex,
            bodyIndex: el.data.values.bodyIndex,
          });
        this.faceAnimationsSmile[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceSmile${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_smile_00001.png`
          )
          .setScale(this.animationsScale, this.animationsScale)
          .setDepth(el.depth + 1)
          .setVisible(true)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.country,
            skinToneIndex: el.data.values.skinToneIndex,
            faceIndex: el.data.values.faceIndex,
          });
      });

      this.armsAnimationsWaveMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `armsWave${this.myAvatar.data.values.bodyIndex}${this.myAvatar.data.values.skinToneIndex}`,
          `0${this.myAvatar.data.values.bodyIndex}_arms_st${this.myAvatar.data.values.skinToneIndex}_wave_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth)
        .setVisible(true)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
        });
      this.faceAnimationsSmileMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `faceSmile${this.myAvatar.data.values.faceIndex}`,
          `0${this.myAvatar.data.values.faceIndex}_face_smile_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth + 1)
        .setVisible(true)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
          faceIndex: this.myAvatar.data.values.faceIndex,
        });
      context.loading.next(false);
    }, 500);
  }

  createLoaderAnimation(){
    const frameNamesLoaderAnim = this.anims.generateFrameNames('loaderAnim', {
      start: 6,
      end: 8,
      zeroPad: 5,
      prefix: 'loader_anim_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'loaderAnim',
      frames: frameNamesLoaderAnim,
      frameRate: 1,
      showOnStart: true,
      hideOnComplete: true,
      repeat: -1
    });

    setTimeout(() => {
      this.loaderAnim = this.add
          .sprite(
            this.myAvatar.x,
            this.myAvatar.y - this.myAvatar.displayHeight / 2,
            `loaderAnim`,
            `loader_anim_00006.png`
          )
          .setScale(this.animationsScale / 2.5, this.animationsScale / 2.5)
          .setDepth(this.myAvatar.depth + 1)
          .setVisible(false)
          .setData({
            loader: true
          })
          .setTint(0xF5DB6C);
    });
  }

  createDrinkCoffeeAnimation() {
    const frameNamesFaceCoffee1 = this.anims.generateFrameNames('faceCoffee1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee1',
      frames: frameNamesFaceCoffee1,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceCoffee2 = this.anims.generateFrameNames('faceCoffee2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee2',
      frames: frameNamesFaceCoffee2,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceCoffee3 = this.anims.generateFrameNames('faceCoffee3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee3',
      frames: frameNamesFaceCoffee3,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceCoffee4 = this.anims.generateFrameNames('faceCoffee4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee4',
      frames: frameNamesFaceCoffee4,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceCoffee5 = this.anims.generateFrameNames('faceCoffee5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee5',
      frames: frameNamesFaceCoffee5,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceCoffee6 = this.anims.generateFrameNames('faceCoffee6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee6',
      frames: frameNamesFaceCoffee6,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesFaceCoffee7 = this.anims.generateFrameNames('faceCoffee7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_COFFEE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceCoffee7',
      frames: frameNamesFaceCoffee7,
      frameRate: 15,
      repeat: 0,
    });

    const frameNamesArmsCoffee1A = this.anims.generateFrameNames(
      'armsCoffee1A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '01_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee1A',
      frames: frameNamesArmsCoffee1A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee1C = this.anims.generateFrameNames(
      'armsCoffee1C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '01_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee1C',
      frames: frameNamesArmsCoffee1C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee1D = this.anims.generateFrameNames(
      'armsCoffee1D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '01_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee1D',
      frames: frameNamesArmsCoffee1D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee2A = this.anims.generateFrameNames(
      'armsCoffee2A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '02_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee2A',
      frames: frameNamesArmsCoffee2A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee2C = this.anims.generateFrameNames(
      'armsCoffee2C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '02_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee2C',
      frames: frameNamesArmsCoffee2C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee2D = this.anims.generateFrameNames(
      'armsCoffee2D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '02_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee2D',
      frames: frameNamesArmsCoffee2D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee3A = this.anims.generateFrameNames(
      'armsCoffee3A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '03_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee3A',
      frames: frameNamesArmsCoffee3A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee3C = this.anims.generateFrameNames(
      'armsCoffee3C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '03_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee3C',
      frames: frameNamesArmsCoffee3C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee3D = this.anims.generateFrameNames(
      'armsCoffee3D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '03_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee3D',
      frames: frameNamesArmsCoffee3D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee4A = this.anims.generateFrameNames(
      'armsCoffee4A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '04_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee4A',
      frames: frameNamesArmsCoffee4A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee4C = this.anims.generateFrameNames(
      'armsCoffee4C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '04_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee4C',
      frames: frameNamesArmsCoffee4C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee4D = this.anims.generateFrameNames(
      'armsCoffee4D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '04_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee4D',
      frames: frameNamesArmsCoffee4D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee5A = this.anims.generateFrameNames(
      'armsCoffee5A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '05_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee5A',
      frames: frameNamesArmsCoffee5A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee5C = this.anims.generateFrameNames(
      'armsCoffee5C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '05_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee5C',
      frames: frameNamesArmsCoffee5C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee5D = this.anims.generateFrameNames(
      'armsCoffee5D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '05_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee5D',
      frames: frameNamesArmsCoffee5D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee6A = this.anims.generateFrameNames(
      'armsCoffee6A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '06_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee6A',
      frames: frameNamesArmsCoffee6A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee6C = this.anims.generateFrameNames(
      'armsCoffee6C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '06_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee6C',
      frames: frameNamesArmsCoffee6C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee6D = this.anims.generateFrameNames(
      'armsCoffee6D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '06_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee6D',
      frames: frameNamesArmsCoffee6D,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee7A = this.anims.generateFrameNames(
      'armsCoffee7A',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '07_arms_stA_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee7A',
      frames: frameNamesArmsCoffee7A,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee7C = this.anims.generateFrameNames(
      'armsCoffee7C',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '07_arms_stC_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee7C',
      frames: frameNamesArmsCoffee7C,
      frameRate: 15,
      repeat: 0,
    });
    const frameNamesArmsCoffee7D = this.anims.generateFrameNames(
      'armsCoffee7D',
      {
        start: 1,
        end: 74,
        zeroPad: 5,
        prefix: '07_arms_stD_coffee_',
        suffix: '.png',
      }
    );
    this.anims.create({
      key: 'armsCoffee7D',
      frames: frameNamesArmsCoffee7D,
      frameRate: 15,
      repeat: 0,
    });

    setTimeout(() => {
      this.armsCoffeeAnimationMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `armsCoffee${this.myAvatar.data.values.bodyIndex}${this.myAvatar.data.values.skinToneIndex}`,
          `0${this.myAvatar.data.values.bodyIndex}_arms_st${this.myAvatar.data.values.skinToneIndex}_coffee_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth)
        .setVisible(false)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
        });
      this.faceCoffeeAnimationMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `faceCoffee${this.myAvatar.data.values.faceIndex}`,
          `0${this.myAvatar.data.values.faceIndex}_face_COFFEE_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth + 1)
        .setVisible(false)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
          faceIndex: this.myAvatar.data.values.faceIndex,
        });

      this.armsCoffeeAnimationMyAvatar.on('animationstart', () => {
        this.armsAnimationsWaveMyAvatar?.setVisible(false);
        this.faceAnimationsSmileMyAvatar?.setVisible(false);
        this.armsCoffeeAnimationMyAvatar?.setVisible(true);
        this.faceCoffeeAnimationMyAvatar?.setVisible(true);
      });

      this.armsCoffeeAnimationMyAvatar.on('animationcomplete', () => {
        this.armsAnimationsWaveMyAvatar?.setVisible(true);
        this.faceAnimationsSmileMyAvatar?.setVisible(true);
        this.armsCoffeeAnimationMyAvatar?.setVisible(false);
        this.faceCoffeeAnimationMyAvatar?.setVisible(false);
      });
    }, 510);
  }

  createEmojiAnimations(){
    const frameNamesFaceBlink1 = this.anims.generateFrameNames('faceBlink1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink1',
      frames: frameNamesFaceBlink1,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceBlink2 = this.anims.generateFrameNames('faceBlink2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink2',
      frames: frameNamesFaceBlink2,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceBlink3 = this.anims.generateFrameNames('faceBlink3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink3',
      frames: frameNamesFaceBlink3,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceBlink4 = this.anims.generateFrameNames('faceBlink4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink4',
      frames: frameNamesFaceBlink4,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceBlink5 = this.anims.generateFrameNames('faceBlink5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink5',
      frames: frameNamesFaceBlink5,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceBlink6 = this.anims.generateFrameNames('faceBlink6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink6',
      frames: frameNamesFaceBlink6,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceBlink7 = this.anims.generateFrameNames('faceBlink7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_BLINK_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceBlink7',
      frames: frameNamesFaceBlink7,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });

    const frameNamesFaceLaugh1 = this.anims.generateFrameNames('faceLaugh1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh1',
      frames: frameNamesFaceLaugh1,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLaugh2 = this.anims.generateFrameNames('faceLaugh2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh2',
      frames: frameNamesFaceLaugh2,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLaugh3 = this.anims.generateFrameNames('faceLaugh3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh3',
      frames: frameNamesFaceLaugh3,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLaugh4 = this.anims.generateFrameNames('faceLaugh4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh4',
      frames: frameNamesFaceLaugh4,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLaugh5 = this.anims.generateFrameNames('faceLaugh5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh5',
      frames: frameNamesFaceLaugh5,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLaugh6 = this.anims.generateFrameNames('faceLaugh6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh6',
      frames: frameNamesFaceLaugh6,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLaugh7 = this.anims.generateFrameNames('faceLaugh7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_LAUGH_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLaugh7',
      frames: frameNamesFaceLaugh7,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });

    const frameNamesFaceLove1 = this.anims.generateFrameNames('faceLove1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove1',
      frames: frameNamesFaceLove1,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLove2 = this.anims.generateFrameNames('faceLove2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove2',
      frames: frameNamesFaceLove2,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLove3 = this.anims.generateFrameNames('faceLove3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove3',
      frames: frameNamesFaceLove3,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLove4 = this.anims.generateFrameNames('faceLove4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove4',
      frames: frameNamesFaceLove4,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLove5 = this.anims.generateFrameNames('faceLove5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove5',
      frames: frameNamesFaceLove5,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLove6 = this.anims.generateFrameNames('faceLove6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove6',
      frames: frameNamesFaceLove6,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceLove7 = this.anims.generateFrameNames('faceLove7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_LOVE_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceLove7',
      frames: frameNamesFaceLove7,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });

    const frameNamesFaceSweat1 = this.anims.generateFrameNames('faceSweat1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat1',
      frames: frameNamesFaceSweat1,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceSweat2 = this.anims.generateFrameNames('faceSweat2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat2',
      frames: frameNamesFaceSweat2,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceSweat3 = this.anims.generateFrameNames('faceSweat3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat3',
      frames: frameNamesFaceSweat3,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceSweat4 = this.anims.generateFrameNames('faceSweat4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat4',
      frames: frameNamesFaceSweat4,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceSweat5 = this.anims.generateFrameNames('faceSweat5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat5',
      frames: frameNamesFaceSweat5,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceSweat6 = this.anims.generateFrameNames('faceSweat6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat6',
      frames: frameNamesFaceSweat6,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });
    const frameNamesFaceSweat7 = this.anims.generateFrameNames('faceSweat7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_SWEAT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceSweat7',
      frames: frameNamesFaceSweat7,
      frameRate: 15,
      showOnStart: true,
      hideOnComplete: true,
      repeat: 0,
    });

    setTimeout(() => {
      this.npcTexturePlayers.forEach((el, index) => {
        this.faceAnimationsBlink[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceBlink${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_BLINK_00001.png`
          )
          .setScale(this.animationsScale, this.animationsScale)
          .setDepth(el.depth + 1)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.country,
            skinToneIndex: el.data.values.skinToneIndex,
            faceIndex: el.data.values.faceIndex,
          });

          this.faceAnimationsLaugh[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceLaugh${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_LAUGH_00001.png`
          )
          .setScale(this.animationsScale, this.animationsScale)
          .setDepth(el.depth + 1)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.country,
            skinToneIndex: el.data.values.skinToneIndex,
            faceIndex: el.data.values.faceIndex,
          });

          this.faceAnimationsLove[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceLove${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_LOVE_00001.png`
          )
          .setScale(this.animationsScale, this.animationsScale)
          .setDepth(el.depth + 1)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.country,
            skinToneIndex: el.data.values.skinToneIndex,
            faceIndex: el.data.values.faceIndex,
          });

          this.faceAnimationsSweat[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceSweat${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_SWEAT_00001.png`
          )
          .setScale(this.animationsScale, this.animationsScale)
          .setDepth(el.depth + 1)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.country,
            skinToneIndex: el.data.values.skinToneIndex,
            faceIndex: el.data.values.faceIndex,
          });
      });

      this.faceAnimationsBlinkMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `faceBlink${this.myAvatar.data.values.faceIndex}`,
          `0${this.myAvatar.data.values.faceIndex}_face_BLINK_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth + 1)
        .setVisible(false)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
          faceIndex: this.myAvatar.data.values.faceIndex,
        });

        this.faceAnimationsLaughMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `faceLaugh${this.myAvatar.data.values.faceIndex}`,
          `0${this.myAvatar.data.values.faceIndex}_face_LAUGH_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth + 1)
        .setVisible(false)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
          faceIndex: this.myAvatar.data.values.faceIndex,
        });

        this.faceAnimationsLoveMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `faceLove${this.myAvatar.data.values.faceIndex}`,
          `0${this.myAvatar.data.values.faceIndex}_face_LOVE_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth + 1)
        .setVisible(false)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
          faceIndex: this.myAvatar.data.values.faceIndex,
        });

        this.faceAnimationsSweatMyAvatar = this.add
        .sprite(
          this.myAvatar.x,
          this.myAvatar.y,
          `faceSweat${this.myAvatar.data.values.faceIndex}`,
          `0${this.myAvatar.data.values.faceIndex}_face_SWEAT_00000.png`
        )
        .setScale(this.animationsScale, this.animationsScale)
        .setDepth(this.myAvatar.depth + 1)
        .setVisible(false)
        .setData({
          index: this.myAvatar.data.values.bodyIndex,
          countryCode: this.myAvatar.data.values.country,
          skinToneIndex: this.myAvatar.data.values.skinToneIndex,
          bodyIndex: this.myAvatar.data.values.bodyIndex,
          faceIndex: this.myAvatar.data.values.faceIndex,
        });

        this.faceAnimationsBlink.forEach((fc, i) => {
          fc.on('animationstart', () => {
            this.faceAnimationsSmile[i].setVisible(false);
          });
          fc.on('animationcomplete', () => {
            this.faceAnimationsSmile[i].setVisible(true);
          });
        });
        this.faceAnimationsLaugh.forEach((fc, i) => {
          fc.on('animationstart', () => {
            this.faceAnimationsSmile[i].setVisible(false);
          });
          fc.on('animationcomplete', () => {
            this.faceAnimationsSmile[i].setVisible(true);
          });
        });
        this.faceAnimationsLove.forEach((fc, i) => {
          fc.on('animationstart', () => {
            this.faceAnimationsSmile[i].setVisible(false);
          });
          fc.on('animationcomplete', () => {
            this.faceAnimationsSmile[i].setVisible(true);
          });
        });
        this.faceAnimationsSweat.forEach((fc, i) => {
          fc.on('animationstart', () => {
            this.faceAnimationsSmile[i].setVisible(false);
          });
          fc.on('animationcomplete', () => {
            this.faceAnimationsSmile[i].setVisible(true);
          });
        });

        this.faceAnimationsBlinkMyAvatar.on('animationstart', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(false);
        });

        this.faceAnimationsBlinkMyAvatar.on('animationcomplete', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(true);
        });

        this.faceAnimationsLaughMyAvatar.on('animationstart', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(false);
        });

        this.faceAnimationsLaughMyAvatar.on('animationcomplete', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(true);
        });

        this.faceAnimationsLoveMyAvatar.on('animationstart', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(false);
        });

        this.faceAnimationsLoveMyAvatar.on('animationcomplete', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(true);
        });

        this.faceAnimationsSweatMyAvatar.on('animationstart', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(false);
        });

        this.faceAnimationsSweatMyAvatar.on('animationcomplete', () => {
          this.faceAnimationsSmileMyAvatar.setVisible(true);
        });

    }, 550);

  }

  public update(): void {
    // if(this.resizeWindow) {
    //   this.reziseUpdateSceneAssets();
    // }

    this.loaderAnim?.setPosition(this.myAvatar?.x - 6, this.myAvatar?.y - this.myAvatar?.displayHeight / 1.5)
    .setDepth(this.myPlayerIndicator?.depth * 10);
    this.spotTheCountryMyAvatarLabel?.setPosition(
      this.myAvatar.x,
      this.myAvatar.y - 100
    );
    this.spotTheCountryMyAvatarLabelText?.setPosition(
      this.myAvatar.x,
      this.myAvatar.y - 100
    );
    if (this.boltWithBarAnim?.anims?.isPlaying) {
      this.boltWithBarAnim
        .setPosition(this.myAvatar?.x, this.myAvatar?.y - 80)
        .setDepth(this.myAvatar?.depth + 100);
    } else if (this.boltWithoutAnim?.anims.isPlaying) {
      this.boltWithoutAnim
        .setPosition(this.myAvatar?.x, this.myAvatar?.y - 80)
        .setDepth(this.myAvatar?.depth + 100);
    }

    this.emojiGameLabel?.setPosition(
      this.myAvatar?.x > this.game.scale.width / 3 ?
        this.myAvatar.x - this.myAvatar.displayWidth * 1.8 :
        this.myAvatar.x + this.myAvatar.displayWidth * 1.8,
      this.myAvatar.y - this.positionHeight(20)
    ).setDepth(this.myAvatar.depth + 5000).setScale(this.game.scale.width <= this.actualImageWidth
      ? (this.game.scale.width / this.actualImageWidth) * 0.76
      : (this.game.scale.width / this.actualImageWidth) * 0.64)
    .setRotation(this.myAvatar?.x > this.game.scale.width / 3 ? 0 : (Math.PI / 180) * 180);

    this.emojiLabelSmallDialog?.setPosition(
      this.myAvatar?.x > this.game.scale.width / 3 ?
        this.myAvatar.x - this.myAvatar.displayWidth / 1.19 :
        this.myAvatar.x + this.myAvatar.displayWidth / 1.19,
      this.myAvatar.y - this.positionHeight(20)
    ).setDepth(this.myAvatar.depth + 5000).setScale(this.game.scale.width <= this.actualImageWidth
      ? (this.game.scale.width / this.actualImageWidth) * 0.76
      : (this.game.scale.width / this.actualImageWidth) * 0.64)
    .setRotation(this.myAvatar?.x > this.game.scale.width / 3 ? 0 : (Math.PI / 180) * 180);

    this.whiteDialogEmoji?.setPosition(
      this.myAvatar.x - 10,
      this.myAvatar.y - this.myAvatar.displayHeight
    ).setDepth(this.myAvatar.depth).setScale(this.game.scale.width <= this.actualImageWidth
      ? (this.game.scale.width / this.actualImageWidth) * 0.76
      : (this.game.scale.width / this.actualImageWidth) * 0.64);

    this.emojiCheck?.setPosition(
      this.whiteDialogEmoji.x - this.whiteDialogEmoji.displayWidth / 2,
      this.whiteDialogEmoji.y - this.whiteDialogEmoji.displayHeight / 2
    ).setDepth(this.whiteDialogEmoji.depth).setScale(this.game.scale.width <= this.actualImageWidth
      ? (this.game.scale.width / this.actualImageWidth) * 0.76
      : (this.game.scale.width / this.actualImageWidth) * 0.64);

    if (this.hasClickedEmoji) {
      this.blinkEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiLabelSmallDialog.x - this.emojiLabelSmallDialog.displayWidth / 10 :
          this.emojiLabelSmallDialog.x + this.emojiLabelSmallDialog.displayWidth / 10,
        this.emojiLabelSmallDialog.y - this.positionHeight(0)
      ).setDepth(this.emojiLabelSmallDialog.depth).setScale(this.emojiLabelSmallDialog.scale);
    } else {
      this.blinkEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiGameLabel.x - this.emojiGameLabel.displayWidth / 2.8 :
          this.emojiGameLabel.x + this.emojiGameLabel.displayWidth / 2.8,
        this.emojiGameLabel.y - this.positionHeight(0)
      ).setDepth(this.emojiGameLabel.depth).setScale(this.emojiGameLabel.scale);
    }

    if (this.hasClickedEmoji) {
      this.loveEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiLabelSmallDialog.x - this.emojiLabelSmallDialog.displayWidth / 10 :
          this.emojiLabelSmallDialog.x + this.emojiLabelSmallDialog.displayWidth / 10,
        this.emojiLabelSmallDialog.y - this.positionHeight(0)
      ).setDepth(this.emojiLabelSmallDialog.depth).setScale(this.emojiLabelSmallDialog.scale);
    } else {
      this.loveEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiGameLabel.x - this.emojiGameLabel.displayWidth / 8 :
          this.emojiGameLabel.x + this.emojiGameLabel.displayWidth / 8,
        this.emojiGameLabel.y - this.positionHeight(0)
      ).setDepth(this.emojiGameLabel.depth).setScale(this.emojiGameLabel.scale);
    }

    if (this.hasClickedEmoji) {
      this.laughEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiLabelSmallDialog.x - this.emojiLabelSmallDialog.displayWidth / 10 :
          this.emojiLabelSmallDialog.x + this.emojiLabelSmallDialog.displayWidth / 10,
        this.emojiLabelSmallDialog.y - this.positionHeight(0)
      ).setDepth(this.emojiLabelSmallDialog.depth).setScale(this.emojiLabelSmallDialog.scale);
    } else {
      this.laughEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiGameLabel.x + (this.emojiGameLabel.displayWidth / 10) :
          this.emojiGameLabel.x - (this.emojiGameLabel.displayWidth / 10),
        this.emojiGameLabel.y - this.positionHeight(0)
      ).setDepth(this.emojiGameLabel.depth).setScale(this.emojiGameLabel.scale);
    }

    if (this.hasClickedEmoji) {
      this.sweatEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiLabelSmallDialog.x - this.emojiLabelSmallDialog.displayWidth / 10 :
          this.emojiLabelSmallDialog.x + this.emojiLabelSmallDialog.displayWidth / 10,
        this.emojiLabelSmallDialog.y - this.positionHeight(0)
      ).setDepth(this.emojiLabelSmallDialog.depth).setScale(this.emojiLabelSmallDialog.scale);
    } else {
      this.sweatEmoji?.setPosition(
        this.myAvatar?.x > this.game.scale.width / 3 ?
          this.emojiGameLabel.x + (this.emojiGameLabel.displayWidth / 3.1) :
          this.emojiGameLabel.x - (this.emojiGameLabel.displayWidth / 3.1),
        this.emojiGameLabel.y - this.positionHeight(0)
      ).setDepth(this.emojiGameLabel.depth).setScale(this.emojiGameLabel.scale);
    }

    this.emojiActions?.forEach((emoji) => {
      const npc = this.npcTexturePlayers.find((item) => item.data.values.country === emoji?.emojiAction?.data?.values?.countryEmoji);
      emoji?.emojiAction?.setPosition(
        npc?.x,
        npc?.y - npc?.displayHeight / 1.43,
      )?.setDepth(npc.depth + 2)?.setScale(npc.scale);
      emoji?.emojiDialog?.setPosition(
        npc?.x,
        npc?.y - npc?.displayHeight / 1.5,
      )?.setDepth(npc.depth + 1)?.setScale(npc.scale);
      emoji?.emojiDialogCheck?.setPosition(
        emoji?.emojiDialog?.x - emoji?.emojiDialog?.displayWidth / 2,
        emoji?.emojiDialog?.y - emoji?.emojiDialog?.displayHeight / 2,
      )?.setDepth(emoji.emojiDialog.depth + 1)?.setScale(emoji.emojiDialog.scale);
    });

    this.emojiActionsMultiplayer?.forEach((emoji) => {
      const npc = this.npcTexturePlayers.find((item) => item.data.values.country === emoji?.emojiAction?.data?.values?.countryEmoji);
      emoji?.emojiAction?.setPosition(
        npc?.x,
        npc?.y - npc?.displayHeight / 1.5,
      )?.setDepth(npc.depth + 2)?.setScale(npc.scale);
      emoji?.emojiDialog?.setPosition(
        npc?.x,
        npc?.y - npc?.displayHeight / 1.5,
      )?.setDepth(npc.depth + 1)?.setScale(npc.scale);
      emoji?.emojiDialogCheck?.setPosition(
        emoji?.emojiDialog?.x - emoji?.emojiDialog?.displayWidth / 2,
        emoji?.emojiDialog?.y - emoji?.emojiDialog?.displayHeight / 2,
      )?.setDepth(emoji.emojiDialog.depth + 1)?.setScale(emoji.emojiDialog.scale);
    });

    this.armsAnimationsWaveMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth - 0.00001);
    this.faceAnimationsSmileMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);

    this.armsCoffeeAnimationMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);
    this.faceCoffeeAnimationMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);

    this.armsAnimationsWave?.forEach((element) => {
      element
        ?.setPosition(
          this.npcTexturePlayers.find(
            (it) => it.data.values.country === element.data.values.countryCode
          )?.x,
          this.npcTexturePlayers.find(
            (it) => it.data.values.country === element.data.values.countryCode
          )?.y
        )
        .setDepth(
          this.npcTexturePlayers.find(
            (it) => it.data.values.country === element.data.values.countryCode
          )?.depth - 0.00001
        );
    });
    this.faceAnimationsSmile?.forEach((element) => {
      element
        ?.setPosition(
          this.npcTexturePlayers.find(
            (it) => it.data.values.country === element.data.values.countryCode
          )?.x,
          this.npcTexturePlayers.find(
            (it) => it.data.values.country === element.data.values.countryCode
          )?.y
        )
        .setDepth(
          this.npcTexturePlayers.find(
            (it) => it.data.values.country === element.data.values.countryCode
          )?.depth + 0.00001
        );
    });
    this.carpets?.forEach((element) => {
      element
        .setPosition(
          this.npcTexturePlayers?.find(
            (el) => el.data.values?.country === element.data.values?.countryCode
          )?.x - 5,
          this.npcTexturePlayers.find(
            (el) => el.data.values.country === element.data.values.countryCode
          )?.displayHeight /
            2 +
            this.npcTexturePlayers.find(
              (el) => el.data.values.country === element.data.values.countryCode
            )?.y
        )
        .setDepth(
          this.npcTexturePlayers?.find(
            (el) => el.data.values.country === element.data.values.countryCode
          )?.depth - 0.0001
        );
    });
    if (this.checkIfBilateralPopUpExists()) {
      const avatar = this.npcTexturePlayers?.find(
        (it) =>
          it?.data?.values.country === this.popUpCountryTextBreak?.data?.values.countryCode
      );
      if(avatar){
        this.setPositionOnBilateralPopUp(avatar);
      }
    }
    this.myPlayerIndicator
      ?.setPosition(this.myAvatar.x - 6, this.myAvatar.y)
      .setDepth(this.myAvatar.depth - 0.0001)
      .setScale(this.myAvatar?.scale);

      this.faceAnimationsBlinkMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);

      this.faceAnimationsBlink?.forEach((element) => {
        element
          ?.setPosition(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.x,
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.y
          )
          .setDepth(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.depth + 0.00001
          );
      });

      this.faceAnimationsLoveMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);

      this.faceAnimationsLove?.forEach((element) => {
        element
          ?.setPosition(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.x,
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.y
          )
          .setDepth(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.depth + 0.00001
          );
      });

      this.faceAnimationsLaughMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);

      this.faceAnimationsLaugh?.forEach((element) => {
        element
          ?.setPosition(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.x,
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.y
          )
          .setDepth(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.depth + 0.00001
          );
      });

      this.faceAnimationsSweatMyAvatar
      ?.setPosition(this.myAvatar?.x, this.myAvatar?.y)
      .setDepth(this.myAvatar?.depth + 0.00001);

      this.faceAnimationsSweat?.forEach((element) => {
        element
          ?.setPosition(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.x,
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.y
          )
          .setDepth(
            this.npcTexturePlayers.find(
              (it) => it.data.values.country === element.data.values.countryCode
            )?.depth + 0.00001
          );
      });

  }

  distancePopUpConstruction() {
    this.distancePopUp = this.add
      .sprite(window.innerWidth / 2, window.innerHeight / 2, 'errorPopUp')
      .setScale(
        this.game.scale.width / this.actualImageWidth,
        (this.game.scale.width * 1.13) / this.actualImageWidth
      )
      .setDepth(3001)
      .setOrigin(0.5, 0.5)
      .setVisible(false);

    this.distancePopUpText = this.add
      .text(
        this.distancePopUp.x,
        this.distancePopUp.y,
        ''
      )
      .setDepth(this.distancePopUp.depth + 1)
      .setOrigin(0.5, 0.5)
      .setAlign('center')
      .setFontSize(20)
      .setFontFamily(context.gameFont)
      .setPadding(20, 60, 20, 60)
      .setWordWrapWidth(400)
      .setStyle({
        color: '#003399',
      })
      .setVisible(false);

    this.distancePopUp.setDisplaySize(
      this.distancePopUpText.width,
      this.distancePopUpText.height * 1.2
    );
  }

  avatarMovement(avatar: any) {
    let tileMovement;
    let xNpc = 0;
    let yNpc = 0;

    do {
      xNpc = Math.floor(
        Phaser.Math.Between(0, this.game.scale.width) / this.gameTileSize
      );
      yNpc = Math.floor(
        Phaser.Math.Between(0, this.game.scale.width * 1.13) / this.gameTileSize
      );

      tileMovement = this.tileLayer?.getTileAt(xNpc, yNpc);
    } while (tileMovement?.index !== 2);

    const fromX = avatar.data.values.fromX;
    const fromY = avatar.data.values.fromY;

    const toX = xNpc;
    const toY = yNpc;

    avatar.setData({
      fromX: avatar.data.values.fromX,
      fromY: avatar.data.values.fromY,
      toX,
      toY,
    });
  }

  findPathNPC(
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
    npcPlayer: any
  ) {
    this.easystar.findPath(fromX, fromY, toX, toY, (path: any) => {
      if (path === null) {
        console.log('Path was not found.');
      } else {
        // alert('Path was found. The first Point is ' + path[0].x + ' ' + path[0].y);
        this.moveNPCByPath(path, npcPlayer);
      }
    });

    this.easystar.calculate();
  }

  moveNPCByPath(path: any, npcPlayer: any) {
    // console.log('moveNPCByPath', npcPlayer);

    const tweens = [];
    for (let i = 2; i < path.length - 1; i++) {
      const ex = path[i + 1].x;
      const ey = path[i + 1].y;

      tweens.push({
        targets: npcPlayer,
        x: { value: ex * this.gameTileSize, duration: 200 },
        y: { value: ey * this.gameTileSize - 30, duration: 200 },
        duration: i === 1 ? 1000 : 300,
      });
    }

    this.npcMovements.push({
      country: npcPlayer?.data?.values?.country,
      tweens: this.tweens.timeline({
        tweens,
        onStart: () => {
          this.carpets
            .find(
              (it) =>
                it.data.values.countryCode === npcPlayer?.data?.values?.country
            )
            .setVisible(true)
            .setAlpha(1)
            .play('altWalkingBreak');
        },
        onComplete: () => {
          this.carpets
            .find(
              (it) =>
                it.data.values.countryCode === npcPlayer?.data?.values?.country
            )
            .setVisible(false)
            .stop();
          npcPlayer.setData({
            fromX: npcPlayer.data.values.toX,
            fromY: npcPlayer.data.values.toY,
          });
        },
        // onComplete: this.checkDistance.bind(this)
      }),
    });
  }

  updateAvatarsDepth() {
    this.movePlayer();
    const cam = this.cameras.main;
    cam.originX = this.sys.canvas.width / 2;
    cam.startFollow(this.myAvatar);

    // if (this.cursors.left.isDown) {
    //   this.myAvatar.setVelocityX(-300);
    // } else if (this.cursors.right.isDown) {
    //   this.myAvatar.setVelocityX(300);
    // }
  }

  createBoltWithoutBarAnimation() {
    const frameNamesBoltWithout = this.anims.generateFrameNames('boltWithout', {
      start: 1,
      end: 29,
      zeroPad: 5,
      prefix: 'BOLT_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'boltWithout',
      frames: frameNamesBoltWithout,
      frameRate: 15,
      repeat: 0,
      hideOnComplete: true,
    });

    this.boltWithoutAnim = this.add.sprite(
      -10,
      -10,
      'boltWithout',
      'BOLT_00001'
    );
  }
  createBoltWithBarAnimation() {
    const frameNamesBoltWithBar = this.anims.generateFrameNames('boltWithBar', {
      start: 1,
      end: 29,
      zeroPad: 5,
      prefix: 'BOLT_BAR_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'boltWithBar',
      frames: frameNamesBoltWithBar,
      frameRate: 15,
      repeat: 0,
      hideOnComplete: true,
    });

    this.boltWithBarAnim = this.add.sprite(
      -10,
      -10,
      'boltWithBar',
      'BOLT_BAR_00001'
    );
  }

  createWarningPopUp(
    typeOfUse: PopUpTypeOfUse,
    sprite?: Phaser.GameObjects.Sprite
  ) {
    this.confirmButtonText?.destroy(true);
    this.moveConfirmationPopUp?.destroy(true);
    this.moveConfirmationPopUpText?.destroy(true);
    this.confirmButton?.destroy(true);

    let bool = true;

    this.moveConfirmationPopUp = this.add
      .sprite(
        this.cameras.main.scrollX + window.innerWidth / 2,
        this.cameras.main.scrollY + window.innerHeight / 2,
        'genericPopUpBreak'
      )
      .setDepth(isNaN(this.avatarPopUpBreak?.depth + 10) ? 90000 : this.avatarPopUpBreak?.depth + 10)
      .setAlpha(0.95)
      .setInteractive();
    this.moveConfirmationPopUpText = this.add
      .text(
        this.moveConfirmationPopUp.x -
          this.moveConfirmationPopUp.displayWidth / 2,
        this.moveConfirmationPopUp.y -
          this.moveConfirmationPopUp.displayHeight / 2,
        ``
      )
      .setFontSize(28)
      .setOrigin(0.5, 0.5)
      .setDepth(this.moveConfirmationPopUp.depth + 10)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#003399',
      })
      .setWordWrapWidth(this.moveConfirmationPopUp.displayWidth * 0.9)
      .setAlign('center')
      .setPadding(20, 20)
      .setLineSpacing(5);

    this.confirmButton = this.add
      .sprite(
        this.moveConfirmationPopUp.x -
          this.moveConfirmationPopUp.displayWidth / 4.5,
        this.moveConfirmationPopUp.y +
          this.moveConfirmationPopUp.displayHeight / 3.5,
        'defaultButtonBreak'
      )
      .setDepth(this.moveConfirmationPopUp.depth + 1)
      .setInteractive({ useHandCursor: true });
    this.confirmButton.on('pointerdown', () => {
      this.confirmButton.destroy(true);
      this.moveConfirmationPopUpText.destroy(true);
      this.confirmButtonText.destroy(true);
      this.moveConfirmationPopUp.destroy(true);
      return;
    });
    this.confirmButtonText = this.add
      .text(
        this.confirmButton.x - this.confirmButton.displayWidth / 2,
        this.confirmButton.y - this.confirmButton.displayHeight / 2,
        `${context.translateService.instant('customize_avatar_confirm_button')}`
      )
      .setFontSize(24)
      .setOrigin(0.5, 0.5)
      .setDepth(this.confirmButton.depth + 10)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#FFFFFF',
      })
      .setPadding(20, 20);

    if (typeOfUse === PopUpTypeOfUse.bilateral) {
      bool = false;
      this.moveConfirmationPopUp?.setScale(
        this.moveConfirmationPopUp.scale * 0.9
      );
      let countryName;
      if (this.myUserBilateral && !this.myBilateral) {
        countryName = this.countries.find(
          (ctr) => ctr.countryCode === this.userCountry
        ).name;
      } else if (this.myBilateral) {
        countryName = sprite.data.values.countryName;
      }
      this.moveConfirmationPopUpText
        ?.setText(
          `${countryName} ${context.translateService.instant(
            'neg_phase_already_in_bilateral_popup'
          )}`
        )
        .setWordWrapWidth(this.moveConfirmationPopUp.displayWidth * 0.9);

        if (this.moveConfirmationPopUpText.height > 180) {
          this.moveConfirmationPopUpText.setFontSize(22);
        } else if (this.moveConfirmationPopUpText.height > 200) {
          this.moveConfirmationPopUpText.setFontSize(20);
        }

        this.moveConfirmationPopUpText.setPosition(
          this.moveConfirmationPopUp?.x,
          this.moveConfirmationPopUp?.y -
            this.moveConfirmationPopUp.displayHeight / 5
        );
        this.confirmButton.setX(this.moveConfirmationPopUp.x);
        this.confirmButtonText?.setPosition(
          this.confirmButton?.x,
          this.confirmButton?.y
        );
    } else if (typeOfUse === PopUpTypeOfUse.noPoints) {
      this.moveConfirmationPopUp?.setScale(
        this.moveConfirmationPopUp.scale * 0.9
      );
      this.moveConfirmationPopUpText
        ?.setText(
          `${context.translateService.instant(
            'not_enough_action_points_message'
          )}`
        )
        .setWordWrapWidth(this.moveConfirmationPopUp?.displayWidth * 0.9);

        if (this.moveConfirmationPopUpText.height > 180) {
          this.moveConfirmationPopUpText.setFontSize(22);
        } else if (this.moveConfirmationPopUpText.height > 200) {
          this.moveConfirmationPopUpText.setFontSize(20);
        }

      this.moveConfirmationPopUpText.setPosition(this.moveConfirmationPopUp?.x,
      this.moveConfirmationPopUp?.y - this.moveConfirmationPopUp.displayHeight / 5 );
      this.confirmButton.setX(this.moveConfirmationPopUp.x);
      this.confirmButtonText?.setPosition(this.confirmButton?.x, this.confirmButton?.y);
    }
  }
}

export const makeCoffeeBreakScene = (ctx) => {
  context = ctx;
  return CoffeeBreakScene;
};
