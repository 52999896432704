import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-voting-finish-modal-component',
  templateUrl: './voting-finish-modal.component.html',
  styleUrls: ['./voting-finish-modal.component.scss'],
})
export class VotingFinishModalComponent {
  @ViewChild('phaserWrapper') phaserWrapper!: ElementRef;
  constructor(
    private router: Router,
  ) {}

  onCheckGameResults() {
    this.router.navigate(['/presentation_proposal'], { replaceUrl: true });
  }
}
