import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { IUserGame, IUserGameNegotiationBilateral } from 'src/app/models/api.model';
import { BilateralDiscussionMessageType, BilateralAnimationStatus, BilateralStatus, GameStatus, INegotiation, Stance, TradeOffStatus } from 'src/app/models/game.model';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { GameStore } from 'src/app/state/gameState/game.store';
import { UserQuery } from '../../state/userState/user.query';
import { AvatarService } from '../../services/avatar-customization.service';
import _ from 'lodash';
import { WebSocketService } from '../../services/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { BilateralScene, makeBilateralScene } from 'src/app/phaser/scenes/bilateral-discussion.scene';
import { EmptyScene, makeEmptyScene } from 'src/app/phaser/scenes/empty.scene';
import { getStanceTranslationKey } from 'src/app/shared/game.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-bilateral-discussion',
  templateUrl: './bilateral-discussion.page.html',
  styleUrls: ['./bilateral-discussion.page.scss'],
})
export class BilateralDiscussionPage implements OnInit {
  @ViewChild('currentUserAvatar') currentUserAvatar: ElementRef;
  @ViewChild('negotiationAvatar') negotiationAvatar: ElementRef;
  gameId: string;
  userGames: IUserGame[] = [];
  userId: string;
  negotiatorUserId: string;
  incoming: boolean;
  bilateralStatus = BilateralStatus;
  tradeOffStatus = TradeOffStatus;
  negotiatorUserId$: Observable<string>;
  negotiatorCountryName: string;
  userGameNegotiations: IUserGameNegotiationBilateral[][];
  pendingAction = false;
  tradeOff = TradeOffStatus.inactive;
  normalUser = 0;
  remoteClosed = false;
  closeTalksWarning = false;
  redLineDeviate = false;
  redLineUncovered = false;
  loading = false;
  currentNegotiations: INegotiation[] = [];
  allNegotiations: INegotiation[] = [];
  gameStatus?: number = 0;
  actionPoints = 0;
  countryPoints = 0;
  councilPoints = 0;
  hasActionPoints = false;

  modal: {
    status: boolean;
    statusText?: string;
    redLineText?: string;
    title: string;
    body: string;
    button: string;
    index?: number;
  } = {
      status: false,
      statusText: '',
      title: '',
      body: '',
      button: '',
    };

  userResponse = {
    accept: 'ACCEPT',
    tradeOff: 'TRADE_OFF',
    refuse: 'REFUSE'
  };

  modalStatus = {
    accepted: 'ACCEPTED',
    tradeoffAccepted: 'TRADEOFF_ACCEPTED',
    closeTalks: 'CLOSE_TALKS',
    declined: 'DECLINED',
    reset: 'RESET',
    left: 'LEFT',
    redLineAccepted: 'RED_LINE_ACCEPTED',
    redLineDeviate: 'RED_LINE_DEVIATE',
    redLineUncovered: 'RED_LINE_UNCOVERED',
  };

  constructor(
    private router: Router,
    private gameQuery: GameQuery,
    private gameStore: GameStore,
    private gameService: GameService,
    private userQuery: UserQuery,
    private avatarService: AvatarService,
    private webSocketService: WebSocketService,
    private translateService: TranslateService,
    private gameInstanceService: GameInstanceService,
  ) {
    this.gameService.updateBilateralNavigation(false);
    this.gameService.resetBilateralAnimations();
  }

  addNegotiation(dIndex, tradeoff, allied) {
    const newNegotiation = {
      sendingUserGameNegotiationid: this.userGameNegotiations[0][dIndex].userGameNegotiationId,
      receivingUserGameNegotiationid: this.userGameNegotiations[1][dIndex].userGameNegotiationId,
      gameId: this.gameQuery.getValue().game.gameId,
      tradeoff,
      allied
    };
    this.currentNegotiations.push(newNegotiation);
    this.gameStore.update((state) => ({
      bilateralDiscussion: {
        ...state.bilateralDiscussion,
        currentState: [
          ...state.bilateralDiscussion.currentState,
          newNegotiation,
        ],
        pending: undefined,
      },
    }));
  }

  ngOnInit() {
    this.webSocketService.bilateralDiscussion
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: { type: string; data: any }) => {
          switch (data.type) {
            case BilateralDiscussionMessageType.proposeAlliance:
              this.userGameNegotiations[0][data.data].bilateralStatus = BilateralStatus.incomingAction;
              this.userGameNegotiations[0][data.data].bilateralInfo = false;
              this.userGameNegotiations[1][data.data].bilateralStatus = BilateralStatus.empty;
              this.userGameNegotiations[1][data.data].bilateralInfo = false;
              this.hasRedLineWarning(data.data);
              this.gameStore.update((state) => ({
                bilateralDiscussion: {
                  ...state.bilateralDiscussion,
                  pending: {
                    dimension: data.data,
                    incoming: true,
                  },
                },
              }));
              this.pendingAction = true;
              this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingTrue);
              this.calculatePoints(data.data);
              break;
            case BilateralDiscussionMessageType.acceptAlliance:
              this.userGameNegotiations[0][data.data].bilateralStatus = BilateralStatus.allied;
              this.userGameNegotiations[1][data.data].bilateralStatus = BilateralStatus.allied;
              this.userGameNegotiations[1][data.data].position = this.userGameNegotiations[0][data.data].position;
              this.userGameNegotiations[1][data.data].positionContent = this.userGameNegotiations[0][data.data].positionContent;
              this.pendingAction = false;
              this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
              this.addNegotiation(data.data, false, true);
              if (this.redLineDeviate) {
                this.redLineDeviate = false;
                this.modal.index = undefined;
                this.setModal(this.modalStatus.redLineAccepted);
              } else {
                this.setModal(this.modalStatus.accepted, data.data);
              }
              break;
            case BilateralDiscussionMessageType.refuseAlliance:
              this.userGameNegotiations[0][data.data].bilateralStatus = BilateralStatus.empty;
              this.userGameNegotiations[1][data.data].bilateralStatus = BilateralStatus.notAllied;
              this.addNegotiation(data.data, false, false);
              this.pendingAction = false;
              this.redLineDeviate = false;
              this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
              this.setModal(this.modalStatus.declined, data.data);
              break;
            case BilateralDiscussionMessageType.proposeTradeOff:
              this.userGameNegotiations[0][data.data].bilateralStatus = BilateralStatus.exchange;
              this.userGameNegotiations[1][data.data].bilateralStatus = BilateralStatus.exchange;
              const pendingIndex = this.userGameNegotiations[1].findIndex(ug => ug.bilateralStatus === BilateralStatus.pending);
              if (pendingIndex > -1) {
                this.calculatePoints(data.data);
                this.userGameNegotiations[1][pendingIndex].bilateralStatus = BilateralStatus.tradeoff;
                this.hasRedLineWarning(data.data);
                this.gameStore.update((state) => ({
                  bilateralDiscussion: {
                    ...state.bilateralDiscussion,
                    pending: {
                      dimension: pendingIndex,
                      incoming: false,
                      exchange: data.data,
                    },
                  },
                }));
              }
              break;
            case BilateralDiscussionMessageType.acceptTradeOff:
              this.userGameNegotiations[0][data.data].bilateralStatus = BilateralStatus.allied;
              this.userGameNegotiations[1][data.data].bilateralStatus = BilateralStatus.allied;
              this.addNegotiation(data.data, false, true);
              this.userGameNegotiations[0][data.data].position = this.userGameNegotiations[1][data.data].position;
              this.userGameNegotiations[0][data.data].positionContent = this.userGameNegotiations[1][data.data].positionContent;
              const acceptTradeOffIndex = this.userGameNegotiations[1].findIndex(ugn => ugn.bilateralStatus === BilateralStatus.pending);
              if (acceptTradeOffIndex > -1) { // should be true always
                this.userGameNegotiations[1][acceptTradeOffIndex].position = this.userGameNegotiations[0][acceptTradeOffIndex].position;
                this.userGameNegotiations[1][acceptTradeOffIndex].positionContent =
                  this.userGameNegotiations[0][acceptTradeOffIndex].positionContent;
                this.userGameNegotiations[0][acceptTradeOffIndex].bilateralStatus = BilateralStatus.allied;
                this.userGameNegotiations[1][acceptTradeOffIndex].bilateralStatus = BilateralStatus.allied;
                this.addNegotiation(acceptTradeOffIndex, true, true);
                this.pendingAction = false;
                this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
                this.tradeOff = TradeOffStatus.inactive;
                if (this.redLineDeviate) {
                  this.redLineDeviate = false;
                  this.modal.index = undefined;
                  this.setModal(this.modalStatus.redLineAccepted);
                } else {
                  this.setModal(this.modalStatus.tradeoffAccepted, data.data, acceptTradeOffIndex);
                }
              }
              break;
            case BilateralDiscussionMessageType.refuseTradeOff:
              this.userGameNegotiations[0][data.data].bilateralStatus = BilateralStatus.empty;
              const refuseTradeOffIndex = this.userGameNegotiations[1].findIndex(ugn => ugn.bilateralStatus === BilateralStatus.pending);
              if (refuseTradeOffIndex > -1) { // should be true always
                this.userGameNegotiations[0][refuseTradeOffIndex].bilateralStatus = BilateralStatus.empty;
                this.userGameNegotiations[1][refuseTradeOffIndex].bilateralStatus = BilateralStatus.empty;
                this.setModal(this.modalStatus.declined, refuseTradeOffIndex);
              }
              this.gameStore.update((state) => ({
                bilateralDiscussion: {
                  ...state.bilateralDiscussion,
                  pending: undefined,
                },
              }));
              this.pendingAction = false;
              this.redLineDeviate = false;
              this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
              this.tradeOff = TradeOffStatus.inactive;
              break;
            case BilateralDiscussionMessageType.closeTalks:
              this.remoteClosed = true;
              this.setModal(this.modalStatus.left);
              break;
            default:
              break;
          }
        }
      );
    this.gameQuery.game$.pipe(untilDestroyed(this)).subscribe((game) => {
      if (this.gameStatus !== game?.status && game?.status === GameStatus.finalVote) {
        this.gameStatus = game?.status;
        if (this.gameQuery.getBilateralUser()) {
          this.closeTalks();
        }
      }
      this.gameStatus = game?.status;
    });
  }

  ionViewWillEnter() {
    this.gameId = this.gameQuery.getValue().game.gameId;
    this.remoteClosed = false;
    this.closeTalksWarning = false;
    this.redLineDeviate = false;
    this.redLineUncovered = false;
    this.modal.status = false;
    this.incoming = this.gameQuery.getValue().bilateralDiscussion.incoming;
    this.userId = this.userQuery.getValue().user.userId;
    this.negotiatorUserId = this.gameQuery.getValue().bilateralDiscussion.userId;
    if (this.incoming) {
      this.normalUser = 1;
    } else {
      this.normalUser = this.gameQuery.getValue().game.userGames.find(ug => ug.userId === this.negotiatorUserId).user.normalUser;
    }

    this.userGames = this.gameQuery.getValue().game.userGames;
    this.negotiatorCountryName = this.gameService.getCountryByUserId(this.negotiatorUserId)?.name || '';
    this.userGameNegotiations = this.gameService.getBilateralDiscussion(this.userId, this.negotiatorUserId);
    this.currentNegotiations = Object.assign([], this.gameQuery.getValue().bilateralDiscussion.currentState);
    const pendingNegotiations = Object.assign({}, this.gameQuery.getValue().bilateralDiscussion.pending);

    this.pendingAction = false;
    this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
    this.tradeOff = TradeOffStatus.inactive;

    if (this.userGameNegotiations.length === 2) {
      this.currentNegotiations.forEach(negotiation => {
        const index = this.userGameNegotiations[0].findIndex(ugn => ugn.userGameNegotiationId === negotiation.sendingUserGameNegotiationid);
        if (index > -1 && this.userGameNegotiations[1][index].userGameNegotiationId === negotiation.receivingUserGameNegotiationid) {
          if (negotiation.allied) {
            this.userGameNegotiations[0][index].bilateralStatus = BilateralStatus.allied;
            this.userGameNegotiations[1][index].bilateralStatus = BilateralStatus.allied;
            if ((negotiation.tradeoff && !this.incoming) || (!negotiation.tradeoff && this.incoming)) {
              this.userGameNegotiations[0][index].position = this.userGameNegotiations[1][index].position;
              this.userGameNegotiations[0][index].positionContent = this.userGameNegotiations[1][index].positionContent;
            } else {
              this.userGameNegotiations[1][index].position = this.userGameNegotiations[0][index].position;
              this.userGameNegotiations[1][index].positionContent = this.userGameNegotiations[0][index].positionContent;
            }
          } else {
            this.userGameNegotiations[0][index].bilateralStatus = BilateralStatus.empty;
            this.userGameNegotiations[1][index].bilateralStatus = BilateralStatus.notAllied;
          }
        }
      });
      if (Object.keys(pendingNegotiations).length) {
        this.pendingAction = true;
        if (pendingNegotiations.hasOwnProperty('exchange')) {
          if (pendingNegotiations.incoming) {
            this.userGameNegotiations[0][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.incomingAction;
            this.userGameNegotiations[0][pendingNegotiations.dimension].bilateralInfo = false;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.empty;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralInfo = false;
            this.userGameNegotiations[0][pendingNegotiations.exchange].bilateralStatus = BilateralStatus.empty;
            this.userGameNegotiations[1][pendingNegotiations.exchange].bilateralStatus = BilateralStatus.pending;
            this.userGameNegotiations[1][pendingNegotiations.exchange].bilateralInfo = false;
            this.tradeOff = TradeOffStatus.proposed;
          } else {
            this.calculatePoints(pendingNegotiations.exchange);
            this.userGameNegotiations[0][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.empty;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.tradeoff;
            this.userGameNegotiations[0][pendingNegotiations.exchange].bilateralStatus = BilateralStatus.exchange;
            this.userGameNegotiations[1][pendingNegotiations.exchange].bilateralStatus = BilateralStatus.exchange;
            this.hasRedLineWarning(pendingNegotiations.exchange);
          }
        } else {
          if (pendingNegotiations.incoming) {
            this.userGameNegotiations[0][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.incomingAction;
            this.userGameNegotiations[0][pendingNegotiations.dimension].bilateralInfo = false;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.empty;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralInfo = false;
          } else {
            this.userGameNegotiations[0][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.empty;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralStatus = BilateralStatus.pending;
            this.userGameNegotiations[1][pendingNegotiations.dimension].bilateralInfo = false;
          }
        }
      }
      console.log('lalala', this.userGameNegotiations);
      this.allNegotiations = this.userGameNegotiations[0].map((ugn, index) => ({
        sendingUserGameNegotiationid: ugn.userGameNegotiationId,
        receivingUserGameNegotiationid: this.userGameNegotiations[1][index].userGameNegotiationId,
        gameId: this.gameId,
        tradeoff: false
      }));
      if (!this.incoming) {
        this.userGameNegotiations[1].forEach((ug, dIndex) => {
          if (ug.stance === Stance.redLine) {
            this.redLineUncovered = true;
            this.setModal(this.modalStatus.redLineUncovered, dIndex, null, ug.positionContent.name);
          }
        });
      }
    }

    console.log('userGameNegotiations', this.userGameNegotiations);

    if (!this.incoming) {
      if (!this.gameQuery.getValue().bilateralDiscussion.started) {
        this.loading = true;
        this.gameService.startBilateral(this.negotiatorUserId).then(res => {
          if (res && res.status === 'Success') {
            console.log(res);
          }
        }).catch(err => {
          console.log(err);
          // this.router.navigate(['/negotiation_phase']);
        }).finally(() => {
          this.gameStore.update(state => ({
            bilateralDiscussion: {
              ...state.bilateralDiscussion,
              started: true
            }
          }));
          this.loading = false;
        });
      }
    }

    this.gameInstanceService.switchScene(
      BilateralScene.key,
      makeBilateralScene
    );
    this.gameService.updateBilateralNavigation(true);

  }

  ionViewDidEnter() {
    // this.gameInstanceService.gameInstance.scale.on('resize', this.resize, this);
    this.gameInstanceService.gameInstance.scale.refresh();
  }

  ionViewWillLeave() {
    this.gameService.resetBilateralAnimations();
    this.gameInstanceService.switchScene(EmptyScene.key, makeEmptyScene);
    this.gameService.updateBilateralNavigation(false);
    this.gameService.updateModalOpen(false);
  }

  ionViewDidLeave() {
    this.gameService.updateBilateralNavigation(false);
  }

  calculatePoints(dIndex: number) {
    this.actionPoints = 0;
    this.countryPoints = 0;
    this.councilPoints = 0;
    const currentUserGame = this.gameQuery
      .getUserGames()
      ?.find((el: IUserGame) => el.userId === this.userId);
    this.hasActionPoints = currentUserGame?.actionPoint > 0;
    if (this.hasActionPoints) {
      this.loading = true;
      this.gameService.updatePlayerPosition({
        userGameNegotiationid: this.userGameNegotiations[0][dIndex].userGameNegotiationId,
        positionId: this.userGameNegotiations[1][dIndex].position,
        calculateOnly: true,
      }).toPromise().then(
        (res) => {
          if (res && res.status === 'Success' && res.data.items[0]) {
            this.actionPoints = (res.data.items[0] as any).actionPoint - currentUserGame.actionPoint;
            this.hasActionPoints = (res.data.items[0] as any).actionPoint >= 0;
            this.countryPoints = (res.data.items[0] as any).countryPoint - currentUserGame.countryPoint;
            this.councilPoints = (res.data.items[0] as any).councilPoint - currentUserGame.councilPoint;
          }
        }
      )
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false;
        });
    }
  }

  canNegotiate(dIndex: number) {
    return this.userGameNegotiations[1][dIndex].bilateralStatus === BilateralStatus.empty
      && this.userGameNegotiations[0][dIndex].bilateralStatus !== BilateralStatus.incomingAction
      && ((!this.pendingAction && !this.incoming) || this.tradeOff)
      && !this.userGameNegotiations[0][dIndex].positionContent.noposition
      && (!this.tradeOff ||
        this.userGameNegotiations[0][dIndex].positionContent.id !== this.userGameNegotiations[1][dIndex].positionContent.id);
  }

  setConfirm(pIndex: number, dIndex: number) {
    if (!this.canNegotiate(dIndex)) {
      return;
    }
    if (pIndex === 0
      && ![BilateralStatus.allied, BilateralStatus.notAllied].includes(this.userGameNegotiations[1][dIndex].bilateralStatus)) {
      if (this.incoming) {
        this.tradeOff = TradeOffStatus.selected;
      }
      this.userGameNegotiations[pIndex][dIndex].bilateralStatus = BilateralStatus.action;
      this.userGameNegotiations[pIndex] = this.userGameNegotiations[pIndex].map((ugn, index) => ({
        ...ugn,
        bilateralStatus: (index !== dIndex && ugn.bilateralStatus === BilateralStatus.action) ?
          BilateralStatus.empty : ugn.bilateralStatus,
      }));
    }
  }

  setInfo(pIndex: number, dIndex: number) {
    this.userGameNegotiations[pIndex][dIndex].bilateralInfo = true;
  }

  removeInfo(pIndex: number, dIndex: number) {
    this.userGameNegotiations[pIndex][dIndex].bilateralInfo = false;
  }

  cancelNegotiation(pIndex: number, dIndex: number) {
    if (this.tradeOff === TradeOffStatus.selected) {
      this.tradeOff = TradeOffStatus.inactive;
      const incomingActionIndex = this.userGameNegotiations[pIndex]
        .findIndex(ugn => ugn.bilateralStatus === BilateralStatus.incomingAction);
      if (incomingActionIndex !== -1) {
        this.userGameNegotiations[pIndex][incomingActionIndex].bilateralStatus = BilateralStatus.empty;
        this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.refuseAlliance, incomingActionIndex);
      }
    }
    this.userGameNegotiations[pIndex][dIndex].bilateralStatus = BilateralStatus.empty;
    this.gameStore.update((state) => ({
      bilateralDiscussion: {
        ...state.bilateralDiscussion,
        pending: undefined,
      },
    }));
    this.pendingAction = false;
    this.redLineDeviate = false;
    this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
  }

  confirmNegotiation(pIndex: number, dIndex: number) {
    if (pIndex === 0) {
      this.gameStore.update((state) => ({
        bilateralStatusAnimations: {
          ...state.bilateralStatusAnimations,
          proposeData: {
            ...state.bilateralStatusAnimations.proposeData,
            dimension: dIndex,
          },
        },
      }));
      this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.empty;
      this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.pending;
      this.userGameNegotiations[1][dIndex].bilateralInfo = false;
      this.pendingAction = true;
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingTrue);
      if (this.normalUser) {
        if (this.tradeOff) {
          this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.proposeTradeOff, dIndex);
          this.tradeOff = TradeOffStatus.proposed;
          this.gameStore.update((state) => ({
            bilateralDiscussion: {
              ...state.bilateralDiscussion,
              pending: {
                ...state.bilateralDiscussion.pending,
                exchange: dIndex,
              },
            },
          }));
        } else {
          this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.proposeAlliance, dIndex);
          this.gameStore.update((state) => ({
            bilateralDiscussion: {
              ...state.bilateralDiscussion,
              pending: {
                dimension: dIndex,
                incoming: false,
              },
            },
          }));
        }
      } else {
        timer(2000).pipe(untilDestroyed(this)).subscribe(() => {
          if (this.userGameNegotiations[pIndex][dIndex].position === this.userGameNegotiations[1][dIndex].position
            || this.userGameNegotiations[1][dIndex].positionContent.noposition) {
            this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.allied;
            this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.allied;
            this.addNegotiation(dIndex, false, true);
            this.userGameNegotiations[1][dIndex].position = this.userGameNegotiations[0][dIndex].position;
            this.userGameNegotiations[1][dIndex].positionContent = this.userGameNegotiations[0][dIndex].positionContent;
            this.pendingAction = false;
            this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
            this.setModal(this.modalStatus.accepted, dIndex);
          } else if (this.userGameNegotiations[1][dIndex].stance === Stance.redLine) {
            this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.notAllied;
            this.userGameNegotiations[1][dIndex].bilateralInfo = false;
            this.addNegotiation(dIndex, false, false);
            this.setModal(this.modalStatus.declined, dIndex);
            this.pendingAction = false;
            this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
          } else {
            let noPositionIndex = -1;
            let negotiableIndex = -1;
            let sensitiveIndex = -1;
            this.userGameNegotiations[pIndex]
              .forEach((ugn, index) => {
                if (index !== dIndex
                  && ugn.bilateralStatus === BilateralStatus.empty
                  && this.userGameNegotiations[pIndex][index].positionContent.id !== this.userGameNegotiations[1 - pIndex][index].positionContent.id) {
                  if (noPositionIndex === -1
                    && ugn.positionContent.noposition) {
                    noPositionIndex = index;
                  } else if (negotiableIndex === -1 && ugn.stance === Stance.negotiable
                    && !this.userGameNegotiations[1 - pIndex][index].positionContent.noposition) {
                    negotiableIndex = index;
                  } else if (sensitiveIndex === -1 && ugn.stance === Stance.sensitive
                    && !this.userGameNegotiations[1 - pIndex][index].positionContent.noposition) {
                    sensitiveIndex = index;
                  }
                }
              });
            if (noPositionIndex > -1) {
              this.selectAlliance(pIndex, dIndex, noPositionIndex);
            } else if (negotiableIndex > -1) {
              this.selectAlliance(pIndex, dIndex, negotiableIndex);
            } else if (sensitiveIndex > -1) {
              this.selectAlliance(pIndex, dIndex, sensitiveIndex);
            } else {
              if (this.userGameNegotiations[1][dIndex].stance === Stance.negotiable ||
                (
                  this.userGameNegotiations[1][dIndex].stance === Stance.sensitive
                  && this.gameQuery.getGameDifficultyLevel() === 'Novice'
                )
              ) {
                this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.allied;
                this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.allied;
                this.addNegotiation(dIndex, false, true);
                this.userGameNegotiations[1][dIndex].position = this.userGameNegotiations[0][dIndex].position;
                this.userGameNegotiations[1][dIndex].positionContent = this.userGameNegotiations[0][dIndex].positionContent;
                this.pendingAction = false;
                this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
                this.setModal(this.modalStatus.accepted, dIndex);
              } else {
                this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.notAllied;
                this.addNegotiation(dIndex, false, false);
                this.pendingAction = false;
                this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
                this.setModal(this.modalStatus.declined, dIndex);
              }
            }
          }
        });
      }
    }
  }

  selectAlliance(pIndex: number, dIndex: number, stanceIndex: number) {
    const options = ['Accept', 'TradeOff'];
    const randomIndex = Math.floor(Math.random() * options.length);
    if (this.userGameNegotiations[1][dIndex].stance === Stance.negotiable && randomIndex === 0) {
      this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.allied;
      this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.allied;
      this.addNegotiation(dIndex, false, true);
      this.userGameNegotiations[1][dIndex].position = this.userGameNegotiations[0][dIndex].position;
      this.userGameNegotiations[1][dIndex].positionContent = this.userGameNegotiations[0][dIndex].positionContent;
      this.pendingAction = false;
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
      this.setModal(this.modalStatus.accepted, dIndex);
    } else {
      this.userGameNegotiations[pIndex][stanceIndex].bilateralStatus = BilateralStatus.exchange;
      this.userGameNegotiations[1][stanceIndex].bilateralStatus = BilateralStatus.exchange;
      this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.tradeoff;
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingTrue);
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.exchange);
      this.calculatePoints(stanceIndex);
      this.gameStore.update((state) => ({
        bilateralDiscussion: {
          ...state.bilateralDiscussion,
          pending: {
            dimension: dIndex,
            incoming: false,
            exchange: stanceIndex,
          },
        },
      }));
    }
  }

  hasRedLineWarning(index: number) {
    if (this.userGameNegotiations[0][index].stance === Stance.redLine) {
      this.redLineDeviate = true;
      this.setModal(this.modalStatus.redLineDeviate, index);
    }
  }

  async acceptTradeoff(pIndex: number, dIndex: number, deviate = false) {
    this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.tradeoffAccepted);
    if (pIndex === 1) {
      if (this.normalUser) {
        await this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.acceptTradeOff, dIndex);
      }
      const exchangeIndex = this.userGameNegotiations[pIndex].findIndex(ugn => ugn.bilateralStatus === BilateralStatus.exchange);
      if (exchangeIndex > -1) { // should be true always
        this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.allied;
        this.userGameNegotiations[pIndex][dIndex].bilateralStatus = BilateralStatus.allied;
        this.addNegotiation(dIndex, false, true);
        this.userGameNegotiations[1][dIndex].position = this.userGameNegotiations[0][dIndex].position;
        this.userGameNegotiations[1][dIndex].positionContent = this.userGameNegotiations[0][dIndex].positionContent;

        this.userGameNegotiations[0][exchangeIndex].position = this.userGameNegotiations[1][exchangeIndex].position;
        this.userGameNegotiations[0][exchangeIndex].positionContent = this.userGameNegotiations[1][exchangeIndex].positionContent;
        this.userGameNegotiations[0][exchangeIndex].bilateralStatus = BilateralStatus.allied;
        this.userGameNegotiations[pIndex][exchangeIndex].bilateralStatus = BilateralStatus.allied;
        this.addNegotiation(exchangeIndex, true, true);
        this.pendingAction = false;
        this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
        if (this.redLineDeviate) {
          this.redLineDeviate = false;
          this.modal.index = undefined;
          this.setModal(this.modalStatus.redLineAccepted);
        } else {
          this.setModal(this.modalStatus.tradeoffAccepted, dIndex, exchangeIndex);
        }
      }
    }
  }

  setModal(status: string, index?: number, tradeOffIndex?: number, redLinePositionText?: string) {
    if (status === this.modalStatus.accepted) {
      this.gameService.updateBilateralAnimationsStatus(status as BilateralAnimationStatus);
      this.modal = {
        status: true,
        statusText: this.modalStatus.accepted,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_accepted_speech_bubble_title',
          { value: this.negotiatorCountryName }),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_accepted_speech_bubble_body',
          { value: this.userGameNegotiations[0][index].dimensionContent.name }),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_accepted_speech_bubble_button')
      };
    } else if (status === this.modalStatus.declined) {
      this.gameService.updateBilateralAnimationsStatus(status as BilateralAnimationStatus);
      this.modal = {
        status: true,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_declined_speech_bubble_title',
          { value: this.negotiatorCountryName }),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_declined_speech_bubble_body',
          { value: this.userGameNegotiations[0][index].dimensionContent.name }),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_declined_speech_bubble_button')
      };
    } else if (status === this.modalStatus.tradeoffAccepted) {
      this.modal = {
        status: true,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_tradeoff_accepted_speech_bubble_title'),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_tradeoff_accepted_speech_bubble_body',
          {
            userDimension: this.userGameNegotiations[0][index].dimensionContent.name,
            tradeOffDimension: this.userGameNegotiations[0][tradeOffIndex].dimensionContent.name
          }),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_tradeoff_accepted_speech_bubble_button')
      };
    } else if (status === this.modalStatus.left) {
      this.modal = {
        status: true,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_closed_discussion_title',
          { value: this.negotiatorCountryName }),
        body: '',
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_closed_discussion_button')
      };
    } else if (status === this.modalStatus.redLineDeviate) {
      this.modal = {
        status: true,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_deviate_redline_modal_title'),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_deviate_redline_modal_body'),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_deviate_redline_modal_confirm_button'),
        index
      };
    } else if (status === this.modalStatus.redLineAccepted) {
      this.modal = {
        status: true,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_redline_accepted_speech_bubble_title'),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_redline_accepted_speech_bubble_body'),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_redline_accepted_speech_bubble_button'),
      };
    } else if (status === this.modalStatus.redLineUncovered) {
      this.modal = {
        status: true,
        statusText: this.modalStatus.redLineUncovered,
        redLineText: redLinePositionText,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_redline_uncovered_speech_bubble_title',
          { country: this.negotiatorCountryName, dimension: index + 1 }),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_redline_uncovered_speech_bubble_body',
          {
            country: this.negotiatorCountryName,
            description: this.userGameNegotiations[1][index].positionContent.name
          }),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_redline_uncovered_speech_bubble_button'),
      };
    } else if (status === this.modalStatus.closeTalks) {
      this.modal = {
        status: true,
        title: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_close_title'),
        body: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_close_body'),
        button: this.translateService.instant(
          'bilateral_talks_dimension_info_area_alliance_close_cancel_button'),
      };
    } else {
      this.redLineUncovered = false;
      this.modal.index = undefined;
      this.modal.status = false;
    }
  }

  userInteraction(action: string) {
    const dIndex = this.userGameNegotiations[0].findIndex(ug => ug.bilateralStatus === BilateralStatus.incomingAction);
    if (dIndex < 0) {
      return;
    }
    if (action === this.userResponse.accept) {
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.acceptAlliance);
      this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.acceptAlliance, dIndex)
        .then(() => {
          this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.allied;
          this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.allied;
          this.addNegotiation(dIndex, false, true);
          this.userGameNegotiations[0][dIndex].position = this.userGameNegotiations[1][dIndex].position;
          this.userGameNegotiations[0][dIndex].positionContent = this.userGameNegotiations[1][dIndex].positionContent;
          this.pendingAction = false;
          console.log('accept', this.redLineDeviate);
          this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
        });
    } else if (action === this.userResponse.refuse) {
      // this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.declined);
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.refuseAlliance);
      this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.refuseAlliance, dIndex)
        .then(() => {
          this.userGameNegotiations[0][dIndex].bilateralStatus = BilateralStatus.empty;
          this.userGameNegotiations[1][dIndex].bilateralStatus = BilateralStatus.empty;
          this.gameStore.update((state) => ({
            bilateralDiscussion: {
              ...state.bilateralDiscussion,
              pending: undefined,
            },
          }));
          this.pendingAction = false;
          this.redLineDeviate = false;
          this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
        });
    } else {
      this.tradeOff = TradeOffStatus.active;
    }
  }

  async refuseTradeoff(pIndex: number, dIndex: number) {
    this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.refuseTradeOff);
    if (pIndex === 1) {
      if (this.normalUser) {
        this.redLineDeviate = false;
        await this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.refuseTradeOff, dIndex);
      }
      this.userGameNegotiations[pIndex][dIndex].bilateralStatus = BilateralStatus.empty;
      const exchangeIndex = this.userGameNegotiations[pIndex].findIndex(ugn => ugn.bilateralStatus === BilateralStatus.exchange);
      if (exchangeIndex > -1) { // should be true always
        this.userGameNegotiations[0][exchangeIndex].bilateralStatus = BilateralStatus.empty;
        this.userGameNegotiations[pIndex][exchangeIndex].bilateralStatus = BilateralStatus.empty;
        this.pendingAction = false;
        this.redLineDeviate = false;
        this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
      }
      this.gameStore.update((state) => ({
        bilateralDiscussion: {
          ...state.bilateralDiscussion,
          pending: undefined,
        },
      }));
      this.pendingAction = false;
      this.redLineDeviate = false;
      this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
    }
  }

  getStanceClass(stance: string) {
    return stance.toLowerCase().replace(/ /g, '_');
  }

  getStanceTranslation(stance: string) {
    return getStanceTranslationKey(stance);
  }

  clearBilateralState() {
    this.gameStore.update({
      bilateralDiscussion: {
        userId: '',
        incoming: false,
        status: BilateralStatus.empty,
        started: false,
        currentState: [],
        pending: undefined,
      }
    });
  }

  closeTalksWarningModal() {
    this.closeTalksWarning = true;
    this.setModal(this.modalStatus.closeTalks);
  }

  closeTalksWarningButton() {
    this.closeTalks();
    this.resetModal();
  }

  resetModal() {
    this.setModal(this.modalStatus.reset);
  }

  closeTalks(remote = false) {
    this.closeTalksWarning = false;
    this.pendingAction = false;
    this.redLineDeviate = false;
    this.gameService.updateBilateralAnimationsStatus(BilateralAnimationStatus.pendingFalse);
    this.tradeOff = TradeOffStatus.inactive;
    if (!this.incoming) {
      let status = 'success';
      let negotiations = this.currentNegotiations.filter(negotiation => negotiation.allied)
        .map(negotiation => _.omit(negotiation, ['allied']));
      if (!negotiations.length) {
        status = 'fail';
        negotiations = this.allNegotiations;
      }
      this.loading = true;
      this.gameService.postBilataralStatus(negotiations, status)
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false;
          if (this.normalUser && !remote) {
            this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.closeTalks);
          }
          if (this.gameStatus !== GameStatus.finalVote) {
            if (this.gameQuery.getTypeOfPhase() === 'break') {
              this.router.navigate(['/coffee-break']);
            } else {
              this.router.navigate(['/negotiation_phase']);
            }
          }
          this.clearBilateralState();
        });
    } else {
      if (!remote) {
        this.gameService.negotiationMessage(this.negotiatorUserId, BilateralDiscussionMessageType.closeTalks);
      }
      this.clearBilateralState();
      if (this.gameStatus !== GameStatus.finalVote) {
        if (this.gameQuery.getTypeOfPhase() === 'break') {
          this.router.navigate(['/coffee-break']);
        } else {
          this.router.navigate(['/negotiation_phase']);
        }
      }
    }
  }

  isDisabled(dIndex: number) {
    if ((!this.pendingAction || this.userGameNegotiations.length !== 2) &&
      !Object.values(
        this.gameQuery.getValue().bilateralStatusAnimations
      ).includes(true)) {
      return false;
    }
    if (
      (!(
        this.tradeOff &&
        this.tradeOff !== TradeOffStatus.proposed &&
        this.userGameNegotiations[0][dIndex].bilateralStatus !==
        BilateralStatus.pending &&
        !this.userGameNegotiations[0][dIndex].positionContent.noposition &&
        this.userGameNegotiations[0][dIndex].positionContent.id !==
        this.userGameNegotiations[1][dIndex].positionContent.id
      ) &&
        ![
          BilateralStatus.pending,
          BilateralStatus.exchange,
          BilateralStatus.tradeoff,
        ].includes(this.userGameNegotiations[1][dIndex].bilateralStatus) &&
        this.userGameNegotiations[0][dIndex].bilateralStatus !==
        BilateralStatus.incomingAction)
    ) {
      return true;
    }
  }

  isTradeOffAvailable() {
    let available = false;
    if (!this.pendingAction && this.incoming) {
      return available;
    }
    this.userGameNegotiations[0].every((ug, dIndex) => {
      if (ug.bilateralStatus === BilateralStatus.empty &&
        this.userGameNegotiations[1][dIndex].bilateralStatus !== BilateralStatus.allied
        && !ug.positionContent.noposition
        && ug.positionContent.id !== this.userGameNegotiations[1][dIndex].positionContent.id) {
        available = true;
        return false;
      }
      return true;
    });
    return available;
  }

  onClickModalButton() {
    if (this.remoteClosed) {
      this.closeTalks(true);
    } else if (this.closeTalksWarning) {
      this.closeTalksWarning = false;
      this.setModal(this.modalStatus.reset);
    } else if (this.redLineDeviate) {
      this.modal.index = undefined;
      this.setModal(this.modalStatus.reset);
    } else {
      this.setModal(this.modalStatus.reset);
    }
  }

  preventClick(e: any) {
    e.stopPropagation();
  }
}
