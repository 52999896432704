export const squarePositionsD1 = [
  { name: 'squareC1', x: 905, y: 610 - 33 },
  { name: 'squareC2', x: 1240, y: 610 - 33 },
  { name: 'squareC3', x: 567, y: 610 - 33 },
  { name: 'squareC4', x: 1580, y: 610 - 33 },
  { name: 'squareT1', x: 735, y: 512 - 30 }, //upleft
  { name: 'squareT2', x: 1410, y: 512 - 30 }, //upright
  { name: 'squareL', x: 398, y: 512 - 30 }, //left
  { name: 'squareR', x: 1749, y: 512 - 30 }, //right
];
export const squarePositionsOtherDs = [
  { name: 'squareC1', x: 902, y: 665 - 32 },
  { name: 'squareC2', x: 1238, y: 665 - 32 },
  { name: 'squareC3', x: 565, y: 665 - 32 },
  { name: 'squareC4', x: 1575, y: 665 - 32 },
  { name: 'squareT1', x: 735, y: 568 - 30 }, //upleft
  { name: 'squareT2', x: 1405, y: 568 - 30 }, //upright
  { name: 'squareL', x: 396, y: 567 - 30 }, //left
  { name: 'squareR', x: 1742, y: 568 - 30 }, //right
];
export const undecidedPosD1 = [
  { place: 'up1', x: 800, y: 675 - 40 },
  { place: 'up2', x: 850, y: 675 - 40 },
  { place: 'up3', x: 900, y: 675 - 40 },
  { place: 'up4', x: 950, y: 675 - 40 },
  { place: 'center1', x: 1070, y: 725 - 40 },
  { place: 'center2', x: 1030, y: 725 - 40 },
  { place: 'center3', x: 990, y: 725 - 40 },
  { place: 'center4', x: 950, y: 725 - 40 },
  { place: 'center5', x: 910, y: 725 - 40 },
  { place: 'center6', x: 870, y: 725 - 40 },
  { place: 'center7', x: 830, y: 725 - 40 },
  { place: 'center8', x: 790, y: 725 - 40 },
  { place: 'center9', x: 750, y: 725 - 40 },
  { place: 'downCenter1', x: 785, y: 761 - 40 },
  { place: 'downCenter2', x: 825, y: 761 - 40 },
  { place: 'downCenter3', x: 865, y: 761 - 40 },
  { place: 'downCenter4', x: 900, y: 761 - 40 },
  { place: 'downCenter5', x: 940, y: 761 - 40 },
  { place: 'downCenter6', x: 980, y: 761 - 40 },
  { place: 'downCenter7', x: 1020, y: 761 - 40 },
  { place: 'downCenter8', x: 905, y: 780 - 40 },
  { place: 'downCenter9', x: 995, y: 780 - 40 },
  { place: 'downCenter10', x: 825, y: 790 - 40 },
  { place: 'downCenter11', x: 865, y: 790 - 40 },
  { place: 'downCenter12', x: 955, y: 790 - 40 },
  { place: 'down1', x: 891, y: 826 - 40 },
  { place: 'down2', x: 940, y: 816 - 40 },
  { place: 'myCountry', x: 920, y: 816 - 40 },
];
export const undecidedPosOtherDs = [
  {
    place: 'up1',
    x: 800,
    y: undecidedPosD1.find((el) => el.place === 'up1').y + 60,
  },
  {
    place: 'up2',
    x: 850,
    y: undecidedPosD1.find((el) => el.place === 'up2').y + 60,
  },
  {
    place: 'up3',
    x: 900,
    y: undecidedPosD1.find((el) => el.place === 'up3').y + 60,
  },
  {
    place: 'up4',
    x: 950,
    y: undecidedPosD1.find((el) => el.place === 'up4').y + 60,
  },
  {
    place: 'center1',
    x: 1070,
    y: undecidedPosD1.find((el) => el.place === 'center1').y + 60,
  },
  {
    place: 'center2',
    x: 1030,
    y: undecidedPosD1.find((el) => el.place === 'center2').y + 60,
  },
  {
    place: 'center3',
    x: 990,
    y: undecidedPosD1.find((el) => el.place === 'center3').y + 60,
  },
  {
    place: 'center4',
    x: 950,
    y: undecidedPosD1.find((el) => el.place === 'center4').y + 60,
  },
  {
    place: 'center5',
    x: 910,
    y: undecidedPosD1.find((el) => el.place === 'center5').y + 60,
  },
  {
    place: 'center6',
    x: 870,
    y: undecidedPosD1.find((el) => el.place === 'center6').y + 60,
  },
  {
    place: 'center7',
    x: 830,
    y: undecidedPosD1.find((el) => el.place === 'center7').y + 60,
  },
  {
    place: 'center8',
    x: 790,
    y: undecidedPosD1.find((el) => el.place === 'center8').y + 60,
  },
  {
    place: 'center9',
    x: 750,
    y: undecidedPosD1.find((el) => el.place === 'center9').y + 60,
  },
  {
    place: 'downCenter1',
    x: 785,
    y: undecidedPosD1.find((el) => el.place === 'downCenter1').y + 60,
  },
  {
    place: 'downCenter2',
    x: 825,
    y: undecidedPosD1.find((el) => el.place === 'downCenter2').y + 60,
  },
  {
    place: 'downCenter3',
    x: 865,
    y: undecidedPosD1.find((el) => el.place === 'downCenter3').y + 60,
  },
  {
    place: 'downCenter4',
    x: 900,
    y: undecidedPosD1.find((el) => el.place === 'downCenter4').y + 60,
  },
  {
    place: 'downCenter5',
    x: 940,
    y: undecidedPosD1.find((el) => el.place === 'downCenter5').y + 60,
  },
  {
    place: 'downCenter6',
    x: 980,
    y: undecidedPosD1.find((el) => el.place === 'downCenter6').y + 60,
  },
  {
    place: 'downCenter7',
    x: 1020,
    y: undecidedPosD1.find((el) => el.place === 'downCenter7').y + 60,
  },
  {
    place: 'downCenter8',
    x: 905,
    y: undecidedPosD1.find((el) => el.place === 'downCenter8').y + 60,
  },
  {
    place: 'downCenter9',
    x: 995,
    y: undecidedPosD1.find((el) => el.place === 'downCenter9').y + 60,
  },
  {
    place: 'downCenter10',
    x: 825,
    y: undecidedPosD1.find((el) => el.place === 'downCenter10').y + 60,
  },
  {
    place: 'downCenter11',
    x: 865,
    y: undecidedPosD1.find((el) => el.place === 'downCenter11').y + 60,
  },
  {
    place: 'downCenter12',
    x: 955,
    y: undecidedPosD1.find((el) => el.place === 'downCenter12').y + 60,
  },
  {
    place: 'down1',
    x: 891,
    y: undecidedPosD1.find((el) => el.place === 'down1').y + 60,
  },
  {
    place: 'down2',
    x: 940,
    y: undecidedPosD1.find((el) => el.place === 'down2').y + 60,
  },
  {
    place: 'myCountry',
    x: 920,
    y: undecidedPosD1.find((el) => el.place === 'myCountry').y + 60,
  },
];
export const boardPositioningCenterD1 = [
  { place: 'up1', x: 740, y: 410 - 40 },
  { place: 'up2', x: 690, y: 430 - 40 },
  { place: 'up3', x: 750, y: 431 - 40 },
  { place: 'upCenter1', x: 840, y: 451 - 40 },
  { place: 'upCenter2', x: 780, y: 450 - 40 },
  { place: 'upCenter3', x: 720, y: 452 - 40 },
  { place: 'upCenter4', x: 660, y: 453 - 40 },
  { place: 'center1', x: 610, y: 470 - 40 },
  { place: 'center2', x: 670, y: 471 - 40 },
  { place: 'center3', x: 730, y: 472 - 40 },
  { place: 'center4', x: 760, y: 473 - 40 },
  { place: 'center5', x: 820, y: 474 - 40 },
  { place: 'center6', x: 850, y: 468 - 40 },
  { place: 'center7', x: 880, y: 469 - 40 },
  { place: 'downCenter1', x: 840, y: 491 - 40 },
  { place: 'downCenter2', x: 830, y: 492 - 40 },
  { place: 'downCenter3', x: 770, y: 489 - 40 },
  { place: 'downCenter4', x: 710, y: 488 - 40 },
  { place: 'downCenter5', x: 650, y: 487 - 40 },
  { place: 'downCenter6', x: 648, y: 487.5 - 40 },
  { place: 'down1', x: 710, y: 521 - 40 },
  { place: 'down2', x: 710, y: 522 - 40 },
  { place: 'down3', x: 730, y: 523 - 40 },
  { place: 'down4', x: 760, y: 519 - 40 },
  { place: 'down5', x: 760, y: 537 - 40 },
  { place: 'down6', x: 740, y: 540 - 40 },
  { place: 'down7', x: 740, y: 544 - 40 },
  { place: 'myCountry', x: 740, y: 544 - 39 },
];
export const boardPositioningCenterOtherDs = [
  { place: 'up1', x: 740, y: 410 + 10 },
  { place: 'up2', x: 690, y: 430 + 10 },
  { place: 'up3', x: 750, y: 431 + 10 },
  { place: 'upCenter1', x: 840, y: 450 + 10 },
  { place: 'upCenter2', x: 780, y: 451 + 10 },
  { place: 'upCenter3', x: 720, y: 452 + 10 },
  { place: 'upCenter4', x: 660, y: 453 + 10 },
  { place: 'center1', x: 610, y: 471 + 10 },
  { place: 'center2', x: 670, y: 472 + 10 },
  { place: 'center3', x: 730, y: 473 + 10 },
  { place: 'center4', x: 760, y: 469 + 10 },
  { place: 'center5', x: 820, y: 468 + 10 },
  { place: 'center6', x: 850, y: 467 + 10 },
  { place: 'center7', x: 880, y: 474 + 10 },
  { place: 'downCenter1', x: 860, y: 490 + 10 },
  { place: 'downCenter2', x: 830, y: 491 + 10 },
  { place: 'downCenter3', x: 780, y: 492 + 10 },
  { place: 'downCenter4', x: 710, y: 493 + 10 },
  { place: 'downCenter5', x: 650, y: 489 + 10 },
  { place: 'downCenter6', x: 630, y: 480.5 },
  { place: 'down1', x: 690, y: 521 + 10 },
  { place: 'down2', x: 710, y: 522 + 10 },
  { place: 'down3', x: 740, y: 523 + 10 },
  { place: 'down4', x: 770, y: 519 + 10 },
  { place: 'down5', x: 760, y: 537 + 10 },
  { place: 'down6', x: 740, y: 540 + 10 },
  { place: 'down7', x: 735, y: 544 + 10 },
  { place: 'myCountry', x: 740, y: 544 + 15 },
];
export const boardPositioningCenterTenD1 = [
  { place: 'up2', x: 730, y: 370 },
  { place: 'upCenter3', x: 760, y: 395 },
  { place: 'center2', x: 820, y: 410 },
  { place: 'center4', x: 850, y: 440 },
  { place: 'center5', x: 810, y: 460 },
  { place: 'downCenter1', x: 770, y: 490 },
  { place: 'downCenter5', x: 740, y: 500 },
  { place: 'down1', x: 700, y: 475 },
  { place: 'down3', x: 660, y: 445 },
  { place: 'down5', x: 710, y: 445 },
  { place: 'down5', x: 710, y: 445 },
  {
    place: 'myCountry',
    x: 720,
    y: 500,
  },
];
export const boardPositioningCenterTenOtherDs = [
  { place: 'up2', x: 690, y: 430 + 10 },
  { place: 'upCenter3', x: 720, y: 452 + 10 },
  { place: 'center2', x: 670, y: 471 + 10 },
  { place: 'center4', x: 760, y: 473 + 10 },
  { place: 'center5', x: 820, y: 474 + 10 },
  { place: 'downCenter1', x: 840, y: 491 + 10 },
  { place: 'downCenter5', x: 650, y: 487 + 10 },
  { place: 'down1', x: 710, y: 521 + 10 },
  { place: 'down3', x: 730, y: 523 + 10 },
  { place: 'down5', x: 760, y: 537 + 10 },
  {
    place: 'myCountry',
    x: 720,
    y: 537,
  },
];
export const countriesBoardPositionsD1 = {
  4: [
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterD1.find((el) => el.place === 'myCountry').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x: boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y,
    },
    {
      countryCode: 'LV',
      x: boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y,
    },
    {
      countryCode: 'LT',
      x: boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y,
    },
    {
      countryCode: 'LU',
      x: boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y,
    },
    {
      countryCode: 'MT',
      x: boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y,
    },
    {
      countryCode: 'NL',
      x: boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y,
    },
  ],
  5: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x +
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x +
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x +
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x +
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x +
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x +
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x + 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y,
    },
  ],
  2: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x -
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x -
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x -
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x -
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x -
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x -
        325,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x - 325,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y,
    },
  ],
  7: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x +
        655,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x +
        655,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x +
        655,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x +
        655,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x +
        655,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x +
        655,
      y: boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x + 655,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y,
    },
  ],
  3: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y - 98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y - 98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y - 98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y - 98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y - 98,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y - 98,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y - 98,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y - 98,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y - 98,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y - 98,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y - 98,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y - 98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x -
        170,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y -
        98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x -
        170,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y -
        98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x -
        170,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y -
        98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x -
        170,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y -
        98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x -
        170,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y -
        98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x -
        170,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y -
        98,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x - 170,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y - 98,
    },
  ],

  6: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y - 98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y - 98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y - 98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y - 98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y - 98,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y - 98,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y - 98,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y - 98,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y - 98,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y - 98,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y - 98,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y - 98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x +
        495,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y -
        98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x +
        495,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y -
        98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x +
        495,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y -
        98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x +
        495,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y -
        98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x +
        495,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y -
        98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x +
        495,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y -
        98,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x + 495,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y - 98,
    },
  ],
  1: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y - 98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y - 98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y - 98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y - 98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y - 98,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y - 98,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y - 98,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y - 98,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y - 98,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y - 98,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y - 98,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y - 98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x -
        510,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y -
        98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x -
        510,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y -
        98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x -
        510,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y -
        98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x -
        510,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y -
        98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x -
        510,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y -
        98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x -
        510,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y -
        98,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x - 510,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y - 98,
    },
  ],
  8: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'myCountry').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'myCountry').y - 98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterD1.find((el) => el.place === 'up1').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterD1.find((el) => el.place === 'up2').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterD1.find((el) => el.place === 'up3').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter1').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter1').y - 98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter2').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter2').y - 98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter3').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter3').y - 98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'upCenter4').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'upCenter4').y - 98,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center1').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center1').y - 98,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterD1.find((el) => el.place === 'center2').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center2').y - 98,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center3').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center3').y - 98,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center4').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center4').y - 98,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterD1.find((el) => el.place === 'center5').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center5').y - 98,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterD1.find((el) => el.place === 'center6').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center6').y - 98,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterD1.find((el) => el.place === 'center7').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'center7').y - 98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').x +
        820,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter1').y -
        98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').x +
        820,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter2').y -
        98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').x +
        820,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter3').y -
        98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').x +
        820,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter4').y -
        98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').x +
        820,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter5').y -
        98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').x +
        820,
      y:
        boardPositioningCenterD1.find((el) => el.place === 'downCenter6').y -
        98,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterD1.find((el) => el.place === 'down1').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterD1.find((el) => el.place === 'down2').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterD1.find((el) => el.place === 'down3').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterD1.find((el) => el.place === 'down4').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterD1.find((el) => el.place === 'down5').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterD1.find((el) => el.place === 'down6').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterD1.find((el) => el.place === 'down7').x + 820,
      y: boardPositioningCenterD1.find((el) => el.place === 'down7').y - 98,
    },
  ],
  0: [
    {
      countryCode: 'myCountry',
      x: undecidedPosD1.find((el) => el.place === 'myCountry').x,
      y: undecidedPosD1.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: undecidedPosD1.find((el) => el.place === 'up1').x,
      y: undecidedPosD1.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: undecidedPosD1.find((el) => el.place === 'up2').x,
      y: undecidedPosD1.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: undecidedPosD1.find((el) => el.place === 'up3').x,
      y: undecidedPosD1.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x: undecidedPosD1.find((el) => el.place === 'up4').x,
      y: undecidedPosD1.find((el) => el.place === 'up4').y,
    },
    {
      countryCode: 'CY',
      x: undecidedPosD1.find((el) => el.place === 'center1').x,
      y: undecidedPosD1.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'CZ',
      x: undecidedPosD1.find((el) => el.place === 'center2').x,
      y: undecidedPosD1.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'DK',
      x: undecidedPosD1.find((el) => el.place === 'center3').x,
      y: undecidedPosD1.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'EE',
      x: undecidedPosD1.find((el) => el.place === 'center4').x,
      y: undecidedPosD1.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'FI',
      x: undecidedPosD1.find((el) => el.place === 'center5').x,
      y: undecidedPosD1.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'FR',
      x: undecidedPosD1.find((el) => el.place === 'center6').x,
      y: undecidedPosD1.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'DE',
      x: undecidedPosD1.find((el) => el.place === 'center7').x,
      y: undecidedPosD1.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'GR',
      x: undecidedPosD1.find((el) => el.place === 'center8').x,
      y: undecidedPosD1.find((el) => el.place === 'center8').y,
    },
    {
      countryCode: 'HU',
      x: undecidedPosD1.find((el) => el.place === 'center9').x,
      y: undecidedPosD1.find((el) => el.place === 'center9').y,
    },
    {
      countryCode: 'IE',
      x: undecidedPosD1.find((el) => el.place === 'downCenter1').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter1').y,
    },
    {
      countryCode: 'IT',
      x: undecidedPosD1.find((el) => el.place === 'downCenter2').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter2').y,
    },
    {
      countryCode: 'LV',
      x: undecidedPosD1.find((el) => el.place === 'downCenter3').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter3').y,
    },
    {
      countryCode: 'LT',
      x: undecidedPosD1.find((el) => el.place === 'downCenter4').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter4').y,
    },
    {
      countryCode: 'LU',
      x: undecidedPosD1.find((el) => el.place === 'downCenter5').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter5').y,
    },
    {
      countryCode: 'MT',
      x: undecidedPosD1.find((el) => el.place === 'downCenter6').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter6').y,
    },
    {
      countryCode: 'NL',
      x: undecidedPosD1.find((el) => el.place === 'downCenter7').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter7').y,
    },
    {
      countryCode: 'PL',
      x: undecidedPosD1.find((el) => el.place === 'downCenter8').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter8').y,
    },
    {
      countryCode: 'PT',
      x: undecidedPosD1.find((el) => el.place === 'downCenter9').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter9').y,
    },
    {
      countryCode: 'RO',
      x: undecidedPosD1.find((el) => el.place === 'downCenter10').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter10').y,
    },
    {
      countryCode: 'SK',
      x: undecidedPosD1.find((el) => el.place === 'downCenter11').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter11').y,
    },
    {
      countryCode: 'SI',
      x: undecidedPosD1.find((el) => el.place === 'downCenter12').x,
      y: undecidedPosD1.find((el) => el.place === 'downCenter12').y,
    },
    {
      countryCode: 'ES',
      x: undecidedPosD1.find((el) => el.place === 'down1').x,
      y: undecidedPosD1.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'SE',
      x: undecidedPosD1.find((el) => el.place === 'down2').x,
      y: undecidedPosD1.find((el) => el.place === 'down2').y,
    },
  ],
};
export const countriesBoardPositionsOtherDs = {
  4: [
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
        .x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
        .y,
    },
    {
      countryCode: 'LV',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
        .x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
        .y,
    },
    {
      countryCode: 'LT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
        .x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
        .y,
    },
    {
      countryCode: 'LU',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
        .x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
        .y,
    },
    {
      countryCode: 'MT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
        .x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
        .y,
    },
    {
      countryCode: 'NL',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
        .x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
        .y,
    },
    {
      countryCode: 'PL',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y,
    },
  ],
  5: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
        .y,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
        .y,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
        .y,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
        .y,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
        .y,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x + 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
        .y,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x +
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y,
    },
  ],
  2: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
        .y,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
        .y,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
        .y,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
        .y,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
        .y,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x - 325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
        .y,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x -
        325,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y,
    },
  ],
  7: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
        .y,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
        .y,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
        .y,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
        .y,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
        .y,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x + 655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
        .y,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x +
        655,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y,
    },
  ],
  3: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y -
        98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x - 170,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x - 170,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x - 170,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y -
        98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y -
        98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y -
        98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y -
        98,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y -
        98,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y -
        98,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y -
        98,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y -
        98,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y -
        98,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y -
        98,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y -
        98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x - 170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .y - 98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x - 170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .y - 98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x - 170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .y - 98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x - 170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .y - 98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x - 170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .y - 98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x - 170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .y - 98,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x -
        170,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y - 98,
    },
  ],

  6: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y -
        98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x + 495,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x + 495,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x + 495,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y -
        98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y -
        98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y -
        98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y -
        98,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y -
        98,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y -
        98,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y -
        98,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y -
        98,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y -
        98,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y -
        98,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y -
        98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x + 495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .y - 98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x + 495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .y - 98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x + 495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .y - 98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x + 495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .y - 98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x + 495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .y - 98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x + 495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .y - 98,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x +
        495,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y - 98,
    },
  ],
  1: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y -
        98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x - 510,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x - 510,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x - 510,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y -
        98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y -
        98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y -
        98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y -
        98,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y -
        98,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y -
        98,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y -
        98,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y -
        98,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y -
        98,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y -
        98,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y -
        98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x - 510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .y - 98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x - 510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .y - 98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x - 510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .y - 98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x - 510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .y - 98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x - 510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .y - 98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x - 510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .y - 98,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x -
        510,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y - 98,
    },
  ],
  8: [
    {
      countryCode: 'myCountry',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'myCountry').y -
        98,
    },
    {
      countryCode: 'AT',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').x + 830,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up1').y - 98,
    },
    {
      countryCode: 'BE',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').x + 830,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up2').y - 98,
    },
    {
      countryCode: 'BG',
      x: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').x + 830,
      y: boardPositioningCenterOtherDs.find((el) => el.place === 'up3').y - 98,
    },
    {
      countryCode: 'HR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter1').y -
        98,
    },
    {
      countryCode: 'CY',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter2').y -
        98,
    },
    {
      countryCode: 'CZ',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter3').y -
        98,
    },
    {
      countryCode: 'DK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'upCenter4').y -
        98,
    },
    {
      countryCode: 'EE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center1').y -
        98,
    },
    {
      countryCode: 'FI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center2').y -
        98,
    },
    {
      countryCode: 'FR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center3').y -
        98,
    },
    {
      countryCode: 'DE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center4').y -
        98,
    },
    {
      countryCode: 'GR',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center5').y -
        98,
    },
    {
      countryCode: 'HU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center6').y -
        98,
    },
    {
      countryCode: 'IE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'center7').y -
        98,
    },
    {
      countryCode: 'IT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .x + 830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter1')
          .y - 98,
    },
    {
      countryCode: 'LV',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .x + 830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter2')
          .y - 98,
    },
    {
      countryCode: 'LT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .x + 830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter3')
          .y - 98,
    },
    {
      countryCode: 'LU',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .x + 830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter4')
          .y - 98,
    },
    {
      countryCode: 'MT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .x + 830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter5')
          .y - 98,
    },
    {
      countryCode: 'NL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .x + 830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'downCenter6')
          .y - 98,
    },
    {
      countryCode: 'PL',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down1').y - 98,
    },
    {
      countryCode: 'PT',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down2').y - 98,
    },
    {
      countryCode: 'RO',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down3').y - 98,
    },
    {
      countryCode: 'SK',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down4').y - 98,
    },
    {
      countryCode: 'SI',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down5').y - 98,
    },
    {
      countryCode: 'ES',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down6').y - 98,
    },
    {
      countryCode: 'SE',
      x:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').x +
        830,
      y:
        boardPositioningCenterOtherDs.find((el) => el.place === 'down7').y - 98,
    },
  ],
  0: [
    {
      countryCode: 'myCountry',
      x: undecidedPosOtherDs.find((el) => el.place === 'myCountry').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'myCountry').y,
    },
    {
      countryCode: 'AT',
      x: undecidedPosOtherDs.find((el) => el.place === 'up1').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'up1').y,
    },
    {
      countryCode: 'BE',
      x: undecidedPosOtherDs.find((el) => el.place === 'up2').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'up2').y,
    },
    {
      countryCode: 'BG',
      x: undecidedPosOtherDs.find((el) => el.place === 'up3').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'up3').y,
    },
    {
      countryCode: 'HR',
      x: undecidedPosOtherDs.find((el) => el.place === 'up4').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'up4').y,
    },
    {
      countryCode: 'CY',
      x: undecidedPosOtherDs.find((el) => el.place === 'center1').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center1').y,
    },
    {
      countryCode: 'CZ',
      x: undecidedPosOtherDs.find((el) => el.place === 'center2').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center2').y,
    },
    {
      countryCode: 'DK',
      x: undecidedPosOtherDs.find((el) => el.place === 'center3').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center3').y,
    },
    {
      countryCode: 'EE',
      x: undecidedPosOtherDs.find((el) => el.place === 'center4').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center4').y,
    },
    {
      countryCode: 'FI',
      x: undecidedPosOtherDs.find((el) => el.place === 'center5').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center5').y,
    },
    {
      countryCode: 'FR',
      x: undecidedPosOtherDs.find((el) => el.place === 'center6').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center6').y,
    },
    {
      countryCode: 'DE',
      x: undecidedPosOtherDs.find((el) => el.place === 'center7').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center7').y,
    },
    {
      countryCode: 'GR',
      x: undecidedPosOtherDs.find((el) => el.place === 'center8').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center8').y,
    },
    {
      countryCode: 'HU',
      x: undecidedPosOtherDs.find((el) => el.place === 'center9').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'center9').y,
    },
    {
      countryCode: 'IE',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter1').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter1').y,
    },
    {
      countryCode: 'IT',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter2').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter2').y,
    },
    {
      countryCode: 'LV',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter3').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter3').y,
    },
    {
      countryCode: 'LT',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter4').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter4').y,
    },
    {
      countryCode: 'LU',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter5').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter5').y,
    },
    {
      countryCode: 'MT',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter6').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter6').y,
    },
    {
      countryCode: 'NL',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter7').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter7').y,
    },
    {
      countryCode: 'PL',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter8').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter8').y,
    },
    {
      countryCode: 'PT',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter9').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter9').y,
    },
    {
      countryCode: 'RO',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter10').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter10').y,
    },
    {
      countryCode: 'SK',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter11').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter11').y,
    },
    {
      countryCode: 'SI',
      x: undecidedPosOtherDs.find((el) => el.place === 'downCenter12').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'downCenter12').y,
    },
    {
      countryCode: 'ES',
      x: undecidedPosOtherDs.find((el) => el.place === 'down1').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'down1').y,
    },
    {
      countryCode: 'SE',
      x: undecidedPosOtherDs.find((el) => el.place === 'down2').x,
      y: undecidedPosOtherDs.find((el) => el.place === 'down2').y,
    },
  ],
};
export const countriesBoardPositionsTenD1 = {
  1: [
    {
      x: boardPositioningCenterTenD1[0].x - 510,
      y: boardPositioningCenterTenD1[0].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[1].x - 510,
      y: boardPositioningCenterTenD1[1].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[2].x - 510,
      y: boardPositioningCenterTenD1[2].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[3].x - 510,
      y: boardPositioningCenterTenD1[3].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[4].x - 510,
      y: boardPositioningCenterTenD1[4].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[5].x - 510,
      y: boardPositioningCenterTenD1[5].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[6].x - 510,
      y: boardPositioningCenterTenD1[6].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[7].x - 510,
      y: boardPositioningCenterTenD1[7].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[8].x - 510,
      y: boardPositioningCenterTenD1[8].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[9].x - 510,
      y: boardPositioningCenterTenD1[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x - 510,
      y: boardPositioningCenterTenD1[10].y - 98,
    },
  ],
  0: [
    {
      x: undecidedPosD1[0].x,
      y: undecidedPosD1[0].y,
    },
    {
      x: undecidedPosD1[3].x,
      y: undecidedPosD1[3].y,
    },
    {
      x: undecidedPosD1[5].x,
      y: undecidedPosD1[5].y,
    },
    {
      x: undecidedPosD1[7].x,
      y: undecidedPosD1[7].y,
    },
    {
      x: undecidedPosD1[10].x,
      y: undecidedPosD1[10].y,
    },
    {
      x: undecidedPosD1[12].x,
      y: undecidedPosD1[12].y,
    },
    {
      x: undecidedPosD1[14].x,
      y: undecidedPosD1[14].y,
    },
    {
      x: undecidedPosD1[18].x,
      y: undecidedPosD1[18].y,
    },
    {
      x: undecidedPosD1[21].x,
      y: undecidedPosD1[21].y,
    },
    {
      x: undecidedPosD1[24].x,
      y: undecidedPosD1[24].y,
    },
    {
      countryCode: 'myCountry',
      x: undecidedPosD1[27].x,
      y: undecidedPosD1[27].y,
    },
  ],
  2: [
    {
      x: boardPositioningCenterTenD1[0].x - 325,
      y: boardPositioningCenterTenD1[0].y,
    },
    {
      x: boardPositioningCenterTenD1[1].x - 325,
      y: boardPositioningCenterTenD1[1].y,
    },
    {
      x: boardPositioningCenterTenD1[2].x - 325,
      y: boardPositioningCenterTenD1[2].y,
    },
    {
      x: boardPositioningCenterTenD1[3].x - 325,
      y: boardPositioningCenterTenD1[3].y,
    },
    {
      x: boardPositioningCenterTenD1[4].x - 325,
      y: boardPositioningCenterTenD1[4].y,
    },
    {
      x: boardPositioningCenterTenD1[5].x - 325,
      y: boardPositioningCenterTenD1[5].y,
    },
    {
      x: boardPositioningCenterTenD1[6].x - 325,
      y: boardPositioningCenterTenD1[6].y,
    },
    {
      x: boardPositioningCenterTenD1[7].x - 325,
      y: boardPositioningCenterTenD1[7].y,
    },
    {
      x: boardPositioningCenterTenD1[8].x - 325,
      y: boardPositioningCenterTenD1[8].y,
    },
    {
      x: boardPositioningCenterTenD1[9].x - 325,
      y: boardPositioningCenterTenD1[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x - 325,
      y: boardPositioningCenterTenD1[10].y,
    },
  ],
  3: [
    {
      x: boardPositioningCenterTenD1[0].x - 170,
      y: boardPositioningCenterTenD1[0].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[1].x - 170,
      y: boardPositioningCenterTenD1[1].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[2].x - 170,
      y: boardPositioningCenterTenD1[2].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[3].x - 170,
      y: boardPositioningCenterTenD1[3].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[4].x - 170,
      y: boardPositioningCenterTenD1[4].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[5].x - 170,
      y: boardPositioningCenterTenD1[5].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[6].x - 170,
      y: boardPositioningCenterTenD1[6].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[7].x - 170,
      y: boardPositioningCenterTenD1[7].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[8].x - 170,
      y: boardPositioningCenterTenD1[8].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[9].x - 170,
      y: boardPositioningCenterTenD1[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x - 170,
      y: boardPositioningCenterTenD1[10].y - 98,
    },
  ],
  4: [
    {
      x: boardPositioningCenterTenD1[0].x,
      y: boardPositioningCenterTenD1[0].y,
    },
    {
      x: boardPositioningCenterTenD1[1].x,
      y: boardPositioningCenterTenD1[1].y,
    },
    {
      x: boardPositioningCenterTenD1[2].x,
      y: boardPositioningCenterTenD1[2].y,
    },
    {
      x: boardPositioningCenterTenD1[3].x,
      y: boardPositioningCenterTenD1[3].y,
    },
    {
      x: boardPositioningCenterTenD1[4].x,
      y: boardPositioningCenterTenD1[4].y,
    },
    {
      x: boardPositioningCenterTenD1[5].x,
      y: boardPositioningCenterTenD1[5].y,
    },
    {
      x: boardPositioningCenterTenD1[6].x,
      y: boardPositioningCenterTenD1[6].y,
    },
    {
      x: boardPositioningCenterTenD1[7].x,
      y: boardPositioningCenterTenD1[7].y,
    },
    {
      x: boardPositioningCenterTenD1[8].x,
      y: boardPositioningCenterTenD1[8].y,
    },
    {
      x: boardPositioningCenterTenD1[9].x,
      y: boardPositioningCenterTenD1[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x,
      y: boardPositioningCenterTenD1[10].y,
    },
  ],
  5: [
    {
      x: boardPositioningCenterTenD1[0].x + 325,
      y: boardPositioningCenterTenD1[0].y,
    },
    {
      x: boardPositioningCenterTenD1[1].x + 325,
      y: boardPositioningCenterTenD1[1].y,
    },
    {
      x: boardPositioningCenterTenD1[2].x + 325,
      y: boardPositioningCenterTenD1[2].y,
    },
    {
      x: boardPositioningCenterTenD1[3].x + 325,
      y: boardPositioningCenterTenD1[3].y,
    },
    {
      x: boardPositioningCenterTenD1[4].x + 325,
      y: boardPositioningCenterTenD1[4].y,
    },
    {
      x: boardPositioningCenterTenD1[5].x + 325,
      y: boardPositioningCenterTenD1[5].y,
    },
    {
      x: boardPositioningCenterTenD1[6].x + 325,
      y: boardPositioningCenterTenD1[6].y,
    },
    {
      x: boardPositioningCenterTenD1[7].x + 325,
      y: boardPositioningCenterTenD1[7].y,
    },
    {
      x: boardPositioningCenterTenD1[8].x + 325,
      y: boardPositioningCenterTenD1[8].y,
    },
    {
      x: boardPositioningCenterTenD1[9].x + 325,
      y: boardPositioningCenterTenD1[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x + 325,
      y: boardPositioningCenterTenD1[10].y,
    },
  ],
  6: [
    {
      x: boardPositioningCenterTenD1[0].x + 495,
      y: boardPositioningCenterTenD1[0].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[1].x + 495,
      y: boardPositioningCenterTenD1[1].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[2].x + 495,
      y: boardPositioningCenterTenD1[2].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[3].x + 495,
      y: boardPositioningCenterTenD1[3].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[4].x + 495,
      y: boardPositioningCenterTenD1[4].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[5].x + 495,
      y: boardPositioningCenterTenD1[5].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[6].x + 495,
      y: boardPositioningCenterTenD1[6].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[7].x + 495,
      y: boardPositioningCenterTenD1[7].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[8].x + 495,
      y: boardPositioningCenterTenD1[8].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[9].x + 495,
      y: boardPositioningCenterTenD1[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x + 495,
      y: boardPositioningCenterTenD1[10].y - 98,
    },
  ],
  7: [
    {
      x: boardPositioningCenterTenD1[0].x + 655,
      y: boardPositioningCenterTenD1[0].y,
    },
    {
      x: boardPositioningCenterTenD1[1].x + 655,
      y: boardPositioningCenterTenD1[1].y,
    },
    {
      x: boardPositioningCenterTenD1[2].x + 655,
      y: boardPositioningCenterTenD1[2].y,
    },
    {
      x: boardPositioningCenterTenD1[3].x + 655,
      y: boardPositioningCenterTenD1[3].y,
    },
    {
      x: boardPositioningCenterTenD1[4].x + 655,
      y: boardPositioningCenterTenD1[4].y,
    },
    {
      x: boardPositioningCenterTenD1[5].x + 655,
      y: boardPositioningCenterTenD1[5].y,
    },
    {
      x: boardPositioningCenterTenD1[6].x + 655,
      y: boardPositioningCenterTenD1[6].y,
    },
    {
      x: boardPositioningCenterTenD1[7].x + 655,
      y: boardPositioningCenterTenD1[7].y,
    },
    {
      x: boardPositioningCenterTenD1[8].x + 655,
      y: boardPositioningCenterTenD1[8].y,
    },
    {
      x: boardPositioningCenterTenD1[9].x + 655,
      y: boardPositioningCenterTenD1[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x + 655,
      y: boardPositioningCenterTenD1[10].y,
    },
  ],
  8: [
    {
      x: boardPositioningCenterTenD1[0].x + 820,
      y: boardPositioningCenterTenD1[0].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[1].x + 820,
      y: boardPositioningCenterTenD1[1].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[2].x + 820,
      y: boardPositioningCenterTenD1[2].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[3].x + 820,
      y: boardPositioningCenterTenD1[3].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[4].x + 820,
      y: boardPositioningCenterTenD1[4].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[5].x + 820,
      y: boardPositioningCenterTenD1[5].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[6].x + 820,
      y: boardPositioningCenterTenD1[6].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[7].x + 820,
      y: boardPositioningCenterTenD1[7].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[8].x + 820,
      y: boardPositioningCenterTenD1[8].y - 98,
    },
    {
      x: boardPositioningCenterTenD1[9].x + 820,
      y: boardPositioningCenterTenD1[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenD1[10].x + 820,
      y: boardPositioningCenterTenD1[10].y - 98,
    },
  ],
};
export const countriesBoardPositionsTenOtherDs = {
  1: [
    {
      x: boardPositioningCenterTenOtherDs[0].x - 510,
      y: boardPositioningCenterTenOtherDs[0].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x - 510,
      y: boardPositioningCenterTenOtherDs[1].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x - 510,
      y: boardPositioningCenterTenOtherDs[2].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x - 510,
      y: boardPositioningCenterTenOtherDs[3].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x - 510,
      y: boardPositioningCenterTenOtherDs[4].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x - 510,
      y: boardPositioningCenterTenOtherDs[5].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x - 510,
      y: boardPositioningCenterTenOtherDs[6].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x - 510,
      y: boardPositioningCenterTenOtherDs[7].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x - 510,
      y: boardPositioningCenterTenOtherDs[8].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x - 510,
      y: boardPositioningCenterTenOtherDs[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x - 510,
      y: boardPositioningCenterTenOtherDs[10].y - 98,
    },
  ],
  0: [
    {
      x: undecidedPosOtherDs[0].x,
      y: undecidedPosOtherDs[0].y,
    },
    {
      x: undecidedPosOtherDs[3].x,
      y: undecidedPosOtherDs[3].y,
    },
    {
      x: undecidedPosOtherDs[5].x,
      y: undecidedPosOtherDs[5].y,
    },
    {
      x: undecidedPosOtherDs[6].x,
      y: undecidedPosOtherDs[6].y,
    },
    {
      x: undecidedPosOtherDs[8].x,
      y: undecidedPosOtherDs[8].y,
    },
    {
      x: undecidedPosOtherDs[12].x,
      y: undecidedPosOtherDs[12].y,
    },
    {
      x: undecidedPosOtherDs[14].x,
      y: undecidedPosOtherDs[14].y,
    },
    {
      x: undecidedPosOtherDs[16].x,
      y: undecidedPosOtherDs[16].y,
    },
    {
      x: undecidedPosOtherDs[20].x,
      y: undecidedPosOtherDs[20].y,
    },
    {
      x: undecidedPosOtherDs[24].x,
      y: undecidedPosOtherDs[24].y,
    },
    {
      countryCode: 'myCountry',
      x: undecidedPosOtherDs[27].x,
      y: undecidedPosOtherDs[27].y,
    },
  ],
  2: [
    {
      x: boardPositioningCenterTenOtherDs[0].x - 325,
      y: boardPositioningCenterTenOtherDs[0].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x - 325,
      y: boardPositioningCenterTenOtherDs[1].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x - 325,
      y: boardPositioningCenterTenOtherDs[2].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x - 325,
      y: boardPositioningCenterTenOtherDs[3].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x - 325,
      y: boardPositioningCenterTenOtherDs[4].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x - 325,
      y: boardPositioningCenterTenOtherDs[5].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x - 325,
      y: boardPositioningCenterTenOtherDs[6].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x - 325,
      y: boardPositioningCenterTenOtherDs[7].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x - 325,
      y: boardPositioningCenterTenOtherDs[8].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x - 325,
      y: boardPositioningCenterTenOtherDs[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x - 325,
      y: boardPositioningCenterTenOtherDs[10].y,
    },
  ],
  3: [
    {
      x: boardPositioningCenterTenOtherDs[0].x - 170,
      y: boardPositioningCenterTenOtherDs[0].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x - 170,
      y: boardPositioningCenterTenOtherDs[1].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x - 170,
      y: boardPositioningCenterTenOtherDs[2].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x - 170,
      y: boardPositioningCenterTenOtherDs[3].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x - 170,
      y: boardPositioningCenterTenOtherDs[4].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x - 170,
      y: boardPositioningCenterTenOtherDs[5].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x - 170,
      y: boardPositioningCenterTenOtherDs[6].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x - 170,
      y: boardPositioningCenterTenOtherDs[7].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x - 170,
      y: boardPositioningCenterTenOtherDs[8].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x - 170,
      y: boardPositioningCenterTenOtherDs[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x - 170,
      y: boardPositioningCenterTenOtherDs[10].y - 98,
    },
  ],
  4: [
    {
      x: boardPositioningCenterTenOtherDs[0].x,
      y: boardPositioningCenterTenOtherDs[0].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x,
      y: boardPositioningCenterTenOtherDs[1].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x,
      y: boardPositioningCenterTenOtherDs[2].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x,
      y: boardPositioningCenterTenOtherDs[3].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x,
      y: boardPositioningCenterTenOtherDs[4].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x,
      y: boardPositioningCenterTenOtherDs[5].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x,
      y: boardPositioningCenterTenOtherDs[6].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x,
      y: boardPositioningCenterTenOtherDs[7].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x,
      y: boardPositioningCenterTenOtherDs[8].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x,
      y: boardPositioningCenterTenOtherDs[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x,
      y: boardPositioningCenterTenOtherDs[10].y,
    },
  ],
  5: [
    {
      x: boardPositioningCenterTenOtherDs[0].x + 325,
      y: boardPositioningCenterTenOtherDs[0].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x + 325,
      y: boardPositioningCenterTenOtherDs[1].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x + 325,
      y: boardPositioningCenterTenOtherDs[2].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x + 325,
      y: boardPositioningCenterTenOtherDs[3].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x + 325,
      y: boardPositioningCenterTenOtherDs[4].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x + 325,
      y: boardPositioningCenterTenOtherDs[5].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x + 325,
      y: boardPositioningCenterTenOtherDs[6].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x + 325,
      y: boardPositioningCenterTenOtherDs[7].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x + 325,
      y: boardPositioningCenterTenOtherDs[8].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x + 325,
      y: boardPositioningCenterTenOtherDs[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x + 325,
      y: boardPositioningCenterTenOtherDs[10].y,
    },
  ],
  6: [
    {
      x: boardPositioningCenterTenOtherDs[0].x + 495,
      y: boardPositioningCenterTenOtherDs[0].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x + 495,
      y: boardPositioningCenterTenOtherDs[1].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x + 495,
      y: boardPositioningCenterTenOtherDs[2].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x + 495,
      y: boardPositioningCenterTenOtherDs[3].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x + 495,
      y: boardPositioningCenterTenOtherDs[4].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x + 495,
      y: boardPositioningCenterTenOtherDs[5].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x + 495,
      y: boardPositioningCenterTenOtherDs[6].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x + 495,
      y: boardPositioningCenterTenOtherDs[7].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x + 495,
      y: boardPositioningCenterTenOtherDs[8].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x + 495,
      y: boardPositioningCenterTenOtherDs[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x + 495,
      y: boardPositioningCenterTenOtherDs[10].y - 98,
    },
  ],
  7: [
    {
      x: boardPositioningCenterTenOtherDs[0].x + 655,
      y: boardPositioningCenterTenOtherDs[0].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x + 655,
      y: boardPositioningCenterTenOtherDs[1].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x + 655,
      y: boardPositioningCenterTenOtherDs[2].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x + 655,
      y: boardPositioningCenterTenOtherDs[3].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x + 655,
      y: boardPositioningCenterTenOtherDs[4].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x + 655,
      y: boardPositioningCenterTenOtherDs[5].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x + 655,
      y: boardPositioningCenterTenOtherDs[6].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x + 655,
      y: boardPositioningCenterTenOtherDs[7].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x + 655,
      y: boardPositioningCenterTenOtherDs[8].y,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x + 655,
      y: boardPositioningCenterTenOtherDs[9].y,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x + 655,
      y: boardPositioningCenterTenOtherDs[10].y,
    },
  ],
  8: [
    {
      x: boardPositioningCenterTenOtherDs[0].x + 820,
      y: boardPositioningCenterTenOtherDs[0].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[1].x + 820,
      y: boardPositioningCenterTenOtherDs[1].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[2].x + 820,
      y: boardPositioningCenterTenOtherDs[2].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[3].x + 820,
      y: boardPositioningCenterTenOtherDs[3].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[4].x + 820,
      y: boardPositioningCenterTenOtherDs[4].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[5].x + 820,
      y: boardPositioningCenterTenOtherDs[5].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[6].x + 820,
      y: boardPositioningCenterTenOtherDs[6].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[7].x + 820,
      y: boardPositioningCenterTenOtherDs[7].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[8].x + 820,
      y: boardPositioningCenterTenOtherDs[8].y - 98,
    },
    {
      x: boardPositioningCenterTenOtherDs[9].x + 820,
      y: boardPositioningCenterTenOtherDs[9].y - 98,
    },
    {
      countryCode: 'myCountry',
      x: boardPositioningCenterTenOtherDs[10].x + 820,
      y: boardPositioningCenterTenOtherDs[10].y - 98,
    },
  ],
};

export const dPositions = [
  { name: 'd1', x: 900, y: 400 },
  { name: 'd2', x: 895, y: 688 },
  { name: 'd3', x: 902, y: 750 },
];
export const dLabelsPositions = [
  { name: 'd1', value: 'D1', x: 115, y: 465 },
  { name: 'd2', value: 'D2', x: 115, y: 530 },
  { name: 'd3', value: 'D3', x: 115, y: 595 },
];

export const textPositionsD1 = [
  {
    value: 4,
    x: squarePositionsD1.find((el) => el.name === 'squareC1').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareC1').y - 285,
  },
  {
    value: 5,
    x: squarePositionsD1.find((el) => el.name === 'squareC2').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareC2').y - 285,
  },
  {
    value: 2,
    x: squarePositionsD1.find((el) => el.name === 'squareC3').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareC3').y - 285,
  },
  {
    value: 7,
    x: squarePositionsD1.find((el) => el.name === 'squareC4').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareC4').y - 285,
  },
  {
    value: 3,
    x: squarePositionsD1.find((el) => el.name === 'squareT1').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareT1').y - 285,
  },
  {
    value: 6,
    x: squarePositionsD1.find((el) => el.name === 'squareT2').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareT2').y - 285,
  },
  {
    value: 1,
    x: squarePositionsD1.find((el) => el.name === 'squareL').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareL').y - 285,
  },
  {
    value: 8,
    x: squarePositionsD1.find((el) => el.name === 'squareR').x - 170,
    y: squarePositionsD1.find((el) => el.name === 'squareR').y - 285,
  },
  {
    value: 0,
    x: squarePositionsD1.find((el) => el.name === 'undecided')?.x - 170,
    y: squarePositionsD1.find((el) => el.name === 'undecided')?.y - 285,
  },
];
export const textPositionsOtherDs = [
  {
    value: 4,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareC1').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareC1').y - 285,
  },
  {
    value: 5,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareC2').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareC2').y - 285,
  },
  {
    value: 2,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareC3').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareC3').y - 285,
  },
  {
    value: 7,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareC4').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareC4').y - 285,
  },
  {
    value: 3,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareT1').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareT1').y - 285,
  },
  {
    value: 6,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareT2').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareT2').y - 285,
  },
  {
    value: 1,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareL').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareL').y - 285,
  },
  {
    value: 8,
    x: squarePositionsOtherDs.find((el) => el.name === 'squareR').x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'squareR').y - 285,
  },
  {
    value: 0,
    x: squarePositionsOtherDs.find((el) => el.name === 'undecided')?.x - 170,
    y: squarePositionsOtherDs.find((el) => el.name === 'undecided')?.y - 285,
  },
];

export const undecidedPositionD1 = [{ name: 'posUndecided', x: 900, y: 731 }];
export const undecidedPositionOtherDs = [
  { name: 'posUndecided', x: 900, y: 790 },
];

export const stairsPosition = { name: 'stairs', x: 1100, y: 992 };

export const zoomBoardPositions = {
  1: [
    { x: 800, y: 350 + 100 },
    { x: 900, y: 450 + 100 },
    { x: 700, y: 450 + 100 },
    { x: 1000, y: 350 + 100 },
  ],
  2: [
    { x: 800, y: 350 + 100 },
    { x: 900, y: 450 + 100 },
    { x: 1000, y: 350 + 100 },
    { x: 1100, y: 450 + 100 },
    { x: 600, y: 350 + 100 },
    { x: 500, y: 450 + 100 },
    { x: 700, y: 450 + 100 },
    { x: 1200, y: 350 + 100 },
  ],
  3: [
    { x: 700, y: 450 + 100 },
    { x: 800, y: 350 + 100 },
    { x: 1100, y: 450 + 100 },
    { x: 1300, y: 450 + 100 },
    { x: 900, y: 450 + 100 },
    { x: 1000, y: 350 + 100 },
    { x: 1200, y: 350 + 100 },
    { x: 500, y: 450 + 100 },
    { x: 600, y: 350 + 100 },
    { x: 1400, y: 350 + 100 },
    { x: 1500, y: 450 + 100 },
    { x: 1550, y: 350 + 100 },
    { x: 300, y: 450 + 100 },
    { x: 400, y: 350 + 100 },
  ],
  4: [
    { x: 800, y: 350 - 50 + 100 },
    { x: 900, y: 450 - 50 + 100 },
    { x: 1000, y: 350 - 50 + 100 },
    { x: 1100, y: 450 - 50 + 100 },
    { x: 1200, y: 350 - 50 + 100 },
    { x: 1300, y: 450 - 50 + 100 },
    { x: 1400, y: 350 - 50 + 100 },
    { x: 400, y: 500 - 50 + 100 },
    { x: 600, y: 500 - 50 + 100 },
    { x: 800, y: 500 - 50 + 100 },
    { x: 300, y: 450 - 50 + 100 },
    { x: 1000, y: 500 - 50 + 100 },
    { x: 1200, y: 500 - 50 + 100 },
    { x: 1400, y: 500 - 50 + 100 },
    { x: 400, y: 350 - 50 + 100 },
    { x: 500, y: 450 - 50 + 100 },
    { x: 600, y: 350 - 50 + 100 },
    { x: 700, y: 450 - 50 + 100 },
    { x: 1500, y: 500 - 100 + 100 },
    { x: 1600, y: 500 - 50 + 100 },
  ],
  5: [
    { x: 1300, y: 450 - 30 + 100 + 30 },
    { x: 1400, y: 350 + 30 + 100 + 30 },
    { x: 1500, y: 450 - 30 + 100 + 30 },
    { x: 1600, y: 350 + 30 + 100 + 30 },
    { x: 300, y: 450 - 180 + 100 + 30 },
    { x: 400, y: 350 - 150 + 100 + 30 },
    { x: 500, y: 450 - 180 + 100 + 30 },
    { x: 600, y: 350 - 150 + 100 + 30 },
    { x: 700, y: 450 - 180 + 100 + 30 },
    { x: 300, y: 450 - 30 + 100 + 30 },
    { x: 400, y: 350 + 30 + 100 + 30 },
    { x: 500, y: 450 - 30 + 100 + 30 },
    { x: 600, y: 350 + 30 + 100 + 30 },
    { x: 700, y: 450 - 30 + 100 + 30 },
    { x: 800, y: 350 + 30 + 100 + 30 },
    { x: 900, y: 450 - 30 + 100 + 30 },
    { x: 1000, y: 350 + 30 + 100 + 30 },
    { x: 1100, y: 450 - 30 + 100 + 30 },
    { x: 1200, y: 350 + 30 + 100 + 30 },
    { x: 800, y: 350 - 150 + 100 + 30 },
    { x: 900, y: 450 - 180 + 100 + 30 },
    { x: 1000, y: 350 - 150 + 100 + 30 },
    { x: 1100, y: 450 - 180 + 100 + 30 },
    { x: 1200, y: 350 - 150 + 100 + 30 },
    { x: 1300, y: 450 - 180 + 100 + 30 },
    { x: 1400, y: 350 - 150 + 100 + 30 },
    { x: 1500, y: 450 - 180 + 100 + 30 },
    { x: 1550, y: 350 - 150 + 100 + 30 },
  ],
};
