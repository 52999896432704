import { Injectable } from '@angular/core';
import { Query, arrayFind } from '@datorama/akita';
import { CountriesStore, CountriesState } from './countries.store';
import { EMPTY, Observable, of } from 'rxjs';
import { Country } from '../../models/country.model';

@Injectable({ providedIn: 'root' })
export class CountriesQuery extends Query<CountriesState> {
  countries$ = this.select('countries');

  constructor(protected store: CountriesStore) {
    super(store);
  }

  getCountries(): any {
    return this.getValue().countries;
  }

  // getCountriesByDifficulty(difficulty: string): any {
  //   return this.select('countries').pipe(
  //       country.filter(dim => dim.year === year + '').length > 0)
  //     );
  // }

  // selectCountryDimensionsByYear(year: string): Observable<Country[]> {
  //   return this.select('countries').pipe(
  //     arrayFind(country => country.dimensions.filter(dim => dim.year === year + '').length > 0));
  // }

  // getPlayerCountryById(id: string): Country{
  //   const playerCountry = this.getValue().countries.find(country => country.avatar.id === id);
  //   if (playerCountry) {
  //     return playerCountry;
  //   } else {
  //     return {} as Country;
  //   }
  // }

  // selectPlayerCountryById(id: string): Observable<Country> {
  //   const playerCountry = this.getValue().countries.find(country => country.avatar.id === id);
  //   if (playerCountry) {
  //     return of(playerCountry);
  //   } else {
  //     return EMPTY;
  //   }
  // }

  selectCountryByName(name: string): Observable<Country> {
    const country = this.getValue().countries.find((cntry: Country) => cntry.name === name);
    if (country) {
      return of(country);
    } else {
      return EMPTY;
    }
  }

  selectCountryByCountryCode(code: string) {
    const country = this.getValue().countries.find((cntry: Country) => cntry.countryCode === code);
    if (country) {
      return country.name;
    } else {
      return code;
    }
  }


  // getCountryByName(name: string): Country {
  //   const country = this.getValue().countries.find((cntry: Country) => cntry.avatar.country.toLowerCase() === name.toLowerCase());
  //   if (country) {
  //     return country;
  //   } else {
  //     return {} as Country;
  //   }
  // }
}
