import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'wordify'
})
export class WordifyPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    return this.sanitizer.sanitize(SecurityContext.NONE, this.sanitizer.bypassSecurityTrustHtml(this.stylize(value)));
  }

  private stylize(text: string): string {
    return text.replace(/\S+\s*/g, '<span class=\'word\' style=\'opacity: 0;\'>$&</span>');
  }

}
