import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BilateralDiscussionMessageType,
  BilateralStatus,
} from 'src/app/models/game.model';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { GameStore } from 'src/app/state/gameState/game.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-negotiation-modal',
  templateUrl: './negotiation-modal.component.html',
  styleUrls: ['./negotiation-modal.component.scss'],
})
export class NegotiationModalComponent implements OnInit {
  bilateralStatus = BilateralStatus;
  bilateralDiscussion = {
    userId: '',
    incoming: true,
    status: BilateralStatus.empty,
  };
  otherCountryName = '';

  constructor(
    private router: Router,
    public gameQuery: GameQuery,
    private gameStore: GameStore,
    private gameService: GameService
  ) {}

  ngOnInit() {
    this.gameQuery
      .select((state) => ({
        game: state.game,
        bilateralDiscussion: state.bilateralDiscussion,
      }))
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.bilateralDiscussion = state.bilateralDiscussion;
        this.otherCountryName =
          this.gameService.getCountryByUserId(this.bilateralDiscussion.userId)
            ?.name || '';
      });
  }

  onAccept() {
    this.gameService
      .negotiationMessage(
        this.bilateralDiscussion.userId,
        BilateralDiscussionMessageType.acceptNegotiation
      )
      .then(() => {
        this.gameStore.update((state) => ({
          bilateralDiscussion: {
            ...state.bilateralDiscussion,
            status: BilateralStatus.empty,
            currentState: [],
            pending: undefined,
          },
        }));
        this.router.navigate(['/bilateral-discussion']);
      })
      .catch((err) => console.error(err));
  }

  onDecline() {
    this.gameService
      .negotiationMessage(this.bilateralDiscussion.userId, BilateralDiscussionMessageType.declineNegotiation)
      .then((res) =>
        this.gameStore.update({
          bilateralDiscussion: {
            userId: '',
            incoming: true,
            status: BilateralStatus.empty,
            started: false,
            currentState: [],
            pending: undefined,
          },
        })
      )
      .catch((err) => console.error(err));
  }

  onCancel() {
    this.gameService
      .negotiationMessage(
        this.bilateralDiscussion.userId,
        BilateralDiscussionMessageType.declineNegotiation
      )
      .then((res) =>
        this.gameStore.update({
          bilateralDiscussion: {
            userId: '',
            incoming: true,
            status: BilateralStatus.empty,
            started: false,
            currentState: [],
            pending: undefined,
          },
        })
      )
      .catch((err) => console.error(err));
  }

  onHide() {
    this.gameStore.update({
      bilateralDiscussion: {
        userId: '',
        incoming: false,
        status: BilateralStatus.empty,
        started: false,
        currentState: [],
        pending: undefined,
      },
    });
  }

  preventClick(e: any) {
    e.stopPropagation();
  }
}
