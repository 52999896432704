// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back {
  position: absolute;
  bottom: 1.917rem;
  left: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 10.208rem;
  height: 2.667rem;
  gap: 0.563rem;
}
.back .icon {
  width: 2.667rem;
  height: 2.667rem;
}
.back .icon:hover {
  opacity: 0.8;
}
.back .text {
  font-size: 1rem;
  color: var(--color-primary-blue);
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/app/components/back-button/back-button.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;AACI;EACE,YAAA;AACN;AAGE;EACE,eAAA;EACA,gCAAA;EACA,gBAAA;AADJ","sourcesContent":[".back {\n  position: absolute;\n  bottom: 1.917rem;\n  left: 2rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  width: 10.208rem;\n  height: 2.667rem;\n  gap: 0.563rem;\n\n  .icon {\n    width: 2.667rem;\n    height: 2.667rem;\n\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n\n  .text {\n    font-size: 1rem;\n    color: var(--color-primary-blue);\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
