import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { retry, tap } from 'rxjs/operators';
import { UserStore } from './user.store';
import { IUserGetResponse, IUserResponse } from 'src/app/models/api.model';
import { EnvService } from 'src/app/services/env.service';
import { GameQuery } from '../gameState/game.query';

@Injectable({providedIn: 'root'})

export class UserService {
  constructor(
    private envService: EnvService,
    private gameQuery: GameQuery,
    private userStore: UserStore,
    private http: HttpClient
  ) {
  }

  setSessionUserId(sessionId: string) {
    const bodyData = {session: sessionId, versionNumber: this.envService.appVersion};
    return this.http
      .post<IUserResponse>(
        `${this.envService.baseApiUrl}/User`,
        bodyData
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            console.log(value.data);
            this.userStore.update(state => ({
              user: {
                ...state.user,
                ...value.data.items[0],
                sessionid: sessionId,
              }
            }));
          }
        })
      );
  }

  getUser() {
    return this.http
      .get<IUserGetResponse>(
        `${this.envService.baseApiUrl}/User`,
      )
      .pipe(
        tap((value) => {
          if (value && value.status === 'Success') {
            console.log('getSessionUserId:' ,value.data);
            this.userStore.update(state => ({
              user: {
                ...state.user,
                ...value.data.items[0].user,
              }
            }));
          }
        })
      );
  }

  setBackgroundMusic(num: number) {
    this.userStore.update(state => ({
      settings: {
        ...state.settings,
        backgroundMusic: num
      }
    }));
  }

  addBackgroundMusic(add: number) {
    if ((add > 0 && this.userStore.getValue().settings.backgroundMusic + add <= 1)
      || (add < 0 && this.userStore.getValue().settings.backgroundMusic + add >= 0)) {
      this.userStore.update(state => ({
        settings: {
          ...state.settings,
          backgroundMusic: parseFloat((this.userStore.getValue().settings.backgroundMusic + add).toFixed(1))
        }
      }));
    }
  }

  setSoundEffects(num: number) {
    this.userStore.update(state => ({
      settings: {
        ...state.settings,
        soundEffects: num
      }
    }));
  }

  addSoundEffects(add: number) {
    if ((add > 0 && this.userStore.getValue().settings.soundEffects + add <= 1)
      || (add < 0 && this.userStore.getValue().settings.soundEffects + add >= 0)) {
      this.userStore.update(state => ({
        settings: {
          ...state.settings,
          soundEffects: parseFloat((this.userStore.getValue().settings.soundEffects + add).toFixed(1))
        }
      }));
    }
  }

  switchLanguage(language: string) {
    this.userStore.update(state => ({
      settings: {
        ...state.settings,
        language
      }
    }));
  }

  switchMute(forceMute = false) {
    this.userStore.update(state => ({
      settings: {
        ...state.settings,
        mute: forceMute || !state.settings.mute
      }
    }));
  }

  resetTheQuizAnswerdQuestions() {
    this.userStore.update({quizAnswerdQuestions: []});
  }

  updateQuizAnswerdQuestions(newQuizGame) {
    console.log(newQuizGame);
    this.userStore.update(state => ({quizAnswerdQuestions: [...state.quizAnswerdQuestions, newQuizGame]}));
  }

  updateRouterUrl(url) {
    this.userStore.update({routerUrl: url});
  }

  changePlayerName(name: string) {
    const gameId = this.gameQuery.getGameId();
    return this.http
      .post<any>(
        `${this.envService.baseApiUrl}/Game/${gameId}/user/name`, {name}
      )
      .pipe(retry(1))
      .toPromise();
  }
}

