import * as Phaser from 'phaser';
import { Subject, Subscription, timer } from 'rxjs';
import { Country } from 'src/app/models/country.model';
import * as pos from './negotiation.scene.positions';
import {
  IDimension,
  IPositionContent,
  ITopic,
  IUserGame,
  IUserGameNegotiation,
} from 'src/app/models/api.model';
import {
  BilateralHistoryStatus,
  PhaserScenes,
  Stance,
  StanceTranslation,
} from 'src/app/models/game.model';
import { catchError, take, takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { NavigationEnd } from '@angular/router';

let context: any;

enum PopUpTypeOfUse {
  noPoints = 'noPoints',
  bilateral = 'bilateral',
  redLine = 'redLine',
}

export class NegotiationScene extends Phaser.Scene {
  static key = 'negotiation-d1-scene';
  static active = true;

  d1Label: Phaser.GameObjects.Text;
  d2Label: Phaser.GameObjects.Text;
  d3Label: Phaser.GameObjects.Text;
  textCountries: Phaser.GameObjects.Text;
  textWeights: Phaser.GameObjects.Text;
  btnText: Phaser.GameObjects.Text;
  councilPoints: Phaser.GameObjects.Text;
  countryPoints: Phaser.GameObjects.Text;
  actionPoints: Phaser.GameObjects.Text;
  zoomLabelText: Phaser.GameObjects.Text;
  popUpCountryText: Phaser.GameObjects.Text;
  popUpPopulationText: Phaser.GameObjects.Text;
  squareTexts: Phaser.GameObjects.Text[];
  undecidedLabelText: Phaser.GameObjects.Text;
  redLinePopUpText1: Phaser.GameObjects.Text;
  redLinePopUpText2: Phaser.GameObjects.Text;
  redLinePopUpText3: Phaser.GameObjects.Text;
  redLineIndicatorZoomText: Phaser.GameObjects.Text;
  moveConfirmationPopUpText: Phaser.GameObjects.Text;
  confirmButtonText: Phaser.GameObjects.Text;
  cancelButtonText: Phaser.GameObjects.Text;
  d1ClickableText: Phaser.GameObjects.Text;
  d2ClickableText: Phaser.GameObjects.Text;
  guideDimensionsText: Phaser.GameObjects.Text;
  zoomCountryNames: Phaser.GameObjects.Text[];
  negotiateButtonText: Phaser.GameObjects.Text;
  guideDisableTimeout: any;

  countries: Country[];
  d3: Phaser.GameObjects.Sprite;
  d2: Phaser.GameObjects.Sprite;
  d1: Phaser.GameObjects.Sprite;
  grid: Phaser.GameObjects.Sprite;
  squareC1: Phaser.GameObjects.Sprite;
  squareC2: Phaser.GameObjects.Sprite;
  squareC3: Phaser.GameObjects.Sprite;
  squareC4: Phaser.GameObjects.Sprite;
  squareT1: Phaser.GameObjects.Sprite;
  squareT2: Phaser.GameObjects.Sprite;
  squareL: Phaser.GameObjects.Sprite;
  squareR: Phaser.GameObjects.Sprite;
  posUndecided: Phaser.GameObjects.Sprite;
  avatar: Phaser.GameObjects.Sprite;
  numOfTiles: number;
  squareInfo: IPositionContent[];
  dimensions: IDimension[] = [];
  dimensionsIds: any[];
  squares: Phaser.GameObjects.Sprite[];
  dimensionsGroup: Phaser.GameObjects.Group;
  sceneGroup: Phaser.GameObjects.Group;
  avatars: Phaser.GameObjects.Sprite[] = [];
  squaresGroup: Phaser.GameObjects.Group;
  d1Clickable: Phaser.GameObjects.Sprite;
  d2Clickable: Phaser.GameObjects.Sprite;
  countryRedPositions: string[];
  countrySensitivePositions: string[];
  avatarSelfGroup: Phaser.GameObjects.Group;
  redGlow: Phaser.GameObjects.Sprite;
  selectedTopic: ITopic;
  yellowGlowGroup: Phaser.GameObjects.Group;
  revealPositionsSub: Subscription;
  revealPositions: boolean;
  flagsAssetsB64: any = {};
  usersDataSubscription: Subscription;
  carpets: Phaser.GameObjects.Sprite[] = [];
  moveMyPlayerTween: Phaser.Tweens.Tween;
  currentCountriesData: IUserGameNegotiation[];
  zoomBoard: Phaser.GameObjects.Sprite;
  overlay: Phaser.GameObjects.Sprite;
  zoomTweenIn: Phaser.Tweens.Tween;
  zoomTweenOut: Phaser.Tweens.Tween;
  zoomAvatars: Phaser.GameObjects.Sprite[];
  moveButton: Phaser.GameObjects.Sprite;
  action: Phaser.GameObjects.Sprite;
  star: Phaser.GameObjects.Sprite;
  flag: Phaser.GameObjects.Sprite;
  cancelButton: Phaser.GameObjects.Sprite;
  zoomStatsGroup: Phaser.GameObjects.Group;
  zoomLabel: Phaser.GameObjects.Sprite;
  avatarPopUp: Phaser.GameObjects.Sprite;
  negotiateButton: Phaser.GameObjects.Sprite;
  peopleIcon: Phaser.GameObjects.Sprite;
  avatarPopUpGroup: Phaser.GameObjects.Group;
  dimensionSubscription: Subscription;
  squareLabels: Phaser.GameObjects.Sprite[];
  squarePins: Phaser.GameObjects.Sprite[] = [];
  zoomLabelTween: Phaser.Tweens.Tween;
  avatarGroup: Phaser.GameObjects.Sprite[];
  userCountry: string;
  legsAnimations: Phaser.GameObjects.Sprite[] = [];
  armsAnimations: Phaser.GameObjects.Sprite[] = [];
  legsAnimationsIdle: Phaser.GameObjects.Sprite[] = [];
  armsAnimationsIdle: Phaser.GameObjects.Sprite[] = [];
  faceAnimationsIdle: Phaser.GameObjects.Sprite[] = [];
  faceAnimations: Phaser.GameObjects.Sprite[] = [];
  zoomAvatarsAnimationsArmsIdle: Phaser.GameObjects.Sprite[] = [];
  zoomAvatarsAnimationsLegsIdle: Phaser.GameObjects.Sprite[] = [];
  zoomAvatarsAnimationsFaceIdle: Phaser.GameObjects.Sprite[] = [];
  selectedDimensionName: string;
  selectedDimensionValue: number;
  minParamDevice = 0;
  zoomMinParamDevice = 0;
  redLinePopUp: Phaser.GameObjects.Sprite;
  hasRedLine: boolean;
  moveConfirmationPopUp: Phaser.GameObjects.Sprite;
  genericCancelButton: Phaser.GameObjects.Sprite;
  confirmButton: Phaser.GameObjects.Sprite;
  userGames = [];
  myPlayerIndicator: Phaser.GameObjects.Sprite;
  myPlayerIndicatorZoom: Phaser.GameObjects.Sprite;
  myBilateral: any;
  myUserBilateral: any;
  routeSubscription: Subscription;
  pointsSubscription: Subscription;
  pointsCalcAfterMovement: IUserGame;
  pointsNow: IUserGame;
  hiddenSubscription: Subscription;
  negotiationPageSubscription: Subscription;
  negotiationNav: boolean;
  redLineIndicatorZoomLabel: Phaser.GameObjects.Sprite;
  redLineIndicatorZoomBottom: Phaser.GameObjects.Sprite;
  bgDisplays: Phaser.GameObjects.Sprite[];
  updatePosTweens: Phaser.Tweens.Tween[] = [];
  whiteArrowDimensions: Phaser.GameObjects.Sprite;
  ovalHelpDimensionsAnim: Phaser.GameObjects.Sprite;
  guideTilesText: Phaser.GameObjects.Text;
  whiteArrowTiles: Phaser.GameObjects.Sprite;
  ovalHelpAnim: Phaser.GameObjects.Sprite;
  cancelButtonBoardText: Phaser.GameObjects.Text;
  isInformational = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  private usersData: IUserGame[];
  private triggerTimer: Phaser.Time.TimerEvent;
  private assetScale = 1.35;
  private dynamicSvgScaleX = 0.64;
  private dynamicSvgScaleY = 0.6;
  private zoomAvatarScaleX = 0.95;
  private zoomAvatarScaleY = 0.9;
  private boardAnimScale = 0.5;
  private zoomAnimScale = 0.72;
  private speedFactor = 0.18;

  constructor() {
    super({
      key: NegotiationScene.key,
      active: NegotiationScene.active,
    });

    this.dimensionsIds = [
      { name: 'd1', id: null, value: 1 },
      { name: 'd2', id: null, value: 2 },
      { name: 'd3', id: null, value: 3 },
    ];

    context.countriesQuery.countries$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.countries = res;
      });

    context.gameQuery.isInformational$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isInformational = res;
      });

    this.selectedTopic = context.gameQuery.getCurrentTopic();

    this.dimensions = this.selectedTopic.dimension;

    this.dimensionsIds.splice(this.dimensions.length, this.dimensionsIds.length - this.dimensions.length);

    this.dimensionsIds.forEach((dim, i) => {
      dim.id = this.dimensions[i].id;
    });
    context.gameService.updateSelectedDimension(
      this.dimensionsIds.find((el) => el.name === 'd1')
    );

    context.gameQuery
      .select((state) => state.game?.userGames)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.userGames = response;
      });

    this.squareInfo = this.dimensions[0].positionContent;
    this.numOfTiles = this.squareInfo.length - 1;
    this.revealPositionsSub = context.gameQuery.revealPositions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.revealPositions = res;
        if (this.revealPositions === true) {
          if (
            this.usersData.find(
              (el) => el.userId === context.userQuery.getUserId()
            ).actionPoint >= 2
          ) {
            this.findSensitivePositions();
          } else {
            this.createWarningPopUp(PopUpTypeOfUse.noPoints);
          }
        } else if (this.revealPositions === false) {
          this.clearSensitivePositions();
        } else {
          return;
        }
      });

    this.usersDataSubscription = context.gameQuery.game$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (!response) {
          return;
        }
        this.usersData = response.userGames;

        this.userCountry = this.usersData.find(
          (el) => el.userId === context.userQuery.getUserId()
        ).country;

        this.usersData
          .find((ug) => ug.country === this.userCountry)
          .user.userGameNegotiation.forEach((dim) => {
            if (dim.stance === Stance.redLine) {
              this.hasRedLine = true;
            }
          });
      });

    this.dimensionSubscription = context.gameQuery.selectedDimension$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dimension) => {
        this.selectedDimensionName = dimension.name;
        this.selectedDimensionValue = dimension.value;
      });

    context.gameService.updateModalOpen(false);
    context.hideZoomBoard();

    this.routeSubscription = context.routerEvents
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.zoomBoardOut();
        }
      });
    let toggleState = false;
    context.toggleChecked$.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      if (toggleState !== e) {
        toggleState = e;
        this.whiteArrowDimensions?.setVisible(e).setDepth(10 ** 10);
        this.guideDimensionsText?.setVisible(e).setDepth(10 ** 10);
        this.ovalHelpDimensionsAnim?.setVisible(e).setDepth(10 ** 10);
        this.whiteArrowTiles?.setVisible(e).setDepth(10 ** 10);
        this.guideTilesText?.setVisible(e).setDepth(10 ** 10);

        this.setVisibilityOnHelpGuide(e);
      }
    });

    setTimeout(() => {
      context.translateService.onLangChange
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) => {
            console.log('COULDNT TRANSLATE ', err);
            throw err;
          })
        )
        .subscribe(() => {
          this.updateData(context.gameQuery.getSelectedDimension().name);
          this.updateTranslatedTexts();
          this.handleAdditionOfSquareLabels();
        });
      context.gameQuery.topics$
        .pipe(takeUntil(this.destroy$))
        .subscribe((topics) => {
          if (
            !topics.length ||
            context.gameQuery.getCurrentTopic().name === this.selectedTopic.name
          ) {
            return;
          }

          this.selectedTopic = context.gameQuery.getCurrentTopic();
          this.updateData(context.gameQuery.getSelectedDimension().name);
          this.handleAdditionOfSquareLabels();
          this.build(
            context.gameQuery.getSelectedDimension().name,
            this.numOfTiles
          );
        });
    }, 4000);
  }

  setVisibilityOnHelpGuide(e: boolean) {
    this.squareLabels?.forEach((lbl) => {
      lbl.setVisible(!e);
    });
    this.squarePins?.forEach((pin) => {
      pin.setVisible(!e);
    });
    this.squareTexts?.forEach((txt) => {
      txt.setVisible(!e);
    });
    this.avatars.forEach((av) => {
      av.setVisible(!e);
    });

    this.redGlow?.setVisible(!e);

    this.avatars?.forEach((element) => {
      this.myPlayerIndicator.setVisible(!e);
      element.setVisible(!e);
      this.legsAnimationsIdle
        .find(
          (el) => el.data.values.countryCode === element.data.values.countryCode
        )
        .setVisible(!e);
      this.faceAnimationsIdle
        .find(
          (el) => el.data.values.countryCode === element.data.values.countryCode
        )
        .setVisible(!e);
      this.armsAnimationsIdle
        .find(
          (el) => el.data.values.countryCode === element.data.values.countryCode
        )
        .setVisible(!e);
    });

    if (this.selectedDimensionName === 'd3') {
      this.d1Clickable?.setVisible(!e);
      this.d2Clickable?.setVisible(!e);
      this.d1ClickableText?.setVisible(!e);
      this.d2ClickableText?.setVisible(!e);
    } else if (this.selectedDimensionName === 'd2') {
      this.d1Clickable?.setVisible(!e);
      this.d1ClickableText?.setVisible(!e);
    }
  }

  loading() {
    context.loading.next(false);
  }

  public preload(): void {
    context.loading.next(true);
    this.loadAssets();
    this.load.on('complete', this.loading);
    this.load.on('loaderror', this.onLoadError);

    this.initializeAvatarsTextures();
  }

  onLoadError(file) {
    console.log({ file });
  }

  public create(): void {
    this.events.on('destroy', () => {
      this.destroy$.next(true);
      this.destroy$.complete();
    });
    this.build(this.selectedDimensionName, this.numOfTiles);
    setTimeout(() => {
      this.arrangeAvatars(this.selectedDimensionName);
    }, 500);

    this.triggerTimer = this.time.addEvent({
      callback: this.updateAvatarsDepth,
      callbackScope: this,
      delay: 250,
      loop: true,
    });

    this.whiteArrowDimensions = this.add
      .sprite(
        pos.dLabelsPositions[0].x - 70,
        pos.dLabelsPositions[1].y - 150,
        'whiteArrow'
      )
      .setRotation(7)
      .setVisible(false);
    this.whiteArrowTiles = this.add
      .sprite(
        pos.squarePositionsD1[0].x - 120,
        pos.squarePositionsD1[0].y - 180,
        'whiteArrow'
      )
      .setRotation(8)
      .setVisible(false);
    this.guideDimensionsText = this.add
      .text(
        this.whiteArrowDimensions.x - 10,
        this.whiteArrowDimensions.y - 160,
        `${context.translateService.instant(
          !this.isInformational ? 'negotiation_phase_guide_elements_text' : 'negotiation_board_isinformational_guide_dimensions'
        )}`
      )
      .setFontSize(24)
      .setAlign('center')
      .setWordWrapWidth(220)
      .setFontFamily(context.gameFont)
      .setVisible(false);

    this.ovalHelpDimensionsAnim = this.add
      .sprite(pos.dLabelsPositions[0].x, pos.dLabelsPositions[1].y, 'oval')
      .setVisible(false);

    this.guideTilesText = this.add
      .text(
        this.whiteArrowTiles.x + 40,
        this.whiteArrowTiles.y - 200,
        `${context.translateService.instant(
          !this.isInformational ? 'negotiation_phase_guide_tiles_text' : 'negotiation_board_isinformational_guide_positions'
        )}`
      )
      .setFontSize(24)
      .setAlign('center')
      .setOrigin(0, 0)
      .setWordWrapWidth(220)
      .setFontFamily(context.gameFont)
      .setVisible(false);

    this.tweens.add({
      scale: this.ovalHelpDimensionsAnim.scale * 1.1,
      targets: this.ovalHelpDimensionsAnim,
      ease: 'Linear',
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    if (this.hasRedLine) {
      context.translate
        .getTranslate()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.redLinePopUpText1?.destroy(true);
          this.redLinePopUpText2?.destroy(true);
          this.redLinePopUpText3?.destroy(true);

          this.redLinePopUp = this.add
            .sprite(700, 300, 'redLinePopUp')
            .setOrigin(0, 0)
            .setDepth(1000000)
            .setAlpha(0.95)
            .setInteractive()
            .on('pointerdown', () => {
              this.redLinePopUp.destroy(true);
              this.redLinePopUpText1.destroy(true);
              this.redLinePopUpText2.destroy(true);
              this.redLinePopUpText3.destroy(true);
            });

          this.redLinePopUpText1 = this.add
            .text(
              this.redLinePopUp.x + 20,
              this.redLinePopUp.y + 60,
              context.translateService.instant(
                'bilateral_talks_redline_uncovered_information_title'
              )
            )
            .setFontSize(28)
            .setFontFamily(context.gameFont)
            .setDepth(this.redLinePopUp.depth + 1)
            .setOrigin(0, 0)
            .setWordWrapWidth(this.redLinePopUp.displayWidth)
            .setAlign('left')
            .setStyle({
              color: '#003399',
              weight: 'bold',
            });

          this.redLinePopUpText2 = this.add
            .text(
              this.redLinePopUpText1.x,
              this.redLinePopUpText1.y + this.redLinePopUpText1.displayHeight,
              `• ${context.translateService.instant(
                'bilateral_talks_redline_uncovered_information_text1'
              )}`
            )
            .setFontSize(22)
            .setDepth(this.redLinePopUp.depth + 1)
            .setOrigin(0, 0)
            .setFontFamily(context.gameFont)
            .setWordWrapWidth(320)
            .setAlign('left')
            .setPadding(18, 20, 1, 1)
            .setStyle({
              color: '#003399',
            });

          this.redLinePopUpText3 = this.add
            .text(
              this.redLinePopUpText2.x,
              this.redLinePopUpText2.y + this.redLinePopUpText2.displayHeight,
              `• ${context.translateService.instant(
                'bilateral_talks_redline_uncovered_information_text2'
              )}`
            )
            .setFontSize(22)
            .setDepth(this.redLinePopUp.depth + 1)
            .setOrigin(0, 0)
            .setFontFamily(context.gameFont)
            .setWordWrapWidth(320)
            .setPadding(18, 20, 1, 1)
            .setAlign('left')
            .setStyle({
              color: '#003399',
            });

          this.redLinePopUp.setDisplaySize(
            this.redLinePopUpText1.displayWidth + 100,
            this.redLinePopUp.displayHeight
          );
          timer(8000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.redLinePopUp?.destroy(true);
              this.redLinePopUpText1?.destroy(true);
              this.redLinePopUpText2?.destroy(true);
              this.redLinePopUpText3?.destroy(true);
            });
        });
    }
    setTimeout(() => {
      this.usersDataSubscription = context.gameQuery.game$
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (!response) {
            return;
          }
          this.usersData = response.userGames;
          this.updatePositions();
        });
      this.hiddenSubscription = context.hidden
        .pipe(takeUntil(this.destroy$))
        .subscribe((hidden) => {
          if (
            !hidden &&
            (context.gameInstance as Phaser.Game).scene.isVisible(
              this.scene.key
            )
          ) {
            this.updatePositions();
          }
        });
    }, 3000);

    this.negotiationPageSubscription = context.negotiationPage
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.negotiationNav = value;
        if (this.negotiationNav) {
          this.updatePositions();
        }
      });
  }

  initializeAvatarsTextures() {
    this.usersData.forEach((c, index) => {
      if (c.country === this.userCountry) {
        const s = new XMLSerializer().serializeToString(
          context.avatarConstruction(c.country, PhaserScenes.negotiationScene)
        );

        const b64 = `data:image/svg+xml;base64,${window.btoa(s)}`;
        const url = b64;

        const key = `${c.country?.toString()}Empty`;
        this.flagsAssetsB64[key] = url;

        if (this.textures.exists(key)) {
          this.textures.get(key).destroy();
        }
        this.textures.addBase64(key, this.flagsAssetsB64[key]?.toString());
      } else {
        const s = new XMLSerializer().serializeToString(
          context.npcAvatarConstruction(
            c.country,
            PhaserScenes.negotiationScene
          )
        );

        const b64 = `data:image/svg+xml;base64,${window.btoa(s)}`;
        const url = b64;

        const key = `${c.country?.toString()}Empty`;
        this.flagsAssetsB64[key] = url;

        if (this.textures.exists(key)) {
          this.textures.get(key).destroy();
        }
        this.textures.addBase64(key, this.flagsAssetsB64[key]?.toString());
      }
    });
  }

  public update(time: number, delta: number): void {
    if (
      this.avatars?.find(
        (av) => av.data.values.countryCode === this.userCountry
      )?.data.values.redLine
    ) {
      this.myPlayerIndicator
        ?.setTexture('myPlayerIndicatorArrow')
        ?.setPosition(
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          )?.x - 5,
          this.avatars.find(
            (av) => av.data.values.countryCode === this.userCountry
          ).y -
            this.avatars.find(
              (av) => av.data.values.countryCode === this.userCountry
            ).displayHeight /
              2
        );
    } else {
      this.myPlayerIndicator
        ?.setTexture('myPlayerIndicator')
        ?.setPosition(
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          )?.x - 5,
          this.avatars.find(
            (el) => el.data.values.countryCode === this.userCountry
          )?.y + 3
        );
    }
    this.myPlayerIndicator?.setVisible(
      this.avatars.find((el) => el.data.values.countryCode === this.userCountry)
        .visible
    );

    this.checkLabelClickability();
    this.redGlow
      ?.setPosition(
        this.avatars.find((el) => el.data.values.redLine === true)?.x - 5,
        this.avatars.find((el) => el.data.values.redLine === true)?.y + 60
      )
      .setDepth(
        this.avatars.find((el) => el.data.values.redLine === true)?.depth -
          0.0001
      );
    this.myPlayerIndicator?.setDepth(
      this.avatars?.find(
        (el) => el.data.values.countryCode === this.userCountry
      )?.depth - 0.0001
    );
    this.d1Clickable?.setDepth(
      isNaN(this.d2Clickable?.depth + 10) ? 10 : this.d2Clickable?.depth + 10
    );
    this.d1ClickableText?.setDepth(this.d1Clickable?.depth + 1);
    this.d2ClickableText?.setDepth(this.d2Clickable?.depth + 1);

    this.carpets?.forEach((element) => {
      element
        .setPosition(
          this.avatars.find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          ).x - 5,
          this.avatars.find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          ).y + 60
        )
        .setDepth(
          this.avatars.find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          ).depth - 0.0001
        );
    });

    this.legsAnimations?.forEach((element) => {
      element
        ?.setPosition(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).x - this.minParamDevice,
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).y
        )
        .setDepth(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).depth - 0.00001
        );
    });
    this.armsAnimations?.forEach((element) => {
      element
        ?.setPosition(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).x - this.minParamDevice,
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).y
        )
        .setDepth(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).depth + 0.00001
        );
    });
    this.faceAnimations?.forEach((element) => {
      element
        ?.setPosition(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).x - this.minParamDevice,
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).y
        )
        .setDepth(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).depth + 0.00001
        );
    });
    this.legsAnimationsIdle?.forEach((element) => {
      element
        ?.setPosition(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).x - this.minParamDevice,
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).y
        )
        .setDepth(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).depth - 0.00001
        );
    });
    this.armsAnimationsIdle?.forEach((element) => {
      element
        ?.setPosition(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).x - this.minParamDevice,
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).y
        )
        .setDepth(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).depth + 0.00001
        );
    });
    this.faceAnimationsIdle?.forEach((element) => {
      element
        ?.setPosition(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).x - this.minParamDevice,
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).y
        )
        .setDepth(
          this.avatars.find(
            (it) =>
              it.data.values.countryCode === element.data.values.countryCode
          ).depth + 0.00001
        );
    });
    this.yellowGlowGroup?.children.each((el: Phaser.GameObjects.Sprite) => {
      el?.setPosition(
        this.avatars.find(
          (it) => it.data.values.countryCode === el.data.values.countryCode
        ).x - 5,
        this.avatars.find(
          (it) => it.data.values.countryCode === el.data.values.countryCode
        ).y + 60
      ).setDepth(el.depth - 0.0001);
    });
  }

  updatePositionsMovement(targetAvatar, destination, index, underTenData) {
    if (
      this.moveMyPlayerTween?.isPlaying() ||
      this.updatePosTweens.find((it) => it?.isPlaying())
    ) {
      return;
    }

    let destinationX = 0;
    let destinationY = 0;

    const occurrences = _.countBy(underTenData.posIndexCount);

    if (!underTenData.isUnderTen) {
      if (targetAvatar.data.values.countryCode === this.userCountry) {
        destinationX = destination.find(
          (item) => item.countryCode === 'myCountry'
        ).x;
        destinationY = destination.find(
          (item) => item.countryCode === 'myCountry'
        ).y;
      } else {
        destinationX = destination.find(
          (el) => el.countryCode === targetAvatar.data.values.countryCode
        ).x;
        destinationY = destination.find(
          (el) => el.countryCode === targetAvatar.data.values.countryCode
        ).y;
      }
    } else {
      if (targetAvatar.data.values.countryCode === this.userCountry) {
        destinationX = destination.find(
          (item) => item.countryCode === 'myCountry'
        ).x;
        destinationY = destination.find(
          (item) => item.countryCode === 'myCountry'
        ).y;
      } else {
        destinationX = destination[occurrences[underTenData.posIndex] - 1].x;
        destinationY = destination[occurrences[underTenData.posIndex] - 1].y;
      }
    }

    if (destinationX === targetAvatar.x && destinationY === targetAvatar.y) {
      return;
    }

    const updatePosTween = this.tweens.add({
      targets: [targetAvatar],
      x: destinationX,
      y: destinationY,
      ease: 'Linear',
      duration: (
        Phaser.Math.Distance.Between(
          targetAvatar.x,
          targetAvatar.y,
          destinationX,
          destinationY
        ) / this.speedFactor
      ).toFixed(1),
      // completeDelay: 3000,
      delay: index * 50,
      onStart: () => {
        if (!this.revealPositions) {
          this.carpets
            .find(
              (it) =>
                it.data.values.countryCode ===
                targetAvatar.data.values.countryCode
            )
            .setVisible(true)
            .play('altWalking');
        }

        this.tweens.add({
          targets: [this.redGlow],
          ease: 'Linear',
          alpha: 1,
          delay: 1200,
          duration: 1600,
        });
        setTimeout(() => {
          this.tweens.add({
            targets: [
              targetAvatar,
              this.legsAnimations.find(
                (el) =>
                  el.data.values.countryCode ===
                  targetAvatar.data.values.countryCode
              ),
              this.armsAnimations.find(
                (el) =>
                  el.data.values.countryCode ===
                  targetAvatar.data.values.countryCode
              ),
              this.faceAnimations.find(
                (el) =>
                  el.data.values.countryCode ===
                  targetAvatar.data.values.countryCode
              ),
              this.redGlow,
              this.myPlayerIndicator,
            ],
            ease: 'Linear',
            alpha: 1,
            delay: 800,
            duration: 1800,
          });
        });
      },
      onComplete: () => {
        if (!this.myPlayerIndicator?.visible) {
          this.myPlayerIndicator.setVisible(true);
        }
        this.legsAnimations
          .find(
            (av) =>
              av.data.values.countryCode ===
              targetAvatar.data.values.countryCode
          )
          .stop()
          .setVisible(false);
        this.armsAnimations
          .find(
            (av) =>
              av.data.values.countryCode ===
              targetAvatar.data.values.countryCode
          )
          .stop()
          .setVisible(false);
        this.faceAnimations
          .find(
            (av) =>
              av.data.values.countryCode ===
              targetAvatar.data.values.countryCode
          )
          .stop()
          .setVisible(false);
        if (
          !this.revealPositions ||
          targetAvatar.data.values.sensitive ||
          targetAvatar.data.values.redLine
        ) {
          this.legsAnimationsIdle
            .find(
              (av) =>
                av.data.values.countryCode ===
                targetAvatar.data.values.countryCode
            )
            .setVisible(true);
          this.armsAnimationsIdle
            .find(
              (av) =>
                av.data.values.countryCode ===
                targetAvatar.data.values.countryCode
            )
            .setVisible(true);
          this.faceAnimationsIdle
            .find(
              (av) =>
                av.data.values.countryCode ===
                targetAvatar.data.values.countryCode
            )
            .setVisible(true);
        }

        this.carpets
          .find(
            (it) =>
              it.data.values.countryCode ===
              targetAvatar.data.values.countryCode
          )
          .setAlpha(1)
          .setVisible(false);
        this.carpets
          .find((it) => it.data.values.countryCode === this.userCountry)
          .setAlpha(0)
          .setVisible(false);
      },
    });
    this.updatePosTweens.push(updatePosTween);
  }

  findRedPositions() {
    this.countryRedPositions = [];
    this.redGlow?.destroy(true);

    this.squareInfo.forEach((element) => {
      element.currentCountryPosition.forEach((el) => {
        if (el.positionStance === Stance.redLine) {
          this.countryRedPositions.push(el.countryCode);
        }
      });
    });
    this.avatars.forEach((element) => {
      element.setData({
        redLine: this.countryRedPositions.includes(
          element.data.values.countryCode
        ),
      });
    });

    if (this.countryRedPositions.length === 0) {
      return;
    }
    const redLineAvatar = this.avatars.find(
      (el) => el.data.values.redLine === true
    );
    this.redGlow = this.add
      .sprite(
        redLineAvatar.x - 5,
        redLineAvatar.y + 60,
        'redGlow',
        '21_Red_Marking_00000'
      )
      .setAlpha(0)
      .setData({
        country: redLineAvatar.data.values.countryCode,
      })
      .setVisible(false)
      .play('redGlow');

    if (redLineAvatar.data.values.countryCode === this.userCountry) {
      this.redGlow.setVisible(true).play('redGlow');
    }
  }

  findSensitivePositions() {
    this.countrySensitivePositions = [];
    if (this.yellowGlowGroup) {
      this.yellowGlowGroup?.children?.each((it) => {
        it.destroy();
      });
    }
    this.redGlow?.setVisible(true);
    this.squareInfo.forEach((element) => {
      element.currentCountryPosition.forEach((el) => {
        if (el.positionStance === 'Sensitive') {
          this.countrySensitivePositions.push(el.countryCode);
        }
      });
    });
    this.avatars?.forEach((element) => {
      element.setData({
        sensitive: this.countrySensitivePositions.includes(
          element.data.values.countryCode
        ),
      });
    });

    if (this.countrySensitivePositions.length === 0) {
      return;
    }
    this.yellowGlowGroup = this.add?.group();
    this.avatars?.forEach((element) => {
      this.carpets.forEach((carpet) => {
        carpet.setVisible(false);
      });
      if (element.data.values.sensitive) {
        this.yellowGlowGroup.add(
          this.add
            .sprite(
              element.x - 5,
              element.y + 60,
              'yellowGlow',
              '20_Yellow_Marking_00000'
            )
            .setDepth(element.depth - 0.0001)
            .setData({ countryCode: element.data.values.countryCode })
        );
      } else if (
        !element.data.values.sensitive &&
        !element.data.values.redLine
      ) {
        if (element.data.values.countryCode === this.userCountry) {
          this.myPlayerIndicator.setVisible(false);
        }
        element.setVisible(false);
        this.legsAnimationsIdle
          .find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          )
          .setVisible(false);
        this.faceAnimationsIdle
          .find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          )
          .setVisible(false);
        this.armsAnimationsIdle
          .find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          )
          .setVisible(false);
        this.legsAnimations
          .find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          )
          .setVisible(false);
        this.armsAnimations
          .find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          )
          .setVisible(false);
        this.faceAnimations
          .find(
            (el) =>
              el.data.values.countryCode === element.data.values.countryCode
          )
          .setVisible(false);
      }
      this.yellowGlowGroup.children.each((yel: Phaser.GameObjects.Sprite) => {
        yel.play('yellowGlow');
      });
    });
  }

  clearSensitivePositions() {
    if (this.yellowGlowGroup) {
      this.yellowGlowGroup.children?.each((it) => {
        it.destroy();
      });
    }
    if (
      this.avatars.find((el) => el.data.values?.redLine === true)?.data?.values
        .countryCode !== this.userCountry
    ) {
      this.redGlow?.setVisible(false);
    }
    this.avatars?.forEach((element) => {
      element.setVisible(true);
      this.myPlayerIndicator.setVisible(true);
      this.legsAnimationsIdle
        .find(
          (el) => el.data.values.countryCode === element.data.values.countryCode
        )
        .setVisible(true);
      this.faceAnimationsIdle
        .find(
          (el) => el.data.values.countryCode === element.data.values.countryCode
        )
        .setVisible(true);
      this.armsAnimationsIdle
        .find(
          (el) => el.data.values.countryCode === element.data.values.countryCode
        )
        .setVisible(true);
    });
  }

  updateData(newDimension: string) {
    let dimId = 0;
    switch (newDimension) {
      case 'd1':
        dimId = this.dimensions?.find(
          (dim) =>
            dim.id ===
            this.dimensionsIds.find((el) => el.name === newDimension)?.id
        )?.id;
        context.gameService.updateSelectedDimension(
          this.dimensionsIds.find((el) => el.name === newDimension)
        );

        break;
      case 'd2':
        dimId = this.dimensions?.find(
          (dim) =>
            dim.id ===
            this.dimensionsIds.find((el) => el.name === newDimension)?.id
        )?.id;
        context.gameService.updateSelectedDimension(
          this.dimensionsIds.find((el) => el.name === newDimension)
        );

        break;
      case 'd3':
        dimId = this.dimensions?.find(
          (dim) =>
            dim.id ===
            this.dimensionsIds?.find((el) => el.name === newDimension)?.id
        )?.id;
        context.gameService.updateSelectedDimension(
          this.dimensionsIds?.find((el) => el.name === newDimension)
        );

        break;
      default:
      case 'd1':
        dimId = this.dimensions?.find(
          (dim) =>
            dim.id ===
            this.dimensionsIds?.find((el) => el.name === newDimension)?.id
        )?.id;
        context.gameService.updateSelectedDimension(
          this.dimensionsIds.find((el) => el.name === newDimension)
        );
        break;
    }
    this.selectedTopic = context.gameQuery.getCurrentTopic();
    this.countries = context.countriesQuery.getCountries();
    this.dimensions = this.selectedTopic?.dimension;
    this.squareInfo = this.dimensions.find(
      (dim) => dim.id === dimId
    ).positionContent;
    this.numOfTiles = this.squareInfo.length - 1;
    this.findRedPositions();
  }

  addInterative(sprite: Phaser.GameObjects.Sprite, opt?: any) {
    sprite.setInteractive({ useHandCursor: true, pixelPerfect: true });
    sprite.on('pointerover', (e: any) => {
      sprite.input.alwaysEnabled = true;
      sprite.setTexture(`${opt.active}`);
      sprite.x = opt.x;
      sprite.y = opt.y;
      // sprite.setOrigin(0, 0);
    });
    sprite.on('pointerout', () => {
      sprite.setTexture(`${opt.inactive}`);
      sprite.x = opt.x;
      sprite.y = opt.y;
      // sprite.setOrigin(0, 0);
    });
    sprite.on('pointerdown', (e) => {
      if (!this.scene.isVisible()) {
        return;
      }
      this.zoomIn(sprite);
    });
  }

  zoomIn(sprite) {
    this.updateCurrentCountries(sprite);
    context.guideDisabled$.next(true);

    if (sprite.data.values.currentCountries.length <= 4) {
      this.zoomInTransition(sprite, 1, 'zoomBoard1', 100);
    } else if (
      sprite.data.values.currentCountries.length <= 8 &&
      sprite.data.values.currentCountries.length > 4
    ) {
      this.zoomInTransition(sprite, 2, 'zoomBoard2', 100);
    } else if (
      sprite.data.values.currentCountries.length <= 14 &&
      sprite.data.values.currentCountries.length > 8
    ) {
      this.zoomInTransition(sprite, 3, 'zoomBoard3', 100);
    } else if (
      sprite.data.values.currentCountries.length <= 20 &&
      sprite.data.values.currentCountries.length > 14
    ) {
      this.zoomInTransition(sprite, 4, 'zoomBoard4', 100);
    } else if (
      sprite.data.values.currentCountries.length <= 27 &&
      sprite.data.values.currentCountries.length > 20
    ) {
      this.zoomInTransition(sprite, 5, 'zoomBoard5', 100);
    }
  }

  addSquareLabels(position) {
    this.squares.forEach((el) => {
      if (el.visible === true) {
        const addParameter = this.defineAddParameter();

        const posIndex = position.find(
          (it) =>
            it.value ===
            (el.data.values?.value === 0
              ? el.data.values?.value
              : el.data.values?.value + addParameter)
        );

        const squarePin = this.add.sprite(
          position.find(
            (it) =>
              it.value ===
              (el.data.values?.value === 0
                ? el.data.values?.value
                : el.data.values?.value + addParameter)
          ).x,
          position.find(
            (it) =>
              it.value ===
              (el.data.values?.value === 0
                ? el.data.values?.value
                : el.data.values?.value + addParameter)
          ).y + 70,
          'squarePin'
        );

        let yFactor = 0;
        if (posIndex.value === 3 || posIndex.value === 6) {
          if (el.data.values.name.length > 22) {
            yFactor = 25;
            squarePin.setY(squarePin.y - 15);
          }
        }

        const label = this.add.sprite(
          position.find(
            (it) =>
              it.value ===
              (el.data.values?.value === 0
                ? el.data.values?.value
                : el.data.values?.value + addParameter)
          ).x,
          position.find(
            (it) =>
              it.value ===
              (el.data.values?.value === 0
                ? el.data.values?.value
                : el.data.values?.value + addParameter)
          ).y - yFactor,
          'squareLabel'
        );
        // .setDepth(1200);

        const text = this.add
          .text(label.x, label.y, `${el.data.values.name}`)
          .setFontSize(28)
          .setDepth(label.depth + 10)
          .setOrigin(0.5, 0.5)
          .setWordWrapWidth(220, true)
          .setFontFamily(context.gameFont)
          .setPadding(10, 20)
          .setAlign('left')
          .setLineSpacing(-5)
          .setStyle({
            color: '#003399',
          });
        if (text.height >= 140) {
          text.setFontSize(23);
        }
        label.setDisplaySize(text.width + 50, text.height);

        this.squareLabels.push(label);
        this.squareTexts.push(text);
        this.squarePins.push(squarePin);
      }
    });
  }

  addAnimatedSquareLabels(position) {
    this.squares.forEach((el, i) => {
      if (el.visible === true) {
        const addParameter = this.defineAddParameter();
        const squarePin = this.add.sprite(700, -80, 'squarePin').setAlpha(0);
        const posIndex = position.find(
          (it) =>
            it.value ===
            (el.data.values?.value === 0
              ? el.data.values?.value
              : el.data.values?.value + addParameter)
        );
        let yFactor = 0;
        if (posIndex.value === 3 || posIndex.value === 6) {
          if (el.data.values.name.length > 22) {
            yFactor = 25;
            squarePin.setY(squarePin.y - 15);
          }
        }

        const label = this.add.sprite(squarePin.x, squarePin.y, 'squareLabel');
        // .setDepth(1200);
        const text = this.add
          .text(label.x, -100, `${el.data.values.name}`)
          .setFontSize(28)
          .setDepth(label.depth + 1)
          .setOrigin(0.5, 0.5)
          .setWordWrapWidth(220, true)
          .setFontFamily(context.gameFont)
          .setPadding(10, 20)
          .setLineSpacing(-5)
          .setAlign('left')
          .setStyle({
            color: '#003399',
          });
        if (text.height >= 140) {
          text.setFontSize(23);
        }
        label.setDisplaySize(text.width + 50, text.height);

        this.squareLabels.push(label);
        this.squareTexts.push(text);
        this.squarePins.push(squarePin);

        const squarePinsTween = this.tweens.add({
          targets: [squarePin],
          x: position.find(
            (it) =>
              it.value ===
              (el.data.values?.value === 0
                ? el.data.values?.value
                : el.data.values?.value + addParameter)
          ).x,
          y:
            position.find(
              (it) =>
                it.value ===
                (el.data.values?.value === 0
                  ? el.data.values?.value
                  : el.data.values?.value + addParameter)
            ).y +
            70 -
            yFactor,
          alpha: 1,
          duration: 300,
          delay: i * 150,
        });

        const squareLabelsTween = this.tweens.add({
          targets: [label, text],
          x: position.find(
            (it) =>
              it.value ===
              (el.data.values?.value === 0
                ? el.data.values?.value
                : el.data.values?.value + addParameter)
          ).x,
          y:
            position.find(
              (it) =>
                it.value ===
                (el.data.values?.value === 0
                  ? el.data.values?.value
                  : el.data.values?.value + addParameter)
            ).y - yFactor,
          duration: 300,
          delay: i * 150,
        });
      }
    });
  }

  updateTranslatedTexts() {
    this.d1Label?.setText(
      `${context.translateService.instant('dimension_letter')}1`
    );
    this.d2Label?.setText(
      `${context.translateService.instant('dimension_letter')}2`
    );
    this.d3Label?.setText(
      `${context.translateService.instant('dimension_letter')}3`
    );
    this.cancelButtonText?.setText(
      `${context.translateService.instant('mobile_cancel_button')}`
    );
    // this.textCountries?.setText();
    // this.textWeights?.setText();
    // this.btnText?.setText();
    // this.redLineIndicatorZoomText?.setText();
    this.d1ClickableText?.setText(
      `${context.translateService.instant('dimension_letter')}1`
    );
    this.d2ClickableText?.setText(
      `${context.translateService.instant('dimension_letter')}2`
    );
    this.guideDimensionsText?.setText(
      `${context.translateService.instant(
        'negotiation_phase_guide_elements_text'
      )}`
    );
    this.guideTilesText?.setText(
      `${context.translateService.instant(
        'negotiation_phase_guide_tiles_text'
      )}`
    );
  }

  handleAdditionOfSquareLabels() {
    this.squareLabels?.forEach((el) => {
      el.destroy(true);
    });
    this.squareTexts?.forEach((el) => {
      el.destroy(true);
    });
    this.squarePins?.forEach((el) => {
      el.destroy(true);
    });
    this.squareLabels = [];
    this.squareTexts = [];

    if (this.selectedDimensionName === 'd1') {
      if (
        context.gameQuery.getIsFirstTimeInDimension(this.selectedDimensionValue)
      ) {
        this.addAnimatedSquareLabels(pos.textPositionsD1);
      } else {
        this.addSquareLabels(pos.textPositionsD1);
      }
    } else if (this.selectedDimensionName === 'd2') {
      if (
        context.gameQuery.getIsFirstTimeInDimension(this.selectedDimensionValue)
      ) {
        this.addAnimatedSquareLabels(pos.textPositionsOtherDs);
      } else {
        this.addSquareLabels(pos.textPositionsOtherDs);
      }
    } else if (this.selectedDimensionName === 'd3') {
      if (
        context.gameQuery.getIsFirstTimeInDimension(this.selectedDimensionValue)
      ) {
        this.addAnimatedSquareLabels(pos.textPositionsOtherDs);
      } else {
        this.addSquareLabels(pos.textPositionsOtherDs);
      }
    }

    context.gameService.updateIsFirstTimeInDimension(
      this.selectedDimensionValue
    );

    this.undecidedLabelText?.destroy(true);

    if (!this.isInformational) {
      this.undecidedLabelText = this.add
        .text(
          this.posUndecided.x,
          this.posUndecided.y - 170,
          context.translateService.instant(
            'plakat_negotiation_phase_undecided_countries'
          )
        )
        .setFontSize(24)
        .setDepth(this.posUndecided.depth + 1)
        .setOrigin(0.5, 0.5)
        .setWordWrapWidth(220)
        .setAlign('center')
        .setFontFamily(context.gameFont)
        .setStyle({
          color: '#FFD924',
        });
    }
  }

  defineAddParameter() {
    let addParameter = 0;

    if (this.numOfTiles === 2) {
      addParameter = 3;
    } else if (this.numOfTiles === 3 || this.numOfTiles === 4) {
      addParameter = 2;
    } else if (this.numOfTiles === 5 || this.numOfTiles === 6) {
      addParameter = 1;
    } else {
      return addParameter;
    }
    return addParameter;
  }

  zoomBoardOut() {
    if (this.zoomTweenIn?.isPlaying() || this.zoomLabelTween?.isPlaying()) {
      return;
    }
    if (!this.zoomAvatars) {
      return;
    }
    context.guideDisabled$.next(false);

    if (!this.isInformational) {
      this.pointsSubscription?.unsubscribe();
    }
    context.loading.next(false);

    this.zoomLabelText?.destroy(true);
    context.gameService.updateModalOpen(false);
    context.hideZoomBoard();

    this.overlay?.setVisible(false);
    this.zoomLabel?.destroy(true);

    this.zoomStatsGroup?.children?.each((it) => {
      it.destroy(true);
    });
    this.avatarPopUpGroup?.children?.each((it: Phaser.GameObjects.Sprite) => {
      it?.setVisible(false);
    });
    this.zoomTweenOut = this.tweens.add({
      targets: [
        this?.zoomBoard,
        ...this.zoomAvatars,
        ...this.zoomCountryNames,
        ...this.bgDisplays,
        this?.redLineIndicatorZoomLabel,
        this?.redLineIndicatorZoomBottom,
        this?.redLineIndicatorZoomText,
        this?.myPlayerIndicatorZoom,
        this?.zoomLabel,
        ...this?.zoomAvatarsAnimationsLegsIdle,
        ...this?.zoomAvatarsAnimationsFaceIdle,
        ...this?.zoomAvatarsAnimationsArmsIdle,
      ],
      x: 900,
      y: 0,
      alpha: 0,
      duration: 500,
      onStart: () => {
        this.moveButton?.destroy();
      },
      onComplete: () => {
        this.zoomBoard?.destroy(true);
      },
    });
    return;
  }

  addClick(
    sprite: Phaser.GameObjects.Sprite | Phaser.GameObjects.Text,
    opt?: any
  ) {
    sprite.setInteractive({ useHandCursor: true });

    sprite.on('pointerdown', (e) => {
      if (!this.scene.isVisible()) {
        return;
      }

      if (
        this.updatePosTweens.find((it) => it?.isPlaying()) ||
        this.zoomLabel?.alpha > 0.2
      ) {
      }

      if (opt === 'exit') {
        this.zoomBoardOut();
      }

      if (
        this.moveMyPlayerTween?.isPlaying() ||
        this.updatePosTweens.find((it) => it?.isPlaying()) ||
        this.zoomTweenIn?.isPlaying() ||
        this.zoomLabelTween?.isPlaying() ||
        this.zoomLabel?.alpha > 0.2
      ) {
        return;
      }

      this.whiteArrowDimensions?.setVisible(false);
      this.guideDimensionsText?.setVisible(false);
      this.ovalHelpDimensionsAnim?.setVisible(false);

      context.toggleChecked$.next(false);
      clearTimeout(this.guideDisableTimeout);
      context.guideDisabled$.next(true);

      switch (opt.name) {
        case 'd2':
          this.updateData('d2');
          if (!this.d1Clickable) {
            this.d1Clickable = this.add
              .sprite(200, 200, 'd1Clickable')
              .setOrigin(0.5, 0.5)
              .setData({ name: 'd1Clickable' });
            this.d1ClickableText = this.add
              .text(
                90,
                230,
                `${context.translateService.instant('dimension_letter')}1`
              )
              .setPadding(10, 10)
              .setFontSize(30)
              .setFontFamily(context.gameFont);
            this.addClick(this.d1Clickable, {
              x: 200,
              y: 200,
              name: this.d1Clickable.data.values.name,
            });
          }
          this.d1Clickable.setVisible(true);
          this.d1ClickableText.setVisible(true);
          this.build(this.selectedDimensionName, this.numOfTiles);
          this.arrangeAvatars(this.selectedDimensionName);

          break;
        case 'd1':
          this.updateData('d1');

          this.build(this.selectedDimensionName, this.numOfTiles);
          this.updatePositions();

          break;
        case 'd3':
          this.updateData('d3');

          if (!this.d2Clickable) {
            this.d2Clickable = this.add
              .sprite(200, 265, 'd2Clickable')
              .setOrigin(0.5, 0.5)
              .setData({ name: 'd2Clickable' });
            this.d2ClickableText = this.add
              .text(
                90,
                295,
                `${context.translateService.instant('dimension_letter')}2`
              )
              .setPadding(10, 10)
              .setFontSize(30)
              .setFontFamily(context.gameFont);

            this.addClick(this.d2Clickable, {
              x: 200,
              y: 265,
              name: this.d2Clickable.data.values.name,
            });
          }
          if (!this.d1Clickable) {
            this.d1Clickable = this.add
              .sprite(200, 200, 'd1Clickable')
              .setOrigin(0.5, 0.5)
              .setData({ name: 'd1Clickable' });
            this.d1ClickableText = this.add
              .text(
                90,
                230,
                `${context.translateService.instant('dimension_letter')}1`
              )
              .setPadding(10, 10)
              .setFontSize(30)
              .setFontFamily(context.gameFont);
            this.addClick(this.d1Clickable, {
              x: 200,
              y: 200,
              name: this.d1Clickable.data.values.name,
            });
          }
          this.d1Clickable.setVisible(true);
          this.d2Clickable.setVisible(true);
          this.d1ClickableText?.setVisible(true);
          this.d2ClickableText?.setVisible(true);
          this.build(this.selectedDimensionName, this.numOfTiles);
          this.arrangeAvatars(this.selectedDimensionName);
          break;
        case 'd1Clickable':
          this.d1Clickable?.setVisible(false);
          this.d2Clickable?.setVisible(false);
          this.d1ClickableText?.setVisible(false);
          this.d2ClickableText?.setVisible(false);
          this.updateData('d1');
          this.build(this.selectedDimensionName, this.numOfTiles);
          this.updatePositions();
          break;
        case 'd2Clickable':
          this.d2Clickable.setVisible(false);
          this.d2ClickableText?.setVisible(false);
          this.updateData('d2');
          this.build(this.selectedDimensionName, this.numOfTiles);
          this.arrangeAvatars(this.selectedDimensionName);
          break;
      }
      context.gameService.revealPositions(false);
    });
  }

  updatePositions() {
    if (context.hidden.getValue() || !this.negotiationNav) {
      return;
    }
    const sqrValuesUnderTen = [];

    this.squares.forEach((sqr) => {
      if (sqr.visible) {
        this.updateCurrentCountries(sqr);
      }
    });
    this.squares
      .filter((square) => square.visible)
      .forEach((sq, i) => {
        if (
          sq.data?.values?.currentCountries?.length <= 10 &&
          sq.data?.values?.currentCountries?.length > 0
        ) {
          sqrValuesUnderTen.push(i);
        }
      });

    const underTenData = {
      isUnderTen: false,
      posIndex: null,
      posIndexCount: [],
    };

    this.updatePosTweens = [];
    context.guideDisabled$.next(true);

    this.avatars.forEach((element, i) => {
      if (i === 0) {
        context.guideDisabled$.next(true);
        context.toggleChecked$.next(false);
      } else if (i === 26) {
        this.guideDisableTimeout = setTimeout(() => {
          if (!this.overlay?.visible) {
            context.guideDisabled$.next(false);
          }
        }, i * 300);
      }

      const posIndex = this.squares.find(
        (el) =>
          el.data.values.id ===
          this.usersData
            .find((item) => item.country === element.data.values.countryCode)
            ?.user.userGameNegotiation.find(
              (item) =>
                item.dimension === context.gameQuery.getSelectedDimension().id
            ).position
      ).data.values.value;

      let addParameter = 0;
      if (posIndex !== 0) {
        addParameter = this.defineAddParameter();
      }

      underTenData.isUnderTen = sqrValuesUnderTen.includes(posIndex);
      underTenData.posIndex = posIndex;

      if (context.gameQuery.getSelectedDimension().name === 'd1') {
        if (!underTenData.isUnderTen) {
          this.updatePositionsMovement(
            element,
            pos.countriesBoardPositionsD1[`${posIndex + addParameter}`],
            i,
            underTenData
          );
        } else {
          underTenData.posIndexCount.push(posIndex);
          this.updatePositionsMovement(
            element,
            pos.countriesBoardPositionsTenD1[`${posIndex + addParameter}`],
            i,
            underTenData
          );
        }
      } else {
        if (!underTenData.isUnderTen) {
          this.updatePositionsMovement(
            element,
            pos.countriesBoardPositionsOtherDs[`${posIndex + addParameter}`],
            i,
            underTenData
          );
        } else {
          underTenData.posIndexCount.push(posIndex);
          this.updatePositionsMovement(
            element,
            pos.countriesBoardPositionsTenOtherDs[`${posIndex + addParameter}`],
            i,
            underTenData
          );
        }
      }
    });
  }

  createScene(dimension?) {
    if (this.sceneGroup) {
      this.sceneGroup.children?.each((it) => {
        it.destroy();
      });
    }
    switch (dimension) {
      case 'd1':
        if (this.dimensionsIds[2]) {
          this.d3 = this.add
            .sprite(
              pos.dPositions.find((el) => el.name === 'd3').x,
              pos.dPositions.find((el) => el.name === 'd3').y,
              'd3'
            )
            .setOrigin(0.5, 0.5)
            .setScale(this.assetScale, this.assetScale)
            .setData({name: 'd3'});

          this.d3Label = this.add
            .text(
              pos.dLabelsPositions.find((el) => el.name === 'd3').x,
              pos.dLabelsPositions.find((el) => el.name === 'd3').y,
              `${context.translateService.instant('dimension_letter')}3`
            )
            .setOrigin(0.5, 0.5)
            .setPadding(10, 10)
            .setFontSize(26)
            .setFontFamily(context.gameFont)
            .setData({name: 'd3Label'});

          this.addClick(this.d3Label, {
            x: pos.dLabelsPositions.find((el) => el.name === 'd3').x,
            y: pos.dLabelsPositions.find((el) => el.name === 'd3').y,
            name: 'd3',
          });
        }
        if (this.dimensionsIds[1]) {
          this.d2 = this.add
            .sprite(
              pos.dPositions.find((el) => el.name === 'd2').x,
              pos.dPositions.find((el) => el.name === 'd2').y,
              'd2'
            )
            .setOrigin(0.5, 0.5)
            .setScale(this.assetScale, this.assetScale)
            .setData({name: 'd2'});

          this.d2Label = this.add
            .text(
              pos.dLabelsPositions.find((el) => el.name === 'd2').x,
              pos.dLabelsPositions.find((el) => el.name === 'd2').y,
              `${context.translateService.instant('dimension_letter')}2`
            )
            .setOrigin(0.5, 0.5)
            .setPadding(10, 10)
            .setFontSize(26)
            .setFontFamily(context.gameFont)
            .setData({name: 'd2Label'});

          this.addClick(this.d2Label, {
            x: pos.dLabelsPositions.find((el) => el.name === 'd2').x,
            y: pos.dLabelsPositions.find((el) => el.name === 'd2').y,
            name: 'd2',
          });
        }
        this.d1 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y,
            'd1'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setData({ name: 'd1' });
        this.grid = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y,
            'grid'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          // .setAlpha(0.2)
          .setAlpha(0, 0, 0.4, 0.4)
          .setData({ name: 'grid' });
        this.d1Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd1').x,
            pos.dLabelsPositions.find((el) => el.name === 'd1').y,
            `${context.translateService.instant('dimension_letter')}1`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setData({ name: 'd1Label' });

        this.sceneGroup = this.add.group();

        this.sceneGroup.addMultiple([this.d1, this.grid, this.d1Label]);

        if (this.dimensionsIds[2]) {
          this.sceneGroup.addMultiple([
            this.d3,
            this.d3Label,
          ]);
        }

        if (this.dimensionsIds[1]) {
          this.sceneGroup.addMultiple([
            this.d2,
            this.d2Label,
          ]);
        }
        break;
      case 'd2':
        if (this.dimensionsIds[2]) {
          this.d3 = this.add
            .sprite(
              pos.dPositions.find((el) => el.name === 'd3').x,
              pos.dPositions.find((el) => el.name === 'd3').y,
              'd3'
            )
            .setOrigin(0.5, 0.5)
            .setScale(this.assetScale, this.assetScale)
            .setData({name: 'd3'});

          this.d3Label = this.add
            .text(
              pos.dLabelsPositions.find((el) => el.name === 'd3').x,
              pos.dLabelsPositions.find((el) => el.name === 'd3').y,
              `${context.translateService.instant('dimension_letter')}3`
            )
            .setOrigin(0.5, 0.5)
            .setPadding(10, 10)
            .setFontSize(26)
            .setFontFamily(context.gameFont)
            .setData({ name: 'd3Label' });

          this.addClick(this.d3Label, {
            x: pos.dLabelsPositions.find((el) => el.name === 'd3').x,
            y: pos.dLabelsPositions.find((el) => el.name === 'd3').y,
            name: 'd3',
          });
        }

        this.d1 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y + 60,
            'd1'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setData({ name: 'd1' });
        this.grid = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y + 60,
            'grid'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          // .setAlpha(0.2)
          .setAlpha(0, 0, 0.4, 0.4)

          .setData({ name: 'grid' });
        this.d1Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd1').x,
            pos.dLabelsPositions.find((el) => el.name === 'd1').y,
            `${context.translateService.instant('dimension_letter')}1`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setVisible(false)
          .setData({ name: 'd1Label' });

        this.d2Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd2').x,
            pos.dLabelsPositions.find((el) => el.name === 'd2').y,
            `${context.translateService.instant('dimension_letter')}2`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setData({ name: 'd2Label' });

        this.addClick(this.d2Label, {
          x: pos.dLabelsPositions.find((el) => el.name === 'd2').x,
          y: pos.dLabelsPositions.find((el) => el.name === 'd2').y,
          name: 'd2',
        });

        this.sceneGroup = this.add.group();

        this.sceneGroup.addMultiple([
          this.d1,
          this.grid,
          this.d1Label,
          this.d2Label,
          ]);

        if (this.dimensionsIds[2]) {
          this.sceneGroup.addMultiple([
            this.d3,
            this.d3Label,
          ]);
        }
        break;
      case 'd3':
        this.d3 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd3').x,
            pos.dPositions.find((el) => el.name === 'd3').y,
            'd3'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setVisible(false)
          .setData({ name: 'd3' });

        this.d1 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y + 60,
            'd1'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setData({ name: 'd1' });
        this.grid = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y + 60,
            'grid'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setAlpha(0, 0, 0.4, 0.4)

          .setData({ name: 'grid' });
        this.d1Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd1').x,
            pos.dLabelsPositions.find((el) => el.name === 'd1').y,
            `${context.translateService.instant('dimension_letter')}1`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setVisible(false)
          .setData({ name: 'd1Label' });

        this.d2Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd2').x,
            pos.dLabelsPositions.find((el) => el.name === 'd2').y,
            `${context.translateService.instant('dimension_letter')}2`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setVisible(false)
          .setData({ name: 'd2Label' });

        this.addClick(this.d2Label, {
          x: pos.dLabelsPositions.find((el) => el.name === 'd2').x,
          y: pos.dLabelsPositions.find((el) => el.name === 'd2').y,
          name: 'd2',
        });

        this.d3Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd2').x,
            pos.dLabelsPositions.find((el) => el.name === 'd2').y,
            `${context.translateService.instant('dimension_letter')}3`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setData({ name: 'd3Label' });

        this.addClick(this.d3Label, {
          x: pos.dLabelsPositions.find((el) => el.name === 'd3').x,
          y: pos.dLabelsPositions.find((el) => el.name === 'd3').y,
          name: 'd3',
        });

        this.sceneGroup = this.add.group();

        this.sceneGroup.addMultiple([
          this.d1,
          this.d3,
          this.grid,
          this.d1Label,
          this.d2Label,
          this.d3Label,
        ]);
        break;
      default:
        this.d3 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd3').x,
            pos.dPositions.find((el) => el.name === 'd3').y,
            'd3'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setData({ name: 'd3' });
        this.d2 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd2').x,
            pos.dPositions.find((el) => el.name === 'd2').y,
            'd2'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setData({ name: 'd2' });
        this.d1 = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y,
            'd1'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setData({ name: 'd1' });
        // this.stairs = this.add
        //   .sprite(pos.stairsPosition.x, pos.stairsPosition.y, 'stairs')
        //   .setOrigin(0.5, 0.5)
        //   .setScale(this.assetScale, this.assetScale)
        //   .setData({ name: 'stairs' });
        this.grid = this.add
          .sprite(
            pos.dPositions.find((el) => el.name === 'd1').x,
            pos.dPositions.find((el) => el.name === 'd1').y,
            'grid'
          )
          .setOrigin(0.5, 0.5)
          .setScale(this.assetScale, this.assetScale)
          .setAlpha(0, 0, 0.4, 0.4)

          .setData({ name: 'grid' });

        this.d1Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd1').x,
            pos.dLabelsPositions.find((el) => el.name === 'd1').y,
            `${context.translateService.instant('dimension_letter')}1`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setData({ name: 'd1Label' });

        this.addClick(this.d1Label, {
          x: pos.dLabelsPositions.find((el) => el.name === 'd1').x,
          y: pos.dLabelsPositions.find((el) => el.name === 'd1').y,
          name: 'd1',
        });

        this.d2Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd2').x,
            pos.dLabelsPositions.find((el) => el.name === 'd2').y,
            `${context.translateService.instant('dimension_letter')}2`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setData({ name: 'd2Label' });

        this.addClick(this.d2Label, {
          x: pos.dLabelsPositions.find((el) => el.name === 'd2').x,
          y: pos.dLabelsPositions.find((el) => el.name === 'd2').y,
          name: 'd2',
        });

        this.d3Label = this.add
          .text(
            pos.dLabelsPositions.find((el) => el.name === 'd3').x,
            pos.dLabelsPositions.find((el) => el.name === 'd3').y,
            `${context.translateService.instant('dimension_letter')}3`
          )
          .setOrigin(0.5, 0.5)
          .setPadding(10, 10)
          .setFontSize(26)
          .setFontFamily(context.gameFont)
          .setData({ name: 'd3Label' });

        this.addClick(this.d3Label, {
          x: pos.dLabelsPositions.find((el) => el.name === 'd3').x,
          y: pos.dLabelsPositions.find((el) => el.name === 'd3').y,
          name: 'd3',
        });
        this.sceneGroup = this.add.group();

        this.sceneGroup.addMultiple([
          this.d1,
          this.d2,
          this.d3,
          // this.stairs,
          this.grid,
          this.d1Label,
          this.d2Label,
          this.d3Label,
        ]);
        break;
    }
  }

  placeTiles(dimension?) {
    if (this.squaresGroup) {
      this.squaresGroup.children?.each((it) => {
        it.destroy();
      });
    }
    let position = [];
    let positionUndecided = [];
    let positionText = [];
    switch (dimension) {
      case 'd1':
        position = pos.squarePositionsD1;
        positionText = pos.textPositionsD1;
        positionUndecided = pos.undecidedPositionD1;
        break;
      case 'd2':
        position = pos.squarePositionsOtherDs;
        positionText = pos.textPositionsOtherDs;
        positionUndecided = pos.undecidedPositionOtherDs;
        break;
      case 'd3':
        position = pos.squarePositionsOtherDs;
        positionText = pos.textPositionsOtherDs;
        positionUndecided = pos.undecidedPositionOtherDs;
        break;
      default:
        position = pos.squarePositionsD1;
        positionText = pos.textPositionsD1;
        positionUndecided = pos.undecidedPositionD1;
        break;
    }
    this.squares = [];

    this.squareC1 = this.add
      .sprite(
        position.find((el) => el.name === 'squareC1').x,
        position.find((el) => el.name === 'squareC1').y,
        'square'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareC1, {
      x: position.find((el) => el.name === 'squareC1').x,
      y: position.find((el) => el.name === 'squareC1').y,
      active: 'square-yellow',
      inactive: 'square',
    });
    this.squareC1.setData({ name: 'squareC1' });
    this.squareC2 = this.add
      .sprite(
        position.find((el) => el.name === 'squareC2').x,
        position.find((el) => el.name === 'squareC2').y,
        'square'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareC2, {
      x: position.find((el) => el.name === 'squareC2').x,
      y: position.find((el) => el.name === 'squareC2').y,
      active: 'square-yellow',
      inactive: 'square',
    });
    this.squareC2.setData({ name: 'squareC2' });

    this.squareC3 = this.add
      .sprite(
        position.find((el) => el.name === 'squareC3').x,
        position.find((el) => el.name === 'squareC3').y,
        'square'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareC3, {
      x: position.find((el) => el.name === 'squareC3').x,
      y: position.find((el) => el.name === 'squareC3').y,
      active: 'square-yellow',
      inactive: 'square',
    });
    this.squareC3.setData({ name: 'squareC3' });

    this.squareC4 = this.add
      .sprite(
        position.find((el) => el.name === 'squareC4').x,
        position.find((el) => el.name === 'squareC4').y,
        'square'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareC4, {
      x: position.find((el) => el.name === 'squareC4').x,
      y: position.find((el) => el.name === 'squareC4').y,
      active: 'square-yellow',
      inactive: 'square',
    });
    this.squareC4.setData({ name: 'squareC4' });

    this.squareT1 = this.add
      .sprite(
        position.find((el) => el.name === 'squareT1').x,
        position.find((el) => el.name === 'squareT1').y,
        'square-upleft'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareT1, {
      x: position.find((el) => el.name === 'squareT1').x,
      y: position.find((el) => el.name === 'squareT1').y,
      active: 'square-upleft-yellow',
      inactive: 'square-upleft',
    });
    this.squareT1.setData({ name: 'squareT1' });

    this.squareT2 = this.add
      .sprite(
        position.find((el) => el.name === 'squareT2').x,
        position.find((el) => el.name === 'squareT2').y,
        'square-upright'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareT2, {
      x: position.find((el) => el.name === 'squareT2').x,
      y: position.find((el) => el.name === 'squareT2').y,
      active: 'square-upright-yellow',
      inactive: 'square-upright',
    });
    this.squareT2.setData({ name: 'squareT2' });

    this.squareL = this.add
      .sprite(
        position.find((el) => el.name === 'squareL').x,
        position.find((el) => el.name === 'squareL').y,
        'square-left'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareL, {
      x: position.find((el) => el.name === 'squareL').x,
      y: position.find((el) => el.name === 'squareL').y,
      active: 'square-left-yellow',
      inactive: 'square-left',
    });
    this.squareL.setData({ name: 'squareL' });

    this.squareR = this.add
      .sprite(
        position.find((el) => el.name === 'squareR').x,
        position.find((el) => el.name === 'squareR').y,
        'square-right'
      )
      .setOrigin(1, 1);
    this.addInterative(this.squareR, {
      x: position.find((el) => el.name === 'squareR').x,
      y: position.find((el) => el.name === 'squareR').y,
      active: 'square-right-yellow',
      inactive: 'square-right',
    });
    this.squareR.setData({ name: 'squareR' });

    this.posUndecided = this.add
      .sprite(
        positionUndecided.find((el) => el.name === 'posUndecided').x,
        positionUndecided.find((el) => el.name === 'posUndecided').y,
        'undecided'
      )
      .setAlpha(!this.isInformational ? 1 : 0)
      .setOrigin(0.5, 0.5);

    if (!this.isInformational) {
      this.addInterative(this.posUndecided, {
        x: positionUndecided.find((el) => el.name === 'posUndecided').x,
        y: positionUndecided.find((el) => el.name === 'posUndecided').y,
        active: 'undecided',
        inactive: 'undecided',
      });
    }
    this.posUndecided.setData({ name: 'undecided', value: 0 });
    // let text = null;
    setTimeout(() => {
      this.squaresGroup = this.add.group();

      this.squaresGroup.addMultiple([
        this.posUndecided,
        this.squareC1,
        this.squareC2,
        this.squareC3,
        this.squareC4,
        this.squareT1,
        this.squareT2,
        this.squareL,
        this.squareR,
        // text,
      ]);

      this.dimensionsGroup = this.add.group();

      this.dimensionsGroup.addMultiple([
        this.d1,
        this.squareC1,
        this.squareC2,
        this.squareC3,
        this.squareC4,
        this.squareT1,
        this.squareT2,
        this.squareL,
        this.squareR,
        this.posUndecided,
        this.grid,
        // text,
      ]);
    });
  }

  setVisibleTiles(numOfTiles?) {
    switch (numOfTiles) {
      case 8:
        break;
      case 7:
        this.squareR.setVisible(false);

        break;
      case 6:
        this.squareL.setVisible(false);
        this.squareR.setVisible(false);

        break;
      case 5:
        this.squareL.setVisible(false);
        this.squareR.setVisible(false);
        this.squareC4.setVisible(false);

        break;
      case 4:
        this.squareL.setVisible(false);
        this.squareR.setVisible(false);
        this.squareC4.setVisible(false);
        this.squareC3.setVisible(false);

        break;
      case 3:
        this.squareC3.setVisible(false);
        this.squareL.setVisible(false);
        this.squareR.setVisible(false);
        this.squareC4.setVisible(false);
        this.squareT2.setVisible(false);

        break;
      case 2:
        this.squareL.setVisible(false);
        this.squareC3.setVisible(false);
        this.squareT1.setVisible(false);
        this.squareC4.setVisible(false);
        this.squareR.setVisible(false);
        this.squareT2.setVisible(false);
        break;
      default:
        break;
    }
    this.squares.push(
      this.posUndecided,
      this.squareL,
      this.squareC3,
      this.squareT1,
      this.squareC1,
      this.squareC2,
      this.squareT2,
      this.squareC4,
      this.squareR
    );

    let i = 0;
    this.squares.forEach((sqr) => {
      if (sqr.visible && sqr.data.values.name !== 'undecided') {
        sqr.setData({
          name: this.squareInfo[i].name,
          id: this.squareInfo[i].id,
          value: i + 1,
          currentCountryPosition: this.squareInfo[i].currentCountryPosition,
        });
        i = i + 1;
      } else if (sqr.visible && sqr.data.values.name === 'undecided') {
        sqr.setData({
          name: this.squareInfo[this.squareInfo.length - 1].name,
          noposition: this.squareInfo[this.squareInfo.length - 1].noposition,
          id: this.squareInfo[this.squareInfo.length - 1].id,
          value: 0,
          currentCountryPosition:
            this.squareInfo[this.squareInfo.length - 1].currentCountryPosition,
        });
      }
    });
  }

  updateAvatarsDepth() {
    this.avatars?.forEach((element) => {
      element.setDepth(element.y);
    });
    this.zoomAvatars?.forEach((element) => {
      element.setDepth(element.y + 2000);
    });
    this.zoomAvatarsAnimationsLegsIdle?.forEach((element) => {
      element.setDepth(
        this.zoomAvatars.find(
          (el) => el.data.values.country === element.data.values.country
        ).depth - 1
      );
    });
    this.zoomAvatarsAnimationsFaceIdle?.forEach((element) => {
      element.setDepth(
        this.zoomAvatars.find(
          (el) => el.data.values.country === element.data.values.country
        ).depth + 1000
      );
    });
    this.zoomAvatarsAnimationsArmsIdle.forEach((element) => {
      element.setDepth(
        this.zoomAvatars.find(
          (el) => el.data.values.country === element.data.values.country
        ).depth - 1
      );
    });
  }
  checkLabelClickability() {
    if (
      this.d1Clickable?.visible === true &&
      this.d2Clickable?.visible === true
    ) {
      this.d3Label.disableInteractive();
    } else if (this.d1Clickable?.visible === true) {
      this.d2Label.disableInteractive();
    }
  }

  moveMyAvatar(sprite) {
    if (
      this.moveMyPlayerTween?.isPlaying() ||
      this.zoomLabelTween.isPlaying() ||
      !this.scene.isVisible()
    ) {
      return;
    }

    let positions = {};

    if (context.gameQuery.getSelectedDimension().name === 'd1') {
      positions = pos.countriesBoardPositionsD1;
    } else {
      positions = pos.countriesBoardPositionsOtherDs;
    }

    let addParameter = 0;
    if (sprite.data.values.value !== 0) {
      addParameter = this.defineAddParameter();
    }

    if (
      this.usersData.find((el) => el.userId === context.userQuery.getUserId())
        .actionPoint !== 0
    ) {
      if (
        this.avatars.find(
          (it) => it.data.values.countryCode === this.userCountry
        ).data.values.redLine
      ) {
        this.createWarningPopUp(
          PopUpTypeOfUse.redLine,
          positions,
          sprite,
          addParameter
        );
      } else {
        this.startMoveMyPlayerTween(positions, sprite, addParameter);
      }
    } else {
      this.createWarningPopUp(PopUpTypeOfUse.noPoints);
    }
  }

  startMoveMyPlayerTween(
    positions,
    sprite: Phaser.GameObjects.Sprite,
    addParameter: number
  ) {
    this.zoomBoardOut();
    this.moveMyPlayerTween = this.tweens.add({
      targets: [
        this.avatars.find(
          (it) => it.data.values.countryCode === this.userCountry
        ),
      ],
      ease: 'Linear',
      duration: (
        Phaser.Math.Distance.Between(
          this.avatars.find(
            (it) => it.data.values.countryCode === this.userCountry
          ).x,
          this.avatars.find(
            (it) => it.data.values.countryCode === this.userCountry
          ).y,
          positions[sprite.data.values.value + addParameter].find(
            (el) => el.countryCode === 'myCountry'
          ).x,
          positions[sprite.data.values.value + addParameter].find(
            (el) => el.countryCode === 'myCountry'
          ).y
        ) / 0.18
      ).toFixed(1),

      x: positions[sprite.data.values.value + addParameter].find(
        (el) => el.countryCode === 'myCountry'
      ).x,
      y: positions[sprite.data.values.value + addParameter].find(
        (el) => el.countryCode === 'myCountry'
      ).y,
      onStart: () => {
        this.postUpdatePlayerPosition(sprite);
        this.carpets
          .find((it) => it.data.values.countryCode === this.userCountry)
          .setVisible(true);
      },
      onComplete: () => {

        if (this.isInformational) {
          this.selectedTopic.dimension.some((dimension) => {
            const position = dimension.positionContent.find((position) => position.id === sprite.data.values.id);
            if (position) {
              context.gameService.updatePositionDescription(position.positionDescription);
              return true;
            } else {
              return false;
            }
          });
        }

        this.carpets
          .find((it) => it.data.values.countryCode === this.userCountry)
          .setVisible(false);
      },
    });
  }

  postUpdatePlayerPosition(sprite: Phaser.GameObjects.Sprite) {
    context.gameService
      .updatePlayerPosition({
        userGameNegotiationid: this.usersData
          .find((el) => el.userId === context.userQuery.getUserId())
          .user.userGameNegotiation.find(
            (el) => el.dimension === context.gameQuery.getSelectedDimension().id
          ).userGameNegotiationId,
        positionId: sprite.data.values.id,
        calculateOnly: false,
      })
      .pipe(
        catchError((err) => {
          this.moveMyPlayerTween.stop();
          setTimeout(() => {
            this.updatePositions();
          });
          throw err;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && value.status === 'Success') {
          context.gameStore.update({ game: value.data.items[0] });
        }
      });
  }

  createWarningPopUp(
    typeOfUse = PopUpTypeOfUse.redLine,
    positions?,
    sprite?: Phaser.GameObjects.Sprite,
    addParameter?: number
  ) {
    this.confirmButtonText?.destroy(true);
    this.genericCancelButton?.destroy(true);
    this.cancelButtonText?.destroy(true);
    this.moveConfirmationPopUp?.destroy(true);
    this.moveConfirmationPopUpText?.destroy(true);
    this.confirmButton?.destroy(true);

    let bool = true;

    this.moveConfirmationPopUp = this.add
      .sprite(
        this.game.scale.width / 2,
        this.game.scale.height / 2,
        'genericPopUp'
      )
      .setDepth(1000001)
      .setAlpha(0.95)
      .setInteractive();
    this.moveConfirmationPopUpText = this.add
      .text(
        this.moveConfirmationPopUp.x -
          this.moveConfirmationPopUp.displayWidth / 2,
        this.moveConfirmationPopUp.y - this.moveConfirmationPopUp.y / 4,
        `${context.translateService.instant(
          'red_line_negotiation_phase_move_popup'
        )}`
      )
      .setFontSize(32)
      .setOrigin(0.5, 0.5)
      .setDepth(this.moveConfirmationPopUp?.depth)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#003399',
      })
      .setWordWrapWidth(this.moveConfirmationPopUp.displayWidth * 0.9)
      .setAlign('center')
      .setPadding(20, 20)
      .setLineSpacing(10);

    this.confirmButton = this.add
      .sprite(
        this.moveConfirmationPopUp.x -
          this.moveConfirmationPopUp.displayWidth / 4.5,
        this.moveConfirmationPopUp.y +
          this.moveConfirmationPopUp.displayHeight / 3.5,
        'defaultButton'
      )
      .setDepth(this.moveConfirmationPopUp?.depth + 1)
      .setInteractive({ useHandCursor: true })
      .on('pointerdown', () => {
        this.confirmButton.destroy(true);
        this.genericCancelButton.destroy(true);
        this.moveConfirmationPopUp.destroy(true);
        this.moveConfirmationPopUpText.destroy(true);
        this.confirmButtonText.destroy(true);
        this.cancelButtonText.destroy(true);
        if (positions && sprite) {
          this.startMoveMyPlayerTween(positions, sprite, addParameter);
        }
      });
    this.confirmButtonText = this.add
      .text(
        this.confirmButton.x - this.confirmButton.displayWidth / 2,
        this.confirmButton.y - this.confirmButton.displayHeight / 2.4,
        `${context.translateService.instant('customize_avatar_confirm_button')}`
      )
      .setFontSize(32)
      .setOrigin(0.5, 0.5)
      .setDepth(this.confirmButton?.depth + 10)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#FFFFFF',
      })
      .setPadding(20, 20);

    this.genericCancelButton = this.add
      .sprite(
        this.moveConfirmationPopUp.x +
          this.moveConfirmationPopUp.displayWidth / 4.5,
        this.moveConfirmationPopUp.y +
          this.moveConfirmationPopUp.displayHeight / 3.5,
        'secondaryButton'
      )
      .setDepth(this.moveConfirmationPopUp?.depth + 1)
      .setVisible(bool)
      .setInteractive({ useHandCursor: true })
      .on('pointerdown', () => {
        this.confirmButton.destroy(true);
        this.genericCancelButton.destroy(true);
        this.moveConfirmationPopUpText.destroy(true);
        this.confirmButtonText.destroy(true);
        this.cancelButtonText.destroy(true);
        this.moveConfirmationPopUp.destroy(true);
        return;
      });

    this.cancelButtonText = this.add
      .text(
        this.genericCancelButton.x -
          this.genericCancelButton.displayWidth / 2.5,
        this.genericCancelButton.y -
          this.genericCancelButton.displayHeight / 2.4,
        `${context.translateService.instant('mobile_cancel_button')}`
      )
      .setFontSize(32)
      .setVisible(bool)
      .setOrigin(0, 0)
      .setDepth(this.genericCancelButton?.depth + 10)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#003399',
      })
      .setPadding(50, 20);

    if (typeOfUse === PopUpTypeOfUse.bilateral) {
      this.genericCancelButton?.destroy(true);
      this.cancelButtonText?.destroy(true);
      bool = false;
      this.moveConfirmationPopUp.setScale(
        this.moveConfirmationPopUp.scale * 0.9
      );
      let countryName;
      if (this.myUserBilateral && !this.myBilateral) {
        countryName = this.countries.find(
          (ctr) => ctr.countryCode === this.userCountry
        ).name;
      } else if (this.myBilateral) {
        countryName = sprite.data.values.countryName;
      }
      this.moveConfirmationPopUpText
        .setText(
          `${countryName} ${context.translateService.instant(
            'neg_phase_already_in_bilateral_popup'
          )}`
        )
        .setWordWrapWidth(this.moveConfirmationPopUp.displayWidth * 0.9);

      this.moveConfirmationPopUpText.setPosition(
        this.moveConfirmationPopUp?.x,
        this.moveConfirmationPopUp?.y -
          this.moveConfirmationPopUp.displayHeight / 5
      );
      this.confirmButton.setX(this.moveConfirmationPopUp.x);
      this.confirmButtonText?.setPosition(
        this.confirmButton?.x,
        this.confirmButton?.y
      );
    } else if (typeOfUse === PopUpTypeOfUse.noPoints) {
      this.genericCancelButton?.destroy(true);
      this.cancelButtonText?.destroy(true);
      this.moveConfirmationPopUp.setScale(
        this.moveConfirmationPopUp.scale * 0.9
      );
      this.moveConfirmationPopUpText
        .setText(
          `${context.translateService.instant(
            'not_enough_action_points_message'
          )}`
        )
        .setWordWrapWidth(this.moveConfirmationPopUp.displayWidth * 0.9);

      this.moveConfirmationPopUpText.setPosition(
        this.moveConfirmationPopUp?.x,
        this.moveConfirmationPopUp?.y -
          this.moveConfirmationPopUp.displayHeight / 5
      );
      this.confirmButton.setX(this.moveConfirmationPopUp.x);
      this.confirmButtonText?.setPosition(
        this.confirmButton?.x,
        this.confirmButton?.y
      );
    } else if (typeOfUse === PopUpTypeOfUse.redLine) {
      this.moveConfirmationPopUpText.setPosition(
        this.moveConfirmationPopUp?.x,
        this.moveConfirmationPopUp?.y -
          this.moveConfirmationPopUp.displayHeight / 5
      );
      this.confirmButtonText?.setPosition(
        this.confirmButton?.x,
        this.confirmButton?.y
      );
    }
  }

  initAvatars() {
    if (this.avatars) {
      this.avatars.forEach((element) => {
        element.destroy();
      });
      this.avatars = [];
    }

    this.avatars = [];
    this.carpets = [];
    this.usersData.forEach(($, i) => {
      this.avatars.push(
        this.add
          .sprite(800, 120, `${this.usersData[i].country}Empty`)
          .setScale(this.dynamicSvgScaleX, this.dynamicSvgScaleY)
          .setOrigin(0.5, 0.5)
          .setAlpha(0)
      );
      this.avatars[i].setData({
        countryCode: `${this.usersData[i].country}`,
        userId: this.usersData[i].userId,
        userGameNegotiationId: this.usersData[i].user.userGameNegotiation.find(
          (el) => el.dimension === context.gameQuery.getSelectedDimension().id
        ).userGameNegotiationId,
        bodyIndex: this.usersData[i].user.avatar_Body + 1,
        skinToneIndex:
          this.usersData[i].user.avatar_SkinTone === 0
            ? 'A'
            : this.usersData[i].user.avatar_SkinTone === 1
            ? 'C'
            : this.usersData[i].user.avatar_SkinTone === 2
            ? 'D'
            : null,
        faceIndex: this.usersData[i].user.avatar_Face + 1,
      });

      this.carpets.push(
        this.add
          .sprite(
            this.avatars[i].x - 5,
            this.avatars[i].y + 60,
            'altWalking',
            '02b_character_selection_Blue_00001'
          )
          .setData({ countryCode: `${this.usersData[i].country}` })
          .setAlpha(0)
      );
    });

    this.myPlayerIndicator = this.add
      .sprite(
        this.avatars.find(
          (av) => av.data.values.countryCode === this.userCountry
        ).x,
        this.avatars.find(
          (av) => av.data.values.countryCode === this.userCountry
        ).y,
        'myPlayerIndicator'
      )
      .setAlpha(0)
      .setVisible(false);

    this.initPlaceAvatars(this.squareInfo);
    this.createIdleAnimation();
    this.createWalkingAnimation();
    this.createAltWalkingAnimation();
    this.createSensitivePosAnimation();
    this.createRedLinePosAnimation();
  }

  createWalkingAnimation() {
    const frameNamesLegs1 = this.anims.generateFrameNames('legs1', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '01_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs1',
      frames: frameNamesLegs1,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegs2 = this.anims.generateFrameNames('legs2', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '02_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs2',
      frames: frameNamesLegs2,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegs3 = this.anims.generateFrameNames('legs3', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '03_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs3',
      frames: frameNamesLegs3,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegs4 = this.anims.generateFrameNames('legs4', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '04_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs4',
      frames: frameNamesLegs4,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegs5 = this.anims.generateFrameNames('legs5', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '05_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs5',
      frames: frameNamesLegs5,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegs6 = this.anims.generateFrameNames('legs6', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '06_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs6',
      frames: frameNamesLegs6,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegs7 = this.anims.generateFrameNames('legs7', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '07_legs_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legs7',
      frames: frameNamesLegs7,
      frameRate: 15,
      repeat: -1,
    });

    const frameNamesArms1A = this.anims.generateFrameNames('arms1A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '01_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms1A',
      frames: frameNamesArms1A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms1C = this.anims.generateFrameNames('arms1C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '01_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms1C',
      frames: frameNamesArms1C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms1D = this.anims.generateFrameNames('arms1D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '01_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms1D',
      frames: frameNamesArms1D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms2A = this.anims.generateFrameNames('arms2A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '02_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms2A',
      frames: frameNamesArms2A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms2C = this.anims.generateFrameNames('arms2C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '02_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms2C',
      frames: frameNamesArms2C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms2D = this.anims.generateFrameNames('arms2D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '02_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms2D',
      frames: frameNamesArms2D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms3A = this.anims.generateFrameNames('arms3A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '03_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms3A',
      frames: frameNamesArms3A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms3C = this.anims.generateFrameNames('arms3C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '03_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms3C',
      frames: frameNamesArms3C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms3D = this.anims.generateFrameNames('arms3D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '03_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms3D',
      frames: frameNamesArms3D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms4A = this.anims.generateFrameNames('arms4A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '04_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms4A',
      frames: frameNamesArms4A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms4C = this.anims.generateFrameNames('arms4C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '04_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms4C',
      frames: frameNamesArms4C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms4D = this.anims.generateFrameNames('arms4D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '04_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms4D',
      frames: frameNamesArms4D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms5A = this.anims.generateFrameNames('arms5A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '05_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms5A',
      frames: frameNamesArms5A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms5C = this.anims.generateFrameNames('arms5C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '05_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms5C',
      frames: frameNamesArms5C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms5D = this.anims.generateFrameNames('arms5D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '05_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms5D',
      frames: frameNamesArms5D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms6A = this.anims.generateFrameNames('arms6A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '06_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms6A',
      frames: frameNamesArms6A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms6C = this.anims.generateFrameNames('arms6C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '06_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms6C',
      frames: frameNamesArms6C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms6D = this.anims.generateFrameNames('arms6D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '06_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms6D',
      frames: frameNamesArms6D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms7A = this.anims.generateFrameNames('arms7A', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '07_Arm_stA_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms7A',
      frames: frameNamesArms7A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms7C = this.anims.generateFrameNames('arms7C', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '07_Arm_stC_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms7C',
      frames: frameNamesArms7C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArms7D = this.anims.generateFrameNames('arms7D', {
      start: 1,
      end: 14,
      zeroPad: 5,
      prefix: '07_Arm_stD_walk_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'arms7D',
      frames: frameNamesArms7D,
      frameRate: 15,
      repeat: -1,
    });
  }

  createIdleAnimation() {
    const frameNamesLegsIdle1 = this.anims.generateFrameNames('legsIdle1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle1',
      frames: frameNamesLegsIdle1,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegsIdle2 = this.anims.generateFrameNames('legsIdle2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle2',
      frames: frameNamesLegsIdle2,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegsIdle3 = this.anims.generateFrameNames('legsIdle3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle3',
      frames: frameNamesLegsIdle3,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegsIdle4 = this.anims.generateFrameNames('legsIdle4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle4',
      frames: frameNamesLegsIdle4,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegsIdle5 = this.anims.generateFrameNames('legsIdle5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle5',
      frames: frameNamesLegsIdle5,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegsIdle6 = this.anims.generateFrameNames('legsIdle6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle6',
      frames: frameNamesLegsIdle6,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesLegsIdle7 = this.anims.generateFrameNames('legsIdle7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_legs_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'legsIdle7',
      frames: frameNamesLegsIdle7,
      frameRate: 15,
      repeat: -1,
    });

    const frameNamesFaceIdle1 = this.anims.generateFrameNames('faceIdle1', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle1',
      frames: frameNamesFaceIdle1,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceIdle2 = this.anims.generateFrameNames('faceIdle2', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle2',
      frames: frameNamesFaceIdle2,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceIdle3 = this.anims.generateFrameNames('faceIdle3', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle3',
      frames: frameNamesFaceIdle3,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceIdle4 = this.anims.generateFrameNames('faceIdle4', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle4',
      frames: frameNamesFaceIdle4,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceIdle5 = this.anims.generateFrameNames('faceIdle5', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle5',
      frames: frameNamesFaceIdle5,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceIdle6 = this.anims.generateFrameNames('faceIdle6', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle6',
      frames: frameNamesFaceIdle6,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesFaceIdle7 = this.anims.generateFrameNames('faceIdle7', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_face_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'faceIdle7',
      frames: frameNamesFaceIdle7,
      frameRate: 15,
      repeat: -1,
    });

    const frameNamesArmsIdle1A = this.anims.generateFrameNames('armsIdle1A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle1A',
      frames: frameNamesArmsIdle1A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle1C = this.anims.generateFrameNames('armsIdle1C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle1C',
      frames: frameNamesArmsIdle1C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle1D = this.anims.generateFrameNames('armsIdle1D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '01_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle1D',
      frames: frameNamesArmsIdle1D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle2A = this.anims.generateFrameNames('armsIdle2A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle2A',
      frames: frameNamesArmsIdle2A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle2C = this.anims.generateFrameNames('armsIdle2C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle2C',
      frames: frameNamesArmsIdle2C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle2D = this.anims.generateFrameNames('armsIdle2D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '02_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle2D',
      frames: frameNamesArmsIdle2D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle3A = this.anims.generateFrameNames('armsIdle3A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle3A',
      frames: frameNamesArmsIdle3A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle3C = this.anims.generateFrameNames('armsIdle3C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle3C',
      frames: frameNamesArmsIdle3C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle3D = this.anims.generateFrameNames('armsIdle3D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '03_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle3D',
      frames: frameNamesArmsIdle3D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle4A = this.anims.generateFrameNames('armsIdle4A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle4A',
      frames: frameNamesArmsIdle4A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle4C = this.anims.generateFrameNames('armsIdle4C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle4C',
      frames: frameNamesArmsIdle4C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle4D = this.anims.generateFrameNames('armsIdle4D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '04_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle4D',
      frames: frameNamesArmsIdle4D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle5A = this.anims.generateFrameNames('armsIdle5A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle5A',
      frames: frameNamesArmsIdle5A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle5C = this.anims.generateFrameNames('armsIdle5C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle5C',
      frames: frameNamesArmsIdle5C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle5D = this.anims.generateFrameNames('armsIdle5D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '05_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle5D',
      frames: frameNamesArmsIdle5D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle6A = this.anims.generateFrameNames('armsIdle6A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle6A',
      frames: frameNamesArmsIdle6A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle6C = this.anims.generateFrameNames('armsIdle6C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle6C',
      frames: frameNamesArmsIdle6C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle6D = this.anims.generateFrameNames('armsIdle6D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '06_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle6D',
      frames: frameNamesArmsIdle6D,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle7A = this.anims.generateFrameNames('armsIdle7A', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_arms_stA_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle7A',
      frames: frameNamesArmsIdle7A,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle7C = this.anims.generateFrameNames('armsIdle7C', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_arms_stC_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle7C',
      frames: frameNamesArmsIdle7C,
      frameRate: 15,
      repeat: -1,
    });
    const frameNamesArmsIdle7D = this.anims.generateFrameNames('armsIdle7D', {
      start: 1,
      end: 74,
      zeroPad: 5,
      prefix: '07_arms_stD_idle_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'armsIdle7D',
      frames: frameNamesArmsIdle7D,
      frameRate: 15,
      repeat: -1,
    });

    setTimeout(() => {
      this.avatars.forEach((el, index) => {
        this.legsAnimationsIdle[index] = this.add
          .sprite(
            el.x,
            el.y,
            `legsIdle${el.data.values.bodyIndex}`,
            `0${el.data.values.bodyIndex}_legs_idle_00000.png`
          )
          .setScale(this.boardAnimScale, this.boardAnimScale)
          .setDepth(el.depth)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.countryCode,
          });
        this.armsAnimationsIdle[index] = this.add
          .sprite(
            el.x,
            el.y,
            `armsIdle${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
            `0${el.data.values.bodyIndex}_arms_st${el.data.values.skinToneIndex}_idle_00000.png`
          )
          .setScale(this.boardAnimScale, this.boardAnimScale)
          .setDepth(el.depth)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.countryCode,
            skinToneIndex: el.data.values.skinToneIndex,
          });
        this.faceAnimationsIdle[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceIdle${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_idle_00001.png`
          )
          .setScale(this.boardAnimScale, this.boardAnimScale)
          .setDepth(el.depth)
          .setVisible(false)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.countryCode,
            skinToneIndex: el.data.values.skinToneIndex,
            faceIndex: el.data.values.faceIndex,
          });

        this.legsAnimations[index] = this.add
          .sprite(
            el.x,
            el.y,
            `legs${el.data.values.bodyIndex}`,
            `0${el.data.values.bodyIndex}_legs_walk_00000.png`
          )
          .setScale(this.boardAnimScale, this.boardAnimScale)
          .setDepth(el.depth)
          .setAlpha(0)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.countryCode,
          });
        this.faceAnimations[index] = this.add
          .sprite(
            el.x,
            el.y,
            `faceIdle${el.data.values.faceIndex}`,
            `0${el.data.values.faceIndex}_face_idle_00000.png`
          )
          .setScale(this.boardAnimScale, this.boardAnimScale)
          .setDepth(el.depth)
          .setAlpha(0)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.countryCode,
            faceIndex: el.data.values.faceIndex,
          });
        this.armsAnimations[index] = this.add
          .sprite(
            el.x,
            el.y,
            `arms${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`,
            `0${el.data.values.bodyIndex}_Arm_st${el.data.values.skinToneIndex}_walk_00000.png`
          )
          .setScale(this.boardAnimScale, this.boardAnimScale)
          .setDepth(el.depth)
          .setAlpha(0)
          .setData({
            index: el.data.values.bodyIndex,
            countryCode: el.data.values.countryCode,
            skinToneIndex: el.data.values.skinToneIndex,
          });

        this.legsAnimations[index].play(`legs${el.data.values.bodyIndex}`);
        this.armsAnimations[index].play(
          `arms${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`
        );

        timer(index * 100)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.legsAnimationsIdle[index].play(
              `legsIdle${el.data.values.bodyIndex}`
            );
            this.armsAnimationsIdle[index].play(
              `armsIdle${el.data.values.bodyIndex}${el.data.values.skinToneIndex}`
            );
            this.faceAnimationsIdle[index].play(
              `faceIdle${el.data.values.faceIndex}`
            );
          });
      });
    });
  }

  createAltWalkingAnimation() {
    const frameNamesAltWalking = this.anims.generateFrameNames('altWalking', {
      start: 1,
      end: 29,
      zeroPad: 5,
      prefix: '02b_character_selection_Blue_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'altWalking',
      frames: frameNamesAltWalking,
      frameRate: 15,
      repeat: -1,
    });
  }
  createSensitivePosAnimation() {
    const frameNamesSensitivePos = this.anims.generateFrameNames('yellowGlow', {
      start: 1,
      end: 29,
      zeroPad: 5,
      prefix: '20_Yellow_Marking_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'yellowGlow',
      frames: frameNamesSensitivePos,
      frameRate: 15,
      repeat: -1,
    });
  }
  createRedLinePosAnimation() {
    const frameNamesRedLinePos = this.anims.generateFrameNames('redGlow', {
      start: 1,
      end: 29,
      zeroPad: 5,
      prefix: '21_Red_Marking_',
      suffix: '.png',
    });
    this.anims.create({
      key: 'redGlow',
      frames: frameNamesRedLinePos,
      frameRate: 15,
      repeat: -1,
    });
  }

  initPlaceAvatars(squareInfo) {
    console.log(this.avatars);

    this.updatePositions();

    this.findRedPositions();
  }

  zoomInPreCalcPoints(sprite: Phaser.GameObjects.Sprite) {
    this.pointsSubscription = context.gameService
      .updatePlayerPosition({
        userGameNegotiationid: this.usersData
          .find((el) => el.userId === context.userQuery.getUserId())
          .user.userGameNegotiation.find(
            (el) => el.dimension === context.gameQuery.getSelectedDimension().id
          ).userGameNegotiationId,
        positionId: sprite.data.values.id,
        calculateOnly: true,
      })
      .pipe(
        take(1),
        catchError((err) => {
          context.loading.next(false);
          throw err;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && value.status === 'Success') {
          this.pointsCalcAfterMovement = value.data.items[0];
          this.pointsNow = context.gameQuery
            .getUserGames()
            .find((el: IUserGame) => el.country === this.userCountry);
          this.actionPoints.setText(
            `${
              this.pointsCalcAfterMovement.actionPoint -
              this.pointsNow.actionPoint
            }`
          );
          this.countryPoints.setText(
            `${
              this.pointsCalcAfterMovement.countryPoint -
              this.pointsNow.countryPoint
            }`
          );
          this.councilPoints.setText(
            `${
              this.pointsCalcAfterMovement.councilPoint -
              this.pointsNow.councilPoint
            }`
          );
          context.loading.next(false);
        }
      });
  }

  updateCurrentCountries(sprite: Phaser.GameObjects.Sprite) {
    const currentCountriesData = [];

    this.usersData.forEach((el) => {
      const tmp = {
        userGameNegotiation: el.user.userGameNegotiation.find(
          (item) => item.position === sprite.data.values.id
        ),
        userId: el.userId,
        country: el.country,
        countryName: this.countries.find(
          (cnt) => cnt.countryCode === el.country
        ).name,
        bodyIndex: el.user.avatar_Body + 1,
        skinToneIndex:
          el.user.avatar_SkinTone === 0
            ? 'A'
            : el.user.avatar_SkinTone === 1
            ? 'C'
            : el.user.avatar_SkinTone === 2
            ? 'D'
            : null,
        faceIndex: el.user.avatar_Face + 1,
      };
      currentCountriesData.push(tmp);
    });

    this.currentCountriesData = currentCountriesData.filter(
      (el) => el.userGameNegotiation !== undefined
    );

    this.squares
      .find((square) => square.data.values.id === sprite.data.values.id)
      .setData({
        currentCountries: this.currentCountriesData,
      });
  }

  zoomInTransition(
    sprite: Phaser.GameObjects.Sprite,
    boardSize: number,
    boardTexture: string,
    y = 0
  ) {
    if (this.overlay?.visible === true || this.zoomTweenOut?.isPlaying()) {
      return;
    }
    if (!this.overlay) {
      this.overlay = this.add
        .sprite(0, 0, 'overlay')
        .setOrigin(0, 0)
        .setDepth(1999);
    } else {
      this.overlay.setVisible(true);
    }

    this.zoomBoard = this.add
      .sprite(900, 0, boardTexture)
      .setDepth(this.overlay?.depth + 1)
      .setAlpha(0);
    this.zoomLabel = this.add
      .sprite(900, 0, 'zoomLabel')
      .setDepth(this.zoomBoard?.depth - 1)
      .setOrigin(0.5, 0.5)
      .setAlpha(0);

    if (this.zoomBoard.alpha === 1) {
      return;
    }
    this.zoomAvatars?.forEach((zm) => {
      zm.destroy(true);
    });
    this.zoomCountryNames?.forEach((zm) => {
      zm.destroy(true);
    });
    this.bgDisplays?.forEach((bg) => {
      bg.destroy(true);
    });
    this.zoomAvatars = [];
    this.zoomCountryNames = [];
    this.bgDisplays = [];
    this.zoomAvatarsAnimationsFaceIdle = [];
    this.zoomAvatarsAnimationsLegsIdle = [];
    this.zoomAvatarsAnimationsArmsIdle = [];
    this.redLineIndicatorZoomLabel?.destroy(true);
    this.redLineIndicatorZoomBottom?.destroy(true);
    this.redLineIndicatorZoomText?.destroy(true);
    this.myPlayerIndicatorZoom?.destroy(true);

    sprite.data.values.currentCountries.forEach((avatar, i) => {
      const zoomAvatar = this.add
        .sprite(
          pos.zoomBoardPositions[boardSize][i].x,
          pos.zoomBoardPositions[boardSize][i].y,
          `${avatar.country}Empty`
        )
        .setScale(this.zoomAvatarScaleX, this.zoomAvatarScaleY)
        .setOrigin(0.5, 0.5)
        .setDepth(2000)
        .setData({
          countryCode: avatar.country,
          countryName: avatar.countryName,
          userGameNegotiation: avatar.userGameNegotiation,
          userId: avatar.userId,
          zoomAvatar: true,
          stance: avatar.userGameNegotiation.stance,
        })
        .setAlpha(0.00001);

      if (
        zoomAvatar.data.values.stance !== Stance.redLine &&
        zoomAvatar.data.values.countryCode !== this.userCountry
      ) {
        const bgDisplay = this.add
          .sprite(
            zoomAvatar.x - 8,
            zoomAvatar.y + zoomAvatar.displayHeight / 5,
            'button'
          )
          .setDepth(zoomAvatar.depth + 800)
          .setScale(0.5)
          .setAlpha(0.00001);
        const countryName = this.add
          .text(
            bgDisplay.x,
            bgDisplay.y,
            `${
              this.countries.find(
                (cntr) =>
                  cntr.countryCode === zoomAvatar.data.values.countryCode
              ).name
            }`
          )
          .setDepth(bgDisplay.depth + 1)
          .setFontSize(20)
          .setAlign('center')
          .setOrigin(0.5)
          .setPadding(5, 3)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#ffffff',
          })
          .setAlpha(0.00001);
        bgDisplay.setDisplaySize(
          countryName.displayWidth,
          countryName.displayHeight
        );
        this.zoomCountryNames.push(countryName);
        this.bgDisplays.push(bgDisplay);
      }

      if (zoomAvatar.data.values.stance === Stance.redLine) {
        this.redLineIndicatorZoomLabel = this.add
          .sprite(
            zoomAvatar.x - 8,
            zoomAvatar.y - zoomAvatar.displayHeight / 1.6,
            'redLineIndicatorZoomLabel'
          )
          .setScale(this.zoomAvatarScaleX, this.zoomAvatarScaleY)
          .setOrigin(0.5)
          .setDepth(zoomAvatar.depth * 100)
          .setData({
            countryCode: avatar.country,
            userGameNegotiation: avatar.userGameNegotiation,
            userId: avatar.userId,
            zoomAvatar: true,
            stance: avatar.userGameNegotiation.stance,
          })
          .setAlpha(0.00001);

        this.redLineIndicatorZoomBottom = this.add
          .sprite(
            this.redLineIndicatorZoomLabel.x,
            zoomAvatar.y + zoomAvatar.displayHeight / 2.4,
            'redLineIndicatorZoomBottom'
          )
          .setScale(this.zoomAvatarScaleX, this.zoomAvatarScaleY)
          .setOrigin(0.5, 0.5)
          .setDepth(zoomAvatar.depth)
          .setData({
            countryCode: avatar.country,
            userGameNegotiation: avatar.userGameNegotiation,
            userId: avatar.userId,
            zoomAvatar: true,
            stance: avatar.userGameNegotiation.stance,
          })
          .setAlpha(0.00001);

        let zoomRedLineText = '';

        if (zoomAvatar.data.values.countryCode !== this.userCountry) {
          zoomRedLineText = this.countries.find(
            (cntr) => cntr.countryCode === zoomAvatar.data.values.countryCode
          ).name;
        } else {
          zoomRedLineText = context.translateService.instant(
            StanceTranslation.redLine
          );
        }

        this.redLineIndicatorZoomText = this.add
          .text(
            this.redLineIndicatorZoomLabel.x,
            this.redLineIndicatorZoomLabel.y -
              this.redLineIndicatorZoomLabel.displayHeight / 3,
            zoomRedLineText
          )
          .setFontSize(20)
          .setOrigin(0.5, 0)
          .setDepth(this.redLineIndicatorZoomLabel.depth + 1)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#B82924',
          })
          .setAlpha(0.00001);

        if (this.redLineIndicatorZoomText.displayWidth < 103) {
          this.redLineIndicatorZoomLabel.setDisplaySize(
            this.redLineIndicatorZoomText.displayWidth * 2,
            this.redLineIndicatorZoomLabel.displayHeight
          );
        } else {
          this.redLineIndicatorZoomLabel.setDisplaySize(
            this.redLineIndicatorZoomText.displayWidth * 1.5,
            this.redLineIndicatorZoomLabel.displayHeight
          );
        }
      }

      if (zoomAvatar.data.values.countryCode === this.userCountry) {
        let texture;
        let avatarY = zoomAvatar.y;
        if (zoomAvatar.data.values.stance === Stance.redLine) {
          texture = 'myPlayerIndicatorBottom';
          avatarY = zoomAvatar.y + zoomAvatar.displayHeight / 2;
        } else {
          texture = 'myPlayerIndicator';
        }
        this.myPlayerIndicatorZoom = this.add
          .sprite(zoomAvatar.x - 8, avatarY, texture)
          .setScale(this.zoomAvatarScaleX + 0.4, this.zoomAvatarScaleY + 0.5)
          .setOrigin(0.5, 0.5)
          .setDepth(2000)
          .setData({
            countryCode: avatar.country,
            userGameNegotiation: avatar.userGameNegotiation,
            userId: avatar.userId,
            zoomAvatar: true,
            stance: avatar.userGameNegotiation.stance,
          })
          .setAlpha(0.00001);
      }

      const zoomAvatarAnimationLegsIdle = this.add
        .sprite(
          pos.zoomBoardPositions[boardSize][i].x - this.zoomMinParamDevice,
          pos.zoomBoardPositions[boardSize][i].y,
          `legsIdle${avatar.bodyIndex}`,
          `0${avatar.bodyIndex}_legs_idle_00005.png`
        )
        .setScale(this.zoomAnimScale)
        .setOrigin(0.5, 0.5)
        .setDepth(zoomAvatar.depth + 10)
        .setData({
          index: avatar.bodyIndex,
          countryCode: avatar.countryCode,
        })
        .setAlpha(0.00001);
      const zoomAvatarAnimationFaceIdle = this.add
        .sprite(
          pos.zoomBoardPositions[boardSize][i].x - this.zoomMinParamDevice,
          pos.zoomBoardPositions[boardSize][i].y,
          `faceIdle${avatar.faceIndex}`,
          `0${avatar.faceIndex}_face_idle_00005.png`
        )
        .setScale(this.zoomAnimScale)
        .setOrigin(0.5, 0.5)
        .setDepth(zoomAvatar.depth + 10)
        .setData({
          index: avatar.bodyIndex,
          countryCode: avatar.countryCode,
          skinToneIndex: avatar.skinToneIndex,
          faceIndex: avatar.faceIndex,
        })
        .setAlpha(0.00001);
      const zoomAvatarAnimationArmsIdle = this.add
        .sprite(
          pos.zoomBoardPositions[boardSize][i].x - this.zoomMinParamDevice,
          pos.zoomBoardPositions[boardSize][i].y,
          `armsIdle${avatar.bodyIndex}${avatar.skinToneIndex}`,
          `0${avatar.bodyIndex}_arms_st${avatar.skinToneIndex}_idle_00005.png`
        )
        .setScale(this.zoomAnimScale)
        .setOrigin(0.5, 0.5)
        .setDepth(zoomAvatar.depth + 10)
        .setData({
          index: avatar.bodyIndex,
          countryCode: avatar.countryCode,
          skinToneIndex: avatar.skinToneIndex,
        })
        .setAlpha(0.00001);

      timer(i * 200)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          zoomAvatarAnimationArmsIdle.play(
            `armsIdle${avatar.bodyIndex}${avatar.skinToneIndex}`
          );
          zoomAvatarAnimationLegsIdle.play(`legsIdle${avatar.bodyIndex}`);
          zoomAvatarAnimationFaceIdle.play(`faceIdle${avatar.faceIndex}`);
        });

      this.zoomAvatars.push(zoomAvatar);
      this.zoomAvatarsAnimationsArmsIdle.push(zoomAvatarAnimationArmsIdle);
      this.zoomAvatarsAnimationsLegsIdle.push(zoomAvatarAnimationLegsIdle);
      this.zoomAvatarsAnimationsFaceIdle.push(zoomAvatarAnimationFaceIdle);
    });

    this.zoomBoardIn(boardSize, sprite, y);
  }

  showAvatarPop(el: Phaser.GameObjects.Sprite) {
    this.avatarPopUpGroup?.destroy(true);

    const requestedUserGame = this.userGames.find(
      (ug) => ug.userId === el.data.values.userId
    );
    const myUserGame = this.userGames.find(
      (ug) => ug.country === this.userCountry
    );

    this.myBilateral = requestedUserGame.user.bilateral?.find(
      (b) => b?.bilateralStatus === BilateralHistoryStatus.started
    )?.bilateralStatus;
    this.myUserBilateral = myUserGame.user.bilateral?.find(
      (b) => b?.bilateralStatus === BilateralHistoryStatus.started
    )?.bilateralStatus;

    if (el.data.values.countryCode !== this.userCountry) {
      if (
        this.myBilateral !== BilateralHistoryStatus.started &&
        this.myUserBilateral !== BilateralHistoryStatus.started
      ) {
        this.createZoomAvatarPopUp(el);
      } else {
        this.createWarningPopUp(PopUpTypeOfUse.bilateral, null, el);
      }
    }
  }

  createZoomAvatarPopUp(el: Phaser.GameObjects.Sprite) {
    this.avatarPopUp = this.add
      .sprite(el.x - 10, el.y - 150, 'avatarPopup')
      .setScale(this.assetScale)
      .setDepth(el.depth * 200);
    this.negotiateButton = this.add
      .sprite(this.avatarPopUp.x, this.avatarPopUp.y + 20, 'negotiateButton')
      .setScale(this.assetScale * 1.1, this.assetScale)
      .setDepth(this.avatarPopUp.depth + 1)
      .setInteractive({ useHandCursor: true })
      .on('pointerdown', () => {
        if (!this.scene.isVisible()) {
          return;
        }
        if (
          this.usersData.find(
            (usr) => usr.userId === context.userQuery.getUserId()
          ).actionPoint !== 0
        ) {
          context.startBilateral(el.data.values.userId);
          this.zoomBoardOut();
          this.avatarPopUpGroup?.destroy(true);
        } else {
          this.createWarningPopUp(PopUpTypeOfUse.noPoints);
        }
      });
    this.negotiateButtonText = this.add
      .text(
        this.negotiateButton.x,
        this.negotiateButton.y,
        `${context.translateService.instant('plakat_global_negotiate_button')}`
      )
      .setFontSize(18)
      .setOrigin(0, 0)
      .setFontFamily(context.gameFont)
      .setDepth(this.negotiateButton.depth + 1);
    if (this.negotiateButtonText.width > 100) {
      this.negotiateButtonText.setFontSize(15);
    }
    this.peopleIcon = this.add
      .sprite(this.avatarPopUp.x - 60, this.avatarPopUp.y - 30, 'peopleIcon')
      .setScale(this.assetScale)
      .setDepth(this.avatarPopUp.depth + 1);
    this.popUpCountryText = this.add
      .text(
        this.avatarPopUp.x - 60,
        this.avatarPopUp.y - 100,
        `${
          this.countries.find(
            (item) => item.countryCode === el.data.values.countryCode
          ).name
        }`
      )
      .setFontSize(24)
      .setScale(this.assetScale)
      .setDepth(this.avatarPopUp.depth + 10)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#003399',
      });
    this.popUpPopulationText = this.add
      .text(
        this.avatarPopUp.x - 30,
        this.avatarPopUp.y - 45,
        `${
          el.data.values.userGameNegotiation.weight
        }% ${context.translateService.instant('dimension_overview_population')}`
      )
      .setFontSize(24)
      .setDepth(this.avatarPopUp.depth + 10)
      .setScale(this.assetScale)
      .setFontFamily(context.gameFont)
      .setStyle({
        color: '#003399',
      });

    if (this.popUpPopulationText.width >= 100) {
      this.popUpPopulationText.setFontSize(18);
    }

    this.avatarPopUp.setPosition(el.x - 10, el.y - 180).setVisible(true);
    this.negotiateButton
      .setPosition(this.avatarPopUp.x, this.avatarPopUp.y + 40)
      .setVisible(true)
      .setDisplaySize(
        this.negotiateButtonText?.displayWidth * 1.7 <= 200
          ? this.negotiateButtonText?.displayWidth * 2.2
          : this.negotiateButtonText?.displayWidth * 1.7,
        this.negotiateButton.displayHeight
      );
    this.negotiateButtonText
      .setPosition(
        this.negotiateButton.x - this.negotiateButton.displayWidth / 2.3,
        this.negotiateButton.y - 10
      )
      .setVisible(true);
    this.peopleIcon
      .setPosition(this.avatarPopUp.x - 90, this.avatarPopUp.y - 25)
      .setVisible(true);
    this.popUpCountryText
      .setPosition(this.avatarPopUp.x, this.avatarPopUp.y - 80)
      .setOrigin(0.5, 0)
      .setVisible(true)
      .setText(
        `${
          this.countries.find(
            (item) => item.countryCode === el.data.values.countryCode
          ).name
        }`
      );
    this.popUpPopulationText
      .setPosition(this.avatarPopUp.x - 65, this.avatarPopUp.y - 40)
      .setVisible(true)
      .setText(
        `${
          context.settingsService.formatDecimalNumber(+this.countries.find(
            (item) => item.countryCode === el.data.values.countryCode
          ).size / 100)
        }% ${context.translateService.instant('dimension_overview_population')}`
      );

    this.avatarPopUpGroup = this.add.group();
    this.avatarPopUpGroup.addMultiple([
      this.avatarPopUp,
      this.negotiateButton,
      this.negotiateButtonText,
      this.peopleIcon,
      this.popUpCountryText,
      this.popUpPopulationText,
    ]);
  }

  zoomBoardIn(boardSize: number, sprite: Phaser.GameObjects.Sprite, y: number) {
    context.toggleChecked$.next(false);
    context.gameService.updateModalOpen(true);
    context.showZoomBoard();

    this.zoomTweenIn = this.tweens.add({
      targets: [this.zoomBoard],
      x: 900,
      y: 500 + y,
      alpha: 1,
      duration: 700,
      onStart: () => {
        context.loading.next(true);
      },
      onComplete: () => {
        this.zoomBoard.setInteractive().on('pointerdown', () => {
          this.avatarPopUpGroup?.setVisible(false);
        });
        this.overlay.setInteractive().on('pointerdown', () => {
          this.avatarPopUpGroup?.setVisible(false);
        });
        this.zoomAvatars.forEach((el: Phaser.GameObjects.Sprite) => {
          el.setAlpha(1);
          if (!this.isInformational) {
            el.setInteractive({useHandCursor: true});
            el.on('pointerdown', () => this.showAvatarPop(el));
          }
        });
        this.bgDisplays.forEach((el: Phaser.GameObjects.Sprite) => {
          el.setAlpha(0.9);
        });
        this.zoomCountryNames.forEach((el) => {
          el.setAlpha(1);
        });
        this.redLineIndicatorZoomLabel?.setAlpha(1);
        this.redLineIndicatorZoomBottom?.setAlpha(1);
        this.redLineIndicatorZoomText?.setAlpha(1);
        this.myPlayerIndicatorZoom?.setAlpha(1);
        this.zoomAvatarsAnimationsLegsIdle.forEach((el) => {
          el.setAlpha(1);
        });
        this.zoomAvatarsAnimationsArmsIdle.forEach((el) => {
          el.setAlpha(1);
        });
        this.zoomAvatarsAnimationsFaceIdle.forEach((el) => {
          el.setAlpha(1);
        });
        switch (boardSize) {
          case 5:
            this.zoomLabelTween = this.tweens.add({
              targets: [this.zoomLabel],
              x: this.zoomBoard.x,
              y: this.zoomBoard.y - this.zoomBoard.displayHeight / 3.4,
              alpha: 1,
              duration: 500,
              onComplete: () => {
                this.zoomLabelText = this.add
                  .text(
                    this.zoomLabel.x,
                    this.zoomLabel.y - this.zoomLabel.displayHeight / 3,
                    `${sprite.data.values.name}`
                  )
                  .setFontSize(30)
                  .setDepth(5000)
                  .setOrigin(0.5, 0.5)
                  .setWordWrapWidth(220)
                  .setFontFamily(context.gameFont)
                  .setAlign('left')
                  .setStyle({
                    color: '#003399',
                  });
                this.cancelButton?.setInteractive();

                if (
                  this.zoomLabelText.height > 100 &&
                  this.zoomLabelText.height <= 105 &&
                  this.zoomLabelText.text.toLowerCase() !==
                    'No position'.toLowerCase()
                ) {
                  this.zoomLabel.setScale(1.2, 2);
                  this.zoomLabelText
                    .setY(this.zoomLabelText.y - 100)
                    .setX(this.zoomLabelText.x - 10);
                } else if (
                  this.zoomLabelText.width > 105 &&
                  this.zoomLabelText.text.toLowerCase() !==
                    'No position'.toLowerCase()
                ) {
                  this.zoomLabelText.setPadding(30, 20);
                  if (this.zoomLabelText.height >= 150) {
                    this.zoomLabelText.setFontSize(25);
                  }
                  this.zoomLabel.setDisplaySize(
                    this.zoomLabelText.width + 50,
                    this.zoomLabelText.height + 200
                  );
                  this.zoomLabelText
                    .setX(this.zoomLabelText.x - 10)
                    .setY(
                      this.zoomLabel.y - this.zoomLabel.displayHeight / 3.2
                    );
                } else if (
                  this.zoomLabelText.width < 105 &&
                  this.zoomLabelText.text.toLowerCase() !==
                    'No position'.toLowerCase()
                ) {
                  this.zoomLabelText.setPadding(70, 20);
                } else if (
                  this.zoomLabelText.text.toLowerCase() ===
                  'No position'.toLowerCase()
                ) {
                  this.zoomLabelText.setPadding(30, 20);
                }
              },
            });

            break;
          default:
            this.zoomLabelTween = this.tweens.add({
              targets: [this.zoomLabel],
              x: this.zoomBoard.x,
              y: this.zoomBoard.y - this.zoomBoard.displayHeight / 4,
              alpha: 1,
              duration: 500,
              onComplete: () => {
                this.zoomLabelText = this.add
                  .text(
                    this.zoomLabel.x,
                    this.zoomLabel.y - 70,
                    `${sprite.data.values.name}`
                  )
                  .setFontSize(30)
                  .setDepth(3000)
                  .setOrigin(0.5, 0.5)
                  .setWordWrapWidth(220)
                  .setPadding(10, 20)
                  .setFontFamily(context.gameFont)
                  .setAlign('left')
                  .setStyle({
                    color: '#003399',
                  });
                this.cancelButton?.setInteractive();

                if (
                  this.zoomLabelText.height > 100 &&
                  this.zoomLabelText.height <= 105 &&
                  this.zoomLabelText.text.toLowerCase() !==
                    'No position'.toLowerCase()
                ) {
                  this.zoomLabel.setScale(1.2, 2);
                  this.zoomLabelText
                    .setY(this.zoomLabelText.y - 70)
                    .setX(this.zoomLabelText.x - 10);
                } else if (
                  this.zoomLabelText.height > 105 &&
                  this.zoomLabelText.text.toLowerCase() !==
                    'No position'.toLowerCase()
                ) {
                  this.zoomLabelText.setPadding(30, 20);
                  if (this.zoomLabelText.height >= 150) {
                    this.zoomLabelText.setFontSize(25);
                  }
                  this.zoomLabel.setDisplaySize(
                    this.zoomLabelText.width + 50,
                    this.zoomLabelText.height + 200
                  );

                  this.zoomLabelText
                    .setX(this.zoomLabelText.x - 10)
                    .setY(
                      this.zoomLabel.y - this.zoomLabel.displayHeight / 3.2
                    );
                } else if (
                  this.zoomLabelText.width < 105 &&
                  this.zoomLabelText.text.toLowerCase() !==
                    'No position'.toLowerCase()
                ) {
                  this.zoomLabelText.setPadding(70, 15);
                } else if (
                  this.zoomLabelText.text.toLowerCase() ===
                  'No position'.toLowerCase()
                ) {
                  this.zoomLabelText.setPadding(30, 20);
                }
              },
            });

            break;
        }
        if (
          !this.isInformational && sprite?.data?.values?.value !== 0 &&
          !sprite?.data?.values?.currentCountries.find(
            (it) => it.country === this.userCountry
          )
        ) {
          this.zoomInPreCalcPoints(sprite);
        } else {
          context.loading.next(false);
        }
        this.createBoardStats(boardSize, sprite);
      },
    });
  }

  createBoardStats(boardSize: number, sprite: Phaser.GameObjects.Sprite) {
    const pointsVisibility =
      !this.isInformational && (sprite.data.values.value !== 0 &&
      !sprite.data.values.currentCountries.find(
        (it) => it.country === this.userCountry
      ));
    switch (boardSize) {
      case 1:
        this.moveButton = this.add
          .sprite(this.zoomBoard.x + 240, this.zoomBoard.y + 80, 'button')
          .setOrigin(0.5)
          .setScale(1.15, 1.55)
          .setDepth(3000)
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => this.moveMyAvatar(sprite));

        this.btnText = this.add
          .text(
            this.moveButton.x,
            this.moveButton.y + 15,
            `${context.translateService.instant(
              'negotiation_phase_move_to_another_position_button'
            )}`
          )
          .setFontSize(30)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#FFFFFF',
          })
          .setDepth(3000);
        this.action = this.add
          .sprite(this.zoomBoard.x - 80, this.zoomBoard.y + 80, 'action')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.actionPoints = this.add
          .text(this.action.x + 20, this.action.y - 10, ``)
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.flag = this.add
          .sprite(this.zoomBoard.x - 10, this.zoomBoard.y + 80, 'flag')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.countryPoints = this.add
          .text(this.flag.x + 20, this.flag.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.star = this.add
          .sprite(this.zoomBoard.x + 70, this.zoomBoard.y + 80, 'star')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.councilPoints = this.add
          .text(this.star.x + 20, this.star.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textWeights = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 50,
            `${
              sprite.data.values.currentCountries.length
            } ${context.translateService.instant(
              'negotiation_phase_move_to_another_position_left_text1'
            )}`
          )
          .setFontSize(22)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setVisible(pointsVisibility)
          .setDepth(3000);
        this.textCountries = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 80,
            `${this.calculateWeights(
              sprite
            )}% ${context.translateService.instant(
              'dimension_overview_population'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.cancelButton = this.add
          .sprite(this.moveButton.x, this.moveButton.y + 70, 'cancelButton')
          .setDepth(3000)
          .setScale(0.8, 0.8)
          .setInteractive({ useHandCursor: true });
        this.cancelButtonBoardText = this.add
          .text(
            this.cancelButton.x,
            this.cancelButton.y + 5,
            `${context.translateService.instant('mobile_cancel_button')}`
          )
          .setFontSize(26)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#003399',
          })
          .setDepth(this.cancelButton.depth + 1);
        this.addClick(this.cancelButton, 'exit');
        this.cancelButton.disableInteractive();

        if (
          sprite.data.values.currentCountries.some(
            (e) => e.country === this.userCountry
          ) ||
          this.checkIfNoPosition(sprite)
        ) {
          this.moveButton.setVisible(false);
          this.btnText.setVisible(false);
        }

        this.zoomStatsGroup = this.add.group();
        this.zoomStatsGroup.addMultiple([
          this.textCountries,
          this.textWeights,
          this.cancelButton,
          this.cancelButtonBoardText,
          this.btnText,
          this.moveButton,
        ]);

        if (!this.isInformational) {
          this.zoomStatsGroup.addMultiple([
            this.star,
            this.councilPoints,
            this.action,
            this.actionPoints,
            this.flag,
            this.countryPoints,
          ]);
        }

        break;
      case 2:
        this.moveButton = this.add
          .sprite(this.zoomBoard.x + 240, this.zoomBoard.y + 80, 'button')
          .setOrigin(0.5)
          .setScale(1.15, 1.55)
          .setDepth(3000)
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => this.moveMyAvatar(sprite));

        this.btnText = this.add
          .text(
            this.moveButton.x,
            this.moveButton.y + 15,
            `${context.translateService.instant(
              'negotiation_phase_move_to_another_position_button'
            )}`
          )
          .setFontSize(30)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#FFFFFF',
          })
          .setDepth(3000);
        this.action = this.add
          .sprite(this.zoomBoard.x - 80, this.zoomBoard.y + 80, 'action')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.actionPoints = this.add
          .text(this.action.x + 20, this.action.y - 10, ``)
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })

          .setDepth(3000);
        this.flag = this.add
          .sprite(this.zoomBoard.x - 10, this.zoomBoard.y + 80, 'flag')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.countryPoints = this.add
          .text(this.flag.x + 20, this.flag.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.star = this.add
          .sprite(this.zoomBoard.x + 70, this.zoomBoard.y + 80, 'star')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.councilPoints = this.add
          .text(this.star.x + 20, this.star.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textWeights = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 50,
            `${
              sprite.data.values.currentCountries.length
            } ${context.translateService.instant(
              'negotiation_phase_move_to_another_position_left_text1'
            )}`
          )
          .setFontSize(22)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setVisible(pointsVisibility)
          .setDepth(3000);
        this.textCountries = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 80,
            `${this.calculateWeights(
              sprite
            )}% ${context.translateService.instant(
              'dimension_overview_population'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.cancelButton = this.add
          .sprite(this.moveButton.x, this.moveButton.y + 70, 'cancelButton')
          .setDepth(3000)
          .setScale(0.8, 0.8)
          .setInteractive({ useHandCursor: true });
        this.cancelButtonBoardText = this.add
          .text(
            this.cancelButton.x,
            this.cancelButton.y + 5,
            `${context.translateService.instant('mobile_cancel_button')}`
          )
          .setFontSize(26)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#003399',
          })
          .setDepth(this.cancelButton.depth + 1);
        this.addClick(this.cancelButton, 'exit');
        this.cancelButton.disableInteractive();

        if (
          sprite.data.values.currentCountries.some(
            (e) => e.country === this.userCountry
          ) ||
          this.checkIfNoPosition(sprite)
        ) {
          this.moveButton.setVisible(false);
          this.btnText.setVisible(false);
        }
        this.zoomStatsGroup = this.add.group();
        this.zoomStatsGroup.addMultiple([
          this.textCountries,
          this.textWeights,
          this.cancelButton,
          this.cancelButtonBoardText,
          this.btnText,
          this.moveButton,
        ]);

        if (!this.isInformational) {
          this.zoomStatsGroup.addMultiple([
            this.star,
            this.councilPoints,
            this.action,
            this.actionPoints,
            this.flag,
            this.countryPoints,
          ]);
        }

        break;
      case 3:
        this.moveButton = this.add
          .sprite(this.zoomBoard.x + 240, this.zoomBoard.y + 80, 'button')
          .setOrigin(0.5)
          .setScale(1.15, 1.55)
          .setDepth(3000)
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => this.moveMyAvatar(sprite));

        this.btnText = this.add
          .text(
            this.moveButton.x,
            this.moveButton.y + 15,
            `${context.translateService.instant(
              'negotiation_phase_move_to_another_position_button'
            )}`
          )
          .setFontSize(30)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#FFFFFF',
          })
          .setDepth(3000);
        this.action = this.add
          .sprite(this.zoomBoard.x - 80, this.zoomBoard.y + 80, 'action')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.actionPoints = this.add
          .text(this.action.x + 20, this.action.y - 10, ``)
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.flag = this.add
          .sprite(this.zoomBoard.x - 10, this.zoomBoard.y + 80, 'flag')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.countryPoints = this.add
          .text(this.flag.x + 20, this.flag.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.star = this.add
          .sprite(this.zoomBoard.x + 70, this.zoomBoard.y + 80, 'star')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.councilPoints = this.add
          .text(this.star.x + 20, this.star.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textWeights = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 50,
            `${
              sprite.data.values.currentCountries.length
            } ${context.translateService.instant(
              'negotiation_phase_move_to_another_position_left_text1'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textCountries = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 80,
            `${this.calculateWeights(
              sprite
            )}% ${context.translateService.instant(
              'dimension_overview_population'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.cancelButton = this.add
          .sprite(this.moveButton.x, this.moveButton.y + 70, 'cancelButton')
          .setDepth(3000)
          .setScale(0.8, 0.8)
          .setInteractive({ useHandCursor: true });
        this.cancelButtonBoardText = this.add
          .text(
            this.cancelButton.x,
            this.cancelButton.y + 5,
            `${context.translateService.instant('mobile_cancel_button')}`
          )
          .setFontSize(26)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#003399',
          })
          .setDepth(this.cancelButton.depth + 1);
        this.addClick(this.cancelButton, 'exit');
        this.cancelButton.disableInteractive();

        if (
          sprite.data.values.currentCountries.some(
            (e) => e.country === this.userCountry
          ) ||
          this.checkIfNoPosition(sprite)
        ) {
          this.moveButton.setVisible(false);
          this.btnText.setVisible(false);
        }
        this.zoomStatsGroup = this.add.group();
        this.zoomStatsGroup.addMultiple([
          this.textCountries,
          this.textWeights,
          this.cancelButton,
          this.cancelButtonBoardText,
          this.btnText,
          this.moveButton,
        ]);

        if (!this.isInformational) {
          this.zoomStatsGroup.addMultiple([
            this.star,
            this.councilPoints,
            this.action,
            this.actionPoints,
            this.flag,
            this.countryPoints,
          ]);
        }

        break;
      case 4:
        this.moveButton = this.add
          .sprite(this.zoomBoard.x + 240, this.zoomBoard.y + 80, 'button')
          .setOrigin(0.5)
          .setScale(1.15, 1.55)
          .setDepth(3000)
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => this.moveMyAvatar(sprite));

        this.btnText = this.add
          .text(
            this.moveButton.x,
            this.moveButton.y + 15,
            `${context.translateService.instant(
              'negotiation_phase_move_to_another_position_button'
            )}`
          )
          .setFontSize(30)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#FFFFFF',
          })
          .setDepth(3000);
        this.action = this.add
          .sprite(this.zoomBoard.x - 80, this.zoomBoard.y + 80, 'action')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.actionPoints = this.add
          .text(this.action.x + 20, this.action.y - 10, ``)
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.flag = this.add
          .sprite(this.zoomBoard.x - 10, this.zoomBoard.y + 80, 'flag')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.countryPoints = this.add
          .text(this.flag.x + 20, this.flag.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.star = this.add
          .sprite(this.zoomBoard.x + 70, this.zoomBoard.y + 80, 'star')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.councilPoints = this.add
          .text(this.star.x + 20, this.star.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textWeights = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 50,
            `${
              sprite.data.values.currentCountries.length
            } ${context.translateService.instant(
              'negotiation_phase_move_to_another_position_left_text1'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textCountries = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 80,
            `${this.calculateWeights(
              sprite
            )}% ${context.translateService.instant(
              'dimension_overview_population'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.cancelButton = this.add
          .sprite(
            this.moveButton.x,
            this.zoomBoard.y + this.zoomBoard.displayHeight / 5.3,
            'cancelButton'
          )
          .setDepth(3000)
          .setScale(0.8, 0.8)
          .setInteractive({ useHandCursor: true });
        this.cancelButtonBoardText = this.add
          .text(
            this.cancelButton.x,
            this.cancelButton.y + 5,
            `${context.translateService.instant('mobile_cancel_button')}`
          )
          .setFontSize(26)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#003399',
          })
          .setDepth(this.cancelButton.depth + 1);
        this.addClick(this.cancelButton, 'exit');
        this.cancelButton.disableInteractive();

        if (
          sprite.data.values.currentCountries.some(
            (e) => e.country === this.userCountry
          ) ||
          this.checkIfNoPosition(sprite)
        ) {
          this.moveButton.setVisible(false);
          this.btnText.setVisible(false);
        }
        this.zoomStatsGroup = this.add.group();
        this.zoomStatsGroup.addMultiple([
          this.textCountries,
          this.textWeights,
          this.cancelButton,
          this.cancelButtonBoardText,
          this.btnText,
          this.moveButton,
        ]);

        if (!this.isInformational) {
          this.zoomStatsGroup.addMultiple([
            this.star,
            this.councilPoints,
            this.action,
            this.actionPoints,
            this.flag,
            this.countryPoints,
          ]);
        }

        break;
      case 5:
        this.moveButton = this.add
          .sprite(this.zoomBoard.x + 240, this.zoomBoard.y + 110, 'button')
          .setOrigin(0.5)
          .setScale(1.15, 1.55)
          .setDepth(3000)
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => this.moveMyAvatar(sprite));

        this.btnText = this.add
          .text(
            this.moveButton.x,
            this.moveButton.y + 15,
            `${context.translateService.instant(
              'negotiation_phase_move_to_another_position_button'
            )}`
          )
          .setFontSize(30)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#FFFFFF',
          })
          .setDepth(3000);
        this.action = this.add
          .sprite(this.zoomBoard.x - 80, this.zoomBoard.y + 110, 'action')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.actionPoints = this.add
          .text(this.action.x + 20, this.action.y - 10, ``)
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.flag = this.add
          .sprite(this.zoomBoard.x - 10, this.zoomBoard.y + 110, 'flag')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.countryPoints = this.add
          .text(this.flag.x + 20, this.flag.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.star = this.add
          .sprite(this.zoomBoard.x + 70, this.zoomBoard.y + 110, 'star')
          .setOrigin(0.5)
          .setDepth(3000)
          .setVisible(pointsVisibility);
        this.councilPoints = this.add
          .text(this.star.x + 20, this.star.y - 10, '')
          .setFontSize(22)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textWeights = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 80,
            `${
              sprite.data.values.currentCountries.length
            } ${context.translateService.instant(
              'negotiation_phase_move_to_another_position_left_text1'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.textCountries = this.add
          .text(
            this.action.x - 250,
            this.zoomBoard.y + 110,
            `${this.calculateWeights(
              sprite
            )}% ${context.translateService.instant(
              'dimension_overview_population'
            )}`
          )
          .setFontSize(22)
          .setVisible(pointsVisibility)
          .setFontFamily(context.gameFont)
          .setStyle({
            color: '#003399',
          })
          .setDepth(3000);
        this.cancelButton = this.add
          .sprite(
            this.moveButton.x,
            this.zoomBoard.y + this.zoomBoard.displayHeight / 4.3,
            'cancelButton'
          )
          .setDepth(3000)
          .setScale(0.8, 0.8)
          .setInteractive({ useHandCursor: true });
        this.cancelButtonBoardText = this.add
          .text(
            this.cancelButton.x,
            this.cancelButton.y + 5,
            `${context.translateService.instant('mobile_cancel_button')}`
          )
          .setFontSize(26)
          .setPadding(0, 0, 0, 18)
          .setFontFamily(context.gameFont)
          .setOrigin(0.5, 0.5)
          .setStyle({
            color: '#003399',
          })
          .setDepth(this.cancelButton.depth + 1);
        this.addClick(this.cancelButton, 'exit');
        this.cancelButton.disableInteractive();

        if (
          sprite.data.values.currentCountries.some(
            (e) => e.country === this.userCountry
          ) ||
          this.checkIfNoPosition(sprite)
        ) {
          this.moveButton.setVisible(false);
          this.btnText.setVisible(false);
        }
        this.zoomStatsGroup = this.add.group();
        this.zoomStatsGroup.addMultiple([
          this.textCountries,
          this.textWeights,
          this.cancelButton,
          this.cancelButtonBoardText,
          this.btnText,
          this.moveButton,
        ]);

        if (!this.isInformational) {
          this.zoomStatsGroup.addMultiple([
            this.star,
            this.councilPoints,
            this.action,
            this.actionPoints,
            this.flag,
            this.countryPoints,
          ]);
        }

        break;
    }
    if (this.btnText.width > 150) {
      this.btnText.setFontSize(22);
    }
  }

  checkIfNoPosition(sprite: Phaser.GameObjects.Sprite): any {
    return sprite.data.values.value === 0;
  }

  arrangeAvatars(dimension) {
    if (dimension === 'd1') {
      this.initAvatars();
    } else {
      setTimeout(() => {
        this.updatePositions();
      }, 550);
    }
  }

  calculateWeights(sprite) {
    let size = 0;
    sprite.data.values.currentCountries.forEach((element) => {
      size =
        +this.countries.find((item) => item.countryCode === element.country)
          .size + size;
    });
    return context.settingsService.formatDecimalNumber(size / 100);
  }

  private build(dimension?, numOfTiles?) {
    this.createScene(dimension);
    this.placeTiles(dimension);
    this.setVisibleTiles(numOfTiles);
    this.handleAdditionOfSquareLabels();
  }

  private loadAssets() {
    this.load.svg('whiteArrow', '../../assets/proposal/white-arrow.svg', {
      scale: this.assetScale * 0.9,
    });
    this.load.svg('oval', '../../assets/proposal/oval.svg', {
      scale: this.assetScale * 0.9,
    });
    this.load.svg('square', '../../assets/d1/position-2024.svg', {
      scale: this.assetScale,
    });
    this.load.svg('square-yellow', '../../assets/d1/position-2024-yellow.svg', {
      scale: this.assetScale,
    });
    this.load.svg('square-upleft', '../../assets/d1/square-upleft.svg', {
      scale: this.assetScale,
    });
    this.load.svg(
      'square-upleft-yellow',
      '../../assets/d1/square-upleft-yellow.svg',
      {
        scale: this.assetScale,
      }
    );
    this.load.svg('square-upright', '../../assets/d1/square-upright.svg', {
      scale: this.assetScale,
    });
    this.load.svg(
      'square-upright-yellow',
      '../../assets/d1/square-upright-yellow.svg',
      {
        scale: this.assetScale,
      }
    );
    this.load.svg('square-right', '../../assets/d1/square-right.svg', {
      scale: this.assetScale,
    });
    this.load.svg(
      'square-right-yellow',
      '../../assets/d1/square-right-yellow.svg',
      {
        scale: this.assetScale,
      }
    );
    this.load.svg('square-left', '../../assets/d1/square-left.svg', {
      scale: this.assetScale,
    });
    this.load.svg(
      'square-left-yellow',
      '../../assets/d1/square-left-yellow.svg',
      {
        scale: this.assetScale,
      }
    );
    this.load.svg('squarePin', '../../assets/d1/squarePin.svg', {
      scale: this.assetScale,
    });
    this.load.svg('d1', '../../../assets/d1/tab-d1.svg');
    this.load.svg('d2', '../../../assets/d1/tab-d2new.svg');
    this.load.svg('d3', '../../../assets/d1/tab-d3.svg');
    this.load.svg('stairs', '../../../assets/d1/stairs.svg');
    this.load.svg('grid', '../../../assets/d1/small-iso-grid.svg');
    this.load.svg('avatar', '../../../assets/d1/avatar10.svg');
    this.load.svg('undecided', '../../../assets/d1/position-undecided.svg', {
      scale: this.assetScale,
    });
    this.load.svg('redLinePopUp', '../../../assets/d1/redLinePopUp.svg', {
      scale: this.assetScale,
    });
    this.load.svg('genericPopUp', '../../../assets/genericPopUp.svg', {
      scale: this.assetScale,
    });
    this.load.svg('defaultButton', '../../../assets/Default.svg', {
      scale: this.assetScale,
    });
    this.load.svg('secondaryButton', '../../../assets/secondary.svg', {
      scale: this.assetScale,
    });
    this.load.svg('d2Clickable', '../../../assets/d1/D2clickable.svg', {
      scale: 1.6,
    });
    this.load.svg('d1Clickable', '../../../assets/d1/D1clickable.svg', {
      scale: 1.6,
    });
    this.load.svg('carpet', '../../../assets/magicCarpet.svg', {
      scale: this.assetScale,
    });
    this.load.svg('zoomBoard1', '../../../assets/zoomBoard1.svg', {
      scale: this.assetScale,
    });
    this.load.svg('zoomBoard2', '../../../assets/zoomBoard2.svg', {
      scale: this.assetScale,
    });
    this.load.svg('zoomBoard3', '../../../assets/zoomBoard3.svg', {
      scale: this.assetScale,
    });
    this.load.svg('zoomBoard4', '../../../assets/zoomBoard4.svg', {
      scale: this.assetScale,
    });
    this.load.svg('zoomBoard5', '../../../assets/zoomBoard5.svg', {
      scale: this.assetScale,
    });
    this.load.svg('overlay', '../../../assets/overlay.svg', {
      scale: this.assetScale,
    });
    this.load.svg('button', '../../../assets/CTA.svg', {
      scale: this.assetScale,
    });
    this.load.svg('action', '../../../assets/icon/Action/16px.svg', {});
    this.load.svg('flag', '../../../assets/icon/Action/bullet-flag.svg', {});
    this.load.svg(
      'star',
      '../../../assets/icon/Action/Icon/Empathy/16px.svg',
      {}
    );
    this.load.svg('zoomLabel', '../../../assets/zoomLabel.svg', {
      scale: this.assetScale,
    });
    this.load.svg('avatarPopup', '../../../assets/avatarPopup.svg', {
      // scale: this.assetScale,
    });
    this.load.svg('negotiateButton', '../../../assets/btnNegotiate.svg', {
      // scale: this.assetScale,
    });
    this.load.svg('peopleIcon', '../../../assets/people.svg', {
      // scale: this.assetScale,
    });
    this.load.svg('cancelButton', '../../../assets/cancelButton.svg', {
      // scale: this.assetScale,
    });
    this.load.svg('squareLabel', '../../../assets/squareLabel.svg', {
      scale: this.assetScale * 1.2,
    });
    this.load.svg(
      'redLineIndicatorZoomLabel',
      '../../../assets/d1/redLineZoomLabel.svg',
      {
        scale: this.assetScale + 0.25,
      }
    );
    this.load.svg(
      'redLineIndicatorZoomBottom',
      '../../../assets/d1/redLineZoomBottom.svg',
      {
        scale: this.assetScale + 0.25,
      }
    );
    // this.load.svg('test', `${context.avatarConstruction()}`);
    // this.load.spritesheet(
    //   'idleFlag',
    //   '../../../assets/testAnimations/texturePack-10.png',
    //   { frameWidth: 32, frameHeight: 38 }
    // );
    this.load.atlas({
      key: 'legs1',
      textureURL: '../../../assets/d1/animations-neg-phase/legs1-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs1.json',
    });
    this.load.atlas({
      key: 'legs2',
      textureURL: '../../../assets/d1/animations-neg-phase/legs2-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs2.json',
    });
    this.load.atlas({
      key: 'legs3',
      textureURL: '../../../assets/d1/animations-neg-phase/legs3-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs3.json',
    });
    this.load.atlas({
      key: 'legs4',
      textureURL: '../../../assets/d1/animations-neg-phase/legs4-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs4.json',
    });
    this.load.atlas({
      key: 'legs5',
      textureURL: '../../../assets/d1/animations-neg-phase/legs5-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs5.json',
    });
    this.load.atlas({
      key: 'legs6',
      textureURL: '../../../assets/d1/animations-neg-phase/legs6-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs6.json',
    });
    this.load.atlas({
      key: 'legs7',
      textureURL: '../../../assets/d1/animations-neg-phase/legs7-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legs7.json',
    });

    this.load.atlas({
      key: 'arms1A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms1A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms1A.json',
    });
    this.load.atlas({
      key: 'arms1C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms1C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms1C.json',
    });
    this.load.atlas({
      key: 'arms1D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms1D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms1D.json',
    });
    this.load.atlas({
      key: 'arms2A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms2A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms2A.json',
    });
    this.load.atlas({
      key: 'arms2C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms2C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms2C.json',
    });
    this.load.atlas({
      key: 'arms2D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms2D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms2D.json',
    });
    this.load.atlas({
      key: 'arms3A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms3A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms3A.json',
    });
    this.load.atlas({
      key: 'arms3C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms3C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms3C.json',
    });
    this.load.atlas({
      key: 'arms3D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms3D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms3D.json',
    });
    this.load.atlas({
      key: 'arms4A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms4A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms4A.json',
    });
    this.load.atlas({
      key: 'arms4C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms4C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms4C.json',
    });
    this.load.atlas({
      key: 'arms4D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms4D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms4D.json',
    });
    this.load.atlas({
      key: 'arms5A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms5A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms5A.json',
    });
    this.load.atlas({
      key: 'arms5C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms5C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms5C.json',
    });
    this.load.atlas({
      key: 'arms5D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms5D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms5D.json',
    });
    this.load.atlas({
      key: 'arms6A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms6A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms6A.json',
    });
    this.load.atlas({
      key: 'arms6C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms6C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms6C.json',
    });
    this.load.atlas({
      key: 'arms6D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms6D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms6D.json',
    });
    this.load.atlas({
      key: 'arms7A',
      textureURL: '../../../assets/d1/animations-neg-phase/arms7A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms7A.json',
    });
    this.load.atlas({
      key: 'arms7C',
      textureURL: '../../../assets/d1/animations-neg-phase/arms7C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms7C.json',
    });
    this.load.atlas({
      key: 'arms7D',
      textureURL: '../../../assets/d1/animations-neg-phase/arms7D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/arms7D.json',
    });

    this.load.atlas({
      key: 'legsIdle1',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle1-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle1.json',
    });
    this.load.atlas({
      key: 'legsIdle2',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle2-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle2.json',
    });
    this.load.atlas({
      key: 'legsIdle3',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle3-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle3.json',
    });
    this.load.atlas({
      key: 'legsIdle4',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle4-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle4.json',
    });
    this.load.atlas({
      key: 'legsIdle5',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle5-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle5.json',
    });
    this.load.atlas({
      key: 'legsIdle6',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle6-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle6.json',
    });
    this.load.atlas({
      key: 'legsIdle7',
      textureURL: '../../../assets/d1/animations-neg-phase/legsIdle7-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/legsIdle7.json',
    });

    this.load.atlas({
      key: 'faceIdle1',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle1-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle1.json',
    });
    this.load.atlas({
      key: 'faceIdle2',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle2-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle2.json',
    });
    this.load.atlas({
      key: 'faceIdle3',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle3-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle3.json',
    });
    this.load.atlas({
      key: 'faceIdle4',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle4-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle4.json',
    });
    this.load.atlas({
      key: 'faceIdle5',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle5-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle5.json',
    });
    this.load.atlas({
      key: 'faceIdle6',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle6-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle6.json',
    });
    this.load.atlas({
      key: 'faceIdle7',
      textureURL: '../../../assets/d1/animations-neg-phase/faceIdle7-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/faceIdle7.json',
    });

    this.load.atlas({
      key: 'armsIdle1A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle1A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle1A.json',
    });
    this.load.atlas({
      key: 'armsIdle1C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle1C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle1C.json',
    });
    this.load.atlas({
      key: 'armsIdle1D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle1D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle1D.json',
    });
    this.load.atlas({
      key: 'armsIdle2A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle2A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle2A.json',
    });
    this.load.atlas({
      key: 'armsIdle2C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle2C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle2C.json',
    });
    this.load.atlas({
      key: 'armsIdle2D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle2D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle2D.json',
    });
    this.load.atlas({
      key: 'armsIdle3A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle3A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle3A.json',
    });
    this.load.atlas({
      key: 'armsIdle3C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle3C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle3C.json',
    });
    this.load.atlas({
      key: 'armsIdle3D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle3D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle3D.json',
    });
    this.load.atlas({
      key: 'armsIdle4A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle4A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle4A.json',
    });
    this.load.atlas({
      key: 'armsIdle4C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle4C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle4C.json',
    });
    this.load.atlas({
      key: 'armsIdle4D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle4D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle4D.json',
    });
    this.load.atlas({
      key: 'armsIdle5A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle5A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle5A.json',
    });
    this.load.atlas({
      key: 'armsIdle5C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle5C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle5C.json',
    });
    this.load.atlas({
      key: 'armsIdle5D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle5D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle5D.json',
    });
    this.load.atlas({
      key: 'armsIdle6A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle6A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle6A.json',
    });
    this.load.atlas({
      key: 'armsIdle6C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle6C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle6C.json',
    });
    this.load.atlas({
      key: 'armsIdle6D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle6D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle6D.json',
    });
    this.load.atlas({
      key: 'armsIdle7A',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle7A-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle7A.json',
    });
    this.load.atlas({
      key: 'armsIdle7C',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle7C-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle7C.json',
    });
    this.load.atlas({
      key: 'armsIdle7D',
      textureURL: '../../../assets/d1/animations-neg-phase/armsIdle7D-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/armsIdle7D.json',
    });
    this.load.atlas({
      key: 'altWalking',
      textureURL: '../../../assets/d1/animations-neg-phase/altWalking-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/altWalking.json',
    });
    this.load.atlas({
      key: 'yellowGlow',
      textureURL: '../../../assets/d1/animations-neg-phase/yellowGlow-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/yellowGlow.json',
    });
    this.load.atlas({
      key: 'redGlow',
      textureURL: '../../../assets/d1/animations-neg-phase/redGlow-0.png',
      atlasURL: '../../../assets/d1/animations-neg-phase/redGlow.json',
    });
    this.load.svg(
      'myPlayerIndicator',
      '../../../assets/myPlayerIndicator.svg',
      {
        scale: this.assetScale + 0.1,
      }
    );
    this.load.svg(
      'myPlayerIndicatorArrow',
      '../../../assets/indicatorArrow.svg',
      {
        scale: this.assetScale + 0.1,
      }
    );
    this.load.svg(
      'myPlayerIndicatorBottom',
      '../../../assets/indicatorBottom.svg',
      {
        scale: this.assetScale + 0.1,
      }
    );
  }
}

// Inject Angular context and return scene
export const makeNegotiationScene = (ctx) => {
  context = ctx;
  return NegotiationScene;
};
