import { ColorTranslation, Stance, StanceTranslation } from "../models/game.model";

export enum GameMode {
  singlePlayer = 'singlePlayer',
  multiPlayer = 'multiPlayer'
}

export enum DifficultyLevel {
  novice = 1,
  intermediate = 2,
  expert = 3
}

export const getDifficultyLevel = (difficulty: string) => {
  if (difficulty === 'Novice') {
    return DifficultyLevel.novice;
  } else if (difficulty === 'Intermediate') {
    return DifficultyLevel.intermediate;
  } else if (difficulty === 'Expert') {
    return DifficultyLevel.expert;
  } else {
    return difficulty;
  }
};

export const getDifficultyLevelById = (difficulty: DifficultyLevel) => {
  if (difficulty === DifficultyLevel.novice) {
    return 'Novice';
  } else if (difficulty === DifficultyLevel.intermediate) {
    return 'Intermediate';
  } else if (difficulty === DifficultyLevel.expert) {
    return 'Expert';
  } else {
    return difficulty;
  }
};

export const getStanceTranslationKey = (stance: string) => {
  if (stance === Stance.redLine) {
    return StanceTranslation.redLine;
  } else if (stance === Stance.negotiable) {
    return StanceTranslation.negotiable;
  } else if (stance === Stance.sensitive) {
    return StanceTranslation.sensitive;
  } else {
    return stance;
  }
};

export const getColorTranslationKey = (color: string) => {
  if (color === 'white') {
    return ColorTranslation.white;
  } else if (color === 'yellow') {
    return ColorTranslation.yellow;
  } else if (color === 'green') {
    return ColorTranslation.green;
  } else if (color === 'blue') {
    return ColorTranslation.blue;
  } else if (color === 'red') {
    return ColorTranslation.red;
  } else if (color === 'black') {
    return ColorTranslation.black;
  } else {
    return color;
  }
};

export const checkUrlInGame = () => ![
    '/',
    '/splash_screen',
    '/launch_screen',
    '/game-starting',
    '/select_mode',
    '/single_player',
    '/multiplayer_mode',
    '/multi_player',
    '/multiplayer-join',
  ].includes(window.location.pathname);
