// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-main-style {
  position: absolute;
  bottom: 1.917rem;
  right: 0.92rem;
}
.buttons-main-style .buttons-style {
  display: inline-block;
}
.buttons-main-style .buttons-style.lang {
  align-self: center;
}
.buttons-main-style .buttons-style .lang-box {
  margin-right: 1rem;
  --background: #CFE7ED;
  background: #CFE7ED;
  --border-radius: 1rem;
  border: 0.1rem solid var(--color-primary-blue);
  border-radius: 1rem;
  color: var(--color-primary-blue);
  font-size: 1rem;
  width: auto;
  height: 2.667rem;
  display: inline-grid;
  align-content: center;
}
.buttons-main-style .buttons-style .lang-box ion-select {
  width: 100%;
}
.buttons-main-style .buttons-style .lang-box ion-select::part(content) {
  width: 100%;
}
.buttons-main-style .buttons-style .lang-box ion-select ::ng-deep select-wrapper-inner {
  width: 100%;
}
.buttons-main-style .buttons-style .menu-buttons {
  margin-right: 1.333rem;
  cursor: pointer;
  width: 2.75rem;
  height: 2.667rem;
}
.buttons-main-style .buttons-style .ion-mute {
  font-size: 1.633rem;
  position: absolute;
  top: 0.533rem;
  left: 0.633rem;
  color: var(--color-primary-blue);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/bottom-settings-buttons/bottom-settings-buttons.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;AACF;AACE;EACE,qBAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,kBAAA;EACA,qBAAA;EACA,mBAAA;EACA,qBAAA;EACA,8CAAA;EACA,mBAAA;EACA,gCAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA;AAAN;AAEM;EACE,WAAA;AAAR;AAGM;EACE,WAAA;AADR;AAIM;EACE,WAAA;AAFR;AAMI;EACE,sBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAJN;AAOI;EACE,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,gCAAA;EACA,eAAA;AALN","sourcesContent":[".buttons-main-style {\n  position: absolute;\n  bottom: 1.917rem;\n  right: 0.92rem;\n\n  .buttons-style {\n    display: inline-block;\n\n    &.lang {\n      align-self: center;\n    }\n\n    .lang-box {\n      margin-right: 1rem;\n      --background: #CFE7ED;\n      background: #CFE7ED;\n      --border-radius: 1rem;\n      border: 0.1rem solid var(--color-primary-blue);\n      border-radius: 1rem;\n      color: var(--color-primary-blue);\n      font-size: 1rem;\n      width: auto;\n      height: 2.667rem;\n      display: inline-grid;\n      align-content: center;\n\n      ion-select {\n        width: 100%;\n      }\n\n      ion-select::part(content) {\n        width: 100%;\n      }\n\n      ion-select ::ng-deep select-wrapper-inner{\n        width: 100%;\n      }\n    }\n\n    .menu-buttons {\n      margin-right: 1.333rem;\n      cursor: pointer;\n      width: 2.75rem;\n      height: 2.667rem;\n    }\n\n    .ion-mute {\n      font-size: 1.633rem;\n      position: absolute;\n      top: 0.533rem;\n      left: 0.633rem;\n      color: var(--color-primary-blue);\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
