import * as Phaser from 'phaser';

let context: any;

export class EmptyScene extends Phaser.Scene {
  static key = 'empty-scene';

  constructor() {
    super({
      key: EmptyScene.key,
    });
  }

  public create() {
  }

  public preload() {
  }
}

export const makeEmptyScene = (ctx) => {
  context = ctx;
  return EmptyScene;
};
