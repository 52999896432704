import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserQuery } from 'src/app/state/userState/user.query';
import { UserService } from 'src/app/state/userState/user.service';
import { languages } from "../../shared/settings.utils";
import { ILanguage } from "../../models/user.model";
import { SettingsService } from "../../services/settings.service";

@UntilDestroy()
@Component({
  selector: 'app-bottom-settings-buttons',
  templateUrl: './bottom-settings-buttons.component.html',
  styleUrls: ['./bottom-settings-buttons.component.scss'],
})
export class BottomSettingsButtonsComponent implements OnInit {
  @Input() flag;
  @Input() showLang = false;
  mute: boolean;
  selectedLang: string;
  languages = languages;

  constructor(
    private userService: UserService,
    private userQuery: UserQuery,
    private settingsService: SettingsService,
    private router: Router) {
  }

  ngOnInit() {
    this.userQuery.settings$.pipe(untilDestroyed(this)).subscribe(settings => {
      this.mute = settings.mute;
    });

    this.userQuery.settings$.pipe(untilDestroyed(this)).subscribe(lang => {
      this.selectedLang = lang.language;
    });
  }

  ionChangeLanguage(event: any) {
    const paramLang = languages.find((language: ILanguage) => {
      return language.code === event.detail.value;
    });

    if (paramLang) {
      this.settingsService.switchLang(paramLang);
    }
  }

  switchMute() {
    this.userService.switchMute();
  }

  onSettings() {
    this.userService.updateRouterUrl(this.router.url);
    this.router.navigate(['/game_settings']);
  }

  onAchievements() {
    this.router.navigate(['/achievements']);
  }
}
