import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NegotiationPhasePage } from './game/negotiation_phase/negotiation-phase.page';
import { TestPage } from './game/test/test.page';
import { LaunchScreenPage } from './game/launch-screen/launch-screen.page';
import { SelectModePage } from './game/select-mode/select-mode.page';
import { SinglePlayerPage } from './game/single-player-menu/single-player-menu.page';
import { CustomizeAvatarPage } from './game/customize-avatar/customize-avatar.page';
import { PresentationProposalPage } from './game/presentation-proposal/presentation-proposal.page';
import { SplashScreenPage } from './splash-screen/splash-screen.page';
import { PresentationOfMissionPage } from './game/presentation-of-mission/presentation-of-mission.page';
import { ProposalPage } from './game/presentation-proposal/proposal/proposal.page';
import { GameStartingPage } from './game/game-starting/game-starting.page';
import { AchievementsPage } from './game/achievements/achievements.page';
import { GameSettingsPage } from './game/game-settings/game-settings.page';
import { BookPageComponent } from './game/book-page/book-page.component';
import { QuizGamePage } from './game/quiz-game/quiz-game.page';
import { OverviewPage } from './game/overview/overview.page';
import { BilateralDiscussionPage } from './game/bilateral-discussion/bilateral-discussion.page';
import { GameMode } from './shared/game.utils';
import { CoffeeBreakPage } from './game/coffee-break/coffee-break.page';
import { MultiplayerRoomPage } from './game/multiplayer-room/multiplayer-room.page';
import { MultiplayerJoinPage } from './game/multiplayer-join/multiplayer-join.page';
import { GameResultsPage } from './game/game-results/game-results.page';
import { MultiplayerGameResultsPage } from './game/multiplayer-game-results/multiplayer-game-results.page';
import { AssignCountriesPage } from './game/assign-countries/assign-countries.page';
import { DirectAccessGuard } from './services/direct-access-guard.service';
import { DownloadPage } from './game/download/download.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'launch_screen',
    pathMatch: 'full',
  },
  {
    path: 'splash_screen',
    component: SplashScreenPage,
  },
  {
    path: 'launch_screen',
    component: LaunchScreenPage,
    // canActivate: [DirectAccessGuard]
  },
  {
    path: 'achievements',
    component: AchievementsPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'game_settings',
    component: GameSettingsPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'select_mode',
    component: SelectModePage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'multiplayer_mode',
    component: SelectModePage,
    data: { mode: GameMode.multiPlayer },
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'single_player',
    component: SinglePlayerPage,
    data: { mode: GameMode.singlePlayer },
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'multi_player',
    component: SinglePlayerPage,
    data: { mode: GameMode.multiPlayer },
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'customize_avatar',
    component: CustomizeAvatarPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'presentation_proposal',
    component: PresentationProposalPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'negotiation_phase',
    component: NegotiationPhasePage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'test_page',
    component: TestPage,
  },
  {
    path: 'mission',
    component: PresentationOfMissionPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'multiplayer-room',
    component: MultiplayerRoomPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'multiplayer-join',
    component: MultiplayerJoinPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'assign-countries',
    component: AssignCountriesPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'proposal',
    component: ProposalPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'book',
    component: BookPageComponent,
    data: { help: false },
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'help',
    component: BookPageComponent,
    data: { help: true },
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'quiz-game',
    component: QuizGamePage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'overview',
    component: OverviewPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'bilateral-discussion',
    component: BilateralDiscussionPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'coffee-break',
    component: CoffeeBreakPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'game_results',
    component: GameResultsPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'multiplayer_game_results',
    component: MultiplayerGameResultsPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'game-starting',
    component: GameStartingPage,
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'download',
    component: DownloadPage,
    canActivate: [DirectAccessGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
