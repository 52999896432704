import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GameQuery } from 'src/app/state/gameState/game.query';
import { GameService } from 'src/app/state/gameState/game.service';
import { ErrorMessages } from 'src/app/models/game.model';
import { GameInstanceService } from 'src/app/services/game-instance.service';
import { TimerService } from 'src/app/services/timer.service';
import { AudioService } from 'src/app/services/audio.service';
import { GameStore } from 'src/app/state/gameState/game.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrackingService } from 'src/app/services/tracking.service';
import { UserQuery } from 'src/app/state/userState/user.query';
import { Country } from 'src/app/models/country.model';
import { CountriesQuery } from 'src/app/state/countriesState/countries.query';

@UntilDestroy()
@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  public errorMessages = ErrorMessages;
  public errorMessage: ErrorMessages;
  public countryName: string;
  private quitTimeout;
  constructor(
    private router: Router,
    private gameQuery: GameQuery,
    private gameStore: GameStore,
    private gameService: GameService,
    public gameInstanceService: GameInstanceService,
    private trackingService: TrackingService,
    private timerService: TimerService,
    private audioService: AudioService,
    private userQuery: UserQuery,
    private countryQuery: CountriesQuery
  ) { }

  ngOnInit() {
    this.gameQuery.select('errorMessage').pipe(untilDestroyed(this)).subscribe((msg) => {
      if (this.errorMessage !== msg) {
        this.onFinalVote();
        this.onNegotiationStarted();
        if (msg) {
          this.audioService.play('modal-open');
        }
        clearTimeout(this.quitTimeout);
        if (msg === ErrorMessages.errorUserTimeout) {
          this.quitTimeout = setTimeout(() => {
            this.onQuit();
          }, 10000);
        } else if (msg === ErrorMessages.timeToBreak) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.bilateralBreakStarts) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 5000);
        } else if (msg === ErrorMessages.finalVote) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 15000);
        } else if (msg === ErrorMessages.timeToNegotiation) {
          setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.bilateralNegotiationStarts) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 5000);
        } else if (msg === ErrorMessages.talkToAnExpertAlreadyPlayed) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.gameCodeCopied) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.negotiationStarted) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.breakTaskEmojiInstructions) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.breakTaskEmojiInstructionsMultiplayer) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        }
        else if (msg === ErrorMessages.breakTaskDrinkCoffeeInstructions) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        } else if (msg === ErrorMessages.errorPermanentMessage) {
          this.quitTimeout = setTimeout(() => {
            this.onQuit();
          }, 10000);
        } else if (msg === ErrorMessages.endBreakMessage) {
          this.quitTimeout = setTimeout(() => {
            this.onEndBreakCancel();
          }, 10000);
        } else if (msg === ErrorMessages.endBreakMessageMultiVote || msg === ErrorMessages.endBreakMessageMultiVoteBilateral) {
          this.quitTimeout = setTimeout(() => {
            this.onEndBreakCancelMultiplayer();
          }, 10000);
        } else if (msg === ErrorMessages.playersDecidedToContinueBreak) {
          this.quitTimeout = setTimeout(() => {
            this.onOk();
          }, 10000);
        }
      }
      this.errorMessage = (msg as ErrorMessages) ?? undefined;
      const countryCode = this.gameQuery.getCountryCodeOfErrorMessage();
      this.countryName = this.countryQuery.selectCountryByCountryCode(countryCode);
    });
  }

  onFinalVote() {
    if (this.errorMessage === ErrorMessages.finalVote) {
      this.router.navigate(['/presentation_proposal']);
    }
  }

  onNegotiationStarted() {
    if (this.errorMessage === ErrorMessages.negotiationStarted) {
      this.router.navigate(['/negotiation_phase']);
    }
  }

  onOk() {
    clearTimeout(this.quitTimeout);
    this.gameService.updateErrorMessage('');
    this.onFinalVote();
    this.onNegotiationStarted();
  }

  onQuit() {
    clearTimeout(this.quitTimeout);
    this.trackingService.click({
      name: 'QuitGame',
      type: 'event'
    });
    this.gameService.updateErrorMessage('');
    this.timerService.pauseTimer();
    this.audioService.play('backgroundSample2');
    this.gameInstanceService.loading.next(true);
    this.gameService
      .gameQuit()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.gameInstanceService.resetGameState();
        this.gameInstanceService.loading.next(false);
        this.router.navigate(['/select_mode']);
      });
  }

  onHostQuit() {
    this.gameService.updateErrorMessage('');
    this.gameStore.update({ game: null });
    this.audioService.play('backgroundSample2');
    this.router.navigate(['/select_mode']);
  }

  preventClick(e: any) {
    e.stopPropagation();
  }

  onEndBreakConfirm() {
    this.gameService.updateErrorMessage('');
    const userGames = this.gameQuery.getUserGames();
    const userId = this.userQuery.getUserId();
    const userGame = userGames?.find((ug) => ug.userId === userId);
    const countries = this.countryQuery.getCountries();
    const userCountry = countries?.find((country: Country) => country.countryCode === userGame.country);
    this.gameService.endBreakMessage({ endBreak: true, countryCode: userCountry.countryCode });
    if (this.gameQuery.isHost() && this.gameQuery.getGameMode() === 1) {
      this.gameInstanceService.loading.next(true);
      this.gameService.statusContinue()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.gameInstanceService.loading.next(false);
      });
    } else {
      this.gameService.endBreakVote(true);
    }
  }

  onEndBreakCancel() {
    clearTimeout(this.quitTimeout);
    this.gameService.updateErrorMessage('');
  }

  onEndBreakConfirmMultiplayer() {
    this.gameService.updateErrorMessage('');
    this.gameService.endBreakVote(true);
  }

  onEndBreakCancelMultiplayer() {
    clearTimeout(this.quitTimeout);
    this.gameService.updateErrorMessage('');
    this.gameService.endBreakVote(false);
  }
}
