// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer {
  position: absolute;
  left: 1.792rem;
  top: 1rem;
  display: flex;
  align-items: center;
  z-index: 2;
  margin-top: 0.1rem;
}
.timer.voting {
  right: 6.792rem;
  top: 1.3rem;
  left: unset;
}
.timer .timer-button {
  width: 1.833rem;
  height: 1.833rem;
}
.timer .minutes {
  color: var(--color-white);
  font-size: 1rem;
  line-height: 1.375rem;
  margin-left: 0.5rem;
  padding-right: 1rem;
  font-weight: bold;
}

.check-here {
  position: absolute;
  top: 1.6rem;
  left: 1rem;
  padding: 1rem;
}
.check-here .text {
  color: white;
  width: 10.708rem;
  font-size: 0.833rem;
  line-height: 1rem;
  text-align: center;
  position: absolute;
  right: -14rem;
  top: 1.5rem;
}
.check-here .white-arrow .white-arrow-icon {
  position: absolute;
  left: 3rem;
  width: 2rem;
  height: 3.333rem;
  transform: rotateX(175deg) rotate(95deg);
  --animate-duration: 1s;
  top: 1rem;
}

.oval {
  position: absolute;
  top: 0.43rem;
  left: 1.196rem;
  z-index: 15;
  cursor: pointer;
}
.oval img {
  width: 3rem;
  height: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/timer/timer.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,WAAA;EACA,WAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,aAAA;AAFF;AAIE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;AAFJ;AAOI;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,gBAAA;EACA,wCAAA;EACA,sBAAA;EACA,SAAA;AALN;;AAWA;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;AARF;AASE;EACE,WAAA;EACA,YAAA;AAPJ","sourcesContent":[".timer {\n  position: absolute;\n  left: 1.792rem;\n  top: 1rem;\n  display: flex;\n  align-items: center;\n  z-index: 2;\n  margin-top: 0.1rem;\n\n  &.voting {\n    right: 6.792rem;\n    top: 1.3rem;\n    left: unset;\n  }\n\n  .timer-button {\n    width: 1.833rem;\n    height: 1.833rem;\n  }\n\n  .minutes {\n    color: var(--color-white);\n    font-size: 1rem;\n    line-height: 1.375rem;\n    margin-left: 0.5rem;\n    padding-right: 1rem;\n    font-weight: bold;\n  }\n}\n\n.check-here {\n  position: absolute;\n  top: 1.6rem;\n  left: 1rem;\n  padding: 1rem;\n\n  .text {\n    color: white;\n    width: 10.708rem;\n    font-size: 0.833rem;\n    line-height: 1rem;\n    text-align: center;\n    position: absolute;\n    right: -14rem;\n    top: 1.5rem;\n  }\n\n  .white-arrow {\n\n    .white-arrow-icon {\n      position: absolute;\n      left: 3rem;\n      width: 2rem;\n      height: 3.333rem;\n      transform: rotateX(175deg) rotate(95deg);\n      --animate-duration: 1s;\n      top: 1rem;\n    }\n\n  }\n}\n\n.oval {\n  position: absolute;\n  top: 0.43rem;\n  left: 1.196rem;\n  z-index: 15;\n  cursor: pointer;\n  img {\n    width: 3rem;\n    height: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
